import React, { useEffect, useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { GET_RETURN_LIST } from "../../config/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import processIcon from "../../assets/img/stepper/process-1.png";
import transitIcon from "../../assets/img/stepper/delivery.png";
import outDeliveryIcon1 from "../../assets/img/stepper/out-delivery-1.png";
import deliveredIcon1 from "../../assets/img/stepper/delivered-1.png";
import pendingIcon1 from "../../assets/img/stepper/pending-1.png";
import completedIcon1 from "../../assets/img/stepper/completed-1.png";
import onHoldIcon1 from "../../assets/img/stepper/onhold-1.png";
import returnIcon from "../../assets/img/stepper/return.png";
import delayIcon1 from "../../assets/img/stepper/delayed-1.png";
import canceledIcon1 from "../../assets/img/stepper/cancelled-1.png";
import SelectLabel from "../Layout/selectlabel";
import { GET_RETURN_TRACKING, UPDATE_RETURN_STATUS } from "../../config/config";
import { Pagination } from "@mui/material";
import { Modal, PaginationItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import removeIcon from "../../assets/img/cancel.png";
import "../../Styles/customerdetails.css";
import "../../Styles/products.css";
import Textarea from "@mui/joy/Textarea";
import "../../Styles/shipment.css";
import viewStatus from "../../assets/img/stepper/status.png";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

let returnStatusDescription = [
  {
    status: "DRAFT",
    description:
      "The shipment request has been received but not yet processed or scheduled for shipment.",
  },
  {
    status: "Processing",
    description:
      "The shipment is being prepared, items are being picked, packed, and labeled for shipping.",
  },
  {
    status: "In Transit",
    description: "The shipment is on its way to the customer's destination.",
  },
  {
    status: "Out for Delivery",
    description:
      "The shipment has reached the local delivery center and is on its way to the customer's address.",
  },
  {
    status: "Delivered",
    description:
      "The shipment has been successfully delivered to the customer.",
  },
  {
    status: "Delayed",
    description:
      "There is a delay in the shipment due to unforeseen circumstances such as weather conditions, logistical issues, etc.",
  },
  {
    status: "On Hold",
    description:
      "The shipment is temporarily on hold due to customer request, payment issues, or other reasons.",
  },
  {
    status: "Cancelled",
    description:
      "The shipment has been cancelled either by the customer or the seller.",
  },
  {
    status: "Returned",
    description:
      "The shipment has been returned by the customer for any reason.",
  },
  {
    status: "Completed",
    description:
      "The entire shipment process, including delivery and any necessary follow-up actions, has been completed successfully.",
  },
];

export default function ReturnItems() {
  const navigate = useNavigate();
  const shipmentListRef = React.useRef();
  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "shipment_table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;

    html2canvas(shipmentListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("shipment-list.pdf");
      }
    );
  };



  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;
  const token = localStorage.getItem("token");
  const [returnList, setReturnList] = useState([]);
  const [returnStatus, setReturnStatus] = useState(false);
  const [returnId, setReturnId] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [updatedStatusDescription, setupdatedStatusDescription] = useState("");
  const [personId, setPersonId] = useState("");
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [returnTrackingModalStatus, setReturnTrackingModalStatus] =
    useState(false);
  const [returnDisplayId, setreturnDisplayId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const openModalToTrackStatusFun = (returnList) => {
    console.log(returnList);
    setReturnTrackingModalStatus(true);
    setreturnDisplayId(returnList.return_display_id);
  };

  const displayTooltipFun = (sts) => {
    console.log(sts);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = returnList.slice(indexOfFirstItem, indexOfLastItem);

  const handleReturnTrackClose = () => {
    setReturnTrackingModalStatus(false);
  };
  const [trackingStatus, settrackingStatus] = useState([]);
  const getTrackStatusFun = async (val) => {
    await fetch(GET_RETURN_TRACKING, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        return_id: val,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          settrackingStatus(data.data.return_tracking_details);
        }
      });
  };

  const UpdateReturnStatus = async () => {
    await fetch(UPDATE_RETURN_STATUS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        return_id: returnId,
        status: updatedStatus,
        details: updatedStatusDescription,
        status_change_by: personId, //person id
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setUpdateAlert(true);
          setUpdateAlertMsg(data.message);
          setSnackSeverity("success");
          returnProductList();
          setReturnStatus(false);
        } else {
          setUpdateAlert(true);
          setUpdateAlertMsg(data.message);
          setSnackSeverity("error");
        }
      });
  };

  const handleViewReturnDetails = (returnId) => {
    console.log(returnId);
    navigate("/return-products-details", {
      state: {
        return_id: returnId,
        person_id: "",
      },
    });
  };

  const returnProductList = async () => {
    await fetch(GET_RETURN_LIST, {
      method: "POST",
      mode: "cors",

      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setReturnList(data.data.return_list);
        }
      });
  };
  useEffect(() => {
    returnProductList();
  }, []);

  const handleClose = () => {
    setReturnStatus(false);
  };

  useEffect(() => {
    const selectedStatus = returnStatusDescription.find(
      (item) => item.status === updatedStatus
    );
    console.log(selectedStatus);
    if (updatedStatus) {
      setupdatedStatusDescription(selectedStatus.description);
    }
  }, [updatedStatus]);

  const userOrg = JSON.parse(localStorage.getItem("user_org"));
  console.log(userOrg.person_id);

  const handleUpdateStatus = (returnDetails) => {
    console.log(returnDetails);
    setPersonId(userOrg.person_id);
    setReturnId(returnDetails.return_id);
    setReturnStatus(true);
  };

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  return (
    <div>
      <Snackbar
        open={updateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity={snackseverity}
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          {updateAlertMsg}
        </Alert>
      </Snackbar>
      <Modal
        open={returnStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setReturnStatus(false);
              }}
            />
            <Box sx={{ padding: "32px !important" }} className="">
              <h2 className="common_font product-list-update-add-stock-header">
                Update Status
              </h2>
              <Box className="product_details_specification_modal_box">
                <FormControl
                  sx={{ width: "350px !important", marginBottom: "30px" }}
                  id="add_pen_brand_formControl"
                  className="customer_details_add_items_form_control"
                >
                  <InputLabel className="common_font" id="brands-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="brands-label"
                    id="shipped-status"
                    label="Status"
                    value={updatedStatus}
                    onChange={(e) => setUpdatedStatus(e.target.value)}
                    InputLabelProps={{
                      className: "common_font",
                    }}
                    inputProps={{
                      className: "common_font",
                    }}
                  >
                    {returnStatusDescription.map((sta, index) => {
                      return (
                        <MenuItem
                          className="common_font"
                          value={sta.status}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          {sta.status}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Textarea
                className="common_font"
                sx={{ height: "100px" }}
                placeholder="Description"
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
                id="status-description"
                value={updatedStatusDescription}
                onChange={(e) => {
                  setupdatedStatusDescription(e.target.value);
                }}
              />

              <Button
                id="shipped-status-update-button"
                onClick={UpdateReturnStatus}
                sx={{ width: "100px", marginBottom: "10px", marginTop: "25px" }}
                className="common-button common_font"
              >
                Update
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
      <Modal
        open={returnTrackingModalStatus}
        onClose={handleReturnTrackClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setReturnTrackingModalStatus(false);
              }}
            />

            <Box sx={{ padding: "32px !important" }}>
              <h2 className="common_font product-list-view-stock-details-modal-header">
                Track Shipment
              </h2>

              <Box className="product-list-modal-sku-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo">
                  Return ID
                </Typography>
                <Typography
                  sx={{ width: "120px !important" }}
                  className="common_font product-list-modal-sku-box-txt"
                >
                  {returnDisplayId}
                </Typography>
              </Box>

              <Box
                sx={{ marginTop: "20px" }}
                className="product-list-view-stock-details-modal-icon-box"
              >
                <Box className="shipment-tracking-status-box shipment-tracking-status-box-modal-content">
                  {trackingStatus.map((sts, index) => {
                    console.log(sts);
                    return (
                      <Box key={index}>
                        <Box>
                          <Tooltip title={displayTooltipFun(sts)}>
                            <div
                              style={{ height: "20px" }}
                              className="all_box_hover common-stepper-icon-container"
                            >
                              {sts.status === "Processing" ? (
                                <img
                                  src={processIcon}
                                  className="common-stepper-img"
                                  alt="process-icon"
                                />
                              ) : sts.status === "In Transit" ? (
                                <img
                                  src={transitIcon}
                                  className="common-stepper-transit-img"
                                  alt="transit-icon"
                                />
                              ) : sts.status === "Out for Delivery" ? (
                                <img
                                  src={outDeliveryIcon1}
                                  className="common-stepper-out-delivery-img"
                                  alt="delivery-icon"
                                />
                              ) : sts.status === "Delivered" ? (
                                <img
                                  src={deliveredIcon1}
                                  className="common-stepper-out-delivery-img"
                                  alt="delivered-icon"
                                />
                              ) : sts.status === "DRAFT" ? (
                                <img
                                  src={pendingIcon1}
                                  className="common-stepper-img"
                                  alt="pending-icon"
                                />
                              ) : sts.status === "Completed" ? (
                                <img
                                  src={completedIcon1}
                                  className="common-stepper-img"
                                  alt="complete-icon"
                                />
                              ) : sts.status === "On Hold" ? (
                                <img
                                  src={onHoldIcon1}
                                  className="common-stepper-img"
                                  alt="on-hold-icon"
                                />
                              ) : sts.status === "Returned" ? (
                                <img
                                  src={returnIcon}
                                  className="common-stepper-return-img"
                                  alt="return-icon"
                                />
                              ) : sts.status === "Delayed" ? (
                                <img
                                  src={delayIcon1}
                                  className="common-stepper-delay-img"
                                  alt="delay-icon"
                                />
                              ) : sts.status === "Cancelled" ? (
                                <img
                                  src={canceledIcon1}
                                  className="common-stepper-img"
                                  alt="delay-icon"
                                />
                              ) : null}
                              {index !== trackingStatus.length - 1 && (
                                <div className="common-stepper-line"></div>
                              )}
                            </div>
                            {index !== trackingStatus.length - 1 && (
                              <div className="common-stepper-space"></div>
                            )}
                          </Tooltip>
                        </Box>

                        <Typography
                          className="small-txt"
                          sx={{ marginTop: "20px", width: "100px" }}
                        >
                          {sts.status}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(currentItems);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        {/* <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Logs Info"
            className="common_font"
            inputProps={{ "aria-label": "Search Logs Info" }}
            onChange={handleSearchChange}
            value={textContent}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <SelectLabel length={returnList.length} onSendData={handleChildData} />
      </div>

      <Paper className="paper common_card_bs">
        <Box className="sales-order-list-export-box">
          <Button
            sx={{ padding: "0px !important" }}
            className="common_font"
            onClick={handleExportClick}
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        </Box>

        <TableContainer ref={shipmentListRef} className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead className="table-head">
              <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Return ID
                </TableCell>

                <TableCell className="common_font log_table_table_head head-table-cell">
                  Customer Name
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Return Reason
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Return Date
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Status
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Track Status
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  View Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="">
              {currentItems &&
                currentItems.map((rl, index) => {
                  console.log(rl);
                  const formattedDate = moment(rl.return_date).format(
                    "DD-MMM-YYYY HH:mm"
                  );
                  return (
                    <TableRow key={index}
                      className={index % 2 === 0 ? 'table-row-even product-list-alternate-table all_box_hover' : 'table-row-even all_box_hover'}
                    // className="table-row-even"
                    >
                      <TableCell className="body-table-cell common_font">
                        {rl.return_display_id}
                      </TableCell>

                      <TableCell className="body-table-cell common_font">
                        {rl.customer_name}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {rl.return_reason}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {formattedDate}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <Typography
                          id="status-change-typo"
                          sx={{
                            display: "flex",
                            width: "100px",
                            justifyContent: "space-around",
                            backgroundColor: "#EBEBEB !important",
                          }}
                          onClick={() => handleUpdateStatus(rl)}
                          className="sales-order-list-status-txt common_font small-txt all_box_hover"
                        >
                          <CreateIcon sx={{ fontSize: "18px" }} />
                          {rl.status}
                        </Typography>
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <img
                          id="track-status-img"
                          src={viewStatus}
                          className="common-stepper-img all_box_hover"
                          alt="viewStatus"
                          onClick={() => {
                            openModalToTrackStatusFun(rl);
                            getTrackStatusFun(rl.return_id);
                          }}
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <Typography
                          className="common_font"
                          onClick={() => handleViewReturnDetails(rl.return_id)}
                        >
                          <VisibilityIcon fontSize="medium" />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box className="pagination-box">
        <Pagination
          id="shipment-list-pagination"
          className="pagination common_font"
          count={Math.ceil(returnList.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          siblingCount={1}
          boundaryCount={1}
        // renderItem={(item) => {
        //   const isArrowButton =
        //     item.type === "previous" || item.type === "next";
        //   return (
        //     <PaginationItem
        //       component={IconButton}
        //       {...item}
        //       disabled={
        //         isArrowButton && item.type === "previous" && currentPage === 1
        //       }
        //       id={isArrowButton ? "shipment-list-pagination" : undefined}
        //       icon={
        //         isArrowButton
        //           ? item.type === "previous"
        //             ? "left"
        //             : "right"
        //           : null
        //       }
        //     />
        //   );
        // }}
        />
      </Box>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { GET_USER_ORG } from "../../config/config";
import { Grid, Typography, CardContent, Chip } from "@mui/material";
import "../../Styles/companyDetails.css";
import ViewAllUsers from "../../components/admin/ViewAllUsers";

const CompanyDetails = () => {
  const [org_details, setOrgDetails] = useState([]);

  useEffect(() => {
    getUserOrg();
  }, []);
  const token = localStorage.getItem("token");

  const getUserOrg = async () => {
    await fetch(GET_USER_ORG, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data);
          setOrgDetails(data.data.user_org_details[0]);
        }
      });
  };

  console.log(org_details);

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Card className="sitecard ">
            <CardContent>
              <Typography className="cards common_font">Site</Typography>
              <Typography>
                <Chip
                  label={org_details.site_id}
                  sx={{ background: "#5eaeec", color: "#ffffff" }}
                  className="Display_id common_font"
                />
              </Typography>
              <Typography className="Display_site_name common_font">
                {org_details.site_name}
              </Typography>
              <Typography className="Display_description common_font">
                <b className="common_font">Description:</b>
                {org_details.site_description}
              </Typography>
              <Typography className="Display_description common_font">
                <b className="common_font">Remark:</b> {org_details.remark ? org_details.remark : "NA"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          <Card className="sitelocation">
            <CardContent>
              <Typography className="cards common_font">Location</Typography>
              <Typography>
                <Chip
                  label={org_details.location_id}
                  sx={{ background: "#feab13", color: "#ffffff" }}
                  className="Display_id common_font"
                />
              </Typography>
              <Typography className="Display_location_name common_font">
                {org_details.location_name}
              </Typography>
              <Typography className="Display_description common_font">
                <b className="common_font">Address:</b> {org_details.location_address}
              </Typography>
              <Typography className="Display_description common_font">
                <b className="common_font">Details:</b> {org_details.other_details}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        md={9}
        sm={12}
        sx={{ width: "100%" }}
        marginTop={5}
        marginBottom={5}
      >
        <ViewAllUsers />
      </Grid>
    </>
  );
};

export default CompanyDetails;

import React, { useEffect, useState } from "react";
import "../../Styles/customers.css";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_LIST } from "../../config/config.js";
import { useTranslation } from "react-i18next";

export default function ListOfCustomers() {

  const { t, i18n } = useTranslation()

  console.log(i18n.language)
  const [lng, setLng] = useState(i18n.language)

  const { heading } = t("customer-list");
  console.log(heading);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [customerList, setCustomerList] = useState([]);

  const getCustomerListFun = async () => {
    await fetch(CUSTOMER_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setCustomerList(data.data.Customer_list);
        }
      });
  };

  useEffect(() => {
    getCustomerListFun();
  }, []);

  const getCustomerDetails = async (cl) => {
    navigate(`/${lng}/dashboard/customer-list/customer-details`, { state: { cl: cl } });
  };

  return (
    <div id="list_of_customer_main_div" className="list_of_customer_main_div">
      <Card
        id="list_of_customer_card"
        className="list_of_customer_card common_card_bs"
      >
        <CardContent>
          <Box
            id="list_of_customer_customer_main_box"
            className="list_of_customer_customer_main_box"
          >
            <Typography
              id="list_of_customer_header_typography"
              className="list_of_customer_header_typography common_font"
            >
              {/* Top 5 Customers */}
              {heading}
            </Typography>

            {customerList.slice(0, 5).map((cl, index) => (
              <Box
                key={index}
                id={`list_of_customer_customer_sub_box_${index}`}
                className="list_of_customer_customer_sub_box"
                onClick={() => {
                  getCustomerDetails(cl);
                }}
              >
                <Typography
                  id={`list_of_customer_typography_${index}`}
                  className="list_of_customer_typography common_font"
                >
                  {cl.first_name} {cl.last_name}
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

// Author: sonal


import React, { useRef, useState, useEffect } from 'react'
import first from '../../assets/img/carousel/11.jpg'
import second from '../../assets/img/carousel/12.jpg'
import third from '../../assets/img/carousel/13.jpg'
import forth from '../../assets/img/carousel/14.jpg'
import fifth from '../../assets/img/carousel/15.jpg'
import six from '../../assets/img/carousel/16.jpg'
import seven from '../../assets/img/carousel/7.jpg'
import eight from '../../assets/img/carousel/8.jpg'
import nine from '../../assets/img/carousel/9.jpg'

import tenth from '../../assets/img/carousel/1.jpg'
import eleven from '../../assets/img/carousel/2.jpg'
import twelve from '../../assets/img/carousel/3.jpg'
import thirteen from '../../assets/img/carousel/4.jpg'
import forteen from '../../assets/img/carousel/5.jpg'
import fifteen from '../../assets/img/carousel/6.jpg'
import sixteen from '../../assets/img/carousel/10.jpg'

// import '../../styles/example.css'
import { useNavigate } from 'react-router-dom'

export default function AllProductsList() {

    const navigate = useNavigate()

    const products = [
        {
            name: 'Black Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: eight,
        },

        {
            name: 'Rollerball Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: second,
        },
        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: eleven,
        },

        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: forteen,
        },
        {
            name: 'Ink Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: forth,
        },

        {
            name: 'Gel Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: fifth,
        },
        {
            name: 'Boll Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: six,
        },
        {
            name: 'Blue Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: seven,
        },

        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: nine,
        },

        {
            name: 'Marker Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: third,
        },
        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: tenth,
        },

        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: twelve,
        },
        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: thirteen,
        },
        {
            name: 'Fountain Pen',
            description: 'Description for Product 1',
            price: 19.99,
            image: first,
        },
        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: fifteen,
        },
        {
            name: 'Gen Pen',
            description: 'Description for Product 2',
            price: 29.99,
            image: sixteen,
        },
    ];

    const [itemsPerRow, setItemsPerRow] = useState(4);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1500) {
                setItemsPerRow(4);
            } else if (screenWidth > 1200) {
                setItemsPerRow(3);
            } else {
                setItemsPerRow(2);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const selectedTypeProductListFun = (product) => {
        console.log(product)
        navigate('/addcart', {
            state: {
                products: product
            }
        })
    }

    return (
        <div>
            <h1 className='common_font'>Best Deals on pens</h1>
            <div className="all_product_list"  >
                {products.map((product, index) => (
                    <div onClick={() => { selectedTypeProductListFun(product) }} key={index} className="all_product_item all_product_list_img_desc_div all_box_hover">
                        <img className='all_product_list_img' src={product.image} alt={product.name} />
                        <div className="product-details">
                            <h3 style={{ textAlign: "", margin: "5px 0px" }} className='common_font'>{product.name}</h3>
                            <p>{product.description}</p>
                            <p>${product.price}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

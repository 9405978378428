// Author: sonal

import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../../Styles/products.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../Redux/actions/actions";
import penLogo from "../../assets/img/pensbrands/Hayman.png";
import penLogo2 from "../../assets/img/pensbrands/Copic.png";

const BransList = [
  {
    name: "HAYMAN",
  },
  {
    name: "ANOTO",
  },
  {
    name: "Camlin",
  },
  {
    name: "COPIC",
  },
];
const PointTypeList = [
  {
    name: "Bold",
  },
  {
    name: "Broad",
  },
  {
    name: "Micro",
  },
  {
    name: "Ultra Fine",
  },
];
const BodyMaterialList = [
  {
    name: "Metal",
  },
  {
    name: "Plastic",
  },
  {
    name: "Steel",
  },
];
const PenTypeList = [
  {
    name: "Ink",
  },
  {
    name: "Ball",
  },
];
const BodyColorList = [
  {
    name: "Black",
  },
  {
    name: "White",
  },
  {
    name: "Brown",
  },
  {
    name: "Blue",
  },
  {
    name: "Silver",
  },
];
const InkColorList = [
  {
    name: "Black",
  },
  {
    name: "Red",
  },
  {
    name: "Golden",
  },
  {
    name: "Blue",
  },
  {
    name: "Silver",
  },
];
const BodyShapeList = [
  {
    name: "Hexagonal",
  },
  {
    name: "Oval",
  },
  {
    name: "Triangular",
  },
  {
    name: "Octagonal",
  },
];

export default function AddPen() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [product_list, setProductList] = useState("");
  const [brands, setBrands] = useState("");
  const [pointType, setPointType] = useState("");
  const [bodyMaterials, setBodyMaterials] = useState("");
  const [pensType, setPensType] = useState("");
  const [bodyColors, setBodyColors] = useState("");
  const [inkColors, setInkColors] = useState("");
  const [bodyShape, setBodyShape] = useState("");
  const [quantity, setQuantity] = useState("");

  const dispatch = useDispatch();

  const pensDetails = {
    brands: brands,
    PointType: pointType,
    bodyMaterials: bodyMaterials,
    pensType: pensType,
    bodyColors: bodyColors,
    inkColors: inkColors,
    bodyShape: bodyShape,
    quantity: quantity,
    image: penLogo,
  };

  const getProducts = () => {
    dispatch(addToCart(pensDetails));
  };

  // const getProducts = async () => {
  // await fetch(`http://192.168.1.132:4000/ms/pm/get-product-list`, {
  //   method: "GET",
  //   mode: "cors",
  //   headers: {
  //     "Content-Type": "application/json",
  //     token: token,
  //   },
  // })
  //   .then((data) => data.json())
  //   .then((data) => {
  //     if (data.success === true) {
  //       setProductList(data);
  //       console.log(product_list);
  //     }
  //   });
  // };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div id="add_pen_container">
      <Card id="add_pen_card">
        <CardContent>
          <Box id="add_pen_box">
            <Box
              id="add_pen_formcontrol_box"
              className="add_pen_formcontrol_box"
            >
              <FormControl
                id="add_pen_brand_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="brands-label">Brands</InputLabel>
                <Select
                  labelId="brands-label"
                  id="brands-select"
                  value={brands}
                  label="Brands"
                  onChange={(e) => setBrands(e.target.value)}
                >
                  {BransList.map((bl, index) => (
                    <MenuItem
                      id={`brands-menu-item-${index}`}
                      key={index}
                      value={bl}
                    >
                      {bl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_point_type_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="point-type-label">Point Type</InputLabel>
                <Select
                  labelId="point-type-label"
                  id="point-type-select"
                  value={pointType}
                  label="Point Type"
                  onChange={(e) => setPointType(e.target.value)}
                >
                  {PointTypeList.map((ptl, index) => (
                    <MenuItem
                      id={`point-type-menu-item-${index}`}
                      key={index}
                      value={ptl.name}
                    >
                      {ptl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_body_materials_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-materials-label">
                  Body Materials
                </InputLabel>
                <Select
                  labelId="body-materials-label"
                  id="body-materials-select"
                  value={bodyMaterials}
                  label="Body Materials"
                  onChange={(e) => setBodyMaterials(e.target.value)}
                >
                  {BodyMaterialList.map((bml, index) => (
                    <MenuItem
                      id={`body-materials-menu-item-${index}`}
                      key={index}
                      value={bml.name}
                    >
                      {bml.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_pens_type_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="pens-type-label">Pens Type</InputLabel>
                <Select
                  labelId="pens-type-label"
                  id="pens-type-select"
                  value={pensType}
                  label="Pens Type"
                  onChange={(e) => setPensType(e.target.value)}
                >
                  {PenTypeList.map((ptl, index) => (
                    <MenuItem
                      id={`pens-type-menu-item-${index}`}
                      key={index}
                      value={ptl.name}
                    >
                      {ptl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_body_colors_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-colors-label">Body Colors</InputLabel>
                <Select
                  labelId="body-colors-label"
                  id="body-colors-select"
                  value={bodyColors}
                  label="Body Colors"
                  onChange={(e) => setBodyColors(e.target.value)}
                >
                  {BodyColorList.map((bcl, index) => (
                    <MenuItem
                      id={`body-colors-menu-item-${index}`}
                      key={index}
                      value={bcl.name}
                    >
                      {bcl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_ink_colors_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="ink-colors-label">Ink Colors</InputLabel>
                <Select
                  labelId="ink-colors-label"
                  id="ink-colors-select"
                  value={inkColors}
                  label="Ink Colors"
                  onChange={(e) => setInkColors(e.target.value)}
                >
                  {InkColorList.map((icl, index) => (
                    <MenuItem
                      id={`ink-colors-menu-item-${index}`}
                      key={index}
                      value={icl.name}
                    >
                      {icl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_body_shape_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-shape-label">Body Shape</InputLabel>
                <Select
                  labelId="body-shape-label"
                  id="body-shape-select"
                  value={bodyShape}
                  label="Body Shape"
                  onChange={(e) => setBodyShape(e.target.value)}
                >
                  {BodyShapeList.map((bsl, index) => (
                    <MenuItem
                      id={`body-shape-menu-item-${index}`}
                      key={index}
                      value={bsl.name}
                    >
                      {bsl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="number"
                id="add_pen_quantity_textfield"
                className="add_pen_textfield"
                label="Quantity"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />

              {brands &&
              pointType &&
              bodyMaterials &&
              pensType &&
              bodyColors &&
              inkColors &&
              bodyShape &&
              quantity ? (
                <Button
                  id="add_pen_button"
                  onClick={() => {
                    getProducts();
                    navigate("/pens-details");
                  }}
                  className="add_pen_button"
                  variant="contained"
                >
                  Add Pen
                </Button>
              ) : (
                <Button
                  id="add_pen_button"
                  className="add_pen_button"
                  variant="contained"
                >
                  Add Pen
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

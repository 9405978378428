import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function SelectLabel(props) {
  console.log(props);
  // function createMenuOptions(number, step = 10) {
  //   const options = [];
  //   for (let i = 0; i <= number; i += step) {
  //     options.push(i);
  //   }
  //   if (options[options.length - 1] !== number) {
  //     options.push(number);
  //   }
  //   return options;
  // }

  // const menuOptions = createMenuOptions(props.length);

  const menuOptions = [10, 20, 50];
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleChange = (e) => {
    const value = e.target.value;
    setItemsPerPage(value);
    props.onSendData(value);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        width: "20%",
        marginBottom: "20px",
      }}
      id="add_pen_brand_formControl"
      className="customer_details_add_items_form_control"
    >
      <InputLabel
        className="common_font"
        style={{ background: "transparent" }}
        id="brands-label"
      >
        Select Row Count
      </InputLabel>
      <Select
        style={{ background: "#fff" }}
        labelId="ItemsPerPage"
        id="ItemsPerPage"
        label="Select Row Count"
        value={itemsPerPage}
        onChange={handleChange}
        InputLabelProps={{
          className: "common_font common_card_bs_sub",
        }}
        inputProps={{
          className: "common_font common_card_bs_sub",
        }}
      >
        {menuOptions.map((item) => {
          return (
            <MenuItem
              className="common_font"
              value={item}
              InputLabelProps={{
                className: "common_font",
              }}
              inputProps={{
                className: "common_font",
              }}
            >
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

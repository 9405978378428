// Author: sonal

import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../Styles/dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackbarContext } from "../../app";
import UserIcon from "../../assets/img/user.jpg";
import PenIcon from "../../assets/img/pen.png";
import { useTranslation } from "react-i18next";
import PlusCodeMap from "./PlusCodeMap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Cell, AreaChart, Area, PieChart, Pie, Text } from 'recharts';
import { PRODUCT_LIST, SALES_ORDER_LIST } from "../../config/config";
import salesIcon from '../../assets/img/sales.png'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


// const initialData = [
//   { Month: 'January', Sales: 400 },
//   { Month: 'February', Sales: 300 },
//   { Month: 'March', Sales: 300 },
//   { Month: 'April', Sales: 200 },
// ];
// const salesData = [
//   {
//     year: 2023,
//     monthlySales: [
//       {
//         month: 'January',
//         weeklySales: [
//           {
//             week: 1,
//             dailySales: [
//               { date: '2023-01-01', pensSold: 120 },
//               { date: '2023-01-02', pensSold: 150 },
//             ],
//           },
//           {
//             week: 2,
//             dailySales: [
//               { date: '2023-01-08', pensSold: 180 },
//               { date: '2023-01-09', pensSold: 130 },
//             ],
//           },
//         ],
//       },
//       {
//         month: 'February',
//         weeklySales: [
//           {
//             week: 1,
//             dailySales: [
//               { date: '2023-02-01', pensSold: 110 },
//               { date: '2023-02-02', pensSold: 170 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     year: 2024,
//     monthlySales: [
//       {
//         month: 'January',
//         weeklySales: [
//           {
//             week: 1,
//             dailySales: [
//               { date: '2024-01-01', pensSold: 210 },
//               { date: '2024-01-02', pensSold: 140 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

let detailedData = {}
//   Jan: [
//     { name: 'Week 1', Sales: 150 },
//     { name: 'Week 2', Sales: 250 },
//   ],
//   Feb: [
//     { name: 'Week 1', Sales: 100 },
//     { name: 'Week 2', Sales: 200 },
//   ],
//   Mar: [
//     { name: 'Week 1', Sales: 150 },
//     { name: 'Week 2', Sales: 150 },
//   ],
//   Apr: [
//     { name: 'Week 1', Sales: 100 },
//     { name: 'Week 2', Sales: 100 },
//   ],
// };
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c'];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
${x + width / 2}, ${y}
C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};
const CustomToolTip = ({ payload, label }) => {
  if (payload && payload.length) {
    return (
      <div>
        <p>{`${label} : ${payload[0].value} pen(s) sold`}</p>
      </div>
    );
  }
  return null;
};
export default function Landing() {
  const contextvalue = React.useContext(SnackbarContext);
  const navigate = useNavigate();
  const location = useLocation()
  console.log(location)




  const today = new Date()
  const monthOrder = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  function formatDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day < 10 ? '0' : ''}${day} ${months[monthIndex]} ${year}`;
  }

  const formattedDate = formatDate(today);
  console.log(formattedDate)

  const { t, i18n } = useTranslation();
  const { salesGraph, productSalesAmountGraph, totalSales, totalCommission } = t("landing")
  console.log(t("customerlist"));
  console.log(i18n.language);
  const [lng, setLng] = useState(i18n.language);
  console.log(lng);

  useEffect(() => {
    console.log(i18n.language)
    if (location.pathname !== `/${lng}/dashboard`) {
      navigate(`/${lng}/dashboard`)
    }
  }, [])

  const [opensnackbar, setOpenSnackbar] = React.useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackseverity, setSnackSeverity] = useState("");


  const handleAlertClose = () => {
    setOpenSnackbar(false);
  };

  const navigateNextPage = (cl) => {
    console.log(cl);
    navigate(`${cl.path}`);
  };

  useEffect(() => {
    console.log(contextvalue);

    if (contextvalue.opensnackbar === true) {
      setSnackMsg(contextvalue.snackmsg);
      setSnackSeverity(contextvalue.snackseverity);
      setOpenSnackbar(contextvalue.opensnackbar);
      contextvalue.opensnackbar = false;
    }
  }, []);

  const [productList, setProductList] = useState([])
  const [salesAmount, setSalesAmount] = useState([])
  const [productAndAmount, setProductAndAmount] = useState([])
  const [totalSalesValue, setTotalSalesValue] = useState("")
  const [salesList, setSalesList] = useState([])
  // const [updatedsalesList, updatedsetSalesList] = useState([])
  const [weeklysalesList, setWeeklySalesList] = useState([])
  const [salesData,setSalesData]= useState([])
  console.log(productList)
  console.log(salesList)
  const [view, setView] = useState('year'); // 'year', 'month', 'week', 'date'
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  // const [view, setView] = useState('year'); // 'year', 'week', 'date'
  // const [currentYear, setCurrentYear] = useState(null);
  // const [currentWeek, setCurrentWeek] = useState(null);


  const handleBarClicks = (data) => {
    console.log(view)
    if (view === 'year') {
      // Clicking on year bar should go to month view
      console.log(`Year clicked: ${data.name}`);
      setView('month');
      setCurrentYear(data.name); // Assuming 'name' holds the year
    } else if (view === 'month') {
      // Clicking on month bar should go to week view
      console.log(`Month clicked: ${data.name}`);
      setView('week');
      setCurrentMonth(data.name); // Assuming 'name' holds the month
    } else if (view === 'week') {
      // Clicking on week bar should go to date view
      console.log(`Week clicked: ${data.name}`);
      setView('date');
      setCurrentWeek(data.name); // Assuming 'name' holds the week identifier (like `Week 1`)
    }
  };
  
  
  
  const handleBackClicks = () => {
    if (view === 'date') {
      setView('week');
    } else if (view === 'week') {
      setView('month');
    } else if (view === 'month') {
      setView('year');
    }
  };

  const getChartData = () => {
    if (view === 'year') {
      return salesData.map((yearData) => ({
        name: yearData.year,
        pensSold: yearData.monthlySales?.reduce((sum, month) =>
          sum + month.weeklySales?.reduce((weekSum, week) =>
            weekSum + week.dailySales?.reduce((daySum, day) => daySum + day.pensSold, 0), 0), 0),
      }));
    } else if (view === 'month') {
      const yearData = salesData.find((y) => y.year === currentYear);
      if (!yearData) return [];
      return yearData.monthlySales?.map((monthData) => ({
        name: monthData.month,
        pensSold: monthData.weeklySales?.reduce((sum, week) =>
          sum + week.dailySales?.reduce((daySum, day) => daySum + day.pensSold, 0), 0),
      }));
    } else if (view === 'week') {
      const yearData = salesData.find((y) => y.year === currentYear);
      if (!yearData) return [];
      const monthData = yearData.monthlySales?.find((m) => m.month === currentMonth);
      if (!monthData) return [];
      return monthData.weeklySales?.map((weekData) => ({
        name: `Week ${weekData.week}`,
        pensSold: weekData.dailySales?.reduce((sum, day) => sum + day.pensSold, 0),
      }));
    } else if (view === 'date') {
      // Get the data for the selected week
      const yearData = salesData.find((y) => y.year === currentYear);
      if (!yearData) return [];
      const monthData = yearData.monthlySales?.find((m) => m.month === currentMonth);
      if (!monthData) return [];
      const weekData = monthData.weeklySales?.find((w) => `Week ${w.week}` === currentWeek);
      if (!weekData) return [];
      console.log("Week data:", weekData.dailySales);
      
      // Return the daily data for the selected week
      return weekData.dailySales?.map((dayData) => ({
        name: dayData.date,  // Use the date for the X-axis
        pensSold: dayData.pensSold,
      }));
    }
  };
  
  
  
  


  function getMonthAbbreviation(dateString) {
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthNames[monthIndex];
  }
  // Function to get the week number within a month from a date
  function getTotalWeeksInMonth(month, year) {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return Math.ceil(lastDayOfMonth / 7);
  }
  const getWeekAndMonth = (date) => {
    // Parse the date string to a date object
    const dateObj = new Date(date);
  
    // Get the month name using an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[dateObj.getMonth()];
  
    // Get the day of the month (1-31)
    const dayOfMonth = dateObj.getDate();
  
    // Find the first day of the month
    const firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
  
    // Get the day of the week for the first day of the month (0-6, 0 is Sunday)
    const startDayOfWeek = firstDay.getDay();
  
    // Calculate the week number: adjust the start day and divide by 7, rounding up
    const weekNumber = Math.ceil((dayOfMonth + startDayOfWeek) / 7);
  
    return {
      month: month,
      week: weekNumber
    };
  };
  const SalesAmountmonthsWithQuantity = {
    Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
    Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
  };
  const productMonthsWithQuantity = {
    Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
    Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
  };



  const token = localStorage.getItem("token")

  const loggedInUser = localStorage.getItem("user");

  let foundUser = JSON.parse(loggedInUser);
  console.log(foundUser)
  const getProducts = async () => {
    await fetch(PRODUCT_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          console.log(data)
          let productList = data.data.Product_list;
          productList.forEach(entry => {
            const month = getMonthAbbreviation(entry.product_quantity_last_updated);
            productMonthsWithQuantity[month] += parseInt(entry.product_stock_quantity);
          });

          console.log(productMonthsWithQuantity)

          const result = Object.keys(productMonthsWithQuantity).map(month => ({
            Month: month,
            Products: productMonthsWithQuantity[month]
          }));

          console.log(result)
          setProductList(result)
        }
      });
  };



  function sumValues(obj) {
    return Object.values(obj).reduce((acc, val) => acc + val, 0);
  }

  const salesOrderListFun = async () => {
    await fetch(SALES_ORDER_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "appplication/json",
        token: token
      }
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data)
          setTotalSalesValue(data.data.sales_order_list.length)
          let salesMonthsWithQuantity = {
            Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0,
            Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0
          };
          let salesWeekWithQuantity = {
            Week1: 0, Week2: 0, Week3: 0, Week4: 0, Week5: 0
          };
            
          let salesOrderList = data.data.sales_order_list;
          console.log(salesOrderList.length)
          let actualSales=[]
          for(let i=0;i<salesOrderList.length;i++){
            console.log(salesOrderList[i].delivery_status)
            if(salesOrderList[i].delivery_status==='Confirmed'){
              actualSales.push(salesOrderList[i])
            }
          }
          console.log(salesOrderList)
          console.log(actualSales)

          const getYear = (date) => new Date(date).getFullYear();
          const getMonth = (date) => new Date(date).toLocaleString('default', { month: 'short' });
          const getWeek = (date) => {
            const dt = new Date(date);
            const firstDayOfYear = new Date(dt.getFullYear(), 0, 1);
            const pastDaysOfYear = (dt - firstDayOfYear) / 86400000;
            return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
          };
          const getDate = (date) => new Date(date).toISOString().split('T')[0];
          
          const salesData = [];
          
          // Grouping data by year, month, week, and date
          salesOrderList.forEach(sale => {
            const year = getYear(sale.sale_date);
            const month = getMonth(sale.sale_date);
            const week = getWeek(sale.sale_date);
            const date = getDate(sale.sale_date);
          
            // Find or create the year entry
            let yearEntry = salesData.find(y => y.year === year);
            if (!yearEntry) {
              yearEntry = { year, monthlySales: [] };
              salesData.push(yearEntry);
            }
          
            // Find or create the month entry
            let monthEntry = yearEntry.monthlySales.find(m => m.month === month);
            if (!monthEntry) {
              monthEntry = { month, weeklySales: [] };
              yearEntry.monthlySales.push(monthEntry);
            }
          
            // Find or create the week entry
            let weekEntry = monthEntry.weeklySales.find(w => w.week === week);
            if (!weekEntry) {
              weekEntry = { week, dailySales: [] };
              monthEntry.weeklySales.push(weekEntry);
            }
          
            // Find or create the date entry
            let dateEntry = weekEntry.dailySales.find(d => d.date === date);
            if (!dateEntry) {
              dateEntry = { date, pensSold: 0 };
              weekEntry.dailySales.push(dateEntry);
            }
          
            // Add the total items (pensSold) to the date
            dateEntry.pensSold += parseInt(sale.total_item, 10);
          });
          
          // Sorting the data
          salesData.sort((a, b) => a.year - b.year); // Sort years in ascending order
          salesData.forEach(yearEntry => {
            yearEntry.monthlySales.sort((a, b) => monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month)); // Sort months
            yearEntry.monthlySales.forEach(monthEntry => {
              monthEntry.weeklySales.sort((a, b) => a.week - b.week); // Sort weeks
              monthEntry.weeklySales.forEach(weekEntry => {
                weekEntry.dailySales.sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort dates
              });
            });
          });
          
          console.log(JSON.stringify(salesData, null, 2));
          setSalesData(salesData)



        //   function belongsToMonth(week, month) {
        //     let w='';
        //     let m='';
        //     console.log(week)
        //     console.log(month)
        //     salesOrderList.forEach(entry => {
        //     const monthe = getMonthAbbreviation(entry.sale_date);
        //     salesMonthsWithQuantity[monthe] += parseInt(entry.total_item);
        //     SalesAmountmonthsWithQuantity[monthe] += Number(entry.total_amount);
        //     const result = getWeekAndMonth(entry.sale_date);
        //     console.log(`Month: ${result.month}, Week Number: ${result.weekNumber}`);
        // // if(month===monthe){
        // //   console.log(month)
        // // }
        // })
        //     return week.includes(month);
        //   }
          let month;
          let result;
          let week;
          const totalWeeksInMonth = 4;
          salesOrderList.forEach(entry => {
            month = getMonthAbbreviation(entry.sale_date);
            salesMonthsWithQuantity[month] += parseInt(entry.total_item);
            console.log(salesMonthsWithQuantity[month])
            // SalesAmountmonthsWithQuantity[month] += Number(entry.total_amount);
            result = getWeekAndMonth(entry.sale_date);
            week = `Week${result.week}`;
            // if (weekNumber <= 5) {
            //   salesWeekWithQuantity[`Week${weekNumber}`] += parseInt(entry.total_item);
            // }
            console.log(month)
            console.log(week)
            if (!detailedData[month]) {
              detailedData[month] = {};
            }

          console.log(detailedData[month][week])
           // Use the week name as a key in the month map to avoid duplicacy
  if (detailedData[month][week]) {
    // If the week entry exists, add the sales quantity for that week
    detailedData[month][week].Sales += parseInt(entry.total_item);
  } else {
    // If it doesn't exist, create a new entry for that week
    detailedData[month][week] = { Week: week, Sales: parseInt(entry.total_item) };
  }

  // Debugging output
  console.log(`Month: ${month}, Week: ${week}, Total Item: ${entry.total_item}`);
  console.log(`Current Week Entry:`, detailedData[month][week]);
            // Check if week entry already exists
            // const weekEntry = detailedData[month]?.find(item => item.name === week);
            // console.log(weekEntry)
            console.log(entry)
            console.log(entry.total_item)
            // if (weekEntry) {
            //   weekEntry.Sales += parseInt(entry.total_item);
            // } else {
            //   detailedData[month]?.push({ name: week, Sales: parseInt(entry.total_item) });
            // }
          })
        
          // console.log('Monthly Sales:', salesMonthsWithQuantity);
          // console.log('Weekly Sales:', salesWeekWithQuantity);
          // const salesAmountResult = Object.keys(SalesAmountmonthsWithQuantity).map(month => ({
          //   Month: month,
          //   SalesAmount: parseFloat(SalesAmountmonthsWithQuantity[month]).toFixed(2)
          // }));

          const salesResult = Object.keys(salesMonthsWithQuantity).map(month => ({
            Month: month,
            Sales: salesMonthsWithQuantity[month],
          }));
          const weeklysalesResult = Object.keys(salesWeekWithQuantity).map(week => ({
            Week: week,
            Sales: salesWeekWithQuantity[week],
          }));
          // Object.keys(salesMonthsWithQuantity).forEach(month => {
          //   // Initialize an empty array for each month
          //   detailedData[month] = [];
          
            // Iterate over all weeks and check if they belong to the current month
            // Object.keys(salesWeekWithQuantity).forEach(week => {
            //   salesOrderList.forEach(entry => {
            //     const month = getMonthAbbreviation(entry.sale_date);
            //     // salesMonthsWithQuantity[monthe] += parseInt(entry.total_item);
            //     const result = getWeekAndMonth(entry.sale_date);
            //     // SalesAmountmonthsWithQuantity[result?.month] += Number(entry.total_amount);
            //     const week = `Week ${result.week}`;
            //     console.log(`Month: ${result.month}, Week Number: ${result.week}, Quantity: ${entry.total_item}`);
                
               
              // })
              // Fill in missing weeks with 0 sales
              // Object.keys(detailedData).forEach(month => {
              //   let weeksInMonth = 4;  // Assuming 4 weeks per month. Adjust if necessary.
                
              //   for (let i = 1; i <= weeksInMonth; i++) {
              //     const weekName = `Week ${i}`;
              //     if (!detailedData[month].find(item => item.name === weekName)) {
              //       detailedData[month].push({ name: weekName, Sales: 0 });
              //     }
              //   }

              //   // Sort weeks to ensure correct order
              //   detailedData[month].sort((a, b) => a.name.localeCompare(b.name));
              // });
              Object.keys(detailedData).forEach(month => {
                // Extract the month index (0-11) from the month name and determine the year
                const monthIndex = new Date(`${month} 1, 2023`).getMonth(); // Year is arbitrary, just to parse the date
                const year = new Date().getFullYear(); // Or extract from your data if necessary
              
                const totalWeeksInMonth = getTotalWeeksInMonth(monthIndex + 1, year); // Adjust for 1-based month
              
                for (let i = 1; i <= totalWeeksInMonth; i++) {
                  const weekName = `Week ${i}`;
                  if (!detailedData[month][weekName]) {
                    // detailedData[month][weekName] = { name: weekName, Sales: 0 };
                  }
                }
              
                // Convert the map-like structure back to the original array format
                detailedData[month] = Object.values(detailedData[month]);
              });
              
              console.log(detailedData);
            // if(month===monthe){
            //   console.log(month)
            // 
           
            // if (!detailedData[result?.month]) {
            //   detailedData[result?.month] = []; // Initialize as an empty array if not existing
            // }
            // const exists = detailedData[result?.month].some(entry => entry.name === week);
            // console.log(exists)
            // if (!exists) {
            //     // If the week does not exist, push the new entry
            //     detailedData[result?.month].push({ name: week, Sales: entry.total_item });
            // }
            // detailedData[result?.month].push({
            //   name: week, // Adjusted key to 'Name' to match the requested output
            //   Sales: salesWeekWithQuantity[week]
            // });
           
             
                
              
            // });
          // });
          console.log(detailedData)
          console.log(salesResult)
          setSalesList(salesResult)
          // console.log(weeklysalesResult)
          setWeeklySalesList(weeklysalesResult)
          // const totalItemsSum = salesOrderList.map(item => parseInt(item.total_item)).reduce((acc, val) => acc + val, 0)

          // const tosalSalesSum = sumValues(salesMonthsWithQuantity);
          // // setTotalSalesValue(tosalSalesSum)
          // setSalesAmount(salesAmountResult)


        }
      })
  }
  useEffect(() => {
   setGraphData([...salesList])

  }, [salesList])
  useEffect(() => {
    getProducts()

  }, [])

  useEffect(() => {
    salesOrderListFun()
  }, [])

  useEffect(() => {
    console.log(salesList)
    console.log(salesAmount)
    console.log(productList)
    const mergedArray = productList.map(product => {
      const salesObj = salesAmount.find(sale => sale.Month === product.Month);
      return {
        Month: product.Month,
        SalesAmount: salesObj ? Number(salesObj.SalesAmount) : "",
        Products: product.Products
      };
    });
    setProductAndAmount(mergedArray)
    console.log(mergedArray)
  }, [salesAmount, productList])

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(active)
    console.log(payload)
    console.log(label)
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Month: ${label}`}</p>
          <p className="label">{`Products: ${payload[0].value}`}</p>
          <p className="label">{`Sales Amount: $${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };
  // const CustomToolTip = ({ active, payload, label, onClick }) => {
  //   console.log(active, payload, label)
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip" onClick={() => onClick(label, payload[0].value)}
  //            onMouseEnter={(e) => e.stopPropagation()}
  //            onMouseLeave={(e) => e.stopPropagation()}>
  //         <p className="label">{`${label} : ${payload[0].value}`}</p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  const userId = localStorage.getItem("user_id")
  const siteName = localStorage.getItem("site_name")
  console.log(siteName)

  const [updateBsClass, setUpdateBsClass] = useState("")
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState({ label: '', value: 0 });
  const [graphData, setGraphData] = useState([...salesList]);
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [xAxisLabel, setXAxisLabel] = useState('Months');

  const handleBarClick = (info) => {
    console.log(info)
    const monthData = detailedData[info.Month];
    console.log(monthData)
    console.log(weeklysalesList)
    console.log(detailedData)
    const newdetailedData = Object.keys(detailedData).map(key => {
      return { [key]: detailedData[key] };
  });
  const nnewdetailedData = newdetailedData.reduce((acc, curr) => {
    return { ...acc, ...curr };
}, []);
  console.log(nnewdetailedData[info.Month])
  const weekOrder = ["Week1", "Week2", "Week3", "Week4", "Week5"];
  const uniqueSet = new Set();
    const filteredData = nnewdetailedData[info.Month].filter((item, index) => index <= 5).filter(item => {
      // Generate a unique identifier for each item. Here, we're using the 'name' property.
      const identifier = item.Week + item.Sales;

      // Check if the identifier is already in the Set
      if (uniqueSet.has(identifier)) {
          return false; // Skip duplicates
      } else {
          uniqueSet.add(identifier); // Add to the Set if not already present
          return true; // Include in the filteredData
      }
  });
  const sortedData = filteredData.sort((a, b) => {
    return weekOrder.indexOf(a.Week) - weekOrder.indexOf(b.Week);
});
    console.log(sortedData)
    if (nnewdetailedData) {
      setGraphData(sortedData);
      setIsDrillDown(true);
      setXAxisLabel('Weeks');
    } else {
      console.warn(`No detailed data available for ${info.Month}`);
    }
  };

  const handleBackClick = () => {
    const currentMonth = new Date().getMonth();
    const filteredData = salesList.filter((item, index) => index <= currentMonth);
    setGraphData(filteredData);
    // setGraphData(salesList);
    setIsDrillDown(false);
    setXAxisLabel('Months');
  };
  // const handleTooltipClick = (month, sales) => {
  //   alert(`Drill-down report for ${month} with sales: ${sales}`);
  //   // Implement your drill-down logic here
  // };

  // const handleMouseEnter = (data) => {
  //   setTooltipData({ label: data.activeLabel, value: data.activePayload[0].value });
  //   setIsTooltipVisible(true);
  // };
  // const handleMouseLeave = () => {
  //   setIsTooltipVisible(false);
  // };
  useState(() => {
    if (siteName === "Goldspot") {
      setUpdateBsClass("company-bdx-card-common-bs")
    }
    else if (siteName === "Pen House") {
      setUpdateBsClass("company-bdx-card-common-bs")
    }
    else {
      setUpdateBsClass("common_card_bs")
    }

  }, [siteName])

  // rgb(237, 247, 237)
  return (
    <div className="common-margin-in-pages" >
      <Snackbar
        open={opensnackbar}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
          id="welcome-msg"
        >
          <Typography id="welcome-msg" className="common_font">
            {snackmsg}
          </Typography>
        </Alert>
      </Snackbar>
      <div id="landing_page_container">

        <Box className="landing-graph-main-box-first">

          <Card id="landing_card"
            className={`landing_card landing-first-row-first-card ${updateBsClass}`}
          // className="landing_card common_card_bs landing-first-row-first-card"
          >
            <CardContent>
              <Box id="landing_card_main_box" className="landing_card_main_box">
                <Box
                  onClick={() => {
                    navigate(`/${i18n.language}/dashboard/customer-list`);
                  }}
                  id={`landing_card_sub_box_0`}
                  className="landing_card_sub_box"
                >
                  <img src={UserIcon} width={35} />
                  <Typography
                    id={`landing_card_typography_0`}
                    className="common_font landing_card_typography"
                  >
                    {t("landingTxt1")}
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    navigate("/search-products");
                  }}
                  id={`landing_card_sub_box_1`}
                  className="landing_card_sub_box"
                >

                  <img src={PenIcon} width={30} />

                  <Typography
                    id={`landing_card_typography_1`}
                    className="common_font landing_card_typography"
                  >
                    {t("landingTxt2")}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

    <Card id="landing_card" className={`landing_card landing-first-row-second-card`}>
      <CardContent className="landing-first-row-second-card-content">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className="landing-first-graph-header-cont"></Typography>
          <h3 className="common_font large-txt">
            YTD Sales Graph  - {view === 'year' ? 'Year' : view === 'month' ? `Month (Year ${currentYear})` : view === 'week' ? `Week (Month ${currentMonth})` : `Date (Week ${currentWeek})`}
            {(view === 'month' || view === 'week' || view === 'date') && ( <IconButton aria-label="back"><ArrowBackIosIcon fontSize="small"  color="primary" onClick={handleBackClicks} /></IconButton>)}
                    {/* Sales Graph (2024) <span className="small-txt">({foundUser.userfullname})&nbsp;</span> */}
            {/* {isDrillDown && <IconButton aria-label="back"><ArrowBackIosIcon fontSize="small"  color="primary" onClick={handleBackClick} /></IconButton> } */}
          </h3>
        </Box>
        {/* <h2>YTD Pen Sales - {view === 'year' ? 'Year' : view === 'week' ? `Week (Year ${currentYear})` : `Date (Year ${currentYear}, Week ${currentWeek})`}</h2>
        <ResponsiveContainer className="landing-first-row-second-card-content-responsive-container" width="100%" height={250}> */}
          {/* <BarChart
            width={520}
            height={300}
            data={graphData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={isDrillDown ? 'Week' : 'Month'}
              tick={{ fill: '#000', fontSize: 12 }}
              label={{ value: xAxisLabel, position: 'insideBottom', offset: -10, fill: '#000', fontSize: 14 }}
            />
            <YAxis
              tick={{ fontSize: 12 }}
              label={{ value: 'No. of Pens', angle: -90, position: 'insideLeft', fill: '#000', fontSize: 14 }}
            />
            <Tooltip content={<CustomToolTip />} cursor={{ fill: 'transparent' }} />
            <Bar dataKey="Sales" fill="#8884d8" shape={<TriangleBar />} onClick={(info, index) => handleBarClick(info)}>
              {graphData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index % 2 === 0 ? '#8884d8' : '#82ca9d'} />
              ))}
            </Bar>
          </BarChart> */}
          {/* <h2>
     
    </h2> */}

    <ResponsiveContainer className="landing-first-row-second-card-content-responsive-container" width="100%" height={300}>
      <BarChart
        width={520}
        height={300}
        data={getChartData()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="pensSold"
          fill="#8884d8"
          shape={<TriangleBar />}
          onClick={(data) => handleBarClicks(data)} // Attach click handler to the bar
        >
        {
        getChartData().map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))
        
      }
        </Bar>

      </BarChart>
    </ResponsiveContainer>

      </CardContent>
    </Card>


        </Box>

        <Box className="landing-graph-main-box">

          <Card id="landing_card" className={`landing_card landing-second-row-first-card ${updateBsClass}`}>
            <CardContent className="landing-first-row-second-card-content ">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography className="landing-first-graph-header-cont"></Typography><h3 className="common_font large-txt">{productSalesAmountGraph} (2024) <span className="small-txt">({foundUser.userfullname})</span>
                </h3>
              </Box>
              <ResponsiveContainer className="landing-second-row-first-card-content-responsive-container">
                <LineChart
                  width={800}
                  height={300}
                  data={productAndAmount}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  <XAxis
                    dataKey="Month"
                    tick={{ fill: '#000', fontSize: 12 }}
                    label={{ value: 'Months', position: 'insideBottom', offset: 0, fill: '#000', fontSize: 14 }}
                  />

                  <YAxis
                    tick={{
                      fontSize: 12, dy: 5,
                      dx: 0,
                    }}
                    label={{ value: 'Products & Amounts', angle: -90, fill: '#000', position: 'insideLeft', offset: 0, fontSize: 14 }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {/* <Legend /> */}
                  <Line type="monotone" dataKey="Products" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="SalesAmount" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>

            </CardContent>
          </Card>

          <div className="landing-second-row-second-card">
            <Card sx={{ height: "50%" }} id="landing_card" className={`landing_card ${updateBsClass}`}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography className="landing-first-graph-header-cont"></Typography>
                  <h3 style={{
                    cursor: "pointer"
                  }} onClick={() => {
                    navigate(`/${i18n.language}/sales-order-list`);
                  }} className="common_font">{totalSales} (2024)</h3></Box>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", }}>

                    <img src={salesIcon} alt="" style={{ width: "25px", height: "25px", }} />
                    <Typography
                      onClick={() => {
                        navigate(`/${i18n.language}/sales-order-list`);
                      }}
                      sx={{ fontWeight: "700", marginLeft: "10px" }} className="common_font large-txt all_box_hover"> {totalSalesValue} Sales Order </Typography>
                  </Box>

                  {/* <Typography sx={{ fontWeight: "500", marginLeft: "6px", marginTop: "10px" }} className="common_font small-txt"> (01 Jan 2024 - {formattedDate})</Typography> */}
                </div>
              </CardContent>

            </Card>

            <Card sx={{ height: "50%" }} id="landing_card" className={`landing_card landing-second-row-second-card-comm ${updateBsClass}`}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography className="landing-first-graph-header-cont"></Typography>
                  <h3 className="common_font">
                    {totalCommission} (2024)</h3>
                </Box>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", }}>
                    <AttachMoneyIcon />
                    <Typography sx={{ fontWeight: "700" }} className="common_font large-txt"> 9,999.00</Typography>
                  </Box>
                  {/* <Typography sx={{ fontWeight: "500", marginLeft: "6px", marginTop: "10px" }} className="common_font small-txt"> (01 Jan 2024 - {formattedDate})</Typography> */}
                </div>
              </CardContent>

            </Card>
          </div>

        </Box>

      </div>
    </div>
  );
}

//


const dashedChartdata = [
  {
    Month: 'Jan',
    SalesAmount: 0,
  },
  {
    Month: 'Feb',
    SalesAmount: 6958,
  },
  {
    Month: 'Mar',
    SalesAmount: 890,
  },
  {
    Month: 'Apr',
    SalesAmount: 0,
  },
  {
    Month: 'May',
    SalesAmount: 0,
  },
  {
    Month: 'Jun',
    SalesAmount: 0,
  },
  {
    Month: 'Jul',
    SalesAmount: 0,
  },
  {
    Month: 'Aug',
    SalesAmount: 0,
  },
  {
    Month: 'Sep',
    SalesAmount: 0,

  },
  {
    Month: 'Oct',
    SalesAmount: 0,

  },
  {
    Month: 'Nov',
    SalesAmount: 0,

  },
  {
    Month: 'Dec  ',
    SalesAmount: 0,

  },
];
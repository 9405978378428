// Author: sonal

import React, { useEffect, useState } from "react";
import "../../Styles/addtocart.css";
import "../../Styles/profile.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import emptycartimg from '../../assets/img/empty_cart.png'


const AddToCart = () => {
  const navigate = useNavigate();
  const [count, setcount] = useState(0);
  const [productPrice, setProductPrice] = useState();
  const getProductDetails = useSelector((state) => state.cardReducer);
  console.log(getProductDetails);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: "10px",
    boxShadow: "0px 0px 3px",
  }));

  const handleQtyIncerement = ()=>{
    setcount(count+1)
    setProductPrice((peviousState)=>peviousState*2)
  }
  const handleQtyDecrement = ()=>{
    setcount(count-1)
    setProductPrice((peviousState)=>peviousState/2)
  }

  useEffect(() => {
    const totalProductPrice = getProductDetails.reduce((accumulator, item) => {
      const price = Number(item.price);

      return accumulator + price;
    }, 0);
    setProductPrice(totalProductPrice);
  }, [getProductDetails]);

  return (
    <Box className="main_container">
      <Grid container spacing={2}>
        <Grid item xs={8} style={{ overflowY: "auto" }} >
         {getProductDetails.length > 0 ? null : 
         <Item>
          <Box>
            <img src={emptycartimg} alt="emptycartimg" width={100} height={100} />
            <Typography>Your cart is empty!</Typography>
            <Typography>Explore our wide selection and find something you like</Typography>
            <Button onClick={()=>navigate('/dashboard')} >Home</Button>
          </Box>
         </Item>}
          {getProductDetails.map((item, index) => {
            console.log(item);
            return (
              <Item key={index}>
                <Box className="Products_details_main">
                  <Box>
                    <img src={item.image} alt="first" className="image_box" />
                  </Box>
                  <Box className="product_details_box">
                    <Typography align="left">{item.name}</Typography>
                    <Typography align="left">{item.description}</Typography>
                    <Typography align="left">{item.price}</Typography>
                    <Box className="quantity">
                      <button
                        className="quantity_buttons"
                        onClick={handleQtyIncerement}
                      >
                        +
                      </button>
                      <button className="quantity_value">{count}</button>
                      {count === 0 ? (
                        <button className="quantity_buttons" disabled>
                          -
                        </button>
                      ) : (
                        <button
                          className="quantity_buttons"
                          onClick={handleQtyDecrement}
                        >
                          -
                        </button>
                      )}
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                      <Button size="small" variant="outlined">
                        Save for Later
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        className="remove"
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Item>
            );
          })}
          {getProductDetails.length > 0 ?
          <Item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="warning"
                variant="contained"
                onClick={() => navigate("/checkout")}
              >
                PLACE ORDER
              </Button>
            </Box>
          </Item> : null}
        </Grid>
        {/* --------------------------------------------------------- */}
        <Grid item xs={4}  style={{ position: "sticky", top: 0 }} >
          <Item>
            <Typography align="left" className="price_details_header">
              PRICE DETAILS
            </Typography>
            <Divider />
            <Box className="price_details">
              <Typography>Price</Typography>
              <Typography>
                {productPrice ? `$${productPrice}` : "$ 0.00"}
              </Typography>
            </Box>
            <Box className="price_details">
              <Typography>Discount</Typography>
              <Typography>$ 0.00</Typography>
            </Box>
            <Box className="price_details">
              <Typography> Dilvery Charges </Typography>
              <Typography>$ 0.00</Typography>
            </Box>
            <Divider />
            <Box className="price_details">
              <Typography className="total_price">Total Price</Typography>
              <Typography className="total_price">
                {productPrice ? `$${productPrice}` : "$ 0.00"}
              </Typography>
            </Box>
          </Item>
          <Box className="Secure_products_headline">
            <Typography>
              <AssuredWorkloadIcon />
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              Safe and Secure Payments.Easy returns.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddToCart;

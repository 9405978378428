// Author: sonal

/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import {
  PRODUCT_DETAILS,
  PRODUCT_LIST,
  UPDATE_PRODUCT_DETAILS,
  UPDATE_PRODUCT_SPECIFICATION,
  ADD_PRODUCTS_IMAGE,
  GET_PRODUCTS_IMAGE,
  DELETE_PRODUCT,
} from "../../config/config";
import { useLocation } from "react-router";
import "../../Styles/products.css";
import "../../Styles/customerdetails.css";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import firstImg from "../../assets/img/carousel/1.jpg";
import specificationIcon from "../../assets/img/specification.png";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import editIcon from "../../assets/img/edit.png";
import errorCancel from "../../assets/img/error_cancel.png";
import warningCancel from "../../assets/img/warning_cancel.png";
import successCancel from "../../assets/img/success_cancel.png";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CancelIcon from "@mui/icons-material/Cancel";
import removeIcon from "../../assets/img/cancel.png";
import { Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import QRCode from 'qrcode';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDetails() {
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const { heading, description } = t("product-description-page");

  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [open, setOpen] = React.useState(false);
  const [productDetails, setProductDetails] = useState([]);
  const [qrCode,setQRCode]=useState()
  const [productsColorsName, setProductsColorsName] = useState([]);
  const [productsColors, setProductsColors] = useState("");
  const [pageStatus, setPageStatus] = useState(location.state.edit);
  const [modalStatus, setModalStatus] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [product_list, setProductList] = useState([]);
  const [brands, setBrands] = useState("");
  const [brandsId, setBrandsId] = useState("");
  const [type, setType] = useState("");
  const [penName, setPenName] = useState("");
  const [updatePriceStatus, setUpdatePriceStatus] = useState(false);
  const [deleteProductAlert, setdeleteProductAlert] = useState(false);
  const [deleteAlertMessage, setdeleteAlertMessage] = useState("");
  console.log(brandsId);

  const [gripType, setGripType] = useState("");
  const [inkType, setInkType] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [diameter, setDiameter] = useState("");
  const [penLength, setPenLength] = useState("");
  const [penWeight, setPenWeight] = useState("");
  const [pointType, setPointType] = useState("");
  const [mechanism, setMechanism] = useState("");
  const [refillability, setRefillability] = useState("");

  const [productId, setProductId] = useState(location.state.productId);
  const [productPriceInINR, setProductPriceInINR] = useState("");
  const [productPriceInUSD, setProductPriceInUSD] = useState("");
  const [productCatagory, setProductCatagory] = useState("");
  const [specialFeatures, setSpecialFeatures] = useState("");
  const [manufacturesWebsite, setManufacturesWebsite] = useState("");

  const [updateAlert, setUpdateAlert] = useState(false);
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [dragging, setDragging] = useState(false);
  const inputRef = useRef(null);

  const [productImages, setProductImages] = useState([]);
  const [uploadAlert, setUploadAlert] = useState(false);
  const [upoadAlertMsg, setUploadAlertMsg] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && isImage(file)) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file (PNG, JPG, JPEG, GIF, etc.).");
    }
  };

  const isImage = (file) => {
    const acceptedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    return acceptedImageTypes.includes(file.type);
  };

  const handleSubmit = () => {
    if (!selectedFile) {
      alert("Please select an image");
      return;
    }

    const formDataObject = new FormData();
    formDataObject.append("product_id", productId);
    formDataObject.append("images", selectedFile);

    fetch(ADD_PRODUCTS_IMAGE, {
      method: "POST",
      body: formDataObject,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Reset states
        setSelectedFile(null);
        setImagePreview(null);
        console.log(data);
        if (data.success === true) {
          setUploadAlertMsg(data.message);
          setUploadAlert(true);
          getProductsImages();
          setPageStatus("");
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        alert("Error uploading image. Please try again."); // Show an error message
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && isImage(file)) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please drop a valid image file (PNG, JPG, JPEG, GIF, etc.).");
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  console.log(location.state);
  console.log(pageStatus);

  const getProductDetailsFun = async () => {
    try {
      // Make the fetch request and wait for the response
      const response = await fetch(PRODUCT_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
    const data = await response.json();
    console.log(data);
        if (data.success === true) {
          setProductDetails(data.data.Product_details[0]);
          setBrands(data.data.Product_details[0].brand_name);
          setBrandsId(data.data.Product_details[0].brand_id);
          setType(data.data.Product_details[0].pen_type);
          setPenName(data.data.Product_details[0].pen_name);

          setGripType(data.data.Product_details[0].grip_type);
          setInkType(data.data.Product_details[0].ink_type);
          setMaterialType(data.data.Product_details[0].material_type);
          setDiameter(data.data.Product_details[0].pen_diameter);
          setPenLength(data.data.Product_details[0].pen_length);
          setPenWeight(data.data.Product_details[0].pen_weight);
          setPointType(data.data.Product_details[0].point_type);
          setMechanism(data.data.Product_details[0].mechanism);
          setRefillability(data.data.Product_details[0].refillability);

          setProductId(data.data.Product_details[0].product_id);
          setProductPriceInINR(data.data.Product_details[0].product_pricing_rs);
          setProductPriceInUSD(
            data.data.Product_details[0].product_pricing_usd
          );
          setProductCatagory(data.data.Product_details[0].product_category);
          setProductsColors(data.data.Product_details[0].colours);
          setSpecialFeatures(data.data.Product_details[0].special_features);
          setManufacturesWebsite(
            data.data.Product_details[0].manufactures_website
          );

          setProductsColorsName(
            data.data.Product_details[0].colours.split(",")
          );
          const qr = await QRCode.toDataURL(data.data.Product_details[0].sku);
          setQRCode(qr)
        }
      } catch (error) {
        console.error('Failed to fetch product details:', error);
    }
  };

  useEffect(() => {
    getProductDetailsFun();
    getProducts();
    getProductsImages();
  }, []);

  const handleClose = () => {
    setBrands("");
    setPenName("");
    setType("");
    setModalOpen(false);
  };

  const getProductsImages = async () => {
    try {
      const response = await fetch(GET_PRODUCTS_IMAGE, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          product_id: productId,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch product images");
      }
      const data = await response.json();
      console.log(data);
      if (data.success) {
        console.log(data.data.metadata);
        setProductImages(data.data.metadata);
        console.log(productImages); // This may not reflect the updated state immediately
      }
    } catch (error) {
      console.error("Error fetching product images:", error);
    }
  };

  console.log(productImages);
  console.log(productImages.url);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getProducts = async () => {
    await fetch(PRODUCT_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          console.log(data.data);
          setProductList(data.data.Product_list);
        }
      });
  };

  const updateProducts = async () => {
    await fetch(UPDATE_PRODUCT_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
        // brand_name: brands,
        brand_id: brandsId,
        product_category: productCatagory,
        pen_type: type,
        pen_name: penName,
        colours: productsColors,
        product_pricing_rs: productPriceInINR,
        product_pricing_usd: productPriceInUSD,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getProductDetailsFun();
          setModalOpen(false);
          setUpdatePriceStatus(false);
        } else if (data.success === false) {
          setUpdateErrorMsg(data.message);
          setUpdateError(true);
          setModalOpen(false);
          getProductDetailsFun();
        }
      });
  };

  const updateSpecificProducts = async () => {
    await fetch(UPDATE_PRODUCT_SPECIFICATION, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
        pen_name: penName,
        material_type: materialType,
        ink_type: inkType,
        refillability: refillability,
        grip_type: gripType,
        point_type: pointType,
        special_features: specialFeatures,
        pen_length: penLength,
        pen_weight: penWeight,
        pen_diameter: diameter,
        manufactures_website: manufacturesWebsite,
        mechanism: mechanism,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getProductDetailsFun();
          setModalOpen(false);
        }
      });
  };

  const updatePriceFunStatus = () => {
    setUpdatePriceStatus(true);
  };

  const calculateRsFun = (e) => {
    setProductPriceInUSD(e.target.value);
  };

  // const uniqueBrandNames = [
  //     // ...new Set(product_list.map((pl) => pl.brand_name)),
  //     ...new Set(product_list.map((pl) => ({ brand_id: pl.brand_id, brand_name: pl.brand_name }))),
  // ];

  const uniqueBrandNames = Object.values(
    product_list.reduce((acc, current) => {
      acc[current.brand_name] = {
        brand_id: current.brand_id,
        brand_name: current.brand_name,
      };
      return acc;
    }, {})
  );

  const PenType = product_list.filter((x) => x.brand_name === brands);
  const uniqueType = [...new Set(PenType.map((pl) => pl.pen_type))];

  const PenName = product_list.filter(
    (x) => x.brand_name === brands && x.pen_type === type
  );
  const uniquePenName = [...new Set(PenName.map((pl) => pl.pen_name))];

  const handleAlertClose = () => {
    setUpdateAlert(false);
    setUploadAlert(false);
    setUpdateError(false);
  };

  const handlePrpductDeleteClose = () => {
    setdeleteProductAlert(false);
  };

  const vertical = "top";
  const horizontal = "center";

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 1, // Show only one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    // vertical: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsForSmall = {
    // className: "center",
    // centerMode: true,
    // infinite: true,
    centerPadding: "60px",
    // slidesToShow: 1,
    // speed: 500,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  function formatPrices(pricesString) {
    // Check if pricesString is defined and not null
    if (pricesString === undefined || pricesString === null) {
      return []; // Return an empty array if pricesString is undefined or null
    }

    // Split the string into an array of prices
    let prices = pricesString.split(",");

    // Define a function to format a single price
    function formatPrice(price) {
      // Remove any commas and parse the string as a floating-point number
      let parsedPrice = parseFloat(price.replace(",", ""));
      // Check if parsedPrice is a valid number
      if (!isNaN(parsedPrice)) {
        // Format the number to have two decimal places and leading zeros
        return parsedPrice.toFixed(2);
      } else {
        return "Invalid Price"; // Or handle the error in any way you prefer
      }
    }

    // Format each price using the formatPrice function
    let formattedPrice = prices.map((price) => formatPrice(price));

    return formattedPrice;
  }

  let formattedPrice = formatPrices(productDetails.product_pricing_usd);
  console.log(formattedPrice);

  const deleteProducts = async () => {
    await fetch(DELETE_PRODUCT, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          navigate(`/${lng}/manage-products`, {
            state: {
              Message: "Product has been successfully deleted.",
              status: localStorage.setItem("deleteProductStatus", true),
            },
          });
        } else {
          setdeleteProductAlert(true);
          setdeleteAlertMessage(data.message);
          setOpen(false);
        }
      });
  };

  return (
    <div className="common-margin-in-pages">
      <Snackbar
        open={updateAlert}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => handleAlertClose()}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">{updateAlertMsg}</Typography>
        </Alert>
      </Snackbar>

      <Snackbar
        open={updateError}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => handleAlertClose()}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "100%" }}
          className="common-error-alert"
        >
          <Typography className="common_font">{updateErrorMsg}</Typography>
        </Alert>
      </Snackbar>

      <Snackbar
        open={uploadAlert}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => handleAlertClose()}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">{upoadAlertMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteProductAlert}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => handlePrpductDeleteClose()}
        className="snackBar-alert"
      >
        <Alert
          onClose={handlePrpductDeleteClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">{deleteAlertMessage}</Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ padding: "10px" }}>
          <DialogTitle className="common_font">{"Are You Sure ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description "
              className="common_font"
            >
              <b>Warning:</b> Permanently Remove Product from Existing Database
              This action will irreversibly delete the product from the current
              database.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              className="common_font common-button"
            >
              Disagree
            </Button>
            <Button
              onClick={deleteProducts}
              className="common_font common-button"
            >
              Agree
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setModalOpen(false);
                getProductDetailsFun();
              }}
            />
            <div className="modal-sub-div">
              {modalStatus === "Specification" ? (
                <Box className="">
                  <Typography
                    id="modal-modal-title "
                    className="common_font"
                    variant="h6"
                    component="h2"
                  >
                    Select Pen
                  </Typography>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="customer_details_add_items_div"
                  >
                    <Box className="product_details_specification_modal_box">
                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Grip Type
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="brands-select"
                          label="Brands"
                          value={gripType}
                          onChange={(e) => setGripType(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={gripType}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {gripType}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control form-control-common-style"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Ink Type
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="type-select"
                          label="Colours"
                          value={inkType}
                          onChange={(e) => setInkType(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={inkType}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {inkType}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Material Type
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={materialType}
                          onChange={(e) => setMaterialType(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={materialType}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {materialType}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box className="product_details_specification_modal_box">
                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Diameter
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={diameter}
                          onChange={(e) => setDiameter(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={diameter}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {diameter}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control form-control-common-style"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Length
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={penLength}
                          onChange={(e) => setPenLength(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={penLength}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {penLength}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Weight
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={penWeight}
                          onChange={(e) => setPenWeight(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={penWeight}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {penWeight}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box className="product_details_specification_modal_box">
                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Point Type
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={pointType}
                          onChange={(e) => setPointType(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={pointType}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {pointType}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control form-control-common-style"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Mechanism
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={mechanism}
                          onChange={(e) => setMechanism(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={mechanism}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {mechanism}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        id="add_pen_brand_formControl"
                        className="customer_details_add_items_form_control"
                      >
                        <InputLabel className="common_font" id="brands-label">
                          Refillability
                        </InputLabel>
                        <Select
                          labelId="brands-label"
                          id="name-select"
                          label="Name"
                          value={refillability}
                          onChange={(e) => setRefillability(e.target.value)}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <MenuItem
                            className="common_font"
                            value={refillability}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {refillability}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      id="add"
                      onClick={updateSpecificProducts}
                      style={{
                        marginTop: "20px",
                        backgroundColor: "#4caf50",
                        fontWeight: "bold",
                        borderRadius: "8px",
                      }}
                      className="common_font common-button"
                    >
                      Update
                    </Button>
                  </div>
                </Box>
              ) : (
                <Box className="">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="common_font"
                  >
                    Select Pen
                  </Typography>
                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <InputLabel className="common_font" id="brands-label">
                        Brand
                      </InputLabel>
                      {/* setBrandsId */}
                      <Select
                        labelId="brands-label"
                        id="brands-select"
                        label="Brands"
                        value={brands}
                        onChange={(e) => setBrands(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {uniqueBrandNames.map((pl, index) => {
                          return (
                            <MenuItem
                              className="common_font"
                              id={`brands-menu-item-${index}`}
                              key={index}
                              value={pl.brand_name}
                              onClick={() => {
                                setBrandsId(pl.brand_id);
                              }}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            >
                              {pl.brand_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control form-control-common-style"
                    >
                      <InputLabel className="common_font" id="brands-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="brands-label"
                        id="type-select"
                        label="Colours"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {uniqueType.map((pl, index) => (
                          <MenuItem
                            className="common_font"
                            id={`brands-menu-item-${index}`}
                            key={index}
                            value={pl}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {pl}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <TextField
                        value={penName}
                        // type=''
                        id="standard-basic"
                        placeholder="Name"
                        className="common_font"
                        variant="outlined"
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                        onChange={(e) => {
                          setPenName(e.target.value);
                        }}
                      />
                    </FormControl>
                  </div>
                  {updatePriceStatus === false ? (
                    <div style={{ height: "80px" }}>
                      <Button
                        variant="contained"
                        id="add"
                        onClick={updatePriceFunStatus}
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#4caf50",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                        className="common_font common-button"
                      >
                        Change Price
                      </Button>
                    </div>
                  ) : (
                    <div style={{ height: "80px" }}>
                      <TextField
                        value={productPriceInUSD}
                        type="number"
                        id="standard-basic"
                        placeholder="Price"
                        className="common_font"
                        variant="standard"
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                        sx={{ marginTop: "20px" }}
                        onChange={(e) => {
                          calculateRsFun(e);
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <Button
                      variant="contained"
                      id="add"
                      onClick={updateProducts}
                      style={{
                        marginTop: "0px",
                        backgroundColor: "#4caf50",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        width: "140px",
                      }}
                      className="common_font common-button"
                    >
                      Update
                    </Button>
                  </div>
                </Box>
              )}
            </div>
          </Box>
        </>
      </Modal>

      <Card
        id="landing_card"
        className="landing_card landing_card_res common_card_bs"
      >
        <CardContent className="customer_details_card">
          <Box>
            <h2 className="cus_detail_header common_font">{heading}</h2>

            <Box className="cus_name common_font">
              <Box className="common_font">
                SKU:{" "}
                <b className="product-details-style">{productDetails.sku}</b>
              </Box>
            </Box>
            <Box className="cus_name common_font">
            <Box className="common_font" style={{ display: 'inline-block' }}>
                QR Code: <img className="qrcode" src={qrCode} alt="QR Code" style={{ display: 'inline-block', verticalAlign: 'middle' }} />
            </Box>
            </Box>
            <Box className="product_details_main_display_box">
              <Box className="product_details_display_img_box" align="center">
                {/* <img className="product_details_img" src={firstImg} alt="" /> */}

                {pageStatus === "edit" ? (
                  <div
                    className={`dropzone ${dragging ? "dragging" : ""
                      } upload-image-div`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    {!imagePreview && (
                      <>
                        <h2 style={{ marginTop: "30%" }}>
                          Drag n Drop Image Here
                        </h2>
                        <p>or</p>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          ref={inputRef}
                        />
                        <Button
                          className="common-button common_font"
                          variant="contained"
                          onClick={() => inputRef.current.click()}
                        >
                          Browse Image
                        </Button>
                      </>
                    )}
                    {imagePreview && (
                      <div className="preview-image-div">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="image-preview"
                        />
                      </div>
                    )}
                  </div>
                ) : productImages.length === 0 ? (
                  <img className="product_details_img" src={firstImg} alt="" />
                ) : (
                  <div align="center" className="main-product-img-slider-div">
                    <Slider {...settings}>
                      {productImages.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image.url}
                            alt={`Slide ${image.images_sequance}`}
                            className="main-product-img-slider"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                )}

                {imagePreview && (
                  <div align="center">
                    {selectedFile.name && <p>File Name: {selectedFile.name}</p>}
                    <Button
                      className="common_font common-button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      className="common_font common-button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                )}

                {pageStatus === "edit" && productImages.length > 0 ? (
                  <>
                    <div
                      align="center"
                      className="small-product-img-slider-div"
                    >
                      <Slider {...settingsForSmall}>
                        {productImages.map((image, index) => (
                          <div key={index}>
                            <img
                              src={image.url}
                              alt={`Slide ${image.images_sequance}`}
                              className="small-product-img-slider"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </>
                ) : null}
              </Box>

              <Box className="product_details_brand_box">
                <Box className="product_details_body_edit_box">
                  <Box>
                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_brand_typography common_font">
                        Brand{" "}
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_sub_typography product_details_brand_txt common_font">
                        {" "}
                        {productDetails.brand_name}{" "}
                      </Typography>
                    </Box>
                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_brand_typography common_font">
                        Color{" "}
                      </Typography>
                      <Typography className="product_details_brand_sub_typography">
                        {" "}
                        {productsColorsName.slice(0, 10).map((color, index) => (
                          <Tooltip title={color}>
                            <Chip
                              size="small"
                              key={index}
                              className="colors"
                              style={{
                                background: `${color}`,
                              }}
                            />
                          </Tooltip>
                        ))}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_brand_typography common_font">
                        Price{" "}
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography product_details_price_txt common_font">
                        ${formattedPrice}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_brand_typography common_font">
                        Type{" "}
                      </Typography>{" "}
                      <Typography className="product-details-style product-details-style product-details-style product_details_brand_typography product_details_brand_sub_typography common_font">
                        {productDetails.pen_type}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_brand_typography common_font">
                        Name{" "}
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography common_font">
                        {productDetails.pen_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    {pageStatus === "edit" ? (
                      <>
                        <CreateIcon
                          onClick={() => {
                            setModalOpen(true);
                            setModalStatus("");
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                      </>
                    ) : null}
                    {location.state.showDeleteIcon === true ? (
                      <Box onClick={handleClickOpen}>
                        <Delete sx={{ cursor: "pointer", color: "red" }} />
                      </Box>
                    ) : null}
                  </Box>
                </Box>

                <Box className="product_details_body_edit_box">
                  <Box>
                    <Box className="product_details_specification_box">
                      <TextSnippetIcon sx={{ marginRight: "10px" }} />

                      <Typography className="product_details_specification_typography common_font">
                        Specification
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Grip Type</b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_specification_sub_txt common_font">
                        &nbsp; {productDetails.grip_type}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Ink Type &nbsp;</b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_specification_sub_txt common_font">
                        {productDetails.ink_type}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Material Type </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_specification_sub_txt common_font">
                        &nbsp;{productDetails.material_type}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        {" "}
                        <b>Diameter </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_specification_sub_txt common_font">
                        &nbsp;{productDetails.pen_diameter}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        {" "}
                        <b>Length </b>
                      </Typography>{" "}
                      <Typography className="product-details-style  product_details_specification_sub_txt common_font">
                        &nbsp;{productDetails.pen_length}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        {" "}
                        <b>Weight </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_specification_sub_txt common_font">
                        &nbsp;{productDetails.pen_weight}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Point Type </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography common_font">
                        &nbsp;{productDetails.point_type}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Mechanism </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography common_font">
                        &nbsp;{productDetails.mechanism}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Refillability </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography common_font">
                        &nbsp;{productDetails.refillability}
                      </Typography>
                    </Box>

                    <Box className="product_details_brand_sub_box">
                      <Typography className="product_details_specification_sub_typography common_font">
                        <b>Manufactures Website </b>
                      </Typography>{" "}
                      <Typography className="product-details-style product_details_brand_typography product_details_brand_sub_typography">
                        <a
                          className="common_font"
                          rel="noopener noreferrer common_font"
                          style={{ color: "blue" }}
                          target="_blank"
                          href={productDetails.manufactures_website}
                        >
                          &nbsp;{productDetails.manufactures_website}
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {pageStatus === "edit" ? (
                      <>
                        <CreateIcon
                          onClick={() => {
                            setModalOpen(true);
                            setModalStatus("Specification");
                          }}
                          sx={{ cursor: "pointer" }}
                        />
                      </>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="product_details_description_box">
              <Box className="">
                {" "}
                <span className="product_details_description_typography common_font">
                  {description} :
                </span>{" "}
                <span
                  style={{
                    fontWeight: "500 !important",
                    fontSize: "18px !important",
                  }}
                  className="common_font"
                >
                  {productDetails.special_features}
                </span>{" "}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

import React, { useEffect, useState, useMemo } from "react";
import { QUOTATION_LIST, DELETE_DRAFT_QUO } from "../../config/config.js";
import { Box, Button, Pagination, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { displaytimestampasperclient } from "../../utils/dateutils.js";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import sortUp from "../../assets/img/sort-1.png";
import sort1 from "../../assets/img/sort-1.png";
import sort2 from "../../assets/img/sort-2.png";
import sortDown from "../../assets/img/sort-2.png";

import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Delete } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import SelectLabel from "../Layout/selectlabel";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f3f4",
  },
}));

const SalesList = () => {
  const salesListRef = React.useRef();

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;

    html2canvas(salesListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("product-list.pdf");
      }
    );
  };

  const { i18n } = useTranslation();

  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const location = useLocation();
  const [quotation_list, setQuotationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [textContent, settextContent] = useState("");
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [productList, setProductList] = useState("");

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // const currentItems = quotation_list.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    getSalesInvoice();
    if (location.state?.productList) {
      setProductList(location.state?.productList);
    }
  }, []);

  const getSalesInvoice = async () => {
    await fetch(QUOTATION_LIST, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: JSON.parse(user).person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          console.log(data);
          setQuotationList(data.data.quatatation_list);
        }
      });
  };

  const deleteDraftQuotation = async (quotation_id) => {
    await fetch(DELETE_DRAFT_QUO, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        quotation_id: quotation_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          setUpdateAlert(true);
          setUpdateAlertMsg(data.message);
          setSnackSeverity("success");
        } else {
          setUpdateAlert(true);
          setUpdateAlertMsg(data.message);
          setSnackSeverity("error");
        }
        getSalesInvoice();
      });
  };

  const handleSearchChange = async (e) => {
    settextContent(e.target.value);
    if (e.target.value === "") {
      getSalesInvoice();
    } else {
      const filteredProducts = quotation_list.filter((item) =>
        item.quotation_display_id
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
      setQuotationList(filteredProducts);
    }
  };

  const salesDeatils = (id, qdid) => {
    navigate(`/${lng}/quotes/${qdid}`, { state: id });
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "descending",
  });
  console.log(sortConfig);
  const sortedItems = useMemo(() => {
    let sortableItems = [...quotation_list];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [quotation_list, sortConfig]);

  const requestSort = (key) => {
    console.log(key, sortedItems);
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;
  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  const updateQuote = (id) => {
    navigate(`/${lng}/dashboard/customer-list/customer-details`, {
      state: {
        productList: location.state.productList,
        id: id,
        status: "editQuotation",
      },
    });
  };

  return (
    <>
      <Snackbar
        open={updateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="sales-list-snackbar-alert common_font"
      >
        <Alert
          onClose={handleAlertClose}
          className="common_font"
          severity={snackseverity}
          sx={{ width: "100%" }}
        >
          {updateAlertMsg}
        </Alert>
      </Snackbar>
      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(currentItems);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Quotation"
            className="common_font"
            inputProps={{ "aria-label": "Search Quotation" }}
            onChange={handleSearchChange}
            value={textContent}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <SelectLabel
          length={quotation_list.length}
          onSendData={handleChildData}
        />
      </div>

      <Paper className="paper common_card_bs">
        <Box className="sales-order-list-export-box">
          <Button
            className="common_font"
            onClick={handleExportClick}
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        </Box>
        <TableContainer ref={salesListRef} className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead className="table-head">
              <TableRow
                className="log_table_table_row  head-table-row common_header_bg "
                style={{ cursor: "pointer" }}
              >
                {productList.length > 0 && (
                  <TableCell className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"></TableCell>
                )}
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom table-cell-sort-order"
                  onClick={() => requestSort("quotation_display_id")}
                >
                  <Box className="table-cell-sort-order-box">
                    Quotation ID{" "}
                    {/* {sortConfig.key === "quotation_display_id" &&
                      sortConfig.direction === "ascending" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )} */}
                    {sortConfig.key === "quotation_display_id" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  onClick={() => requestSort("customer_name")}
                >
                  <Box className="table-cell-sort-order-box">
                    Customer{" "}
                    {/* {sortConfig.key === "customer_name" &&
                    sortConfig.direction === "ascending" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )} */}
                    {sortConfig.key === "customer_name" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-sort-order table-cell-without-border-bottom"
                  onClick={() => requestSort("created_by_name")}
                >
                  <Box className="table-cell-sort-order-box">
                    Created By{" "}
                    {sortConfig.key === "created_by_name" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>

                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  onClick={() => requestSort("quotation_date")}
                >
                  <Box className="table-cell-sort-order-box">
                    Created{" "}
                    {/* {sortConfig.key === "quotation_date" &&
                    sortConfig.direction === "ascending" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )} */}
                    {sortConfig.key === "quotation_date" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell-message table-cell-sort-order table-cell-without-border-bottom"
                  onClick={() => requestSort("quotation_status")}
                >
                  <Box className="table-cell-sort-order-box">
                    Status{" "}
                    {/* {sortConfig.key === "quotation_status" &&
                    sortConfig.direction === "ascending" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )} */}
                    {sortConfig.key === "quotation_status" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  sx={{ textAlign: "right !important" }}
                  align="right"
                  onClick={() => requestSort("total_amount")}
                >
                  <Box
                    className="table-cell-sort-order-box"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    Amount{" "}
                    {/* {sortConfig.key === "total_amount" &&
                      sortConfig.direction === "ascending" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )} */}
                    {sortConfig.key === "total_amount" &&
                      sortConfig.direction === "ascending" ? (
                      <img src={sortUp} className="sort-icon" alt="sortUp" />
                    ) : (
                      <img
                        src={sortDown}
                        className="sort-icon"
                        alt="sortDown"
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  sx={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems &&
                currentItems.map((item, index) => {
                  const total_amount = Number(item.total_amount);
                  return (
                    <StyledTableRow
                      onClick={() =>
                        salesDeatils(
                          item.quotation_id,
                          item.quotation_display_id
                        )
                      }
                      key={index} className="table-row-even all_box_hover">
                      {productList.length > 0 && (
                        <TableCell className="body-table-cell common_font">
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => updateQuote(item.quotation_id)}
                            disabled={
                              item.quotation_status === "DRAFT" ? false : true
                            }
                            size="small"
                          >
                            ADD
                          </Button>
                        </TableCell>
                      )}
                      <TableCell className="body-table-cell common_font">
                        <Chip
                          label={item.quotation_display_id}
                          style={{ fontWeight: "700" }}
                          className="common_font"
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                          }}
                        >
                          {item.customer_name ? (
                            <img
                              src={`https://ui-avatars.com/api/?color=ffff&name=${item.customer_name[0]}&&background=random`}
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          <span>
                            &nbsp;&nbsp;
                            {item.customer_name ? item.customer_name : "-"}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                          }}
                        >
                          <img
                            src={`https://ui-avatars.com/api/?color=ffff&name=${item && item.created_by_name
                              ? item.created_by_name[0]
                              : "U"
                              }&background=random`}
                            style={{
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",
                            }}
                            alt="created-by"
                          />
                          <span>&nbsp;&nbsp;{item.created_by_name}</span>
                        </div>
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {displaytimestampasperclient(item.quotation_date)}
                      </TableCell>

                      <TableCell className="body-table-cell common_font">
                        <Chip
                          label={item.quotation_status}
                          sx={
                            item.quotation_status === "DRAFT"
                              ? { fontWeight: "700" }
                              : item.quotation_status == "OUT_FOR_ACCEPTANCE" ||
                                "OUT FOR ACCEPTANCE"
                                ? {
                                  backgroundColor: "#fff1d6 !important",
                                  color: "#c88c32",
                                  fontWeight: "700",
                                }
                                : item.quotation_status === "COMPLETED"
                                  ? {
                                    backgroundColor: "#dbf6e5",
                                    color: "#118d57",
                                    fontWeight: "700",
                                  }
                                  : {}
                          }
                          className="common_font"
                        />
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        sx={{ textAlign: "right" }}
                      >
                        <b>$ {total_amount.toFixed(2)}</b>
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        sx={{ textAlign: "left" }}
                      >
                        <IconButton
                          aria-label="view"
                          size="small"
                        // onClick={() =>
                        //   salesDeatils(
                        //     item.quotation_id,
                        //     item.quotation_display_id
                        //   )
                        // }
                        >
                          <VisibilityIcon
                            id="quotation-list-eye-icon"
                            sx={{ color: "#38a5ff" }}
                          />
                        </IconButton>
                        {item.quotation_status === "DRAFT" ? (
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              deleteDraftQuotation(item.quotation_id)
                            }
                          >
                            <Delete
                              id="quotation-list-delete-icon"
                              sx={{ color: "#d32f2f" }}
                            />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box className="pagination-box">
        <Pagination
          className="pagination"
          count={Math.ceil(quotation_list.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
    </>
  );
};

export default SalesList;

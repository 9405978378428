// Author: sonal

/** @format */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QRCode from 'qrcode';
import {
  ADD_PRODUCT_STOCK,
  PRODUCT_LIST,
  PRODUCT_STOCK_DETAILS,
  UPDATE_PRODUCT_STOCK,
  TOP3_PRODUCT,
} from "../../config/config";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";
import { Modal, PaginationItem } from "@mui/material";
import "../../Styles/users.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput } from "@mui/material";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import CachedIcon from "@mui/icons-material/Cached";
import removeIcon from "../../assets/img/cancel.png";
import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SelectLabel from "../Layout/selectlabel";

const moment = require("moment");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProductList() {
  const productListRef = React.useRef();

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "product_table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;

    html2canvas(productListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("product-list.pdf");
      }
    );
  };

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const token = localStorage.getItem("token");

  const { i18n } = useTranslation();
  console.log(i18n.language);

  const [lng, setLng] = useState(i18n.language);

  const [modalStatus, setModalStatus] = useState("");

  const [addProductStock, setAddProductStock] = useState(false);
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [addQuantity, setAddQuantity] = useState("");
  const [productId, setProductId] = useState("");
  const [productUpdateAlert, setProductUpdateAlert] = useState(false);
  const [productUpdateAlertMsg, setProductUpdateAlertMsg] = useState("");
  const [productAddAlertMsg, setProductAddAlertMsg] = useState("");
  const [productAlertStatus, setProductAlertStatus] = useState("");
  const [productErrorAlert, setProductErrorAlert] = useState(false);
  const [productErrorMsg, setProductErrorMsg] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [viewProductStockDetails, setViewProductStockDetails] = useState(false);
  const [updatedProductDetails, setUpdatedProductDetails] = useState([]);

  const loggedInUser = localStorage.getItem("user");
  let foundUser = JSON.parse(loggedInUser);

  const [getProductList, setGetProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [textContent, settextContent] = useState("");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  console.log(textContent);

  const currentItems = getProductList.slice(indexOfFirstItem, indexOfLastItem);
  const currentProductItems = updatedProductDetails.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  console.log(updatedProductDetails);

  const handleClose = () => {
    setAddProductStock(false);
  };

  const viewProductStockDetailsClose = () => {
    setViewProductStockDetails(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  console.log(token);

  const getProducts = async () => {
    const response = await fetch(PRODUCT_LIST, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            token: token,
        },
    });
    const data = await response.json();
    console.log(data);

    if (data.success === true) {
        const storeProductList = data.data.Product_list;
        // Use Promise.all to wait for all QR code promises to resolve
        const newArrayWithIndex = await Promise.all(storeProductList.map(async (obj, index) => {
            const qrcode = await QRCode.toDataURL(obj.sku);
            return {
                ...obj,
                index: index + 1,
                qrcode: qrcode // Include the resolved QR code data
            };
        }));

        setGetProductList(newArrayWithIndex);
        console.log(newArrayWithIndex); // This will now log the updated array with QR codes
    }
};
  // };

  const updateProductStock = async () => {
    await fetch(UPDATE_PRODUCT_STOCK, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
        quantity: quantity,
        restocked_by: foundUser.person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setProductUpdateAlert(true);
          setProductAlertStatus("updateProductStock");
          setProductUpdateAlertMsg(data.message);
          getProducts();
          setAddProductStock(false);
        } else {
          setProductErrorAlert(true);
          setProductErrorMsg(data.message);
        }
      });
  };

  const productQauntityAdd = async () => {
    await fetch(ADD_PRODUCT_STOCK, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: productId,
        quantity: addQuantity,
        restocked_by: foundUser.person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setProductUpdateAlert(true);
          setProductAlertStatus("productQauntityAdd");
          setProductAddAlertMsg(data.message);
          setAddQuantity("");
          getProducts();
          setAddProductStock(false);
        } else {
          setProductErrorAlert(true);
          setProductErrorMsg(data.message);
        }
      });
  };

  const productStockDetails = async (pid) => {
    await fetch(PRODUCT_STOCK_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        product_id: pid.product_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        console.log(data.data.Product_restocking_details.length);
        if (data.success === true) {
          if (data.data.Product_restocking_details.length !== 0) {
            setViewProductStockDetails(true);
            setUpdatedProductDetails(data.data.Product_restocking_details);
            setSku(data.data.Product_restocking_details[0].sku);
            setName(pid.pen_name);
          }
        }
      });
  };

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  const handleSearchChange = async (e) => {
    settextContent(e.target.value);
    console.log(e.keyCode);
    console.log(e);

    if (e.target.value === "") {
      getProducts();
    }
    else {
      const filteredProducts = getProductList.filter(
        (user) =>
          user.sku.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.brand_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          user.pen_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log(filteredProducts);
      setGetProductList(filteredProducts);
      setCurrentPage(1);
    }
  };

  const handleSpace = (e) => {
    console.log(e.keyCode)
    if (e.keyCode === 32) {
      settextContent("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
  }



  useEffect(() => {
    getProducts();
  }, []);

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const getCurrentProductDetails = (item) => {
    setModalStatus("UpdateStock");
    console.log(item);
    setSku(item.sku);
    setBrand(item.brand_name);
    setName(item.pen_name);
    setQuantity(item.product_stock_quantity);
    setProductId(item.product_id);
    setAddProductStock(true);
  };

  const addProductStockFun = (item) => {
    setModalStatus("AddStock");
    setSku(item.sku);
    setBrand(item.brand_name);
    setName(item.pen_name);
    setQuantity(item.product_stock_quantity);
    setProductId(item.product_id);
    setAddProductStock(true);
  };

  const handleProductUpdateAlertClose = () => {
    setProductUpdateAlert(false);
    setProductErrorAlert(false);
  };
  const [productDeleteMessage, setProductDeleteMessage] = useState("");
  const [deleteProductAlert, setdeleteProductAlert] = useState(false);
  const [deleteProductSeverity, setdeleteProductSeverity] = useState("");
  const handleProductDeleteAlertClose = () => {
    setdeleteProductAlert(false);
  };

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;
  // EDF7ED

  useEffect(() => {
    console.log(location);

    if (location.state && localStorage.getItem("deleteProductStatus")) {
      setdeleteProductAlert(true);
      setProductDeleteMessage(location.state.Message);
      setdeleteProductSeverity("success");
    }
    setTimeout(() => {
      localStorage.removeItem("deleteProductStatus");
    }, 3001);
  }, [location]);

  const [top3Products, setTop3Details] = useState([]);

  useEffect(() => {
    const fetchTop3Products = async () => {
      try {
        const response = await fetch(TOP3_PRODUCT, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            token: token, // Assuming token is defined somewhere
          },
        });
        const data = await response.json();
        if (data.success === true) {
          console.log("Response", data.data.RecommendedProductList);
          setTop3Details(data.data.RecommendedProductList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTop3Products();
  }, []);

  const handleClick = (productId) => {
    navigate(`/${lng}/products-list/product-description-page`, {
      state: {
        productId: productId,
        showDeleteIcon: false,
      },
    });
  };

  return (
    <div className="common-margin-in-pages">
      <Snackbar
        open={productUpdateAlert}
        onClose={handleProductUpdateAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="product-list-snackbar-alert"
      >
        {productAlertStatus === "updateProductStock" ? (
          <Alert
            onClose={handleProductUpdateAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            <Typography className="common_font">
              {productUpdateAlertMsg}
            </Typography>
          </Alert>
        ) : (
          <Alert
            onClose={handleProductUpdateAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            <Typography className="common_font">
              {productAddAlertMsg}
            </Typography>
          </Alert>
        )}
      </Snackbar>

      <Snackbar
        open={productErrorAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => handleProductUpdateAlertClose()}
        className="product-list-snackbar-alert"
      >
        <Alert
          onClose={handleProductUpdateAlertClose}
          severity="error"
          sx={{ width: "100%" }}
          className="common-error-alert"
        >
          <Typography className="common_font">{productErrorMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteProductAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => handleProductDeleteAlertClose()}
        className="product-list-snackbar-alert"
      >
        <Alert
          onClose={handleProductDeleteAlertClose}
          severity={deleteProductSeverity}
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">
            {productDeleteMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Modal
        open={addProductStock}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setAddProductStock(false);
              }}
            />
            {modalStatus === "UpdateStock" ? (
              <Box sx={{ padding: "24px !important" }} className="">
                <h2 className="common_font product-list-update-add-stock-header">
                  Update Product In Stock
                </h2>
                <Box className="product-list-modal-brand-box">
                  <Typography className="common_font product-list-update-add-stock-head-typo">
                    SKU
                  </Typography>
                  <Typography className="common_font product-list-modal-sku-box-txt">
                    {sku}
                  </Typography>
                </Box>

                <Box className="product-list-modal-brand-box">
                  <Typography className="common_font product-list-update-add-stock-head-typo">
                    Name
                  </Typography>
                  <Typography className="common_font ">{name}</Typography>
                </Box>

                <h3 className="common_font product-list-update-add-stock-sub-header">
                  Update Product
                </h3>

                <Box
                  sx={{ marginBottom: "20px" }}
                  className="product-list-modal-quanity-box"
                >
                  <Typography className="common_font product-list-update-add-stock-qnt-typo">
                    Existing Quantity
                  </Typography>
                  <TextField
                    type="number"
                    value={quantity}
                    id="standard-basic"
                    placeholder="Quantity"
                    className="common_font"
                    variant="standard"
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                    InputLabelProps={{
                      className: "common_font",
                    }}
                    inputProps={{
                      className: "common_font",
                    }}
                  />
                </Box>

                <Button
                  id="update-product-stock-button"
                  onClick={updateProductStock}
                  className="common-button common_font product-list-update-add-stock-head-typo"
                >
                  Update
                </Button>
              </Box>
            ) : (
              <Box
                sx={{ padding: "24px !important" }}
                className="customer_details_selector_modal"
              >
                <h2 className="common_font product-list-update-add-stock-header">
                  Add Product In Stock
                </h2>

                <Box className="product-list-modal-sku-box">
                  <Typography className="common_font product-list-add-stock-head-typo">
                    SKU
                  </Typography>
                  <Typography className="common_font product-list-modal-sku-box-txt">
                    {sku}
                  </Typography>
                </Box>

                <Box className="product-list-modal-brand-box">
                  <Typography className="common_font product-list-add-stock-head-typo">
                    Name
                  </Typography>
                  <Typography className="common_font ">{name}</Typography>
                </Box>
                <Box className="product-list-modal-brand-box">
                  <Typography className="common_font product-list-add-stock-head-typo">
                    Existing Quantity
                  </Typography>
                  <Typography className="common_font ">{quantity}</Typography>
                </Box>

                <h3 className="common_font product-list-update-add-stock-sub-header">
                  Add Product
                </h3>

                <Box
                  sx={{ marginBottom: "20px" }}
                  className="product-list-modal-quanity-box"
                >
                  <Typography className="common_font product-list-add-stock-qnt-typo">
                    Add Product Quantity
                  </Typography>
                  <TextField
                    type="number"
                    value={addQuantity}
                    id="standard-basic"
                    placeholder="Quantity"
                    className="common_font"
                    variant="standard"
                    onChange={(e) => {
                      setAddQuantity(e.target.value);
                    }}
                    InputLabelProps={{
                      className: "common_font",
                    }}
                    inputProps={{
                      className: "common_font",
                    }}
                  />
                </Box>

                <Button
                  id="add-product-stock-button"
                  onClick={productQauntityAdd}
                  className="common-button common_font product-list-update-add-stock-head-typo"
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>
        </>
      </Modal>

      <Modal
        open={viewProductStockDetails}
        onClose={viewProductStockDetailsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setViewProductStockDetails(false);
              }}
            />

            <Box sx={{ padding: "20px" }} className="">
              <h2 className="common_font product-list-view-stock-details-modal-header">
                Product Stock Details
              </h2>

              <Box className="product-list-modal-sku-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo">
                  SKU
                </Typography>
                <Typography className="common_font product-list-modal-sku-box-txt">
                  {sku}
                </Typography>
              </Box>

              <Box
                sx={{ marginBottom: "20px" }}
                className="product-list-modal-brand-box"
              >
                <Typography className="common_font product-list-view-stock-details-modal-head-typo">
                  Product Name
                </Typography>
                <Typography className="common_font ">{name}</Typography>
              </Box>

              <Box className="product-list-view-stock-details-modal-timeline-box">
                {updatedProductDetails.map((ele, ind) => {
                  return (
                    <Box
                      key={ind}
                      className="product-list-view-stock-details-modal-timeline-sub-box"
                    >
                      <Box className="product-list-view-stock-details-modal-time-details-box">
                        <Box className="product-list-view-stock-details-modal-time-details-sub-box">
                          <Box
                            className="product-list-view-stock-details-modal-head-typo"
                            sx={{ marginLeft: "-10px" }}
                          >
                            <Typography className="common_font product-list-common-align-style small-txt">
                              {formatclient(ele.restock_date_time)}
                            </Typography>
                            <Typography className="common_font product-list-common-align-style small-txt">
                              {" "}
                              {formattimeStampMoment(
                                ele.restock_date_time
                              )}{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="product-list-view-stock-details-modal-icon-box">
                        <Box className="product-list-view-stock-details-modal-icon-box">
                          {ele.remark === "Restocked" ? (
                            <Tooltip title="Restocked">
                              <DataSaverOnIcon className="product-list-view-stock-details-modal-icon-add-icon all_box_hover" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Adjustment">
                              {" "}
                              <CachedIcon className="product-list-view-stock-details-modal-icon-update-icon all_box_hover" />
                            </Tooltip>
                          )}

                          <Typography className="product-list-view-stock-details-modal-icon-box-typo"></Typography>
                        </Box>
                      </Box>

                      <Box className="product-list-view-stock-details-modal-qty-by-box">
                        <Box className="product-list-view-stock-details-modal-time-details-sub-box">
                          <Box className="product-list-view-stock-details-modal-qty-by-box-sub-box">
                            <Typography className="common_font small-txt product-list-common-align-style">
                              {" "}
                              By {ele.restocked_by_name.split(" ")[0]}{" "}
                            </Typography>
                            <Typography className="common_font product-list-common-align-style small-txt">
                              Qty ({ele.quantity_restocked}){" "}
                            </Typography>

                            {ele.remark === "Adjustment" ? (
                              <Typography
                                sx={{ marginTop: "5px" }}
                                className="common_font product-list-modal-adjustment-box-txt"
                              >
                                {ele.remark}{" "}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ marginTop: "5px" }}
                                className="common_font product-list-modal-restocked-box-txt"
                              >
                                {ele.remark}{" "}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box sx={{ float: "right" }}>
                <Box sx={{ display: "flex", alignItem: "center" }}>
                  <Typography className="common_font product-list-modal-restocked-box-txt">
                    Restocked
                  </Typography>{" "}
                  <Typography className="common_font product-list-common-align-style medium-txt">
                    &nbsp;: Add Product in Stock
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    marginTop: "10px",
                  }}
                  className="common_font product-list-common-align-style small-txt"
                >
                  <Typography className="common_font product-list-modal-adjustment-box-txt">
                    Adjustment
                  </Typography>
                  <Typography className="common_font product-list-common-align-style medium-txt">
                    &nbsp;: Update Product in Stock
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>

      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(currentItems);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>

      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
          }}
        >
          <Paper
            component="form"
            className="search_document_paper common_card_bs_sub"
            onSubmit={(e) => e.preventDefault()}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Products"
              className="common_font"
              inputProps={{ "aria-label": "Search Products" }}
              onChange={handleSearchChange}
              value={textContent}
              onKeyDown={handleSpace}
              onSubmit={handleSubmit}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <SelectLabel
            length={getProductList.length}
            onSendData={handleChildData}
          />
        </div>

        <div align="center">
          <Typography
            className="common_font"
            variant="h3"
            style={{ textAlign: "center" }}
          >
            Top 3 Selling Products
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "20px" }}
          >
            {top3Products.map((product, index) => (
              <Paper
                key={index}
                className="top_products_card"
                style={{
                  padding: "10px",
                  maxWidth: "180px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  cursor: "pointer", // Add cursor pointer to indicate it's clickable
                }}
                onClick={() => handleClick(product.product_id)}
              >
                <Stack direction="column" spacing={1} alignItems="flex-start">
                  <Typography
                    className="common_font top3-product-name"
                    variant="body1"
                  >
                    {product.pen_name}
                  </Typography>
                  <Typography className="common_font sku-border" variant="body1">
                    {product.sku}
                  </Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </div>

        <br />

        <Paper className="paper common_card_bs">
          <Box className="sales-order-list-export-box">
            <Button
              className="common_font"
              onClick={handleExportClick}
              startIcon={<DownloadIcon />}
            >
              Export
            </Button>
          </Box>

          <TableContainer ref={productListRef} className="table-container">
            <Table size="small" aria-label="a dense table">
              <TableHead className="table-head">
                <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    S.n.
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    SKU
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    QR Code
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Brands
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Item Type
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Item Name
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Quantity/ Last Updated
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Item Color
                  </TableCell>
                  <TableCell
                    align="center"
                    className="common_font log_table_table_head head-table-cell-message"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="">
                {currentItems &&
                  currentItems.map((gpl, index) => {
                    var colors = gpl.colours.split(",");
                    const serialNumber =
                      (currentPage - 1) * itemsPerPage + index + 1;
                    return (
                      <TableRow key={index}
                        onClick={() =>
                          navigate(
                            `/${lng}/products-list/product-description-page`,
                            {
                              state: {
                                productId: gpl.product_id,
                                showDeleteIcon: false,
                              },
                            }
                          )
                        }
                        className={index % 2 === 0 ? 'table-row-even product-list-alternate-table all_box_hover' : 'table-row-even all_box_hover'}
                      // className="table-row-even"
                      >
                        <TableCell className="body-table-cell common_font">
                          {serialNumber}
                        </TableCell>
                        <TableCell className="body-table-cell common_font ">
                          <Typography
                            sx={{ padding: "0px !important" }}
                            className="product-list-modal-sku-box-txt common_font"
                          >
                            {gpl.sku}
                          </Typography>
                        </TableCell>
                        <TableCell className="body-table-cell common_font ">
                         
                          <img className ="qrcode" src={gpl.qrcode} alt="QR Code" />
                          
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          {gpl.brand_name}
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          {gpl.pen_type}
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          {gpl.pen_name}
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                              sx={{ width: "120px" }}
                              className="all_box_hover"
                              onClick={() => {
                                productStockDetails(gpl);
                              }}
                            >
                              {gpl.product_stock_quantity} (
                              {formattimeStampMoment(
                                gpl.product_quantity_last_updated
                              )}
                              )
                            </Box>
                            {/* <AddIcon
                              id={`add-stock-${index}`}
                              onClick={() => {
                                addProductStockFun(gpl);
                              }}
                              className="all_box_hover"
                              sx={{ marginLeft: "10px" }}
                            /> */}
                            {/* <i
                              id={`update-stock-${index}`}
                              onClick={() => {
                                getCurrentProductDetails(gpl);
                              }}
                              class="fa fa-pen-to-square all_box_hover"
                              style={{ marginLeft: "10px" }}
                            ></i> */}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {colors.slice(0, 5).map((color, index) => (
                            <Tooltip title={color}>
                              <Chip
                                size="small"
                                key={index}
                                className="colors"
                                style={{
                                  background: `${color}`,
                                }}
                              />
                            </Tooltip>
                          ))}
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          <Box className="product-list-table-cell-action-box ">
                            {/* <CreateIcon
                              id={`product-list-edit-icon-${gpl.index}`}
                              onClick={() =>
                                navigate(
                                  `/${lng}/products-list/product-description-page`,
                                  {
                                    state: {
                                      productId: gpl.product_id,
                                      edit: "edit",
                                    },
                                  }
                                )
                              }
                              className="all_box_hover"
                            /> */}

                            <VisibilityIcon
                              id={`product-list-eye-icon-${gpl.index}`}
                              onClick={() =>
                                navigate(
                                  `/${lng}/products-list/product-description-page`,
                                  {
                                    state: {
                                      productId: gpl.product_id,
                                      showDeleteIcon: false,
                                    },
                                  }
                                )
                              }
                              sx={{ marginLeft: "5px", cursor: "pointer" }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box className="pagination-box">
          <Pagination
            id="product-list-pagination"
            className="pagination common_font"
            count={Math.ceil(getProductList.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            siblingCount={1}
            boundaryCount={1}
          // renderItem={(item) => {
          //   const isArrowButton =
          //     item.type === "previous" || item.type === "next";
          //   return (
          //     <PaginationItem
          //       component={IconButton}
          //       {...item}
          //       disabled={
          //         isArrowButton &&
          //         item.type === "previous" &&
          //         currentPage === 1
          //       }
          //       id={isArrowButton ? "product-list-pagination" : undefined}
          //       icon={
          //         isArrowButton
          //           ? item.type === "previous"
          //             ? "left"
          //             : "right"
          //           : null
          //       }
          //     />
          //   );
          // }}
          />
        </Box>
      </Box>
    </div>
  );
}

// Author: sonal

import * as React from "react";
import Typography from "@mui/material/Typography";
import BODEX_LOGO from '../../assets/img/Bodex.png'

export default function Footer() {
  const dateTime = new Date().getFullYear()

  return (
    <div style={{ display: "flex", justifyContent: "center" }}
      className="footer_div"
    >
      <a href="https://bodex.io/" target="_blank" rel="noreferrer">
        <img src={BODEX_LOGO} alt="BODEX LOGO" width={100} />
      </a>

      <Typography className="common_font">
        <a
          className="common_font"
          href="https://bodex.io/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          &copy; Copyright {dateTime}
        </a>
      </Typography>
    </div>
  );
}

import React, { useState } from 'react'
import { Box, Button, Card, CardContent, TextField } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from 'react-router-dom';



const BransList = [
    {
        name: "HAYMAN"
    },
    {
        name: "ANOTO"
    },
    {
        name: "Camlin"
    },
    {
        name: "COPIC"
    },
]
const PointTypeList = [
    {
        name: "Bold"
    },
    {
        name: "Broad"
    },
    {
        name: "Micro"
    },
    {
        name: "Ultra Fine"
    },
]
const BodyMaterialList = [
    {
        name: "Metal"
    },
    {
        name: "Plastic"
    },
    {
        name: "Steel"
    },

]
const PenTypeList = [
    {
        name: "Ink"
    },
    {
        name: "Ball"
    },


]
const BodyColorList = [
    {
        name: "Black"
    },
    {
        name: "White"
    },
    {
        name: "Brown"
    },
    {
        name: "Blue"
    },
    {
        name: "Silver"
    },

]
const InkColorList = [
    {
        name: "Black"
    },
    {
        name: "Red"
    },
    {
        name: "Goler"
    },
    {
        name: "Blue"
    },
    {
        name: "Silver"
    },

]
const BodyShapeList = [
    {
        name: "Hexagonal"
    },
    {
        name: "Oval"
    },
    {
        name: "Triangular"
    },
    {
        name: "Octagonal"
    },


]

export default function UpdatePenDetails() {

    const navigate = useNavigate()
    const location = useLocation()
    console.log(location.state)

    const [brands, setBrands] = useState(location.state.PenData.brands.name)
    const [pointType, setPointType] = useState(location.state.PenData.PointType)
    const [bodyMaterials, setBodyMaterials] = useState(location.state.PenData.bodyMaterials)
    const [pensType, setPensType] = useState(location.state.PenData.pensType)
    const [bodyColors, setBodyColors] = useState(location.state.PenData.bodyColors)
    const [inkColors, setInkColors] = useState(location.state.PenData.inkColors)
    const [bodyShape, setBodyShape] = useState(location.state.PenData.bodyShape)
    const [quantity, setQuantity] = useState(location.state.PenData.quantity)


    return (
        <div>
            <Card>
                <CardContent>
                    <Box>
                        <Box className="add_pen_formcontrol_box">
                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Brands</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={brands}
                                    label="Brands"
                                    onChange={(e) => setBrands(e.target.value)}
                                >
                                    {
                                        BransList.map((bl, index) => {
                                            return (
                                                <MenuItem key={index} value={bl.name}>{bl.name}</MenuItem>
                                            )
                                        })
                                    }
                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>


                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Point Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pointType}
                                    label="Point Type"
                                    onChange={(e) => setPointType(e.target.value)}
                                >
                                    {
                                        PointTypeList.map((ptl, index) => {
                                            return (
                                                <MenuItem key={index} value={ptl.name}>{ptl.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Body Materials</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={bodyMaterials}
                                    label="Body Materials"
                                    onChange={(e) => setBodyMaterials(e.target.value)}
                                >
                                    {
                                        BodyMaterialList.map((bml, index) => {
                                            return (
                                                <MenuItem key={index} value={bml.name}>{bml.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Pens Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pensType}
                                    label="Pens Type"
                                    onChange={(e) => setPensType(e.target.value)}
                                >
                                    {
                                        PenTypeList.map((ptl, index) => {
                                            return (
                                                <MenuItem key={index} value={ptl.name}>{ptl.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>


                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Body Colors</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={bodyColors}
                                    label="Body Colors"
                                    onChange={(e) => setBodyColors(e.target.value)}
                                >
                                    {
                                        BodyColorList.map((bcl, index) => {
                                            return (
                                                <MenuItem key={index} value={bcl.name}>{bcl.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Ink Colors</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inkColors}
                                    label="Ink Colors"
                                    onChange={(e) => setInkColors(e.target.value)}
                                >
                                    {
                                        InkColorList.map((icl, index) => {
                                            return (
                                                <MenuItem key={index} value={icl.name}>{icl.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>


                            <FormControl className="add_pen_formControl" >
                                <InputLabel id="demo-simple-select-label">Body Shape</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={bodyShape}
                                    label="Body Shape"
                                    onChange={(e) => setBodyShape(e.target.value)}
                                >
                                    {
                                        BodyShapeList.map((bsl, index) => {
                                            return (
                                                <MenuItem key={index} value={bsl.name}>{bsl.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <TextField className='add_pen_textfield' label="Quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />


                            <Button onClick={() => { navigate('/pens-details') }} className='add_pen_button' variant='contained'>Update Pen</Button>


                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import "../../Styles/edituserdetails.css";
import { Box, Button, Typography } from "@mui/material";
import userProfile from "../../assets/img/user.jpg";
import { useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Chip from "@mui/material/Chip";
import Divider from "@mui/joy/Divider";
import { Pagination } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import dayjs from 'dayjs';

import {
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_PHONENUMBER,
  CUSTOMER_CONTACT_DETAILS,
  CUSTOMER_DETAILS,
  EDIT_CUSTOMER_ADDRESS,
  EDIT_CUSTOMER_PHONENUMBER,
  EDIT_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_NAME,
  USER_LOGIN_DETAILS,
  USER_CHANGE_PASSWORD,
} from "../../config/config";

import { format, parseISO } from "date-fns";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput, FormHelperText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BusinessIcon from "@mui/icons-material/Business";
import moment from "moment";
import removeIcon from "../../assets/img/cancel.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


const EditUserDetails1 = () => {
  const [lastLogin, setLastLogin] = useState("");

  const currentDate = moment(lastLogin);
  const formattedDate = currentDate.format("DD MMM YYYY HH:mm a");
  const location = useLocation();
  console.log(location);
  const token = localStorage.getItem("token");

  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerContactDetailId, setCustomerContactDetailId] = useState("");
  const [customerEmailContactDetailId, setCustomerEmailContactDetailId] =
    useState("");
  const [customerContactDetails, setCustomerContactDetails] = useState([]);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerConfirmPhoneNumber, setCustomerConfirmPhoneNumber] =
    useState("");
  const [customerAddress, setCustomerAddress] = useState([]);
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerGender, setCustomerGender] = useState("");
  const [customerMaritalStatus, setCustomerMaritalStatus] = useState("");
  const [customerDOB, setCustomerDOB] = useState("");
  const [customerCreatedDate, setCustomerCreatedDate] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [customerPersonId, setcustomerPersonId] = useState("");
  const [addCustomerPhoneNumber, setAddCustomerPhoneNumber] = useState("");
  const [addCustomerEmail, setAddCustomerEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updatedPasswordAlert, setUpdatedPassAlert] = useState(false);
  const [updateFirstName, setUpdateFirstName] = useState("");
  const [updateLastName, setupdateLastName] = useState("");
  const [updateCustomerGender, setUpdateCustomerGender] = useState("");
  const [updateCustomerDOB, setUpdateCustomerDOB] = useState("");
  const [updateCustomerOccupation, setUpdateCustomerOccupation] = useState("");
  const [updateCustomerMaritalStatus, setUpdateCustomerMaritalStatus] =
    useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [contactPriority, setcontactPriority] = useState()
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setstreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [addressId, setAddressId] = useState("");

  const [addZip, setAddZip] = useState("");
  const [addCity, setAddCity] = useState("");
  const [addState, setAddState] = useState("");
  const [addCountry, setAddCountry] = useState("");
  const [addStreetAdd, setAddStreetAdd] = useState("");

  const [customerNameUpdateAlert, setCustomerNameUpdateAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [customerNameUpdatetxt, setCustomerNameUpdatetxt] = useState("");
  const [msgType, setMsgType] = useState("");
  const [customerPhoneUpdatetxt, setCustomerPhoneUpdatetxt] = useState("");
  const [customerEmailUpdatetxt, setCustomerEmailUpdatetxt] = useState("");
  const [customerAddressUpdatetxt, setCustomerAddressUpdatetxt] = useState("");
  const [customerProfileUpdatetxt, setCustomerProfileUpdatetxt] = useState("");

  const [modalStatus, setModalStatus] = useState("");
  const [alertStatus, setalertStatus] = useState("");

  const [addModalStatus, setAddModalStatus] = useState("");
  const [addAlertStatus, setAddAlertStatus] = useState("");

  const [customercontactAddAlert, setCustomercontactAddAlert] = useState(false);
  const [customerEmailAddtxt, setCustomerEmailAddtxt] = useState("");
  const [customerPhoneNumberAddtxt, setCustomerPhoneNumberAddtxt] =
    useState("");
  const [customerAddressAddtxt, setCustomerAddressAddtxt] = useState("");
  const [updateUserPassword, setupdateUserPassword] = useState("");

  const [LoginStatus, setLoginStatus] = useState("");
  const [loginType, setLoginType] = useState("");
  const [loginIp, setLoginIp] = useState("");
  let itemsPerPage = 5;
  const [allLoginDetails, setAllLoginDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = allLoginDetails.slice(startIndex, endIndex);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [dateOfBirth, setDateOfBirth] = useState(null);

  const handleDateOfBirthChange = (newvalue) => {
    console.log(newvalue.$d);
    const selectedDate = new Date(newvalue.$d);
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD')


    setDateOfBirth(formattedDate);
  };

  console.log(firstName);
  console.log(LoginStatus);

  useEffect(() => {
    setCustomerDetails(location.state.details);
    getCustomerDetails();
    getCustomerContactDetails();
  }, []);

  const [getFilterPhoneData, setGetFilterPhoneData] = useState([]);
  const [getFilterEmailData, setGetFilterEmailData] = useState([]);
  console.log(getFilterPhoneData);

  useEffect(() => {
    console.log(customerContactDetails);
    let filterPhoneData = [];
    let filterEmailData = [];
    customerContactDetails.map((fpd) => {
      console.log(fpd);
      if (fpd.contact_type === "phone") {
        filterPhoneData.push(fpd);
      } else {
        filterEmailData.push(fpd);
      }
    });
    console.log(filterPhoneData);
    setGetFilterPhoneData(filterPhoneData);
    setGetFilterEmailData(filterEmailData);
  }, [customerContactDetails]);

  const getCustomerDetails = async () => {
    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: location.state.personId }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        setcustomerPersonId(data.data.Customer_details[0].person_id);
        setFirstName(data.data.Customer_details[0].first_name);
        setLastName(data.data.Customer_details[0].last_name);
        setupdateLastName(data.data.Customer_details[0].last_name);
        setUpdateFirstName(data.data.Customer_details[0].first_name);
        console.log(data.data.Customer_contcat_details);

        console.log(data.data.Customer_contact_details);
        setCustomerContactDetails(data.data.Customer_contact_details);
        setCustomerAddress(data.data.Customer_address);
        setCustomerOccupation(data.data.Customer_details[0].occupation);
        setCustomerGender(data.data.Customer_details[0].gender);
        setCustomerMaritalStatus(data.data.Customer_details[0].marital_status);

        const date = data.data.Customer_details[0].date_of_birth;
        const parsedDate = parseISO(date);
        const formattedDate = format(parsedDate, "dd MMM yyyy");
        setCustomerDOB(formattedDate);

        const createdDate = data.data.Customer_details[0].person_created_at;
        const createdParsedDate = parseISO(createdDate);
        const createdFormattedDate = format(createdParsedDate, "dd MMM yyyy");
        setCustomerCreatedDate(createdFormattedDate);
      });
  };

  const getCustomerContactDetails = async () => {
    await fetch(CUSTOMER_CONTACT_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: location.state.personId }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
      });
  };

  const updateUserName = async (e) => {
    console.log("updateUserName");
    try {
      const response = await fetch(UPDATE_CUSTOMER_NAME, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          person_id: customerPersonId,
          first_name: updateFirstName,
          last_name: updateLastName,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        getCustomerDetails();
        setModalOpen(false);
        setalertStatus("updateCustomerName");
        setCustomerNameUpdatetxt(data.message);
        setCustomerNameUpdateAlert(true);
        setMsgType("success");
      } else {
        getCustomerDetails();
        setModalOpen(false);
        setalertStatus("updateCustomerName");
        setCustomerNameUpdatetxt(data.message);
        setCustomerNameUpdateAlert(true);
        setMsgType("error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updatePhoneNumber = async (e) => {
    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: formatUsPhone(customerPhoneNumber),
        confirm_contact_details: customerConfirmPhoneNumber,
        person_contact_detail_id: customerContactDetailId,
        contact_type: "phone",
        contact_priority: contactPriority
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedPhoneNumber");
          setCustomerPhoneUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setCustomerPhoneNumber("");
          setCustomerConfirmPhoneNumber("");
          setMsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedPhoneNumber");
          setCustomerPhoneUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setCustomerPhoneNumber("");
          setCustomerConfirmPhoneNumber("");
          setMsgType("error");
        }
      });
  };

  const updateEmail = async (e) => {
    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: customerEmail,
        person_contact_detail_id: customerEmailContactDetailId,
        contact_type: "email",
        contact_priority: contactPriority,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerEmail");
          setCustomerEmailUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setCustomerEmail("");
          setMsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerEmail");
          setCustomerEmailUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setCustomerEmail("");
          setMsgType("error");
        }
      });
  };

  const updateCustomerAddress = async (e) => {
    await fetch(EDIT_CUSTOMER_ADDRESS, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        address_id: addressId,
        person_id: customerPersonId,
        street_address: streetAddress,
        city: city,
        state: state,
        zip_code: zip,
        country: country,
        address_type: "",
        plus_code: "ABC12312",
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerAddress");
          setCustomerAddressUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setMsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerAddress");
          setCustomerAddressUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setMsgType("error");
        }
      });
  };

  const updateCustomerProfile = async (e) => {
    await fetch(EDIT_CUSTOMER_PROFILE, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        date_of_birth: dateOfBirth,
        gender: updateCustomerGender,
        occupation: updateCustomerOccupation,
        marital_status: updateCustomerMaritalStatus,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerProfile");
          setCustomerProfileUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setMsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerProfile");
          setCustomerProfileUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setMsgType("error");
        }
      });
  };

  const getAddresDetails = (item) => {
    console.log(item);
    setCountry(item.country);
    setState(item.state);
    setZip(item.zip_code);
    setCity(item.city);
    setstreetAddress(item.street_address);
    setAddressId(item.address_id);
  };

  const handleClose = () => {
    setModalOpen(false);
    setUpdateFirstName("");
    setupdateLastName("");
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setAddCustomerEmail("");
    setAddCustomerPhoneNumber("");
  };

  const handleAlertClose = () => {
    setCustomerNameUpdateAlert(false);
  };

  const handleErrorAlertClose = () => {
    setErrorAlert(false);
  };

  const setUpdatedPasswordAlertClose = () => {
    setUpdatedPassAlert(false);
  };

  const handleLoginDetails = () => {
    setModalStatus("userLoginDetails");
    setModalOpen(true);
  };

  const handleAddAlertClose = () => {
    setCustomercontactAddAlert(false);
  };

  const handleChangePassword = () => {
    setModalStatus("changePassword");
    setModalOpen(true);
  };

  const AddPhoneNumber = async (e) => {
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: formatUsPhone(addCustomerPhoneNumber),
        contact_type: "phone",
        contact_priority: getFilterPhoneData.length + 1
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerPhoneNumberAddtxt(data.message);
          setAddAlertStatus("addPhoneNumer");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerPhoneNumber("");
          setMsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerPhoneNumberAddtxt(data.message);
          setAddAlertStatus("addPhoneNumer");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerPhoneNumber("");
          setMsgType("error");
        }
      });
  };

  const AddEmail = async (e) => {
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: addCustomerEmail,
        contact_type: "email",
        contact_priority: getFilterEmailData.length + 1,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerEmailAddtxt(data.message);
          setAddAlertStatus("addEmail");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setMsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerEmailAddtxt(data.message);
          setAddAlertStatus("addEmail");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setMsgType("error");
        }
      });
  };

  const AddAddress = async (e) => {
    const options = {
      person_id: customerPersonId,
      street_address: addStreetAdd,
      city: addCity,
      state: addState,
      zip_code: addZip,
      country: addCountry,
      address_type: "",
      plus_code: "ABC12312",
    };
    console.log(options);
    await fetch(ADD_CUSTOMER_ADDRESS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerAddressAddtxt(data.message);
          setAddAlertStatus("addAddress");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setMsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerAddressAddtxt(data.message);
          setAddAlertStatus("addAddress");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setMsgType("error");
        }
      });
  };

  const clickEditNames = () => {
    setModalStatus("updateCustomerName");
    setModalOpen(true);
    setUpdateFirstName(firstName);
    setupdateLastName(lastName);
  };

  const clickEditEmail = (ccd) => {
    console.log(ccd)
    setModalStatus("updatedEmail");
    setModalOpen(true);
    setCustomerEmailContactDetailId(ccd.person_contact_detail_id);
    setCustomerEmail(ccd.contact_details);
    setcontactPriority(ccd.contact_priority)
  };

  const clickAddEmail = () => {
    setAddModalStatus("addEmail");
    setAddModalOpen(true);
  };

  const clickEditPhoneNumber = (ccd) => {
    console.log(ccd)
    setModalStatus("updatedPhoneNumber");
    setModalOpen(true);
    setCustomerContactDetailId(ccd.person_contact_detail_id);
    setCustomerPhoneNumber(ccd.contact_details);
    setIsValidPhone(formatUsPhone(ccd.contact_details));
    setcontactPriority(ccd.contact_priority
    )
  };

  const clickAddPhoneNumber = () => {
    setAddModalStatus("addPhoneNumber");
    setAddModalOpen(true);
  };

  const clickAddressEdit = (item) => {
    getAddresDetails(item);
    setModalStatus("updateCustomerAddress");
    setModalOpen(true);
  };

  const clickAddAddress = () => {
    setAddModalStatus("addAddress");
    setAddModalOpen(true);
  };

  const clickPersonalinfoEdit = () => {
    setModalStatus("updateCustomerProfile");
    setModalOpen(true);
    setUpdateCustomerOccupation(customerOccupation);
    setUpdateCustomerGender(customerGender);
    setUpdateCustomerDOB(customerDOB);
    setDateOfBirth(customerDOB)
    setUpdateCustomerMaritalStatus(customerMaritalStatus);
  };

  function formatUsPhone(phone) {
    const numericPhone = phone.replace(/\D/g, "");

    // Apply the phone number format (XXX) XXX-XXXX
    const formattedPhone = numericPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedPhone;
  }

  const [isValidPhone, setIsValidPhone] = useState(false);

  const validatePhone = (phoneNumber) => {
    // Use a simple validation for illustration purposes
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const handleEditPhoneNumber = (e) => {
    const newPhone = e.target.value;
    setCustomerPhoneNumber(newPhone);
    setIsValidPhone(validatePhone(newPhone));
  };

  const handleAddPhone = (e) => {
    const newPhone = e.target.value;
    setAddCustomerPhoneNumber(newPhone);
    setIsValidPhone(validatePhone(newPhone));
  };

  const navigate = useLocation();
  console.log(navigate);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  console.log(allLoginDetails);
  //User Logs
  const userLoginDetails = async () => {
    await fetch(USER_LOGIN_DETAILS, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        user_id: location.state.Email,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        setAllLoginDetails(data.data.user_login_details);
        setLoginStatus(data.data.user_login_details[0].login_status);
        setLoginType(data.data.user_login_details[0].login_type);
        setLoginIp(data.data.user_login_details[0].login_from_ip);
        setLastLogin(data.data.user_login_details[0].web_app_user_last_login);
      });
  };

  useEffect(() => {
    userLoginDetails();
  }, []);

  const changeUserPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setModalOpen(false);
      setErrorAlert(true);
      seterrorMsg("Password and confirm password must match");
      return;
    } else {
      await fetch(USER_CHANGE_PASSWORD, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          user_id: location.state.personId,
          password: password,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success === true) {
            console.log(data.message);
            setModalOpen(false);
            setUpdatedPassAlert(true);
            setupdateUserPassword(data.message);
          }
        });
    }
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCustomerEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const handleAddEmail = (e) => {
    if (e.target.value === "") {
      setIsValidEmail(false);
    }
    const newEmail = e.target.value;

    setAddCustomerEmail(e.target.value);
    setIsValidEmail(validateEmail(newEmail));
  };

  return (
    <div
      className="customer_profile_start_div"
      style={{ backgroundColor: "white", width: "100%" }}
    >
      <Snackbar
        open={customerNameUpdateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {alertStatus === "updateCustomerName" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}

          >
            <Typography className="common_font">
              {customerNameUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedPhoneNumber" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerPhoneUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerAddress" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerAddressUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerEmail" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerEmailUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerProfile" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerProfileUpdatetxt}
            </Typography>
          </Alert>
        ) : null}
      </Snackbar>

      <Snackbar
        open={customercontactAddAlert}
        onClose={handleAddAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {addAlertStatus === "addEmail" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerEmailAddtxt}
            </Typography>
          </Alert>
        ) : addAlertStatus === "addPhoneNumer" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerPhoneNumberAddtxt}
            </Typography>
          </Alert>
        ) : addAlertStatus === "addAddress" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">
              {customerAddressAddtxt}
            </Typography>
          </Alert>
        ) : null}
      </Snackbar>

      <Snackbar
        open={errorAlert}
        onClose={handleErrorAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleErrorAlertClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">{errorMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={updatedPasswordAlert}
        onClose={setUpdatedPasswordAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={setUpdatedPasswordAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">{updateUserPassword}</Typography>
        </Alert>
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setModalOpen(false);

              }}
            />
            <div className="modal-sub-div">
              {modalStatus === "updateCustomerName" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Name
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="first-name"
                          >
                            First Name
                          </InputLabel>
                          <OutlinedInput
                            id="First Name"
                            label="First Name"
                            name="First Name"
                            value={updateFirstName}
                            onChange={(e) => setUpdateFirstName(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="last-name"
                          >
                            Last Name
                          </InputLabel>
                          <OutlinedInput
                            id="Last Name"
                            label="Last Name"
                            name="Last Name"
                            value={updateLastName}
                            onChange={(e) => setupdateLastName(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="common-button common_font"
                          onClick={updateUserName}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : modalStatus === "updatedPhoneNumber" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PersonIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    Edit Customer Phone
                  </h3>
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        className="common_font"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel
                          className="common_font"
                          htmlFor="Phone Number"
                        >
                          Phone Number
                        </InputLabel>
                        <OutlinedInput
                          id="Phone Number"
                          label="Phone Number"
                          name="Phone Number"
                          value={formatUsPhone(customerPhoneNumber)}
                          onChange={handleEditPhoneNumber}
                          error={!isValidPhone}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                            maxLength: 10,
                          }}
                        />
                        {!isValidPhone && (
                          <FormHelperText error>
                            Please enter a valid 10-digit phone number.
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {/* confirm Phone number input box */}

                    {/* <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel
                          className="common_font"
                          htmlFor="confirm-phone"
                        >
                          Confirm Phone Number
                        </InputLabel>
                        <OutlinedInput
                          id="confirm-phone"
                          label="Confirm Phone Number"
                          name="Confirm Phone Number"
                          value={formatUsPhone(customerConfirmPhoneNumber)}
                          onChange={(e) =>
                            setCustomerConfirmPhoneNumber(e.target.value)
                          }
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        />
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12}>
                      {customerPhoneNumber && isValidPhone ? (
                        <Button
                          className="common-button common_font"
                          onClick={() => {
                            updatePhoneNumber();
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          className="common-button common_font"
                          disabled
                          variant="contained"
                          type="submit"
                          sx={{ opacity: "40%", cursor: "not-allowed" }}
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : modalStatus === "updatedEmail" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PersonIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    Edit Customer Email
                  </h3>
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        id="edit-customer-email"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel className="common_font" htmlFor="Email">
                          Email
                        </InputLabel>
                        <OutlinedInput
                          id="Email"
                          label="Email"
                          name="Email"
                          value={customerEmail}
                          error={!isValidEmail}
                          // onChange={(e) => setCustomerEmail(e.target.value)}
                          onChange={handleEmailChange}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        />
                        {!isValidEmail && (
                          <FormHelperText error>
                            Please enter a valid email address.
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      {isValidEmail ? (
                        <Button
                          id="Edit-customer-email-update-button"
                          className="common-button common_font"
                          onClick={() => {
                            updateEmail();
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          id="Edit-customer-email-update-button"
                          className="common-button common_font"
                          sx={{ opacity: "40%", cursor: "not-allowed" }}
                          variant="contained"
                          type="button"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : modalStatus === "updateCustomerAddress" ? (
                <Box className="edit_address ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Address
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="street address"
                          >
                            Street Address
                          </InputLabel>
                          <OutlinedInput
                            id="street address"
                            label="street address"
                            name="street address"
                            value={streetAddress}
                            onChange={(e) => setstreetAddress(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="city">
                            City
                          </InputLabel>
                          <OutlinedInput
                            id="city"
                            label="city"
                            name="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="state">
                            State
                          </InputLabel>
                          <OutlinedInput
                            id="state"
                            label="state"
                            name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="country">
                            Country
                          </InputLabel>
                          <OutlinedInput
                            id="country"
                            label="country"
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="zip">
                            Zip Code
                          </InputLabel>
                          <OutlinedInput
                            id="zip"
                            label="Zip Code"
                            name="zip"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid item xs={12} className="address_submit_button">
                      <Button
                        onClick={() => {
                          updateCustomerAddress();
                        }}
                        className="common-button common_font"
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              ) : modalStatus === "updateCustomerProfile" ? (
                <Box className="edit_address ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Profile
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="Occupation"
                          >
                            <Typography sx={{ fontSize: "24px", textDecoration: "none" }}>

                            </Typography>
                            Occupation
                          </InputLabel>
                          <OutlinedInput
                            id="Occupation"
                            label="Occupation"
                            name="Occupation"
                            value={updateCustomerOccupation}
                            onChange={(e) =>
                              setUpdateCustomerOccupation(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="Gender">
                            Gender
                          </InputLabel>
                          <OutlinedInput
                            id="Gender"
                            label="Gender"
                            name="Gender"
                            value={updateCustomerGender}
                            onChange={(e) =>
                              setUpdateCustomerGender(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="DOB">
                            DOB
                          </InputLabel>
                          <OutlinedInput
                            id="DOB"
                            label="DOB"
                            name="DOB"
                            value={updateCustomerDOB}
                            onChange={(e) =>
                              setUpdateCustomerDOB(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth required>
                          <LocalizationProvider
                            id="date-picker-add-customer"
                            dateAdapter={AdapterDayjs}
                          >
                            <DemoContainer
                              id="date-picker-add-customer"
                              components={["DatePicker"]}
                              sx={{ padding: "0px !important" }}
                            >
                              <DatePicker
                                className="common_font"
                                id="date-picker-add-customer"
                                label="D.O.B"
                                value={dayjs(dateOfBirth)}
                                onChange={handleDateOfBirthChange}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    id: "ReportDate",
                                    className: "common_font",
                                    inputProps: {
                                      className: "common_font",
                                    },
                                    InputLabelProps: {
                                      className: "common_font",
                                    },
                                    InputLabel: {
                                      className: "common_font",
                                    },
                                  },
                                }}
                                InputLabelProps={{
                                  className: "common_font",
                                }}
                                inputProps={{
                                  className: "common_font",
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="MaritalStatus"
                          >
                            Marital Status
                          </InputLabel>
                          <OutlinedInput
                            id="Marital-Status"
                            label="Marital Status"
                            name="Marital Status"
                            value={updateCustomerMaritalStatus}
                            onChange={(e) =>
                              setUpdateCustomerMaritalStatus(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid item xs={12} className="address_submit_button">
                      <Button
                        onClick={() => {
                          updateCustomerProfile();
                        }}
                        className="common-button common_font"
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              ) : modalStatus === "userLoginDetails" ? (
                <>
                  <Grid item xs={12} md={6} sm={6}>
                    <Box sx={{ mb: 1 }}>
                      <Typography level="title-md">User Login Logs</Typography>
                    </Box>
                    <Divider />
                    <TableContainer className="table-container">
                      <Table size="small" aria-label="a dense table">
                        <TableHead className="table-head">
                          <TableRow
                            className="log_table_table_row  head-table-row common_header_bg "
                          // sx={{ backgroundColor: "#EEEDEB !important" }}
                          >
                            <TableCell className="common_font log_table_table_head head-table-cell">
                              #
                            </TableCell>
                            <TableCell className="common_font log_table_table_head head-table-cell">
                              Status
                            </TableCell>
                            <TableCell className="common_font log_table_table_head head-table-cell">
                              IP-Address
                            </TableCell>
                            <TableCell
                              align="center"
                              className="common_font log_table_table_head head-table-cell"
                            >
                              Type
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              align="center"
                              className="common_font log_table_table_head head-table-cell"
                            >
                              Date & Time
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {itemsToDisplay.map((item, index) => {
                            const loginDate = moment(
                              item.web_app_user_last_login
                            );
                            const formattedDate = loginDate.format(
                              "DD/MMM/YYYY HH:mm a"
                            );
                            return (
                              <TableRow className="table-row-even">
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="center"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="center"
                                >
                                  <div className="tabel-cell-Boxes">
                                    <Chip
                                      label={
                                        item.login_status
                                          ? item.login_status
                                          : ""
                                      }
                                      variant="outlined"
                                      color="success"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="center"
                                >
                                  <div className="tabel-cell-Boxes">
                                    {item.login_from_ip
                                      ? item.login_from_ip
                                      : ""}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="center"
                                >
                                  {item.login_type ? item.login_type : ""}
                                </TableCell>
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="center"
                                ></TableCell>
                                <TableCell
                                  className="body-table-cell common_font"
                                  align="right"
                                >
                                  {formattedDate ? formattedDate : ""}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className="pagination-box">
                      <Pagination
                        className="pagination"
                        count={Math.ceil(allLoginDetails.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                        siblingCount={1}
                        boundaryCount={1}
                      />
                    </Box>
                  </Grid>
                </>
              ) : modalStatus === "changePassword" ? (
                <>
                  <Box className="edit_address ">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": {
                              m: 1,
                              minWidth: "40ch",
                            },
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <h3
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                            className="common_font"
                          >
                            <KeyIcon
                              fontSize="medium"
                              sx={{ marginRight: "5px" }}
                            />
                            Reset Password
                          </h3>

                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            id=""
                          >
                            <InputLabel
                              className="common_font"
                              htmlFor="outlined-adornment-password"
                            >
                              Password
                            </InputLabel>
                            <OutlinedInput
                              id="edit-user-details-password-adornment"
                              type={showPassword ? "text" : "password"}
                              fullWidth
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              label="Password"
                            />
                          </FormControl>
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            id=""
                          >
                            <InputLabel
                              className="common_font"
                              htmlFor="outlined-adornment-password"
                            >
                              Confirm Password
                            </InputLabel>
                            <OutlinedInput
                              id="edit-user-details-confirm-password-adornment"
                              type={showPassword ? "text" : "password"}
                              fullWidth
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              value={confirmPassword}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              label="Password"
                            />
                          </FormControl>

                          <Grid
                            sx={{ width: "100%" }}
                            item
                            xs={12}
                            className="address_submit_button"
                          >
                            {password && confirmPassword ? (
                              <Button
                                id="edit-user-details-update-password"
                                className="common-button common_font"
                                variant="contained"
                                type="submit"
                                onClick={changeUserPassword}
                                sx={{ float: "left !important" }}
                              >
                                Reset Password
                              </Button>
                            ) : (
                              <Button
                                id="edit-user-details-update-password"
                                className="common-button common_font"
                                variant="contained"
                                type="submit"
                                sx={{
                                  float: "left !important",
                                  opacity: "40%",
                                }}
                                disabled
                              >
                                Reset Password
                              </Button>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : null}
            </div>
          </Box>
        </>
      </Modal>

      <Modal
        open={addModalOpen}
        onClose={() => {
          handleAddClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setAddModalOpen(false);
                setAddCustomerEmail("")
                setAddCustomerPhoneNumber("")
              }}
            />
            <div className="modal-sub-div">
              {addModalStatus === "addEmail" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add New Email
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="email">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="email"
                            label="Email"
                            name="Email"
                            value={addCustomerEmail}
                            error={!validateEmail}
                            onChange={handleAddEmail}
                            // onChange={(e) =>
                            //   setAddCustomerEmail(e.target.value)
                            // }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                          {!isValidEmail && (
                            <FormHelperText error>
                              Please enter a valid email address.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        {isValidEmail ? (
                          <Button
                            id="add-email-modal-id"
                            className="common-button common_font"
                            onClick={AddEmail}
                            variant="contained"
                            type="submit"
                          >
                            Add Email
                          </Button>
                        ) : (
                          <Button
                            id="add-email-modal-id"
                            className="common-button common_font"
                            disabled
                            sx={{ cursor: "not-allowed", opacity: "40%" }}
                            variant="contained"
                            type="submit"
                          >
                            Add Email
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : addModalStatus === "addPhoneNumber" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Phone Number
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="Phone Number"
                          >
                            Phone Number
                          </InputLabel>
                          <OutlinedInput
                            id="Phone Number"
                            label="Phone Number"
                            name="Phone Number"
                            value={formatUsPhone(addCustomerPhoneNumber)}
                            error={!isValidPhone}
                            onChange={handleAddPhone}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                              maxLength: 10,
                            }}
                          />
                          {!isValidPhone && (
                            <FormHelperText error>
                              Please enter a valid Phone Number.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        {isValidPhone ? (
                          <Button
                            id="add-phone-id"
                            className="common-button common_font"
                            onClick={AddPhoneNumber}
                            variant="contained"
                            type="submit"
                          >
                            Add Phone Number
                          </Button>
                        ) : (
                          <Button
                            id="add-email-modal-id"
                            sx={{ opacity: "40%", cursor: "not-allowed" }}
                            className="common-button common_font"
                            onClick={AddEmail}
                            disabled
                            variant="contained"
                            type="button"
                          >
                            Add Phone Number
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : addModalStatus === "addAddress" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Address
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="Street Address"
                          >
                            Street Address
                          </InputLabel>
                          <OutlinedInput
                            id="Street Address"
                            label="Street Address"
                            name="Street Address"
                            value={addStreetAdd}
                            onChange={(e) => setAddStreetAdd(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="City">
                            {" "}
                            City
                          </InputLabel>
                          <OutlinedInput
                            id="City"
                            label="City"
                            name="City"
                            value={addCity}
                            onChange={(e) => setAddCity(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="State">
                            State{" "}
                          </InputLabel>
                          <OutlinedInput
                            id="State"
                            label="State"
                            name="State"
                            value={addState}
                            onChange={(e) => setAddState(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="Country">
                            Country{" "}
                          </InputLabel>
                          <OutlinedInput
                            id="Country"
                            label="Country"
                            name="Country"
                            value={addCountry}
                            onChange={(e) => setAddCountry(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="Zip Code"
                          >
                            Zip Code
                          </InputLabel>
                          <OutlinedInput
                            id="Zip Code"
                            label="Zip Code"
                            name="Zip Code"
                            value={addZip}
                            onChange={(e) => setAddZip(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          className="common-button common_font"
                          onClick={AddAddress}
                          variant="contained"
                          type="submit"
                        >
                          Add Address
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null}
            </div>
          </Box>
        </>
      </Modal>

      <div className="customer_profile_main_div common_card_bs">
        <div className="customer_profile_div">
          {/* <Grid container spacing={2}> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={4}> */}
          <Box className="customer_profile_box">
            <div className="customer_profile_box_div_head edit-user-details-box-header">
              <Typography className="common_font">Your Profile</Typography>
              <Box className="customer_profile_box_div_head_box">
                <Typography className="customer_profile_box_div_head_box_typography common_font">
                  Created
                </Typography>
                <Typography className="common_font">
                  {customerCreatedDate}
                </Typography>
              </Box>
            </div>

            <div className="customer_profile_box_div_middle">
              <img
                src={userProfile}
                className="customer_profile_box_div_head_img"
                alt="userProfile"
              />
            </div>

            <div className="customer_profile_box_div_body">
              <Typography className="customer_profile_box_div_body_typography common_font">
                {firstName} {lastName}
              </Typography>

              <CreateIcon
                onClick={() => {
                  clickEditNames();
                }}
                sx={{ cursor: "pointer" }}
              />
            </div>
          </Box>
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={4}> */}
          <Box className="customer_profile_box_email">
            <div className="edit_user_details_box_div_head_email edit_user_details_box_div_header">
              <Typography className="common_font">Emails</Typography>
            </div>

            <div className="customer_profile_box_div_body_email">
              <div className="email-div-height ">
                {getFilterEmailData.map((ccd, index) => {
                  const isPrimary = (ccd.contact_priority === "1");
                  console.log(index);
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",

                            marginTop: "10px",
                            alignItems: "center",
                          }}
                          className="customer_profile_box_div_body_email_txt common_font"
                        >
                          <Box
                            className="common_font"
                            sx={{
                              display: "flex",
                              gap: "5px",
                              marginTop: "10px",
                            }}
                          >
                            {isPrimary ? (
                              <Typography className="customer_profile_box_div_body_email_typography   common_font">
                                Primary
                              </Typography>
                            ) : (
                              ""
                            )}
                            <span>{ccd.contact_details}</span>
                          </Box>
                        </Box>
                        <CreateIcon
                          id={`edit-icon-${index}`}
                          onClick={() => {
                            clickEditEmail(ccd);
                          }}
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </Box>
                    </>
                  );
                })}
              </div>
              <Button
                id="add-email-id"
                onClick={() => {
                  clickAddEmail();
                }}
                className="customer_profile_box_div_body_email_button common_font"
              >
                Add Email
              </Button>
            </div>
          </Box>

          <Box className="edit-user-details-box-email-2">
            <div className="edit_user_details_box_div_head_email edit_user_details_box_div_header">
              <Typography className="common_font">Phone Numbers</Typography>
            </div>

            <div className="customer_profile_box_div_body_email">
              <div className="email-div-height ">
                {getFilterPhoneData.map((ccd, index) => {
                  console.log(ccd);
                  const isContactPrimary =
                    ccd.contact_priority === "1" || index === 0;
                  const PhoneNumberUpdatedDate = ccd.updated_at;
                  const PhoneNumberParsedDate = parseISO(
                    PhoneNumberUpdatedDate
                  );
                  let PhoneNumberFormattedDate = format(
                    PhoneNumberParsedDate,
                    "dd MMM yyyy"
                  );
                  console.log(PhoneNumberFormattedDate);
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            marginLeft: "10px",
                            display: "flex",
                            gap: "5px",
                          }}
                          className="customer_profile_box_div_body_email_txt common_font"
                        >
                          {isContactPrimary ? (
                            <Typography className="customer_profile_box_div_body_email_typography common_font">
                              Primary
                            </Typography>
                          ) : (
                            " "
                          )}
                          <span>{ccd.contact_details}</span>
                        </Typography>
                        <CreateIcon
                          onClick={() => {
                            clickEditPhoneNumber(ccd);
                          }}
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </Box>
                    </>
                  );
                })}
              </div>

              <Button
                onClick={() => {
                  clickAddPhoneNumber();
                }}
                className="customer_profile_box_div_body_email_button common_font"
              >
                Add Phone Number
              </Button>
            </div>
          </Box>

          <Box className="customer_profile_box_address">
            <div className="edit_user_details_box_div_head_email edit_user_details_box_div_header">
              <Typography className="common_font">Address</Typography>
            </div>

            <div className="customer_profile_box_div_body_email">
              <div className="email-div-height ">
                {customerAddress.map((item, index) => {
                  const isAddress = index === 0;

                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                      className="customer_profile_box_div_body_email_txt common_font"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {isAddress ? (
                          <Typography className="customer_profile_box_div_body_email_typography common_font">
                            Primary
                          </Typography>
                        ) : (
                          " "
                        )}
                        <Typography
                          key={index}
                          sx={{
                            marginLeft: "10px",
                            marginTop: "10px !important",
                          }}
                          className="common_font"
                        >
                          {item.street_address}, {item.city}, {item.state},{" "}
                          {item.country}
                        </Typography>
                      </Box>
                      <CreateIcon
                        id={`create-icon-${index}`}
                        onClick={() => {
                          clickAddressEdit(item);
                        }}
                        sx={{ cursor: "pointer", marginRight: "10px" }}
                      />
                    </Box>
                  );
                })}
              </div>

              <Button
                onClick={() => {
                  clickAddAddress();
                }}
                className="customer_profile_box_div_body_email_button common_font"
              >
                Add Address
              </Button>
            </div>
          </Box>

          <Box className="customer_profile_box_personal_information">
            <div className="edit_user_details_box_div_head_email edit_user_details_box_div_header">
              <Typography className="common_font">
                Personal Information
              </Typography>
              <CreateIcon
                onClick={() => {
                  clickPersonalinfoEdit();
                }}
                sx={{ cursor: "pointer" }}
              />
            </div>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div className="customer_profile_box_div_body_email">
                <Typography
                  sx={{ marginLeft: "10px", marginTop: "10px !important" }}
                  className="customer_profile_box_div_body_address_typography common_font"
                >
                  Occupation
                </Typography>

                <Typography
                  sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                  className="customer_profile_box_div_body_address_txt common_font"
                >
                  {customerOccupation}
                </Typography>

                <Typography
                  sx={{ marginLeft: "10px", marginTop: "10px !important" }}
                  className="customer_profile_box_div_body_address_typography common_font"
                >
                  Gender
                </Typography>

                <Typography
                  sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                  className="customer_profile_box_div_body_address_txt common_font"
                >
                  {customerGender}
                </Typography>

                <Typography
                  sx={{ marginLeft: "10px", marginTop: "10px !important" }}
                  className="customer_profile_box_div_body_address_typography common_font"
                >
                  Date Of Birth
                </Typography>
                <Typography
                  sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                  className="customer_profile_box_div_body_address_txt common_font"
                >
                  {customerDOB}
                </Typography>
              </div>
              <div className="customer_profile_box_div_body_email">
                <Typography
                  sx={{ marginLeft: "10px", marginTop: "10px !important" }}
                  className="customer_profile_box_div_body_address_typography common_font"
                >
                  Marital Status
                </Typography>

                <Typography
                  sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                  className="customer_profile_box_div_body_address_txt common_font"
                >
                  {customerMaritalStatus}
                </Typography>
                <Typography
                  sx={{ marginTop: "0px !important" }}
                  className="customer_profile_box_div_body_address_txt common_font"
                >
                  <Button
                    id="edit-user-details-reset-button"
                    className="common_font common-button"
                    sx={{ margin: "10px" }}
                    variant="text"
                    onClick={handleChangePassword}
                  >
                    Reset Password
                  </Button>
                </Typography>
              </div>
            </Box>
          </Box>

          <Box className="customer_profile_box_address">
            <div className="edit_user_details_box_div_head_email edit_user_details_box_div_header">
              <Typography className="common_font">
                {" "}
                Recent Login Status
              </Typography>
            </div>
            {
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <Box
                      sx={{ flexDirection: "column" }}
                      className="user-profile-second-card-first-div-sub-box "
                    >
                      <div className="user-profile-common-display">
                        <Typography className="user-profile-recent-login-status-typography common_font">
                          Login Status{" "}
                        </Typography>
                        <Typography className="user-profile-recent-login-status-txt common_font">
                          {LoginStatus ? LoginStatus : ""}
                        </Typography>
                      </div>

                      <div
                        style={{ marginTop: "10px" }}
                        className="user-profile-common-display"
                      >
                        <Typography className="user-profile-recent-login-status-typography common_font">
                          Login Type{" "}
                        </Typography>
                        <Typography className="common_font">
                          {loginType ? loginType : ""}
                        </Typography>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className="user-profile-common-display"
                      >
                        <Typography className="user-profile-recent-login-status-typography common_font">
                          Login IP{" "}
                        </Typography>
                        <Typography className="common_font">
                          {loginIp ? loginIp : ""}
                        </Typography>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className="user-profile-common-display"
                      >
                        <Typography className="user-profile-recent-login-status-typography common_font">
                          Last Login Time{" "}
                        </Typography>
                        <Typography className="common_font">
                          {lastLogin ? formattedDate : ""}
                        </Typography>
                      </div>
                      <Box onClick={handleLoginDetails}>
                        <Typography
                          className="common_font"
                          sx={{
                            textAlign: "end",
                            fontSize: "14px",
                            marginTop: "15px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Learn More
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            }
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EditUserDetails1;

/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import { GET_ALL_PRODUCT_LIST, CREATE_PRODUCT } from "../../config/config";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Paper, Tooltip, Stack, Chip, Button } from "@mui/material";
import DoneOutlineSharpIcon from "@mui/icons-material/DoneOutlineSharp";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const CreateProducts = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  // Variables for Listing out the data
  const [product_list, setProductList] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [gripList, setGripList] = useState([]);
  const [inkTypeList, setInkTypeList] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [pointTypeList, setPointTypeList] = useState([]);
  const [mechanismList, setMechanismList] = useState([]);
  const [refillabilityList, setRefillabilityList] = useState([]);

  const token = localStorage.getItem("token");

  const [brands, setBrands] = useState("");
  const [brandId, setBrandId] = useState("");
  const [colors, setColors] = useState("");
  const [type, setType] = useState("");
  const [penName, setPenName] = useState("");
  const [priceInDollars, setPriceinDollars] = useState("");
  const [priceInRs, setPriceinRs] = useState("");
  const [grip, setGrip] = useState("");
  const [inkType, setInkType] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [diameter, setDiameter] = useState("");
  const [length, setLength] = useState("");
  const [weight, setWeight] = useState("");
  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [pointType, setPointType] = useState("");
  const [mechanism, setMechanism] = useState("");
  const [refillability, setRefillability] = useState("");
  const [manufacturesWebsite, setManufacturesWebsite] = useState("");

  const [createProductAlert, setCreateProductAlert] = useState(false);
  const [createProductAlertMsg, setCreateProductAlertMsg] = useState("");
  const [createProductError, setCreateProductError] = useState(false);
  const [createProductErrorMsg, setCreateProductErrorMsg] = useState("");

  const handleBrandChange = (event) => {
    const selectedBrandName = event.target.value;
    // Find the corresponding brand object from the product_list
    const selectedBrand = product_list.find(
      (product) => product.brand_name === selectedBrandName
    );
    // Extract the brand_id from the selected brand object and set it
    setBrandId(selectedBrand.brand_id);
    // Set the selected brand name
    setBrands(selectedBrandName);
  };

  console.log(brandId);
  console.log(brands);

  const handlePenNameChange = (event) => {
    setPenName(event.target.value);
  };

  // Exchange rate from Dollar to INR
  const exchangeRate = 82.9;

  const handlePriceInDollarsChange = (event) => {
    let newPriceInDollars = event.target.value;
    // Allow only digits and a single '.' character
    newPriceInDollars = newPriceInDollars.replace(/[^\d.]/g, "");
    // Ensure '.' occurs only once
    const dotIndex = newPriceInDollars.indexOf(".");
    if (dotIndex !== -1) {
      newPriceInDollars =
        newPriceInDollars.substring(0, dotIndex + 1) +
        newPriceInDollars.substring(dotIndex + 1).replace(".", "");
    }
    setPriceinDollars(newPriceInDollars);
    if (newPriceInDollars !== "") {
      const newPriceInRs = (
        parseFloat(newPriceInDollars) * exchangeRate
      ).toFixed(2);
      setPriceinRs(newPriceInRs);
    } else {
      setPriceinRs("");
    }
  };

  const handlePriceInRsChange = (event) => {
    let newPriceInRs = event.target.value;
    // Allow only digits and a single '.' character
    newPriceInRs = newPriceInRs.replace(/[^\d.]/g, "");
    // Ensure '.' occurs only once
    const dotIndex = newPriceInRs.indexOf(".");
    if (dotIndex !== -1) {
      newPriceInRs =
        newPriceInRs.substring(0, dotIndex + 1) +
        newPriceInRs.substring(dotIndex + 1).replace(".", "");
    }
    setPriceinRs(newPriceInRs);
    if (newPriceInRs !== "") {
      const newPriceInDollars = (
        parseFloat(newPriceInRs) / exchangeRate
      ).toFixed(2);
      setPriceinDollars(newPriceInDollars);
    } else {
      setPriceinDollars("");
    }
  };

  const handleDiameterChange = (event) => {
    // Remove non-numeric characters from the input value
    let newDiameter = event.target.value.replace(/\D/g, "");
    // Limit to 3 characters
    newDiameter = newDiameter.slice(0, 3);
    setDiameter(newDiameter);
  };

  const handleLengthChange = (event) => {
    // Remove non-numeric characters from the input value
    let newLength = event.target.value.replace(/\D/g, "");
    newLength = newLength.slice(0, 3);
    setLength(newLength);
  };

  const handleWeightChange = (event) => {
    // Remove non-numeric characters from the input value
    let newWeight = event.target.value.replace(/\D/g, "");
    newWeight = newWeight.slice(0, 3);
    setWeight(newWeight);
  };

  const handleSKUChange = (event) => {
    let newSku = event.target.value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 10);
    setSku(newSku);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    getProductsList();
  }, []);

  console.log("Hi");

  const getProductsList = async () => {
    await fetch(GET_ALL_PRODUCT_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          // Set Brands List
          const brandsdata = data.data.Product_list.map(
            (product) => product.brand_name
          );
          const uniqueBrands = Array.from(new Set(brandsdata)); // Filter out duplicate brand names
          setBrandNames(uniqueBrands);

          //   Set Colors
          const colorlist = data.data.Product_list.map(
            (product) => product.colours
          );
          const uniqueColors = Array.from(new Set(colorlist));
          setColorsList(uniqueColors);

          //   Set Type
          const typelist = data.data.Product_list.map(
            (product) => product.pen_type
          );
          const uniqueType = Array.from(new Set(typelist));
          setTypeList(uniqueType);

          // Set Grip
          const gripList = data.data.Product_list.map(
            (product) => product.grip_type
          );
          const uniqueGrip = Array.from(new Set(gripList));
          setGripList(uniqueGrip);

          //   Set Ink Type
          const inkList = data.data.Product_list.map(
            (product) => product.ink_type
          );
          const uniqueInkType = Array.from(new Set(inkList));
          setInkTypeList(uniqueInkType);

          //   Set Material Type
          const materialList = data.data.Product_list.map(
            (product) => product.material_type
          );
          const uniqueMaterialType = Array.from(new Set(materialList));
          setMaterialTypeList(uniqueMaterialType);

          //   Set Point Type
          const poittypelist = data.data.Product_list.map(
            (product) => product.point_type
          );
          const uniquePointType = Array.from(new Set(poittypelist));
          setPointTypeList(uniquePointType);

          // Set Mechanism
          const mechanismlist = data.data.Product_list.map(
            (product) => product.mechanism
          );
          const uniqueMechanism = Array.from(new Set(mechanismlist));
          setMechanismList(uniqueMechanism);

          // Set Refillability
          const refillabilitylist = data.data.Product_list.map(
            (product) => product.refillability
          );
          const uniqueRefillability = Array.from(new Set(refillabilitylist));
          setRefillabilityList(uniqueRefillability);

          console.log(data.data);
          console.log("Hello");
          setProductList(data.data.Product_list);
        } else {
          console.log("Error");
        }
      });
  };

  console.log(brandNames);
  console.log(colorsList);
  console.log(typeList);
  console.log(gripList);
  console.log(inkTypeList);
  console.log(materialTypeList);
  console.log(mechanismList);
  console.log(pointTypeList);
  console.log(refillabilityList);
  console.log(typeof colors);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch(CREATE_PRODUCT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        sku: sku,
        brand_id: brandId,
        product_category: "Pens",
        pen_type: type,
        pen_name: penName,
        colours: selectedChips.join(","),
        product_pricing_rs: parseFloat(priceInRs),
        product_pricing_usd: parseFloat(priceInDollars),
        material_type: materialType,
        ink_type: inkType,
        grip_type: grip,
        refillability: refillability,
        point_type: pointType,
        special_features: description,
        pen_length: parseFloat(length),
        pen_weight: parseFloat(weight),
        pen_diameter: parseFloat(diameter),
        manufactures_website: manufacturesWebsite,
        mechanism: mechanism,
      }),
    });

    const data = await response.json();
    console.log(data);
    if (data.success === true) {
      // Product created successfully
      setCreateProductAlert(true);
      setCreateProductAlertMsg(data.message);
      console.log("Product created successfully");
      setTimeout(() => {
        navigate(`/${lng}/products-list`);
      }, 3001); // 3001 milliseconds = 3 seconds
    } else {
      console.error("Failed to create product");
      setCreateProductError(true);
      setCreateProductErrorMsg(data.message);
    }
  };

  const rainbowColors = [
    { color: "#9400D3", name: "Violet" },
    { color: "#4B0082", name: "Indigo" },
    { color: "#0000FF", name: "Blue" },
    { color: "#00FF00", name: "Green" },
    { color: "#FFFF00", name: "Yellow" },
    { color: "#FF7F00", name: "Orange" },
    { color: "#FF0000", name: "Red" },
    { color: "#000000", name: "Black" },
    { color: "#FFFFFF", name: "White" },
    { color: "#A52A2A", name: "Brown" },
    { color: "#FFC0CB", name: "Pink" },
    { color: "#800080", name: "Purple" },
    { color: "#808080", name: "Grey" },
  ];
  const [selectedChips, setSelectedChips] = React.useState([]);

  const handleClick = (name) => {
    if (selectedChips.includes(name)) {
      setSelectedChips(selectedChips.filter((chip) => chip !== name));
    } else {
      setSelectedChips([...selectedChips, name]);
    }
  };

  const handleAlertClose = () => {
    setCreateProductAlert(false);
    setCreateProductError(false);
  };
  const vertical = "top";
  const horizontal = "center";
  console.log(typeof selectedChips);
  console.log(selectedChips.length);
  return (
    <>
      <div className="common-margin-in-pages">
        <Snackbar
          open={createProductAlert}
          anchorOrigin={{ vertical, horizontal }}
          onClose={() => handleAlertClose()}
          className="snackBar-alert"
        >
          <Alert
            onClose={handleAlertClose}
            severity="success"
            // sx={{ width: "100%" }}
            className="common-success-alert formcontrol-width"
          >
            <Typography className="common_font">
              {createProductAlertMsg}
            </Typography>
          </Alert>
        </Snackbar>

        <Snackbar
          open={createProductError}
          anchorOrigin={{ vertical, horizontal }}
          onClose={() => handleAlertClose()}
          className="snackBar-alert"
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            // sx={{ width: "100%" }}
            className="common-error-alert formcontrol-width"
          >
            <Typography className="common_font">
              {createProductErrorMsg}
            </Typography>
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Card
          id="landing_card"
          className="landing_card landing_card_res common_card_bs"
        >
          <CardContent className="customer_details_card">
            <Box id="add_pen_box">
              <Box
                id="add_pen_formcontrol_box"
                className="add_pen_formcontrol_box"
              >
                <Grid container spacing={2}>
                  {/* Vertical Stack 1 */}
                  <Grid item xs={6}>
                    <Paper
                      elevation={3}
                      // style={{ padding: 20 }}
                      className="add-product-paper"
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <FormControl
                            id="add_pen_brand_formControl"
                            className="formcontrol-width"
                            // sx={{ width: "100%" }}
                          >
                            <InputLabel
                              className="common_font"
                              id="brands-label"
                            >
                              Brands
                            </InputLabel>
                            <Select
                              labelId="brands-label"
                              id="brands-select"
                              value={brands}
                              label="Brands"
                              onChange={handleBrandChange}
                              className="formcontrol-width"
                              // sx={{ width: "100%" }}
                            >
                              {brandNames.map((brand, index) => (
                                <MenuItem
                                  id={`brands-menu-item-${index}`}
                                  key={index}
                                  value={brand}
                                >
                                  <Typography className="common_font">
                                    {brand}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <br />
                          <br />
                          <FormControl
                            id="add_pen_brand_formControl"
                            className="formcontrol-width"

                            // sx={{ width: "100%" }}
                          >
                            <InputLabel
                              className="common_font"
                              id="color-label"
                            >
                              Pen Type
                            </InputLabel>
                            <Select
                              labelId="type-label"
                              id="pen-type"
                              value={type}
                              label="Pen Type"
                              onChange={(e) => setType(e.target.value)}
                            >
                              {typeList.map((type, index) => (
                                <MenuItem
                                  id={`colors-menu-item-${index}`}
                                  key={index}
                                  value={type}
                                >
                                  <Typography className="common_font">
                                    {type}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <br />
                          <br />
                          <TextField
                            id="outlined-basic"
                            label="Price ($)"
                            variant="outlined"
                            value={priceInDollars}
                            onChange={handlePriceInDollarsChange}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            className="formcontrol-width"
                            // sx={{ width: "100%" }}
                          />
                          <br />
                          <br />
                          <TextField
                            id="outlined-basic"
                            label="Price (Rs.)"
                            variant="outlined"
                            value={priceInRs}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            onChange={handlePriceInRsChange}
                            inputProps={{
                              className: "common_font",
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            className="formcontrol-width"
                            // sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  {/* Vertical Stack 2 */}
                  <Grid item xs={6}>
                    <Paper
                      elevation={3}
                      className="add-product-paper"
                      // style={{ padding: 20 }}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={1}
                            className="product-stack"
                            // sx={{ paddingTop: "15px", paddingBottom: "15px" }}
                          >
                            <Typography className="product_details_brand_typography common_font">
                              Color &nbsp;
                            </Typography>

                            {rainbowColors.map(({ color, name }, index) => (
                              <Tooltip key={index} title={name}>
                                <Chip
                                  className={
                                    selectedChips.includes(name)
                                      ? "create-product-colors selected"
                                      : "create-product-colors"
                                  }
                                  onClick={() => handleClick(name)}
                                  style={{ backgroundColor: color }}
                                  variant="filled"
                                  size="small"
                                  icon={
                                    selectedChips.includes(name) ? (
                                      <DoneOutlineSharpIcon
                                        color="success"
                                        className="color-select"
                                      />
                                    ) : null
                                  }
                                />
                              </Tooltip>
                            ))}
                          </Stack>

                          <br />

                          <TextField
                            id="outlined-basic"
                            label="Pen Name"
                            variant="outlined"
                            value={penName}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                            onChange={handlePenNameChange}
                            className="formcontrol-width"
                            // sx={{ width: "100%" }} // Set the width to 100%
                          />

                          <br />
                          <br />
                          <TextField
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={4.4}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                            value={description}
                            onChange={handleDescriptionChange}
                            className="formcontrol-width"
                            // sx={{ width: "100%" }} // Set the width to 100%
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <br />

                <h3>Specification</h3>
                <Grid container spacing={2}>
                  {/* Vertical Stack 1 */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Paper
                      elevation={3}
                      className="add-product-paper"
                      // style={{ padding: 20 }}
                    >
                      <Grid container direction="column" spacing={5}>
                        <Grid item>
                          <Stack direction="row" spacing={7}>
                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="color-label"
                              >
                                Grip Type
                              </InputLabel>
                              <Select
                                labelId="color-label"
                                id="colors-select"
                                value={grip}
                                label="Grip Type"
                                onChange={(e) => setGrip(e.target.value)}
                              >
                                {gripList.map((grip, index) => (
                                  <MenuItem
                                    id={`colors-menu-item-${index}`}
                                    key={index}
                                    value={grip}
                                  >
                                    <Typography className="common_font">
                                      {grip}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="color-label"
                              >
                                Ink Type
                              </InputLabel>
                              <Select
                                labelId="color-label"
                                id="ink-type"
                                value={inkType}
                                label="Ink Type"
                                onChange={(e) => setInkType(e.target.value)}
                              >
                                {inkTypeList.map((ink, index) => (
                                  <MenuItem
                                    id={`colors-menu-item-${index}`}
                                    key={index}
                                    value={ink}
                                  >
                                    <Typography className="common_font">
                                      {ink}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>
                          <Stack direction="row" spacing={7}>
                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="color-label"
                              >
                                Material Type
                              </InputLabel>
                              <Select
                                labelId="color-label"
                                id="colors-select"
                                value={materialType}
                                label="Material Type"
                                onChange={(e) =>
                                  setMaterialType(e.target.value)
                                }
                              >
                                {materialTypeList.map((material, index) => (
                                  <MenuItem
                                    id={`colors-menu-item-${index}`}
                                    key={index}
                                    value={material}
                                  >
                                    <Typography className="common_font">
                                      {material}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="mechanism-label"
                              >
                                Mechanism
                              </InputLabel>
                              <Select
                                labelId="mechanism-label"
                                id="mechanism-select"
                                value={mechanism}
                                label="Mechanism"
                                onChange={(e) => setMechanism(e.target.value)}
                              >
                                {mechanismList.map((mechanism, index) => (
                                  <MenuItem
                                    id={`mechanism-menu-item-${index}`}
                                    key={index}
                                    value={mechanism}
                                  >
                                    <Typography className="common_font">
                                      {mechanism}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>

                          <Stack direction="row" spacing={7}>
                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="point-type-label"
                              >
                                Point Type
                              </InputLabel>
                              <Select
                                labelId="point-type-label"
                                id="point-type-select"
                                value={pointType}
                                label="Point Type"
                                onChange={(e) => setPointType(e.target.value)}
                              >
                                {pointTypeList.map((pointType, index) => (
                                  <MenuItem
                                    id={`point-type-menu-item-${index}`}
                                    key={index}
                                    value={pointType}
                                  >
                                    <Typography className="common_font">
                                      {pointType}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              id="add_pen_brand_formControl"
                              className="add_pen_formControl"
                            >
                              <InputLabel
                                className="common_font"
                                id="point-type-label"
                              >
                                Refillability
                              </InputLabel>
                              <Select
                                labelId="point-type-label"
                                id="point-type-select"
                                value={refillability}
                                label="Refillability"
                                InputLabelProps={{
                                  className: "common_font",
                                }}
                                inputProps={{
                                  className: "common_font",
                                }}
                                onChange={(e) =>
                                  setRefillability(e.target.value)
                                }
                              >
                                {refillabilityList.map(
                                  (refillability, index) => (
                                    <MenuItem
                                      id={`point-type-menu-item-${index}`}
                                      key={index}
                                      value={refillability}
                                    >
                                      <Typography className="common_font">
                                        {refillability}
                                      </Typography>
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Stack>
                          <br />
                          <Stack direction="row" spacing={7}>
                            <TextField
                              id="outlined-basic"
                              label="Diameter (mm)"
                              variant="outlined"
                              value={diameter}
                              helperText={
                                diameter.length === 3 ? (
                                  <span className="warning-text">
                                    Maximum 3 digits allowed
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              onChange={handleDiameterChange}
                              className="small-textfields"
                            />
                            <TextField
                              id="outlined-basic"
                              label="Length (mm)"
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              variant="outlined"
                              value={length}
                              helperText={
                                length.length === 3 ? (
                                  <span className="warning-text">
                                    Maximum 3 digits allowed
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                              onChange={handleLengthChange}
                              className="small-textfields"
                              // sx={{ width: "50%" }} // Set the width to 100%
                            />
                            <TextField
                              id="outlined-basic"
                              label="Weight (gm)"
                              variant="outlined"
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              value={weight}
                              helperText={
                                weight.length === 3 ? (
                                  <span className="warning-text">
                                    Maximum 3 digits allowed
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                              onChange={handleWeightChange}
                              className="small-textfields"
                              // sx={{ width: "50%" }} // Set the width to 100%
                            />
                            <TextField
                              id="outlined-basic"
                              label="SKU"
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              variant="outlined"
                              value={sku}
                              helperText={
                                sku.length > 0 ? (
                                  sku.length === 10 ? (
                                    <span className="success-text">
                                      {/* SKU is exactly 10 characters */}
                                    </span>
                                  ) : (
                                    <span className="warning-text">
                                      SKU must be of 10 characters
                                    </span>
                                  )
                                ) : null
                              }
                              onChange={handleSKUChange}
                              className="small-textfields"
                            />
                          </Stack>
                          <br />
                          <TextField
                            id="outlined-basic"
                            label="Manufacturers Website"
                            variant="outlined"
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                            value={manufacturesWebsite}
                            onChange={(e) =>
                              setManufacturesWebsite(e.target.value)
                            }
                            className="formcontrol-width"
                            // sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <br />

                {brands &&
                type &&
                penName &&
                priceInDollars &&
                priceInRs &&
                grip &&
                inkType &&
                materialType &&
                diameter &&
                length &&
                weight &&
                description &&
                pointType &&
                mechanism &&
                refillability &&
                manufacturesWebsite &&
                selectedChips.length !== 0 ? (
                  sku.length === 10 ? (
                    <Button
                      className="common_font common-button"
                      sx={{ width: "150px" }}
                      onClick={handleSubmit}
                    >
                      Create Product
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="common_font common-button"
                      sx={{
                        width: "150px",
                        opacity: "40%",
                        cursor: "not-allowed",
                      }}
                    >
                      Create Product
                    </Button>
                  )
                ) : (
                  <Button
                    disabled
                    className="common_font common-button"
                    sx={{
                      width: "150px",
                      opacity: "40%",
                      cursor: "not-allowed",
                    }}
                  >
                    Create Product
                  </Button>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

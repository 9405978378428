import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { USER_DETAILS, CUSTOMER_DETAILS } from "../../config/config";
import "../../Styles/customerInfoAdmin.css";
import male_icon from "../../assets/img/male_icon.png";
import femalee_icon from "../../assets/img/female_icon.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import PersonIcon from "@mui/icons-material/Person";
import Skeleton from "@mui/material/Skeleton";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const moment = require("moment");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "80px",
  width: "auto",
  boxShadow: "0px 0px 3px",
  alignItems: "center",
}));

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "405px",
  width: "auto",
  boxShadow: "0px 0px 3px",
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "auto",
  width: "auto",
  boxShadow: "0px 0px 3px",
}));

const AdminInfo = () => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const {
    heading,
    customerDetails,
    totalSalesRep,
    totalAdmin,
    activeUsers,
    deactiveUsers,
    viewAll,
  } = t("manage-users");

  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const Token = localStorage.getItem("token");
  const [userEmail, setuserEmail] = useState("");
  console.log(userEmail);
  const [alluserList, setAllUserList] = useState([]);
  const [activeUserList, setActiveUserList] = useState([]);
  const [deactiveUserList, setdeActiveUserList] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [customerFullName, setCustomerFullName] = useState([]);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [customerContact, setCustomerContact] = useState([]);
  console.log(customerContact);
  const [personId, setpersonId] = useState("");

  console.log(customerFullName);

  console.log();
  const userList = () => {
    fetch(USER_DETAILS, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: Token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        if (data.success === true) {
          setAllUserList(data.data.user_list);
          console.log(data.data.user_list);

          console.log(data.data.user_list[0].person_id);
          const activeUsers = data.data.user_list.filter(
            (user) => user.user_status === "active"
          );

          const deActiveUsers = data.data.user_list.filter(
            (user) => user.user_status === "inactive"
          );

          const totalAdmin = data.data.user_list.filter(
            (user) => user.user_type === "Admin"
          );

          const totalSalesRep = data.data.user_list.filter(
            (user) => user.user_type === "User"
          );

          console.log(activeUsers);
          console.log(data.data);

          setuserEmail(data.data.user_list[0].user_id);
          setActiveUserList(activeUsers);
          setdeActiveUserList(deActiveUsers);
          setAdmin(totalAdmin);
          setSalesRep(totalSalesRep);
          CustomerDetails(data.data.user_list[0].person_id);
          setpersonId(data.data.user_list[0].person_id);
        }
      });
  };

  const CustomerDetails = async (id) => {
    const token = localStorage.getItem("token");

    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          const contact = [];
          contact.push(data.data);
          const mappedPhone = contact[0].Customer_contact_details.filter(
            (item) => item.contact_type === "phone"
          );
          console.log(mappedPhone);
          setCustomerFullName(data.data.Customer_details[0]);
          setCustomerAddress(data.data.Customer_address[0]);
          setCustomerContact(mappedPhone);
        }
      });
  };

  const userInfo = async (id, email) => {
    setpersonId(id);
    console.log(email);
    setuserEmail(email);
    const token = localStorage.getItem("token");

    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          const contact = [];
          contact.push(data.data);
          const mappedPhone = contact[0].Customer_contact_details.filter(
            (item) => item.contact_type === "phone"
          );

          setCustomerFullName(data.data.Customer_details[0]);
          setCustomerAddress(data.data.Customer_address[0]);
          setCustomerContact(mappedPhone);
        }
      });
  };

  useEffect(() => {
    userList();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, marginTop: "20px", marginBottom: "50px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Item className="common_card_bs">
            <div className="card-1 common_font">
              <Box className="card-box-padding">
                <Typography className="common_font">{totalSalesRep}</Typography>
                <Typography className="common_font">
                  <b>{salesRep ? salesRep.length : ""}</b>
                </Typography>
              </Box>
              <Box className="card-box-padding">
                <PersonIcon fontSize="large" style={{ color: "#7BD3EA" }} />
              </Box>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item className="common_card_bs">
            <div className="card-1 common_font">
              <Box className="card-box-padding">
                <Typography className="common_font">{totalAdmin}</Typography>
                <Typography className="common_font">
                  <b>{admin ? admin.length : ""}</b>
                </Typography>
              </Box>
              <Box className="card-box-padding">
                <ManageAccountsIcon
                  fontSize="large"
                  style={{ color: "#D63484" }}
                />
              </Box>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item className="common_card_bs">
            <div className="card-1 common_font">
              <Box className="card-box-padding">
                <Typography className="common_font">{activeUsers}</Typography>
                <Typography className="common_font">
                  <b>{activeUserList ? activeUserList.length : ""}</b>
                </Typography>
              </Box>
              <Box className="card-box-padding">
                <AccessibilityIcon
                  fontSize="large"
                  style={{ color: "green" }}
                />
              </Box>
            </div>
          </Item>
        </Grid>
        <Grid item xs={6} md={3}>
          <Item className="common_card_bs">
            <div className="card-1 common_font">
              <Box className="card-box-padding">
                <Typography className="common_font">{deactiveUsers}</Typography>
                <Typography className="common_font">
                  <b>{deactiveUserList ? deactiveUserList.length : ""}</b>
                </Typography>
              </Box>
              <Box className="card-box-padding">
                <PersonOffIcon fontSize="large" style={{ color: "#FC6736" }} />
              </Box>
            </div>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={9} sm={12}>
          <Item2 className="common_card_bs">
            <Box align="left" sx={{ marginLeft: "10px" }}>
              <h3 className="common_font">{heading}</h3>
            </Box>
            <TableContainer className="table-container">
              <Table size="small" aria-label="a dense table">
                <TableHead className="table-head">
                  <TableRow
                    className="log_table_table_row  head-table-row common_header_bg "
                  // sx={{ backgroundColor: "#EEEDEB !important" }}
                  >
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      #
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      User Name
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      Email
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      User Created on
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell-message">
                      User Type
                    </TableCell>
                    <TableCell
                      className="common_font log_table_table_head head-table-cell"
                      sx={{ textAlign: "right" }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alluserList.slice(0, 5).map((item, index) => {
                    console.log(item);
                    const formattedDate = moment(item.user_created_at).format(
                      "DD MMM YYYY"
                    );
                    return (
                      <TableRow
                        // onClick={() => userInfo(item.person_id, item.user_id)}
                        onClick={() =>
                          navigate(`/${lng}/manage-users/${personId}`, {
                            state: {
                              personId: item.person_id,
                              Email: item.user_id,
                            },
                          })
                        }
                        // className="table-row-even all_box_hover"
                        className={index % 2 === 0 ? 'table-row-even product-list-alternate-table all_box_hover' : 'table-row-even all_box_hover'}
                      >
                        <TableCell className="body-table-cell common_font">
                          {alluserList ? <>{index + 1}</> : <Skeleton />}
                        </TableCell>
                        <TableCell
                          id={`user-details-customer-name-${index}`}
                          className="body-table-cell common_font"
                        >
                          <div
                            className="tabel-cell-Boxes"
                          // onClick={() => userInfo(item.person_id)}
                          // onClick={() =>
                          //   navigate(`/${lng}/manage-users/${personId}`, {
                          //     state: {
                          //       personId: item.person_id,
                          //       Email: item.user_id,
                          //     },
                          //   })
                          // }
                          >
                            {alluserList ? (
                              <>
                                {item.gender === "Female" ? (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={femalee_icon}
                                    className="Avatar-height-width"
                                  />
                                ) : (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={male_icon}
                                    className="Avatar-height-width"
                                  />
                                )}
                                <Typography className="common_font">
                                  {item.first_name ? (
                                    <>
                                      {item.first_name} {item.last_name}
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </Typography>
                              </>
                            ) : (
                              <Skeleton width={80} height={20} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          <div
                            className="tabel-cell-Boxes"
                            onClick={() =>
                              userInfo(item.person_id, item.user_id)
                            }
                          >
                            {item.user_id ? (
                              <span>{item.user_id ? item.user_id : "NA"}</span>
                            ) : (
                              <Skeleton width={80} height={20} />
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          {formattedDate ? (
                            <Box
                              className="tabel-cell-Boxes"
                              onClick={() =>
                                userInfo(item.person_id, item.user_id)
                              }
                            >
                              {formattedDate ? formattedDate : "NA"}
                            </Box>
                          ) : (
                            <Skeleton width={80} height={20} />
                          )}
                        </TableCell>

                        <TableCell className="body-table-cell common_font">
                          {item.person_id ? (
                            <Box
                              className="tabel-cell-Boxes"
                              onClick={() =>
                                userInfo(item.person_id, item.user_id)
                              }
                            >
                              {item.user_type ? (
                                <>
                                  {item.user_type === "User"
                                    ? "Sales Rep"
                                    : item.user_type}
                                </>
                              ) : (
                                "NA"
                              )}
                            </Box>
                          ) : (
                            <Skeleton width={80} height={20} />
                          )}
                        </TableCell>
                        <TableCell
                          className="body-table-cell common_font"
                          sx={{ textAlign: "right" }}
                        >
                          {item.user_status ? (
                            <>
                              {item.user_status === "active" ? (
                                <Chip
                                  className="common_font"
                                  label="Active"
                                  color="success"
                                  size="medum"
                                />
                              ) : (
                                <Chip
                                  className="common_font"
                                  label="Inactive"
                                  color="warning"
                                  size="medium"
                                />
                              )}
                            </>
                          ) : (
                            <Skeleton />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Box
                className="view-all"
                onClick={() => {
                  navigate(`/${lng}/manage-users/all-users`);
                }}
              >
                <Typography className="common_font">{viewAll}</Typography>
              </Box>
            </TableContainer>
          </Item2>
        </Grid>
        <Grid item xs={12} md={3} sm={12}>
          <Item1 className="common_card_bs">
            <Box className="admin-profile-box" align="left">
              <h3 className="common_font">{customerDetails}</h3>

              <Box align="center" className="profile-main">
                {customerFullName.gender === "Male" ? (
                  <Avatar
                    alt="Remy Sharp"
                    src={male_icon}
                    style={{ width: "50px", height: "50px" }}
                  />
                ) : (
                  <Avatar
                    alt="Remy Sharp"
                    src={femalee_icon}
                    style={{ width: "50px", height: "50px" }}
                  />
                )}
              </Box>
              <Typography className="username common_font">
                {customerFullName.first_name ? (
                  <>
                    {customerFullName
                      ? customerFullName.first_name +
                      " " +
                      customerFullName.last_name
                      : "NA"}
                  </>
                ) : (
                  <Box className="skeleton-box">
                    <Skeleton height={30} width={80} />
                  </Box>
                )}
              </Typography>
              <Box className="customer-name-edit-profile">
                <Button
                  className="customButton common_font common-button"
                  size="small"
                  onClick={() =>
                    navigate(`/${lng}/manage-users/${personId}`, {
                      state: {
                        personId: personId,
                        Email: userEmail,
                      },
                    })
                  }
                >
                  Edit{" "}
                </Button>
              </Box>
              <Box className="address-main-box">
                <Box className="address-box-1">
                  <LocationOnIcon fontSize="medium" />
                  {customerAddress.country ? (
                    <>
                      <Typography className="common_font" textAlign={"left"}>
                        {" "}
                        {customerAddress ? customerAddress.country : ""},{" "}
                        {customerAddress ? customerAddress.city : ""},{" "}
                        {customerAddress ? customerAddress.state : ""},{" "}
                        {customerAddress ? customerAddress.street_address : ""},{" "}
                        {customerAddress ? customerAddress.zip_code : ""}
                      </Typography>
                    </>
                  ) : (
                    <Box className="skeleton-box">
                      <Skeleton height={40} width={80} />
                    </Box>
                  )}
                </Box>
                <Box className="address-box-2">
                  <PhoneIcon fontSize="medium" />
                  {customerContact.map((item, index) => {
                    console.log(item);
                    return (
                      <Typography className="common_font" align="left">
                        {" "}
                        {item.contact_priority === "0"
                          ? item.contact_details
                          : null}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>

              <Box className="contact-info">
                <Button
                  className="common_font common-button"
                  variant="contained"
                  color="primary"
                >
                  Message
                </Button>
                <Button
                  className="common_font common-button"
                  variant="contained"
                  color="error"
                >
                  Call
                </Button>
              </Box>
            </Box>
          </Item1>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminInfo;

// Author: sonal

import React, { useEffect, useState } from "react";
import "../../Styles/logs.css";
import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import SelectLabel from "../Layout/selectlabel";

import {
  GET_LOGS,
  POSTGRESQL_2_ALGOLIA_LOGS,
  USER_DETAILS,
} from "../../config/config";
import { displaytimestampasperclient } from "../../utils/dateutils.js";
import { Button, Pagination } from "@mui/material";

const moment = require("moment");

export default function UserLogs() {
  const [alluserList, setAllUserList] = useState([]);
  const [logEntriesList, setLogEntriesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = logEntriesList.slice(indexOfFirstItem, indexOfLastItem);

  const token = localStorage.getItem("token");

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const userList = () => {
    fetch(USER_DETAILS, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        setAllUserList(data.data.user_list);
      });
  };

  useEffect(() => {
    userList();
    posttGreSQLAlgoliaLogs();
  }, []);

  const posttGreSQLAlgoliaLogs = () => {
    fetch(GET_LOGS, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setLogEntriesList(data.data.logEntries);
        }
      });
  };

  useEffect(() => {
    userList();
  }, []);

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }
  const [textContent, settextContent] = useState("");

  const handleSearchChange = async (e) => {
    settextContent(e.target.value);
    console.log(e.target.value);

    if (e.target.value === "") {
      posttGreSQLAlgoliaLogs();
    } else {
      const filteredLogs = currentItems.filter((logs) => {
        return logs.logMessage
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });

      console.log(filteredLogs);
      setLogEntriesList(filteredLogs);
    }
  };


  const [updateCss, setUpdateCss] = useState("")
  const userType = localStorage.getItem("user_type")

  useState(() => {
    if (userType === "User") {

      setUpdateCss("logs-bg logs-main-div")
    }
    else {
      setUpdateCss("logs-bg-2 logs-main-div")
    }

  }, [userType])

  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        {/* <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Logs Info"
            className="common_font"
            inputProps={{ "aria-label": "Search Logs Info" }}
            onChange={handleSearchChange}
            value={textContent}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <SelectLabel
          length={logEntriesList.length}
          onSendData={handleChildData}
        />
      </div>

      <div className="logs-bg logs-main-div">
        <TableContainer className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead sx={{ marginTop: "20px" }} className="table-head">
              <TableRow className="log_table_table_row  head-table-row">
                <TableCell className="common_font log_table_table_head head-table-cell logs-header ">
                  #
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell logs-header">
                  User Id
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell logs-header">
                  Date & Time
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell logs-header">
                  status
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell logs-header">
                  Log Messages
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems &&
                currentItems.map((item, index) => {
                  const formattedDate = moment
                    .utc(item.timestamp)
                    .local()
                    .format("DD MMM YYYY hh:mm A");
                  const serialNumber =
                    (currentPage - 1) * itemsPerPage + index + 1;
                  return (
                    <TableRow className="table-row-even all_box_hover logs-folder">
                      <TableCell className="body-table-cell common_font logs-file-txt logs-table-cell">
                        {/* {index + 1} */}
                        {serialNumber}
                      </TableCell>

                      <TableCell className="body-table-cell common_font logs-folder-1 logs-table-cell">
                        {item.userId}
                      </TableCell>
                      <TableCell className="body-table-cell common_font logs-file logs-table-cell">
                        {displaytimestampasperclient(formattedDate)}
                      </TableCell>
                      <TableCell className="body-table-cell common_font logs-folder-1 logs-table-cell">
                        {item.status.includes("success") ? (
                          <Typography className="common_font status-success">
                            {item.status}
                          </Typography>
                        ) : item.status.includes("error") ? (
                          <Typography className="common_font status-error">
                            {item.status}
                          </Typography>
                        ) : item.status.includes("warning") ? (
                          <Typography className="common_font status-warning">
                            {item.status}
                          </Typography>
                        ) : (
                          <Typography className="common_font">
                            {item.status}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        id={`user-details-customer-name-${index}`}
                        className="body-table-cell common_font logs-folder logs-table-cell"
                      >
                        {item.logMessage.includes("Request") ? (
                          <Box className="log-request-txt">
                            {" "}
                            {item.logMessage}
                          </Box>
                        ) : item.logMessage.includes("Response") ? (
                          <Box className="log-response-txt">
                            {" "}
                            {item.logMessage}
                          </Box>
                        ) : item.logMessage.includes("Successfully!") ? (
                          <Box className="log-success-txt">
                            {" "}
                            {item.logMessage}
                          </Box>
                        ) : item.logMessage.includes("Successfully") ? (
                          <Box className="log-success-txt">
                            {" "}
                            {item.logMessage}
                          </Box>
                        ) : item.logMessage.includes("Incorrect") ? (
                          <Box className="log-incorect-txt">
                            {" "}
                            {item.logMessage}
                          </Box>
                        ) : item.logMessage.includes("Add") ? (
                          <Box className="log-add-txt"> {item.logMessage}</Box>
                        ) : (
                          <Box className="log-incorect-txt">
                            {item.logMessage}
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Box sx={{ marginBottom: "20px !important" }} className="pagination-box">
        <Pagination
          id="product-list-pagination"
          className="pagination common_font"
          count={Math.ceil(logEntriesList.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
    </div>
  );
}

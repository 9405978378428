import { Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import '../../Styles/pagenotfound.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
    const navigate = useNavigate()


    const { i18n } = useTranslation();
    console.log(i18n.language)

    const [lng, setLng] = useState(i18n.language)

    return (
        <div className='page_not_found_main_div'>
            <Typography className='page_not_found_main_txt'>404</Typography>
            <Typography className='page_not_found_sub_txt'>Sorry, the page you are looking for might be under construction or does not exist.</Typography>
            <Button onClick={() => navigate(`/${lng}/dashboard`)} className='page_not_found_button' variant='contained'>Back to Dashbaord</Button>
        </div>
    )
}

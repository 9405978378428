import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../../Styles/payment.css";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import GooglePay from "../../assets/img/payment/googlepay.png";
import PhonePe from "../../assets/img/payment/phonepe.png";
import PaymentOptionGif from "../../assets/img/payment/UPI.gif";
export default function PaymentOptions() {
  const [paymentOption, setPaymentOption] = useState(false);

  const [paymentOptionImage, setPaymentOptionImage] = useState(false);

  return (
    <div>
      {paymentOption ? (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent
            className=""
            sx={{ padding: "0px !important", marginBottom: "20px" }}
          >
            <Box
              className="payment_card_content_cndt"
              sx={{ backgroundColor: "#7eb3e2", height: "50px" }}
            >
              <Typography className="payment_card_1_box_primary_txt_cndt">
                {" "}
                <span className="payment_card_1_span_cndt">4</span> PAYMENT
                OPTIONS <CheckIcon className="payment_card_1_icon" />{" "}
              </Typography>
            </Box>

            <Box
              sx={{ marginLeft: "0px !important" }}
              className="payment_card_box_2_cndt"
            >
              <Box className="payment_options_options_box">
                <Radio />

                <img
                  src={PaymentOptionGif}
                  className="payment_options_google_pay_img"
                  alt=""
                />

                <Typography>PhonePe / GooglePay / Paytm / UPI</Typography>
              </Box>

              <Box className="payment_options_options_box">
                <Radio />
                <Box>
                  <Typography>Credit / Debit / ATM Card</Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Add and secure cardsas per RBI guidelines
                  </Typography>
                </Box>
              </Box>

              <Box className="payment_options_options_box">
                <Radio />
                <Typography>Net Banking</Typography>
              </Box>

              <Box
                sx={{ borderBottom: "0px" }}
                className="payment_options_options_box"
              >
                <Radio />
                <Typography>Cash on Delivery</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent className="payment_card_content" sx={{}}>
            <Box className="payment_card_1_box">
              <Typography className="payment_card_1_box_primary_txt">
                {" "}
                <span className="payment_card_1_span">4</span>PAYMENT OPTIONS
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setPaymentOption(true);
              }}
              className="payment_card_1_button"
            >
              CHANGE
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

import React, { useEffect,useRef, useState } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { VERIFY_TOKEN } from "../config/config";
import { useAuth } from "../provider/AuthProvider";
import Unauthorized from "../components/Unauthorized";
import Extra from "../components/Extra";
const PrivateRoutes = ({permissions}) => {
  const { userD } = useAuth();
  const [userType,setUserType]=useState('');
  const [userPermission,setUserPermission]=useState([])
  const location = useLocation();
    const navigate = useNavigate();
    console.log(userD)
    const effectRan = useRef(false);
    var token=false;   
        const loggedInUser = localStorage.getItem("user");
        let foundUser;
        if (loggedInUser) {
          foundUser = JSON.parse(loggedInUser);
          console.log(foundUser)
          console.log(foundUser.token)
          token=true;
         }
         console.log(userD)
         console.log(permissions)
        useEffect(()=>{
            if(!effectRan.current) {
            fetch(VERIFY_TOKEN, {
                method: "GET",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                  authorization:foundUser?.token,
                  usertype:foundUser?.usertype
                },
              })
                .then((data) => data.json())
                .then((data) => {
                  console.log(data)
                  if(data.error==='jwt expired'){
                    // navigate('/login')
                  }
                  let currentTimestamp = Math.floor(Date.now() / 1000);
                  try{
                  if(data.success===true){
                    setUserType(data?.data?.decoded?.usertype)
                    setUserPermission(data?.data?.decoded?.permissions)
                    if(data?.data?.decoded?.exp-currentTimestamp>1){

                    }else{ 
                    //  navigate('/login')
                    }
                  }
                }catch(e){
                    console.log(e)
                }
                })
            }
            return () => effectRan.current = true;
        },[]);
        if (userD?.usertype) {
          const userpermission = userD?.permissions;
          console.log(userpermission)
          console.log(permissions)
          console.log(foundUser?.permissions)
          const isAllowed = permissions.some((allowed) =>
            userpermission.includes(allowed)
          );
          return isAllowed ? <Outlet /> : <Extra/>;
        }
        else if(foundUser?.usertype){
          const userpermission = foundUser?.permissions;
          console.log(userpermission)
          console.log(permissions)
          console.log(userType)
          console.log(userPermission)
          console.log(foundUser?.permissions)
          if(userPermission.length>0){
          const isAllowed = permissions.some((allowed) =>
          userPermission.includes(allowed)
          );
          return isAllowed ? <Outlet /> : <Extra/>;
          }
          // const isAllowed = permissions.some((allowed) =>
          // userpermission.includes(allowed)
          // );
          // return isAllowed ? <Outlet /> : <Extra/>;
        }
        else{
          // let auth = {'token':token}
    return <Navigate to="/login"/>
    
        }
        // return <Navigate to='/login' />;
    //   let auth = {'token':token}
    // return(
    //     auth.token ? <Outlet/> : <Navigate to="/login"/>
    // )
}

export default PrivateRoutes
import React from "react";
import { Box, Typography, } from "@mui/material";
import "../../Styles/profile.css";
import UserPng from "../../assets/img/png/user.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ViewListIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentsIcon from "@mui/icons-material/Payments";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import Edit from "@mui/icons-material/ModeEdit";
import { SIGNOUT } from "../../config/config";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "10px",
}));


const Profile = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    fetch(SIGNOUT, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data?.responseData?.error === false) {
          window.localStorage.clear();
          navigate("/");
        }
      });
  };

  const loggedInUser = localStorage.getItem("user");
  let foundUser = JSON.parse(loggedInUser);
  console.log(foundUser.userfullname)

  return (
    <>
      <Box className="main_container">
        {/* <Box className="primary_box"></Box> */}
        {/* <div className="Profile_image">
          <img
            src={UserPng}
            alt="UserPng"
            style={{ width: "100%", height: "auto" }}
          />
          <IconButton
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              backgroundColor: "#fff",
            }}
            size="small"
          >
            <Edit />
          </IconButton>
        </div> */}
        <div style={{ width: "100%", margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
          <img
            src={UserPng}
            alt="UserPng"
            style={{ width: "200px", height: "200px" }}
          />
        </div>
        <Box className="secondary_box">
          <Box sx={{ flexGrow: 1, marginTop: "30px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <h1 className="common_font">
                  Personal Information <Button className="common_font">Edit</Button>{" "}
                </h1>
                <Box className="username_fileds">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    className="common_font"
                  />

                  <TextField
                    id="outlined-basic"
                    label="Middle Name"
                    variant="outlined"
                    className="common_font"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    className="common_font"
                  />
                </Box>
                <Box className="user_gender">
                  <FormControl>
                    <FormLabel className="common_font" id="demo-radio-buttons-group-label">
                      Gender
                    </FormLabel>
                    <Box className="radiogroup">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"

                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Box>
                <h3 className="common_font">
                  Email Address <Button className="common_font">Edit</Button>{" "}
                </h3>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                />
                <h3 className="common_font">
                  Mobile Number <Button className="common_font">Edit</Button>{" "}
                </h3>
                <TextField
                  id="outlined-basic"
                  label="Mobile Number"
                  variant="outlined"
                />
                <Box className="faq">
                  <Typography className="common_font">
                    <h3 className="common_font">FAQs</h3>
                    How can I contact customer support? You can reach our
                    customer support team through the Contact Us page on our
                    website. We're here to assist you with any inquiries or
                    concerns. Do you have a physical store? Currently, we
                    operate exclusively online. For any assistance, please
                    contact our customer support team.
                  </Typography>
                </Box>
              </Grid>
              <Grid item className="profile_dashboard" xs={12} sm={12} md={3} lg={3}>
                <Item>
                  <Box className="userDetails_Box">
                    <img src={UserPng} alt="UserPng" className="user_image" />
                    <Box className="username_section">
                      <Typography textAlign={"left"} className="common_font">Hello,</Typography>
                      <Typography className="common_font" sx={{ textAlign: "left !important" }}>{foundUser.userfullname ? foundUser.userfullname : "USER"}</Typography>
                    </Box>
                  </Box>
                </Item>
                <Item>
                  <Box sx={{ display: "flex" }}>
                    <ViewListIcon />
                    <Typography sx={{ paddingLeft: "10px", textAlign: "left !important" }} className="common_font">
                      My Orders
                    </Typography>
                  </Box>
                </Item>
                <Item>
                  <Box sx={{ display: "flex" }}>
                    <SettingsIcon />
                    <Typography sx={{ paddingLeft: "10px", textAlign: "left !important" }} className="common_font">
                      Account Settings
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Button className="common_font">Profile Information</Button>
                    <Button className="common_font">Manage Addresses</Button>
                    <Button className="common_font">Other Information</Button>
                  </Box>
                </Item>
                <Item>
                  <Box sx={{ display: "flex" }}>
                    <PaymentsIcon />
                    <Typography className="common_font" sx={{ paddingLeft: "10px" }}>
                      Payments
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Button className="common_font">Gift Cards</Button>
                    <Button className="common_font">Saved UPI's</Button>
                    <Button className="common_font">Saved Cards</Button>
                  </Box>
                </Item>
                <Item>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LogoutIcon />
                    <Button onClick={handleLogout} className="common_font">Logout</Button>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;

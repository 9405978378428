// Author: sonal


import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

import {
  Box,
  List,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
//
import { StyledNavItem } from "./styles";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.string,
      img: PropTypes.string, // Add this line
    })
  ).isRequired,
};
export default function NavSection({ data = [], ...other }) {
  console.log(data);

  const firstSection = [...data.slice(0, 3)];
  const secondSection = [...data.slice(-2)];
  const salesSection = data.slice(3, 4);
  const AdminSection = data.slice(4, 5);
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);
  const [AccordionExpandedIndex, setAccordionExpandedIndex] = useState(12);

  const [isAdminAccordionExpanded, setAdminAccordionExpanded] = useState(false);
  const [adminAccordionExpandedIndex, setAdminAccordionExpandedIndex] =
    useState(12);

  const handleAccordionClick = (index, section) => {
    console.log(section, index);
    if (section === "sales") {
      setAccordionExpanded(!isAccordionExpanded);
      setAccordionExpandedIndex(index);
    } else if (section === "admin") {
      setAdminAccordionExpanded(!isAdminAccordionExpanded);
      setAdminAccordionExpandedIndex(index);
    }
  };

  const accordionStyle = {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    className: "common_font",
    marginTop: "0 !important",
    marginBottom: "0 !important",
    marginLeft: "-10px !important",
  };

  return (
    <Box  {...other}>
      <List
        disablePadding
        sx={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "24px",
          paddingBottom: "0px",
        }}
      >
        {firstSection.map((item, index) => (
          <NavItem key={item.title} item={item} id={`Nav-item-${index}`} />
        ))}

        {salesSection.map((item, index) => (
          <Accordion
            expanded={isAccordionExpanded && AccordionExpandedIndex === index}
            onClick={() => handleAccordionClick(index, "sales")}
            sx={accordionStyle}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color:
                      isAccordionExpanded === true &&
                        AccordionExpandedIndex === index
                        ? "#fec237 !important"
                        : "white !important",
                  }}
                  id={"expand-quotation"}
                />
              }
              aria-controls="quotation-tool-content"
              id="quotation-tool-header"
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color:
                    isAccordionExpanded === true &&
                      AccordionExpandedIndex === index
                      ? "#fec237 !important"
                      : "white !important",
                }}
                variant="h6"
                id={`quotation-tool-label`}
              >
                <i
                  className={`${item.icon}`}
                  style={{
                    fontSize: "23px",
                    marginRight: "12px",
                    marginLeft: "3px",
                    color:
                      isAccordionExpanded === true
                        ? "#fec237 !important"
                        : "white !important",
                  }}
                ></i>
                <span
                  className="common_font "
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.title}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: "-20px !important",
                padding: "8px 16px   !important",
              }}
            >
              <List disablePadding sx={{ paddingLeft: "20px" }}>
                {item.info.map((item, index) => (
                  <NavItem key={item.title} item={item} id={`quo-${index}`} />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        {secondSection.map((item, index) => (
          <NavItem key={item.title} item={item} id={`Nav-item-${index}`} />
        ))}
        {AdminSection.map((item, index) => (
          <Accordion
            expanded={
              isAdminAccordionExpanded && adminAccordionExpandedIndex === index
            }
            onClick={() => handleAccordionClick(index, "admin")}
            sx={accordionStyle}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color:
                      isAdminAccordionExpanded === true &&
                        adminAccordionExpandedIndex === index
                        ? "#fec237 !important"
                        : "white !important",
                  }}
                  id={"expand-quotation"}
                />
              }
              aria-controls="quotation-tool-content"
              id="quotation-tool-header"
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color:
                    isAdminAccordionExpanded === true &&
                      adminAccordionExpandedIndex === index
                      ? "#fec237 !important"
                      : "white !important",
                }}
                variant="h6"
                id={`quotation-tool-label`}
              >
                <i
                  className={`${item.icon}`}
                  style={{
                    fontSize: "23px",
                    marginRight: "12px",
                    marginLeft: "3px",
                    color:
                      isAdminAccordionExpanded === true
                        ? "#fec237 !important"
                        : "white !important",
                  }}
                ></i>
                <span
                  className="common_font "
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.title}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: "-20px !important",
                padding: "8px 16px   !important",
              }}
            >
              <List disablePadding sx={{ paddingLeft: "20px" }}>
                {item.info.map((item, index) => (
                  <NavItem key={item.title} item={item} id={`admin-${index}`} />
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item, id }) {
  console.log(id);
  const location = useLocation();
  console.log(location.pathname);

  const { title, path, icon, info } = item;
  console.log(info);
  const handleItemClick = (event) => {
    event.stopPropagation();
  };

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      className="SideBar_navigation common_font"
      onClick={handleItemClick}
    >
      <div className="sidebar_icon">
        {/* <Box
          component="img"
          src={item.img}
          alt={item.title}
          sx={{ width: 32, height: 32, color: "white" }}
          /> */}
        {item.img ? (
          <item.img />
        ) : (
          <i className={icon} style={{ fontSize: "20px" }}></i>
        )}
      </div>
      <ListItemText
        primary={
          <Typography id={id} className="sidebar_txt common_font">
            {title}
          </Typography>
        }
      />
      {/* {info && info} */}
    </StyledNavItem>
  );
}

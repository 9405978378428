import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import first from '../../assets/img/carousel/11.jpg'
import second from '../../assets/img/carousel/12.jpg'
import third from '../../assets/img/carousel/13.jpg'
import forth from '../../assets/img/carousel/14.jpg'
import fifth from '../../assets/img/carousel/15.jpg'
import six from '../../assets/img/carousel/16.jpg'
import seven from '../../assets/img/carousel/7.jpg'
import eight from '../../assets/img/carousel/8.jpg'
import nine from '../../assets/img/carousel/9.jpg'

import tenth from '../../assets/img/carousel/1.jpg'
import eleven from '../../assets/img/carousel/2.jpg'
import twelve from '../../assets/img/carousel/3.jpg'
import thirteen from '../../assets/img/carousel/4.jpg'
import forteen from '../../assets/img/carousel/5.jpg'
import fifteen from '../../assets/img/carousel/6.jpg'
import sixteen from '../../assets/img/carousel/10.jpg'

const products = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: eight,
  },

  {
    name: 'Rollerball Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: second,
  },
  {
    name: 'Ink Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: forth,
  },

  {
    name: 'Gel Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: fifth,
  },
  {
    name: 'Boll Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: six,
  },
  {
    name: 'Blue Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: seven,
  },
  {
    name: 'Blue Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: seven,
  },
  {
    name: 'Blue Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: seven,
  },




];

const bluePenList = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: tenth,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: seven,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: eight,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: fifth,
  },
]

const ballPenList = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: eleven,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: twelve,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: forteen,
  },

]

const parkerPenList = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: thirteen,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: fifteen,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: nine,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: six,
  },

]
const fountainPenList = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: first,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: second,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: third,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: forth,
  },

]
const watermanPenList = [
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: sixteen,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: fifth,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: eight,
  },
  {
    name: 'Black Pen',
    description: 'Description for Product 2',
    price: 29.99,
    image: nine,
  },

]


export default function SelectedTypeProductList() {

  const location = useLocation()
  const navigate = useNavigate()
  console.log(location)

  const [productsList, setProductsList] = useState([])
  console.log(productsList)

  useEffect(() => {
    console.log(location)
    console.log(bluePenList)
    if (location.state.type === "Ink Pen") {
      setProductsList(bluePenList)
    }
    else if (location.state.type === "Ball Pen") {
      setProductsList(ballPenList)
    }
    else if (location.state.type === "Parker Pen") {
      setProductsList(parkerPenList)
    }
    else if (location.state.type === "Fountain Pen") {
      setProductsList(fountainPenList)
    }
    else if (location.state.type === "Waterman Fountain Pen") {
      setProductsList(watermanPenList)
    }
    else {
      setProductsList(products)

    }
  }, [location])

  const selectedTypeProductListFun = (product) => {
    console.log(product)
    navigate('/addcart', {
      state: {
        products: product
      }
    })
  }

  return (
    <div className='selected_type_product_list_main_div'>
      <h1 className='common_font'>Different Types of pens</h1>
      <div className="all_product_list"  >
        {productsList.map((product, index) => (
          <div onClick={() => { selectedTypeProductListFun(product) }} key={index} className="all_product_item all_product_item_div all_box_hover">
            <img src={product.image} alt={product.name} className='' />
            <div className="product-details">
              <h3 style={{ textAlign: "", margin: "5px 0px" }} className='common_font'>{product.name}</h3>
              <p>{product.description}</p>
              <p>${product.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

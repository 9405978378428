// @mui
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItemButton } from "@mui/material";

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  // color: "#000000 !important",
  borderRadius: "12px",
  fontWeight: "bold",
  "&:hover": {
    background: "none",
  },
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 40,
  height: 40,
  // color: "#000000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

/** @format */

//import from packages
import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, TextField, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from "@mui/material/Alert";
import { useAlert } from "../../alert/alert-context";
import { useParams } from 'react-router-dom';
//config
import { SIGNUP_URL, SIGNUP_WQR, CHECK_TWO_FA_STATUS } from "../../../config/config";

//images
import loginimage from "../../../assets/img/png/loginbg.jpg";
import logo from "../../../assets/img/bodex_logo.png";

//css
import "../../../Styles/login.css";
import "../../../index.css";

export default function QRCodeforExistingUser() {
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const { showAlert } = useAlert();
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [base64String, setBase64String] = useState()
  const [activate2FA, setActivate2FA] = useState(false)
  const [secret, setSecret] = useState('')
  const [twoFAConfig, setTwoFAConfig] = useState({})
  const { id } = useParams();
  console.log(id)
  let userID = id
  const handleAlertClose = () => {
    setShowLoginAlert(false);
    setShowErrorAlert(false)
  };

  const handleChange = (event) => {
    setActivate2FA(event.target.checked);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const Logo = () => <img src={logo} alt="logo" className="bodex_logo" />;
  const QRCode = () => <img src={imageUrl} alt="DecodedImage" />
  const generateQRCodeforExistingUser = (id) => {
    fetch(SIGNUP_WQR, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        include2FA: true,
        useruniqueID: id
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data)
        if (data.success === true) {
          if (data.data?.qrCode) {
            setBase64String(data.data?.qrCode)
            setSecret(data.data?.secret)
            setTwoFAConfig({
              qrCode: data.data?.qrCode,
              otpAuthUrl: data.data?.otpAuthUrl
            })
          }
        }

      })
  }
  
  useEffect(() => {
    if (!effectRan.current) {
      console.log(userID)
      if (userID) {
        fetch(CHECK_TWO_FA_STATUS, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            userId: userID
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data)
            if (data.success === true) {
              if (data?.data[0]?.two_factor === true) {
                setBase64String(data.data[0]?.two_fa_config?.qrCode)
                setSecret(data.data[0]?.two_fa_secret)
                setTwoFAConfig(data.data[0]?.two_fa_config)
              } else {
                generateQRCodeforExistingUser(userID)
              }

            }
          })

      }
    }
    return () => effectRan.current = true;
  }, [userID])
  useEffect(() => {
    if (base64String) {
      console.log(base64String)
      // const [, encodedData] = base64String.split(',');
      const encodedData = base64String.split(',')[1]; // Remove the prefix
      if (encodedData) {
        console.log(encodedData)
        const binaryData = atob(encodedData);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        // Cleanup function to revoke the object URL when the component unmounts
        return () => URL.revokeObjectURL(url);
      }
    }
  }, [base64String]);

  //  const callUserSignUp =async ()=>{
  //     if(activate2FA){
  //       await fetch(SIGNUP_WQR, {
  //         method: "POST",
  //         mode: "cors",
  //         body: JSON.stringify({
  //           include2FA:activate2FA
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then((data) => data.json())
  //         .then((data) => {
  //           console.log(data)
  //           if(data.success===true){
  //             if(data.data?.qrCode){
  //               setBase64String(data.data.qrCode)
  //               setSecret(data.data.secret)
  //               setTwoFAConfig({
  //                 qrCode:data.data.qrCode,
  //                 otpAuthUrl:data.data.otpAuthUrl
  //               })
  //             }else{           
  //               if(data.message==='User registered successfully!'){
  //                 setShowLoginAlert(true);
  //                 setAlertMessage("User registered successfully!");
  //                setTimeout(()=>{
  //                 navigate('/login')
  //                },3001)

  //               }else if(data.message==='User already exists!'){
  //                 setShowErrorAlert(true)
  //                 setAlertMessage("User already exists!");
  //               }
  //             }
  //           }
  //         })
  //     }else{
  //       userSignUp()
  //     }
  //  }
  const userSignUp = async () => {
    await fetch(SIGNUP_URL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        useremail: email,
        username: email,
        password: password,
        usertype: "Admin",
        include2FA: activate2FA,
        secret: secret,
        twofaconfig: twoFAConfig
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          if (data.data?.qrCode) {
            setBase64String(data.data.qrCode)
          } else {
            if (data.message === 'User registered successfully!') {
              setShowLoginAlert(true);
              setAlertMessage("User registered successfully! Please login to get started.");


            } else if (data.message === 'User already exists!') {
              setShowErrorAlert(true)
              setAlertMessage("User already exists!");
            }
          }
        }

      });
  };
  const handleGoToSignInClick = () => {
    console.log(firstName,
      lastName,
      email,
      password,
      "Admin",
      activate2FA)
    userSignUp()
    navigate('/login');
  };
  return (
    <div className="login-container">
      <Card className="login-page css-selector">
        <Logo />
        {/* <CardMedia
          component="img"
          image={loginimage}
          alt="login image"
          className="responsive-image"
        /> */}
        {/* {imageUrl? */}
        <CardContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Stack spacing={3}>
                <Typography variant="h4" className="heading">
                  Scan the below QRCode
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="center">
                  {imageUrl ? (
                    <QRCode />
                  ) : (
                    <p>Loading image...</p>
                  )}
                </Stack>
                <center>
                  <Typography variant="h8" >
                    Use your device to scan the QR code for enabling the Two-factor Authentication.
                  </Typography>
                </center>
                <center>
                  <Button
                    className="css-selector button"
                    variant="contained"
                    // disabled={!password || !email}
                    onClick={handleGoToSignInClick}
                  >
                    Go to Signin
                  </Button>
                </center>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
        {/* :
      <CardContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Stack spacing={3}>
                <Typography variant="h4" className="heading">
                  SIGN UP
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <TextField
                    label="First Name"
                    variant="outlined"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="textfield-container"
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="textfield-container"
                  />
                </Stack>
                <TextField
                  label="Email"
                  variant="outlined"
                  id="user"
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  className="textfield-container"
                />
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    className="outlined-input"
                  />
                </FormControl>

                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-confirmpassword">
                    Confirm Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirmpassword"
                    type={"password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    label="Password"
                    className="outlined-input"
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                 <FormControlLabel
                  value="start"
                  control={<Switch color="primary" onChange={handleChange}/>}
                  label="2FA"
                  defaultChecked 
                />
                </FormControl>
                
                <center>
                  <Button
                    className="css-selector button"
                    variant="contained"
                    disabled={!password || !email}
                    onClick={callUserSignUp}
                  >
                    SIGN UP
                  </Button>
                </center>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
  } */}
      </Card>
      {showLoginAlert ?
        <span className="alert-container">
          <Alert severity="success" onClose={handleAlertClose}>
            {alertMessage}
          </Alert>
        </span> : ''
      }
      {showErrorAlert ?
        <span className="alert-container">
          <Alert severity="error" onClose={handleAlertClose}>
            {alertMessage}
          </Alert>
        </span> : ''
      }
    </div>
  );
}

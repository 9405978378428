import React, { useEffect, useState } from "react";
import {
  GET_USER_ORG,
  SALES_ORDER_DETAILS,
  MAKE_PAYMENT,
  GET_RETURN_DETAILS,
} from "../../config/config";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Card,
  CardContent,
  Chip,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Button,
  TextField,
  SwipeableDrawer,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SiteLogo from "../../assets/img/ThePenCompany.png";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "@mui/material/Modal";
import Payment from "../../assets/img/payment.gif";
import { useTreeItem } from "@mui/x-tree-view";
import { useTranslation } from "react-i18next";
const moment = require("moment");

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f3f4",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Returnproductdetails() {
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const location = useLocation();
  console.log(location.state);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpen = () => {
    setModalOpen(true);
    setTimeout(() => {
      setModalOpen(false);
      navigate(`/${lng}/shipment`);
    }, 3001);
  };
  const handleClose = () => setModalOpen(false);

  const [salesOrderDetails, setsalesOrderDetails] = useState([]);
  const [salesOrderProductList, setSalesOrderProductList] = useState([]);
  const [org_details, setOrgDetails] = useState([]);
  const [paymentAmt, setPaymentAmt] = useState("0");
  const [loading, setLoading] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("Cash");
  const [paymentNote, setPaymentNote] = React.useState("Payment note here...");
  const [paymentDate, setPaymentDate] = React.useState(
    dayjs(new Date().toDateString())
  );
  const [showErrorMsg, setShowErrorMsg] = React.useState("");

  console.log(paymentAmt, paymentMethod, paymentDate);
  console.log(salesOrderDetails.tax_amount);

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  const getSalesOrderDetailsFun = async () => {
    await fetch(SALES_ORDER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        sales_display_id: "4cc5c252-da44-4931-8b91-b75d80302689",
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setsalesOrderDetails(data.data.sales_order_details[0]);
          setPaymentAmt(data.data.sales_order_details[0].final_amount);
          setSalesOrderProductList(data.data.sales_order_product_list);
        }
      });
  };

  const getUserOrg = async () => {
    await fetch(GET_USER_ORG, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data);
          setOrgDetails(data.data.user_org_details);
        }
      });
  };

  useEffect(() => {
    getSalesOrderDetailsFun();
    getUserOrg();
  }, []);

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handlePaymentAmountInput = (e) => {
    const value = e.target.value;
    const reg = /^\d*\.?\d*$/;
    if (reg.test(value) || value === "") {
      if (+value > +salesOrderDetails?.total_amount) {
        setShowErrorMsg("Amount should be less than or equal to grand total.");
      } else {
        setShowErrorMsg("");
        setPaymentAmt(value);
      }
    }
  };

  const handlePaymentNote = (e) => {
    setPaymentNote(e.target.value);
  };

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handlePaymentDate = (newvalue) => {
    console.log(newvalue);
    setPaymentDate(newvalue.$d);
  };

  const methods = [
    "Cash",
    "Credit Card",
    "Debit Card",
    "Bank Transfer",
    "Paypal",
  ];

  const makePayment = async () => {
    setLoading(true);
    await fetch(MAKE_PAYMENT, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        sales_id: salesOrderDetails.sales_id,
        payment_amount: paymentAmt,
        payment_status: "SUCCESS",
        payment_created_by:
          org_details[0]?.first_name + " " + org_details[0]?.last_name,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          // setSnackSeverity("success");
          // setUpdateAlertMsg(data.message);
          // setUpdateAlert(true);
          toggleDrawer("right", false);
          setLoading(false);
          handleOpen();
        }
      });
  };
  const [returnDetails, setReturnDetails] = useState([]);
  console.log(returnDetails);
  const getReturnDetails = () => {
    fetch(GET_RETURN_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        return_id: location.state.return_id,
        // person_id: location.state.person_id
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        setReturnDetails(data.data.return_details);
      });
  };

  useEffect(() => {
    getReturnDetails();
  }, []);

  const list = (anchor) => (
    <Box sx={{ width: 400, padding: "20px" }} role="presentation">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography
            variant="h6"
            className="common_font"
            style={{ fontWeight: "bold", marginLeft: "10px", color: "#24a4eb" }}
          >
            Payment
          </Typography>
        </div>
        <div>
          <Button
            className="common_font common-button"
            variant="contained"
            sx={{
              background: "#f1f1f2 !important",
              color: "#000000 !important",
            }}
            onClick={toggleDrawer(anchor, false)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "50px",
          background: "#f9f9fa",
          borderRadius: "8px",
          paddingTop: "10px",
          paddingBottom: "25px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography variant="p" className="common_font">
            <b className="common_font">Grand Total:</b>
          </Typography>
          <Typography
            variant="p"
            className="common_font"
            sx={{
              color: "#24a4eb !important",
            }}
          >
            <b className="common_font">
              $ {Number(salesOrderDetails?.final_amount).toFixed(2)}
            </b>
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          <Typography variant="p" className="common_font">
            <b className="common_font">Payment Amount :</b>
          </Typography>
          <Box>
            <TextField
              className="common_font"
              fullWidth
              hiddenLabel
              sx={{
                marginTop: "5px",
                background: "#ffffff",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <i class="fa-solid fa-dollar"></i>
                  </InputAdornment>
                ),
                className: "common_font",
              }}
              InputLabelProps={{
                className: "common_font",
              }}
              id="payment-amount"
              value={paymentAmt}
              onChange={handlePaymentAmountInput}
              placeholder="0.00"
              size="small"
            />
            <div
              className="common_font"
              style={{
                color: "#eb0014",
                fontSize: "12px",
                fontWeight: "bold",
                padding: "5px",
              }}
            >
              <Typography className="common_font">
                {" "}
                {showErrorMsg ? showErrorMsg : ""}
              </Typography>
            </div>
          </Box>
        </div>
        <div
          style={{
            padding: "10px",
          }}
        >
          <Typography variant="p" className="common_font">
            <b className="common_font">Payment Date :</b>
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer id="payment-date-picker" components={["DatePicker"]}>
              <DatePicker
                id="payment-date-picker"
                hiddenLabel
                className="common_font"
                sx={{
                  width: "380px",
                  background: "#ffffff",
                }}
                value={paymentDate}
                onChange={handlePaymentDate}
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <Typography variant="p" className="common_font">
            <b className="common_font">Payment Method :</b>
          </Typography>
          <FormControl>
            <Select
              id="payment-method"
              fullWidth
              size="small"
              displayEmpty
              className="common_font"
              sx={{ background: "#ffffff" }}
              value={paymentMethod}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return methods[0];
                }
                return selected;
              }}
              MenuProps={MenuProps}
              InputLabelProps={{
                className: "common_font",
              }}
              inputProps={{
                className: "common_font",
              }}
            >
              {methods.map((method) => (
                <MenuItem
                  key={method}
                  value={method}
                  style={getStyles(method, paymentMethod, theme)}
                  InputLabelProps={{
                    className: "common_font",
                  }}
                  inputProps={{
                    className: "common_font",
                  }}
                  className="common_font"
                >
                  {method}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <Typography variant="p" className="common_font">
            <b>Payment Note :</b>
          </Typography>
          <Box>
            <TextField
              className="common_font"
              fullWidth
              hiddenLabel
              sx={{
                marginTop: "5px",
                background: "#ffffff",
              }}
              id="payment-note"
              multiline
              rows={4}
              value={paymentNote}
              onChange={handlePaymentNote}
              placeholder="Payment note here..."
              size="small"
              InputLabelProps={{
                className: "common_font",
              }}
              inputProps={{
                className: "common_font",
              }}
            />
          </Box>
        </div>
        {paymentAmt > 0 &&
        paymentDate != "" &&
        paymentMethod != "" &&
        showErrorMsg == "" ? (
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            className="common_font common-button"
            variant="contained"
            sx={{ marginLeft: "10px", marginTop: "10px" }}
            onClick={makePayment}
            id="make-payment-final"
          >
            Payment
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            className="common_font common-button"
            variant="contained"
            sx={{ marginLeft: "10px", marginTop: "10px" }}
            disabled
          >
            Payment
          </LoadingButton>
        )}
      </div>
    </Box>
  );

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="payment_selector_modal common-display-style common_card_bs">
            <center>
              <Typography
                variant="p"
                style={{
                  color: "#24a4eb",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="common_font"
              >
                Payment Successfull!
              </Typography>

              <img
                src={Payment}
                alt="payment sucessfull"
                style={{ width: "200px", padding: "10px" }}
              />

              <Typography
                variant="p"
                style={{
                  color: "#fec237",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                className="common_font"
              >
                Your order has been shipped!
              </Typography>
            </center>
          </Box>
        </>
      </Modal>
      <Card
        id="landing_card"
        className="landing_card common_card_bs"
        style={{ marginBottom: "20px" }}
      >
        {" "}
        <Snackbar
          open={updateAlert}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={snackseverity}
            sx={{ width: "100%" }}
          >
            {updateAlertMsg}
          </Alert>
        </Snackbar>
        <CardContent className="customer_details_card">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "30px",
              paddingLeft: "20px",
              paddingTop: "10px",
            }}
          >
            <div>
              <img src={SiteLogo} alt="site-logo" />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginTop: "20px",
                paddingLeft: "20px",
              }}
            >
              <Typography
                variant="h2"
                className="common_font"
                sx={{ fontWeight: "bold" }}
              >
                {org_details[0]?.location_name}{" "}
                <Tooltip title={org_details[0]?.site_description}>
                  <InfoIcon
                    fontSize="small"
                    style={{ cursor: "pointer", opacity: "0.3" }}
                  />
                </Tooltip>
              </Typography>
              <Typography
                variant="p"
                className="common_font"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon sx={{ color: "#38a5ff" }} />
                {org_details[0]?.location_address}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                marginTop: "20px",
                paddingLeft: "20px",
              }}
            >
              <div>
                <Typography variant="h7" component="p" className="common_font">
                  <b>Return From : </b>
                </Typography>

                <Typography
                  variant="h7"
                  style={{ marginTop: "10px" }}
                  component="p"
                  className="common_font"
                >
                  {returnDetails[0]?.customer_name}{" "}
                  <span style={{ fontSize: "13px" }}>(Customer)</span>
                </Typography>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h7" component="p" className="common_font">
                  <b>Shipping Address :</b> <br />
                  {returnDetails ? (
                    <span>
                      Street : {returnDetails[0]?.street_address}
                      <br />
                      City : {returnDetails[0]?.city}
                      <br />
                      State / Zip : {returnDetails[0]?.state}
                      {" / "}
                      {returnDetails[0]?.zip_code}
                      <br />
                      Country : {returnDetails[0]?.country}
                    </span>
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h7" component="p" className="common_font">
                  <b>Order Created At : </b>
                </Typography>

                <Typography
                  variant="h7"
                  style={{ marginTop: "10px" }}
                  component="p"
                  className="common_font"
                >
                  <span>
                    {formatclient(returnDetails[0]?.created_at)} {", "}
                    {formattimeStampMoment(returnDetails[0]?.created_at)}
                  </span>
                </Typography>
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                paddingLeft: "20px",
              }}
            >
              <div>
                <Typography variant="h7" component="p" className="common_font">
                  <b>Return To : </b>
                </Typography>

                <Typography
                  variant="h7"
                  style={{ marginTop: "10px" }}
                  component="p"
                  className="common_font"
                >
                  {org_details[0]?.location_name}{" "}
                  <span style={{ fontSize: "13px" }}>(Sales Rep)</span>
                </Typography>
              </div>

              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <Typography variant="h7" component="p" className="common_font">
                  <b>Return Address :</b> <br />
                  <span>{org_details[0]?.location_address}</span>
                </Typography>
              </div>
            </div>
            <div></div>
            <div style={{ marginTop: "20px", paddingRight: "30px" }}>
              {/* <QRCode value={qrData} size={100} level={"H"} /> */}
            </div>
          </div>
        </CardContent>
        <Paper className="paper" style={{ padding: "40px" }}>
          <TableContainer className="table-container">
            <Table size="small" aria-label="a dense table">
              <TableHead className="table-head">
                <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    <b>SKU</b>
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    <b>Description</b>
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    <b>Qty</b>
                  </TableCell>
                  <TableCell
                    className="common_font log_table_table_head head-table-cell"
                    style={{ textAlign: "right" }}
                  >
                    <b>Unit Price</b>
                  </TableCell>
                  <TableCell
                    className="common_font log_table_table_head head-table-cell-message"
                    style={{ textAlign: "right" }}
                  >
                    <b>Total</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returnDetails &&
                  returnDetails.map((item, index) => {
                    console.log(item);
                    const unit_price = Number(item.unit_price).toFixed(2);
                    const item_total_price = Number(
                      item.item_total_price
                    ).toFixed(2);
                    return (
                      <StyledTableRow key={index} className="table-row-even">
                        <TableCell className="body-table-cell common_font">
                          <Chip
                            label={item.sku}
                            style={{ fontWeight: "700" }}
                            className="common_font"
                          />
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          <b>{item.pen_name}</b>
                          <br />
                          {/* {item.special_features} */}
                        </TableCell>
                        <TableCell className="body-table-cell common_font">
                          {item.quantity}
                        </TableCell>
                        <TableCell
                          className="body-table-cell common_font"
                          style={{ textAlign: "right" }}
                        >
                          ${unit_price}
                        </TableCell>
                        <TableCell
                          className="body-table-cell common_font"
                          style={{ textAlign: "right" }}
                        >
                          ${item_total_price}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableRow>
                <TableCell
                  colSpan={3}
                  className="body-table-cell common_font table-cell-without-border-bottom"
                />
                <TableCell
                  colSpan={1}
                  className="body-table-cell common_font table-cell-without-border-bottom"
                  align="right"
                >
                  <b>Sub Total</b>
                </TableCell>
                <TableCell
                  align="right"
                  className="body-table-cell common_font table-cell-without-border-bottom"
                >
                  ${Number(returnDetails[0]?.total_item_price).toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={3}
                  className="body-table-cell common_font table-cell-without-border-bottom"
                />
                <TableCell
                  colSpan={1}
                  align="right"
                  className="body-table-cell common_font table-cell-without-border-bottom"
                >
                  <b>Taxes</b>
                </TableCell>
                <TableCell
                  align="right"
                  className="body-table-cell common_font table-cell-without-border-bottom"
                >
                  {/* ${Number(salesOrderDetails?.tax_amount).toFixed(2)} */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={3}
                  className="body-table-cell common_font table-cell-without-border-bottom"
                />
                <TableCell
                  colSpan={1}
                  align="right"
                  className="body-table-cell common_font"
                >
                  <b>Discount</b>
                </TableCell>
                <TableCell
                  align="right"
                  className="body-table-cell common_font"
                >
                  {/* ${Number(salesOrderDetails?.discount_amount).toFixed(2)} */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={3}
                  className="body-table-cell common_font table-cell-without-border-bottom"
                />
                <TableCell
                  colSpan={1}
                  align="right"
                  className="body-table-cell common_font table-cell-without-border-bottom"
                >
                  <b
                    style={{
                      fontSize: "18px",
                      textDecorationLine: "underline",
                      textDecorationStyle: "double",
                    }}
                  >
                    Grand Total
                  </b>
                </TableCell>
                <TableCell
                  align="right"
                  className="body-table-cell common_font table-cell-without-border-bottom"
                >
                  <b
                    style={{
                      fontSize: "18px",
                      textDecorationLine: "underline",
                      textDecorationStyle: "double",
                    }}
                  >
                    ${Number(returnDetails[0]?.total_item_price).toFixed(2)}
                  </b>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Paper>
      </Card>
    </div>
  );
}

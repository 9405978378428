// Author: sonal
import { displaytimestampasperclient } from '../utils/dateutils';
import React, { useEffect, useState } from 'react'
import bsaLogo from '../assets/img/BSA-11.png'
import { BUILD_INFO_URL, JENKINS_URL, BUILDINFODATETIME, BUILDINFOSTATUS, BUILDINFOAPPVERSION } from '../config/config'
import { Box } from '@mui/material';
const moment = require("moment");

export default function BuildInfo() {
    const token = localStorage.getItem("token");
    const [buildDataTime, setBuildDataTime] = useState("")
    const [buildStatus, setBuildStatus] = useState("")
    const [buildStatusVal, setBuildStatusVal] = useState("")
    const [appVersion, setAppVersion] = useState("")

    console.log(buildStatus)
    const getBuildInfoData = async () => {

        await fetch(BUILD_INFO_URL + 'build-info', {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                token: token,
            },
        })
            .then((data) => data.json())
            .then((data) => {
                console.log(data)
                setAppVersion(data.version)
                setBuildStatusVal(data.lastBuildStatus)
                setBuildDataTime(data.lastBuildDate)
            });

    }

  useEffect(() => {
    getBuildInfoData();
  }, []);

  return (
    <div>
      <div align="center">
        <h2>Baseline SaaS App</h2>

        <img
          src={bsaLogo}
          style={{
            width: "200px",
            height: "50px",
          }}
          alt="bsa"
        />
        <br />

                <div className='build-info-head-div' align="center">
                    <div>
                        <div className='build-info-main-div build-info-div'>
                            <Box className='build-info-div-b common_font build-info-txt '>Last Build Date</Box>
                            <span style={{ fontWeight: "bold" }}>:</span>
                            <Box className="build-info-txt common_font">
                                {/* {buildDataTime} */}
                                {BUILDINFODATETIME}
                            </Box>
                        </div>

                        <div className='build-info-main-div build-info-div'>
                            <Box className='build-info-div-b common_font build-info-txt '>Last Build Success </Box>
                            <span style={{ fontWeight: "bold" }}> :</span>
                            <Box className="build-info-txt common_font">
                                {/* {buildStatusVal} */}
                                {BUILDINFOSTATUS}
                            </Box>
                        </div>

                        <div className='build-info-main-div'>
                            <Box className='build-info-div-b common_font build-info-txt '>App Version No </Box>
                            <span style={{ fontWeight: "bold" }}>:</span>
                            <Box className="build-info-txt common_font">
                                {/* {appVersion} */}
                                {BUILDINFOAPPVERSION}
                            </Box>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}

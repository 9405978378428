import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { USER_DETAILS } from "../../config/config";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import male_icon from "../../assets/img/male_icon.png";
import femalee_icon from "../../assets/img/female_icon.png";
import { Button, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import "../../Styles/viewallusers.css";
import { useTranslation } from "react-i18next";

const moment = require("moment");

export default function ViewAllUsers() {
  const userListRef = React.useRef();

  const { t, i18n } = useTranslation()
  const { heading } = t("manage-users-all-users")


  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language)
  }, [i18n.language])

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "user_table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;

    html2canvas(userListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("product-list.pdf");
      }
    );
  };

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 7;
  const localStoragePersonId = JSON.parse(localStorage.getItem("user"));

  const loggedPersonId = localStoragePersonId.person_id;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(rows);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setRows(tempData);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const userList = () => {
    const Token = localStorage.getItem("token");
    fetch(USER_DETAILS, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: Token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        if (data.success === true) {
          setRows(data.data.user_list);
        }
      });
  };

  useEffect(() => {
    userList();
  }, []);

  const filteredItems = rows.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.gender.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.marital_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.occupation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedUsers = filteredItems.slice(startIndex, endIndex);

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;

  return (
    <>
      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(paginatedUsers);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>

      <div
        className="common_card_bs"
        style={{ background: "#ffffff", paddingBottom: "30px" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px 5px 20px",
          }}
        >
          <Box className="view-all-main-header">
            <h3 className="common_font">{heading}</h3>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              id="customer-select"
              value={searchQuery}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              InputLabelProps={{
                className: "common_font",
              }}
              inputProps={{
                className: "common_font",
              }}
            />

            <Button
              className="common_font export-data "
              onClick={handleExportClick}
              startIcon={<DownloadIcon fontSize="large" />}
            >
              Export
            </Button>
          </Box>
        </Box>

        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer ref={userListRef} className="table-container">
            <Table sx={{ overflowX: "auto" }} aria-label="simple table">
              <TableHead className="table-head">
                <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                  <TableCell className="common_font log_table_table_head head-table-cell"></TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    User Name
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    Email
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell">
                    User Created on
                  </TableCell>
                  <TableCell className="common_font log_table_table_head head-table-cell-message">
                    User type
                  </TableCell>
                  <TableCell
                    className="common_font log_table_table_head head-table-cell"
                    sx={{ textAlign: "right" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {paginatedUsers.map((row, index) => {
                      console.log(row);
                      const formattedDate = moment(row.user_created_at).format(
                        "DD MMM YYYY"
                      );

                      return (
                        <Draggable
                          key={row.person_id}
                          draggableId={row.person_id}
                          index={index}
                        >
                          {(provider) => (
                            <TableRow
                              className={index % 2 === 0 ? ' product-list-alternate-table all_box_hover' : 'all_box_hover'}
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                              onClick={() =>
                                navigate(`/${lng}/manage-users/${row.person_id}`, {
                                  state: {
                                    personId: row.person_id,
                                    Email: row.user_id,
                                  },
                                })
                              }
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                {...provider.dragHandleProps}
                                sx={{ cursor: "grab" }}
                              >
                                <DragIndicatorIcon fontSize="small" />
                              </TableCell>
                              <TableCell className="body-table-cell common_font">
                                <div className="tabel-cell-Boxes">
                                  {row.gender === "Female" ? (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={femalee_icon}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={male_icon}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    />
                                  )}
                                  <Typography className="common_font">
                                    {row.first_name ? (
                                      <span>
                                        <span>
                                          {row.first_name} {row.last_name}
                                        </span>
                                        {loggedPersonId === row.person_id ? (
                                          <Chip
                                            className="common_font"
                                            label="You"
                                            size="small"
                                            color="success"
                                            sx={{ marginLeft: "10px" }}
                                          />
                                        ) : null}
                                      </span>
                                    ) : (
                                      "NA"
                                    )}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell className="body-table-cell common_font">
                                <div className="tabel-cell-Boxes">
                                  <span>
                                    {row.user_id ? row.user_id : "NA"}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell className="body-table-cell common_font">
                                <Box className="tabel-cell-Boxes">
                                  {formattedDate ? formattedDate : ""}
                                </Box>
                              </TableCell>

                              <TableCell className="body-table-cell common_font">
                                <Box className="tabel-cell-Boxes">
                                  {row.user_type ? (
                                    <>
                                      {row.user_type === "User"
                                        ? "Sales Rep"
                                        : row.user_type}
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                className="body-table-cell common_font"
                                sx={{ textAlign: "right" }}
                              >
                                {row.user_status === "active" ? (
                                  <Chip
                                    className="common_font"
                                    label="Active"
                                    color="success"
                                    size="medum"
                                  />
                                ) : (
                                  <Chip
                                    className="common_font"
                                    label="Inactive"
                                    color="warning"
                                    size="medium"
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
        <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            className="pagination common_font"
          />
        </Box>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const googleMapsApiKey = 'AIzaSyBwNIm4NF2Dh-T24xsfT0xgiYtRA5OuAgI';

const PlusCodeMap = (data) => {
  console.log(data)
  const [markers, setMarkers] = useState([]);
  const [retrievedplusCode, setRetrievedplusCode] = useState([data.AddressDetails.PlusCode]);
  const [Location,setLocation]=useState()
  // Example plus codes
  const plusCodes = [data.AddressDetails.PlusCode];
  const CompleteAddress = data.AddressDetails.CompleteAddress

  console.log(CompleteAddress)
  console.log(data.AddressDetails.plusCode)
  console.log(data.AddressDetails.CompleteAddress)

  useEffect(() => {
    // Fetch the location details for each plus code
    const fetchLocationDetails = async (plusCode) => {
      try {
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(plusCode)}&key=${googleMapsApiKey}`;
        const response = await fetch(apiUrl);
        const data = await response.json();

        // Extract the location coordinates
        const location = data.results[0]?.geometry?.location;
        console.log(location)
        setLocation(location)
        return location;
      } catch (error) {
        console.error('Error fetching location details:', error);
        return null;
      }
    };

    // Fetch location details for each plus code and update markers
    const updateMarkers = async () => {
      const newMarkers = await Promise.all(plusCodes.map(async (plusCode) => {
        const location = await fetchLocationDetails(plusCode);
        console.log(location)
        return location ? { position: location, plusCode, address: CompleteAddress } : null;
      }));
      console.log('New Markers:', newMarkers);
      setMarkers(newMarkers.filter(Boolean));
    };
    if (plusCodes[0]) {
      updateMarkers();
    }

    console.log(retrievedplusCode, plusCodes)
    // console.log(runCount)
  }, [plusCodes[0], CompleteAddress]); // Dependency array to run the effect once on mount

  const mapOptions = {
    center:Location,
    // center: { lat: 37.09024, lng: -95.712891 }, // Initial center of the map
    zoom: 12 // Initial zoom level
  };

  return (

    <GoogleMap mapContainerStyle={{ width: '100%', height: '400px', fontFamily: "Nunito !important" }} center={mapOptions.center} zoom={mapOptions.zoom}>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker.position} title={`Address: ${marker.plusCode + ", " + marker.address}`} />
        // `Address: ${marker.address + "\n" + "PlusCode: " +marker.plusCode}`
      ))}
    </GoogleMap>


  );
};

export default PlusCodeMap;
// Author: sonal

import { ADD_TO_CART, HIT_NOTIFICATION_FUN, REMOVE_FROM_CART, HIT_GET_USER_PROFILE } from "./cartConstants"


export const addToCart = (item) => {
    return {
        type: ADD_TO_CART,
        data: item
    }
}

export const removeFromCart = (item) => {
    return {
        type: REMOVE_FROM_CART,
        data: item
    }
}

export const hitNotificationFun = (item) => {
    console.log(item)
    return {
        type: HIT_NOTIFICATION_FUN,
        data: item
    }
}

export const hitGetUserProfile = (item)=>{
    console.log(item)
    return {
        type : HIT_GET_USER_PROFILE,
        data: item
    }
}

export const resetUserProfileImage = () => ({
    type: HIT_GET_USER_PROFILE,
    data: false,
  });
import React, { useRef } from "react";
import first from "../../assets/img/carousel/11.jpg";
import second from "../../assets/img/carousel/12.jpg";
import third from "../../assets/img/carousel/13.jpg";
import forth from "../../assets/img/carousel/14.jpg";
import fifth from "../../assets/img/carousel/15.jpg";
import six from "../../assets/img/carousel/10.jpg";
import seven from "../../assets/img/carousel/7.jpg";
import eight from "../../assets/img/carousel/8.jpg";
import nine from "../../assets/img/carousel/9.jpg";
import ten from "../../assets/img/carousel/3.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "../../Styles/products.css";
import { useNavigate } from "react-router-dom";

export default function ProductsTypeList() {
  const navigate = useNavigate();

  const products = [
    {
      name: "Ink Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: seven,
    },
    {
      name: "Ball Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: ten,
    },
    {
      name: "Parker Pen",
      description: "Description for Product 1",
      price: 19.99,
      image: nine,
    },
    {
      name: "Fountain Pen",
      description: "Description for Product 1",
      price: 19.99,
      image: first,
    },
    {
      name: "Waterman Fountain Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: six,
    },
    {
      name: "Marker Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: third,
    },
    {
      name: "Ink Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: forth,
    },
    {
      name: "Gel Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: fifth,
    },

    {
      name: "Black Pen",
      description: "Description for Product 2",
      price: 29.99,
      image: eight,
    },

  ];

  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollAmount = 300; // Adjust the scroll amount as needed
    const container = scrollContainerRef.current;

    if (container) {
      if (direction === "left") {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    }
  };

  const selectedTypeProductListFun = (product) => {
    console.log(product);
    navigate("/selectedtypeproductlist", {
      state: {
        type: product.name,
        products: product,
      },
    });
  };

  return (
    <div className="product-list-container">
      <h1 className="common_font">Types of Pens</h1>
      <div className="product-list" ref={scrollContainerRef}>
        {products.map((product, index) => (
          <div
            onClick={() => {
              selectedTypeProductListFun(product);
            }}
            key={index}
            className="product-item all_box_hover"
          >
            <img
              className="selected_type_product_list_img"
              src={product.image}
              alt={product.name}
            />
            <div className="product-details">
              <h3
                style={{ textAlign: "center", margin: "5px 0px" }}
                className="common_font"
              >
                {product.name}
              </h3>
            </div>
          </div>
        ))}
      </div>
      <button className="scroll-button" onClick={() => handleScroll("left")}>
        <ArrowBackIosIcon sx={{ marginTop: "-6px !important" }} />
      </button>
      <button className="scroll-button" onClick={() => handleScroll("right")}>
        <ArrowForwardIosIcon
          sx={{ marginTop: "-6px !important", marginLeft: "-5px !important" }}
        />
      </button>
    </div>
  );
}

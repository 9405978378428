import React, { useEffect, useState } from "react";
import { displaytimestampasperclient } from "../../utils/dateutils";
import font from "../../assets/fonts/Nunito/Nunito-Light.ttf";
import fontb from "../../assets/fonts/Nunito/Nunito-Bold.ttf";
import logo from "../../assets/img/Bodex.png";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Nunito-Light.ttf",
  src: font,
});
Font.register({
  family: "NunitoSans_10pt-Bold.ttf",
  src: fontb,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    height: "100vh",
    borderColor: "#000",
    borderWidth: 1,
    fontFamily: "Nunito-Light.ttf",
  },
  content: {
    padding: 20,
  },
  logo: {
    width: 70,
    marginBottom: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 18,
    marginBottom: 20,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  status: {
    marginBottom: 20,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  statusText: {
    fontSize: 10,
    backgroundColor: "#fff1d6",
    color: "#c88c32",
    padding: 7,
    borderRadius: 15,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  quotationDetails: {
    marginTop: -15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  quotationText: {
    fontSize: 8,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  quotationID: {
    fontSize: 8,
    fontFamily: "Nunito-Light.ttf",
  },
  quotationFor: {
    fontSize: 15,
    marginTop: 20,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  customerName: {
    fontSize: 15,
    marginTop: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  customerNameCaption: {
    fontSize: 10,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
    marginTop: 2,
  },
  occupation: {
    fontSize: 10,
    flexDirection: "row",
  },
  occupationTitle: {
    fontSize: 10,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  address: {
    fontSize: 12,
    fontFamily: "Nunito-Light.ttf",
  },

  table: {
    marginTop: 30,
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  skuTable: {
    display: "flex",
    flexDirection: "column",
    marginTop: 30,
  },
  skuRowHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#38a5ff",
    padding: 7,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  skuRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    padding: 7,
  },
  skuRowPricing: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 7,
  },
  skuDetail: {
    width: "40%",
    fontSize: 12,
  },
  skuDetailDescription: {
    width: "100%",
    fontSize: 12,
    flexDirection: "column",
  },
  PenName: {
    width: "100%",
    fontSize: 12,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  skuDetailPricing: {
    textAlign: "right",
    width: "40%",
    fontSize: 12,
  },
  GrandTotalPricing: {
    textAlign: "right",
    width: "40%",
    fontSize: 12,
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
  totalSection: {
    marginTop: 10,
    borderTop: "1 solid black",
    paddingTop: 5,
  },
  qrCode: {
    marginTop: 20,
    height: 80,
    width: 80,
  },
  boldValue: {
    fontFamily: "NunitoSans_10pt-Bold.ttf",
  },
});

const PdfLayout = (props) => {
  console.log(props);

  const [quotation_details, setQuotationDetails] = useState([]);

  // const [contact_details, setContactDetails] = useState([]);
  const [contact_number, setContactNumber] = useState(null);
  const [contact_email, setContactEmail] = useState(null);
  const [quotation_product_list, setQuotationProductList] = useState([]);

  useEffect(() => {
    setQuotationDetails(props.data.quotation_details);
    // setContactDetails(props.data.contact_details);
    if (props.data.contact_details.length > 0) {
      var phone = props.data.contact_details.filter(
        (item) => item.contact_type === "phone"
      );
      var email = props.data.contact_details.filter(
        (item) => item.contact_type === "email"
      );
      setContactNumber(phone[0]?.contact_details);
      setContactEmail(email[0]?.contact_details);
    }
    setQuotationProductList(props.data.quotation_product_list);
  }, [props]);

  console.log(contact_number, contact_email);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.header}>
            <View style={styles.headerText}>
              <Image style={styles.logo} src={logo} />
            </View>
            {/* <View style={styles.status}>
              <Text style={styles.statusText}>
                {quotation_details[0]?.quotation_status}
              </Text>
            </View> */}
          </View>
          <View style={styles.quotationDetails}>
            <Text style={styles.quotationText}>Quotation ID :</Text>
            <Text style={styles.quotationID}>
              {" "}
              {quotation_details[0]?.quotation_display_id}
            </Text>
          </View>
          <View style={styles.header}>
            <View>
              <View style={styles.quotationFor}>
                <Text>Quotation For :</Text>
              </View>
              <View style={styles.customerName}>
                <Text>
                  {quotation_details[0]?.customer_first_name}{" "}
                  {quotation_details[0]?.customer_last_name}{" "}
                </Text>
                <Text style={styles.customerNameCaption}>
                  ({quotation_details[0]?.person_type})
                </Text>
              </View>
              {/* <View style={styles.occupation}>
                <Text style={styles.occupationTitle}>Occupation : </Text>
                <Text>{quotation_details[0]?.occupation}</Text>
              </View> */}
            </View>
          </View>

          <View style={styles.header}>
            <View style={styles.quotationFor}>
              <Text>Shipping Address :</Text>
              <Text style={styles.address}>
                Street :{" "}
                <Text style={styles.boldValue}>
                  {quotation_details[0]?.shipping_street_address}
                </Text>
              </Text>
              <Text style={styles.address}>
                City :  <Text style={styles.boldValue}>{quotation_details[0]?.shipping_city}</Text>
              </Text>
              <Text style={styles.address}>
                State / Zip : <Text style={styles.boldValue}>
                {quotation_details[0]?.shipping_state}
                </Text>
                {" / "}
                <Text style={styles.boldValue}>{quotation_details[0]?.shipping_zip_code}</Text>
              </Text>
              <Text style={styles.address}>
                Country :  <Text style={styles.boldValue}>{quotation_details[0]?.shipping_country}</Text>
              </Text>
              {contact_number !== "" ? (
                <Text style={styles.address}>Phone : <Text style={styles.boldValue}> {contact_number}</Text></Text>
              ) : null}
              {contact_email !== "" ? (
                <Text style={styles.address}>Email : <Text style={styles.boldValue}>{contact_email}</Text></Text>
              ) : null}
            </View>
            <View style={styles.quotationFor}>
              <Text>Billing Address :</Text>
              <Text style={styles.address}>
                Street :<Text style={styles.boldValue}>{quotation_details[0]?.billing_street_address}</Text> 
              </Text>
              <Text style={styles.address}>
                City : <Text style={styles.boldValue}>{quotation_details[0]?.billing_city}</Text>
              </Text>
              <Text style={styles.address}>
                State / Zip :<Text style={styles.boldValue}>{quotation_details[0]?.billing_state}</Text> 
                {" / "}
                <Text style={styles.boldValue}>{quotation_details[0]?.billing_zip_code}</Text>
              </Text>
              <Text style={styles.address}>
                Country : <Text style={styles.boldValue}>{quotation_details[0]?.billing_country}</Text>
              </Text>
            </View>
            <View></View>
            <View></View>
          </View>
          <View style={styles.quotationFor}>
            <Text>Quotation Created At :</Text>
          </View>
          <View style={styles.customerName}>
            <Text>
              {quotation_details[0]
                ? displaytimestampasperclient(
                    quotation_details[0].quotation_date
                  )
                : ""}
            </Text>
          </View>
          <View style={styles.skuTable}>
            <View style={styles.skuRowHeader}>
              <Text style={styles.skuDetail}>SKU</Text>
              <Text style={styles.skuDetailDescription}>Description</Text>
              <Text style={styles.skuDetail}>&nbsp; &nbsp; Qty</Text>
              <Text style={styles.skuDetailPricing}>Unit Price</Text>
              <Text style={styles.skuDetailPricing}>Total</Text>
            </View>
            {quotation_product_list &&
              quotation_product_list.map((item) => {
                const unit_price = Number(item.unit_price).toFixed(2);
                const item_total_price = Number(item.item_total_price).toFixed(
                  2
                );
                return (
                  <View style={styles.skuRow}>
                    <Text style={styles.skuDetail}>{item.sku}</Text>
                    <Text style={styles.skuDetailDescription}>
                      <Text style={styles.PenName}>{item.pen_name}</Text>
                      <Text>
                        {" \n"}
                        {item.special_features.length > 50
                          ? `${item.special_features.slice(0, 60)}...`
                          : item.special_features}
                      </Text>
                    </Text>
                    <Text style={styles.skuDetail}>
                      &nbsp; &nbsp; &nbsp;
                      {item.quantity}
                    </Text>
                    <Text style={styles.skuDetailPricing}>$ {unit_price}</Text>
                    <Text style={styles.skuDetailPricing}>
                      $ {item_total_price}
                    </Text>
                  </View>
                );
              })}
            <View style={styles.skuRowPricing}>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailDescription}></Text>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailPricing}>Sub Total</Text>
              <Text style={styles.skuDetailPricing}>
                ${Number(quotation_details[0]?.total_amount).toFixed(2)}
              </Text>
            </View>
            <View style={styles.skuRowPricing}>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailDescription}></Text>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailPricing}>Taxes</Text>
              <Text style={styles.skuDetailPricing}>
                $ {Number(quotation_details[0]?.tax_amount).toFixed(2)}
              </Text>
            </View>
            <View style={styles.skuRowPricing}>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailDescription}></Text>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailPricing}>Discount</Text>
              <Text style={styles.skuDetailPricing}>
                - $ {Number(quotation_details[0]?.discount_amount).toFixed(2)}
              </Text>
            </View>
            <View style={styles.skuRowPricing}>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.skuDetailDescription}></Text>
              <Text style={styles.skuDetail}></Text>
              <Text style={styles.GrandTotalPricing}>Grand Total</Text>
              <Text style={styles.GrandTotalPricing}>
                $ {Number(quotation_details[0]?.final_amount).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfLayout;

/** @format */

import 'webrtc-adapter'; 
import React, { useEffect,useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Pagination,
} from "@mui/material";
import "../../Styles/customerdetails.css";
import "../../Styles/products.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import "../../Styles/products.css";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";
import Checkbox from "@mui/material/Checkbox";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { OutlinedInput, FormHelperText } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PlusCodeMap from "../dashboard/PlusCodeMap.jsx";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import Skeleton from "@mui/material/Skeleton";
import Radio from "@mui/material/Radio";
import Badge from "@mui/material/Badge";
import {QrReader} from 'react-qr-reader';

import {
  ADD_CUSTOMER_ADDRESS,
  EDIT_CUSTOMER_ADDRESS,
  PRODUCT_LIST,
  QUOTATION_DETAILS,
  ADD_CUSTOMER_PHONENUMBER,
  CUSTOMER_LIST,
  GET_DISCOUNT_LIST,
  GET_COUPON_LIST,
  QUOTATION_DETAILS_BY_DISPLAY_ID,
  UPDATE_QUOTATION,
} from "../../config/config.js";
import { CUSTOMER_DETAILS, ADD_QUOTATION } from "../../config/config.js";
import {
  UPDATE_CUSTOMER_NAME,
  EDIT_CUSTOMER_PHONENUMBER,
} from "../../config/config.js";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hitNotificationFun } from "../Redux/actions/actions.js";
import removeIcon from "../../assets/img/cancel.png";
import EmailIcon from "@mui/icons-material/Email";
import QuoCreted from "../../assets/img/quotationcreated.png";
import offer from "../../assets/img/offer.png";
import offer2 from "../../assets/img/offer-2.png";
import disoffer from "../../assets/img/dis-offer.png";

const moment = require("moment");

const CustomerDetails = () => {
  const { t } = useTranslation();
  const {
    heading,
    subHeading,
    card1,
    card2,
    editCustomerName,
    editCustomerAddress,
    manageCustomerAddress,
    editCustomerPhone,
    addCustomerEmail,
    editCustomerEmail,
    searchCustomers,
    quotations,
    selectItm,
  } = t("customer-details");
  const [scanResult, setScanResult] = useState('');
  const dispatch = useDispatch();
  const previousDataRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const [customerDetails, setCustomerDetails] = useState("");
  const [product_list, setProductList] = useState([]);
  const [Orders, setOrders] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [brands, setBrands] = useState("");
  const [type, setType] = useState("");
  const [penName, setPenName] = useState("");
  const [itemquantity, setQuantity] = useState([]);
  const [quantityuser, setQuantityUser] = useState([]);
  const [indexcheck, setIndex] = useState(0);
  const [sales, setSales] = useState(false);
  const [personId, setPersonId] = useState();
  const [addressId, setAddressId] = useState();
  const [updateAlert, setUpdateAlert] = useState(false);
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setstreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectPenBySKU, SetSelectPenBySKU] = useState("");
  const [customerAddresses, setcustomerAddresses] = useState();
  const [newzip, setnewZip] = useState("");
  const [newcity, setnewCity] = useState("");
  const [newstate, setnewState] = useState("");
  const [newcountry, setnewCountry] = useState("");
  const [newstreetAdd, setnewstreetAdd] = useState("");
  const [plusCode, setPlusCode] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [firstAddress, setFirstAddress] = useState();
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [Email, setEmail] = useState([]);
  console.log(Email);
  const [personContactId, setpersonContactId] = useState("");
  const [addNewPhone, setaddNewPhonenumber] = useState("");
  const [addNewEmail, setNewEmail] = useState("");
  const [customerPersonId, setcustomerPersonId] = useState("");
  const [showStockmsg, setShowStockMsg] = useState(false);
  const [stockmsg, setStockMsg] = useState("");
  const [addItemId, setAddItemId] = useState(0);
  const [defaultpersonId, setDefaultPersonId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [quotationCount, setCustomerQuotationsCount] = useState(0);
  const [quotationList, setQuotationList] = useState([]);
  const [quotationDetails, setQuotationDetails] = useState([]);
  const [quotationValue, setQuotationValue] = useState("All");
  const [quotationStatusValue, setQuotationStatusValue] = useState("All");
  const [quotationModal, setQuotationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [createdByNameList, setCreatedByNameList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [originalQuotationList, setOriginalQuotationList] = useState([]);
  const [originalStatusList, setOriginalStatusList] = useState([]);
  const [discount_percentages, setDiscountPercentages] = useState([]);
  const [default_discount_percentage, setDefaultDiscountPercentage] =
    useState("");
  const [discount, setDiscount] = useState("");
  const [preDiscount, setPreDiscount] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  console.log(selectedDiscount);
  console.log(discount);
  const [discountAmt, setDiscountAmt] = useState("00.00");
  const [grand_total, setGrandTotal] = useState("00.00");
  const [couponCode, SetCouponCode] = useState("");
  const [coupon_list, setCouponList] = useState([]);
  const [selected_coupon_code, setSelectedCouponCode] = useState("");
  const [couponAlert, setCouponAlert] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [itemAdded, setItemAdded] = useState(false)
  const [data, setData] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [scannerKey, setScannerKey] = useState(0);

  let itemsPerPage;
  if (window.innerWidth > 992) {
    itemsPerPage = 10;
  } else {
    itemsPerPage = 8;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = quotationList.slice(indexOfFirstItem, indexOfLastItem);
  let skuarray=[]
  useEffect(() => {
    getProducts();
    console.log(data)
    if(itemAdded===true){
      addItem()
    }
   
  },[itemAdded])
  useEffect(() => {
    console.log(quotationModal)
    if (quotationModal === true) {
      let getCreatedByName = [];
      let getStatus = [];
      currentItems.map((ele) => {
        getCreatedByName.push(ele.created_by_name);
        getStatus.push(ele.quotation_status);
      });

      const uniqueCreatedByNameArray = [...new Set(getCreatedByName)];
      uniqueCreatedByNameArray.unshift("All");

      const uniqueStatusArray = [...new Set(getStatus)];
      uniqueStatusArray.unshift("All");

      setCreatedByNameList(uniqueCreatedByNameArray);
      setStatusList(uniqueStatusArray);
      setOriginalStatusList(uniqueStatusArray);
    }
  }, [quotationModal]);

  useEffect(() => {
    setOriginalQuotationList();
  }, []);

  useEffect(() => {
    setOriginalStatusList();
  }, []);

  useEffect(() => {
    if (location.state?.productList) {
      if (location.state?.id) {
        getQuotationDetails(location.state.id, location.state.productList);
      } else {
        setOrders(location.state.productList);
        setIsAccordionOpen(true);
      }
    }
  }, []);

  const filterByCreatedNameFun = (e) => {
    setQuotationValue(e.target.value);
    const searchTerm = e.target.value.trim().toLowerCase();

    if (!searchTerm) {
      setQuotationList([...originalQuotationList]);
      return;
    }

    if (e.target.value === "All") {
      setQuotationList(originalQuotationList);
    } else {
      const filteredQuotations = originalQuotationList.filter((val) =>
        val.created_by_name.toLowerCase().includes(searchTerm)
      );
      setQuotationList(filteredQuotations);
    }
  };

  const filterByStatusFun = (e) => {
    setQuotationStatusValue(e.target.value);

    const searchTerm = e.target.value.trim().toLowerCase();
    if (!searchTerm) {
      setStatusList([...originalStatusList]);
      return;
    }

    if (e.target.value === "All") {
      setQuotationList(originalQuotationList);
    } else {
      const filteredStatus = originalQuotationList.filter((val) =>
        val.quotation_status.toLowerCase().includes(searchTerm)
      );
      setQuotationList(filteredStatus);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  const addItemIdInc = () => {
    setAddItemId(addItemId + 1);
  };
  console.log(addItemId);
  const [showquanmsg, setShowQuanMsg] = useState(false);

  var total = 0;
  console.log(phoneNumber);
  const handleQuantityChange = (
    index,
    quantity,
    product_id,
    unit_price,
    product_stock_quantity
  ) => {
    console.log(
      index,
      quantity,
      product_id,
      unit_price,
      product_stock_quantity
    );
    setIndex(index);

    const newQuantities = [...itemquantity];
    newQuantities[index] = quantity;

    setQuantity(newQuantities);
    const newquantityuser = [...quantityuser];
    newquantityuser[index] = { quantity, product_id, unit_price };
    setQuantityUser(newquantityuser);
    console.log(quantityuser);
    if (+newQuantities[index] === 0) {
      setShowQuanMsg(true);
      newQuantities[index] = "";
      setStockMsg(`Quantity should be atleast 1!`);
    } else if (+newQuantities[index] > +product_stock_quantity) {
      setShowQuanMsg(true);
      setShowStockMsg(true);
      newQuantities[index] = "";
      setStockMsg(`Only ${product_stock_quantity} item left in stock!`);
    } else {
      setStockMsg(" ");
      setShowStockMsg(false);
      setShowQuanMsg(false);
    }
  };

  const filteredCustomer = customerList.filter(
    (customer) =>
      customer.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  console.log(stockmsg, showStockmsg);

  var uniqueBrandNames = [...new Set(product_list.map((pl) => pl.brand_name))];
  const PenType = product_list.filter((x) => x.brand_name === brands);
  var uniqueType = [...new Set(PenType.map((pl) => pl.pen_type))];
  const PenName = product_list.filter(
    (x) => x.brand_name === brands && x.pen_type === type
  );
  var uniquePenName = [...new Set(PenName.map((pl) => pl.pen_name))];
  var Item = product_list.filter(
    (x) =>
      x.brand_name === brands && x.pen_type === type && x.pen_name === penName
  );

  const [ModalValue, setModalValue] = useState("");
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const [snackseverity, setSnackSeverity] = useState("");
  const [contactPriroty, setcontactPriroty] = useState();
  const handleOpen = (value, personDetail) => {
    console.log(personDetail);
    // if (value === "EditCustomer") {
    //   setOrders([]);
    //   setIsAccordionOpen(false);
    // }
    if (personDetail) {
      console.log(personDetail);
      setZip(personDetail.zip_code);
      setState(personDetail.state);
      setCountry(personDetail.country);
      setCity(personDetail.city);
      setstreetAddress(personDetail.street_address);
      setAddressId(personDetail.address_id);
      const usPhoneNumberFormate = formatUsPhone(personDetail.contact_details);
      setPhone(usPhoneNumberFormate);
      setpersonContactId(personDetail.person_contact_detail_id);
      setemail(personDetail.contact_details);
      setIsValidEmail(validateEmail(personDetail.contact_details));
      setcontactPriroty(personDetail.contact_priority);
    }

    console.log(value);

    setModalValue(value);
    if (value === "AddItems") {
      setOpen(true);
      getProducts();
    } else {
      setOpen(true);
    }

    if (value === "EditCustomer") {
      setSearchQuery("");
      getCustomerList("EditCustomer");
      setOpen(true);
    } else {
      setOpen(true);
    }
    if (value === "ApplyDiscount") {
      setPreDiscount(discount);
    }
  };
//   const handleScan = (data) => {
//     if (data) {
//       console.log(data)
//         setScanResult(data);
//         setShowScanner(false); // Optionally close scanner after successful scan
//     }
// };

const handleError = err => {
    console.error(err);
};

const handleScanClick = () => {
    setData('')
    setShowScanner(true);
    setItemAdded(false);
    setScannerKey((prevKey) => prevKey + 1);

    
};
const handleResult = (result, error) => {
 try{
  if(result) {
    setShowScanner(false);
    SelectPenBySKU(result.text)
    // addItem()
    setItemAdded(true);
    
    
    console.log(result);
    setData(result.text);
    // setItemAdded(true);
    
      // if (!itemAdded) {
      //   addItem()
      //   setItemAdded(true);
      
      // }
    
  }
}catch(error){
  if (error) {
    console.error(error);
  }
}
  
  // let newresult=''
  // if (scanComplete) {
  //   newresult=''
  //   return;
  // }else{
  //   newresult=result 
  // }
  // console.log(newresult)
  // if (newresult && newresult !=='No result' && newresult !== previousDataRef.current) {
 
  //   let scannedResult = newresult.text;
  //   console.log(scannedResult);
  //   setData(scannedResult);
  //   setShowScanner(false);
  //   setScanComplete(true); // Mark scan as complete
  //   previousDataRef.current = newresult;

  // }

  // if (error) {
  //   console.info(error); // Log errors to console
  // }
};
    // if(scannedResult){
    //   SelectPenBySKU(scannedResult)
    //   if (!itemAdded) {
    //     addItem()
    //     setItemAdded(true);
    //     setShowScanner(false);
      
    //   }
      
      
    //  }
  // useEffect(()=>{
    

  // },[scanComplete])
  const addItem = () => {
    console.log(Orders, Item);
    if (Object.keys(Orders).length > 0) {
        function areObjectsEqual(obj1, obj2) {
            // Check if both objects have the same keys and values
            return Object.keys(obj1).length === Object.keys(obj2).length &&
                   Object.entries(obj1).every(([key, value]) => obj2[key] === value);
        }

        function isArray1InArray2(Item, Orders) {
            return Item.every(obj1 => 
                Orders.some(obj2 => areObjectsEqual(obj1, obj2))
            );
        }

        let result = isArray1InArray2(Item, Orders);
        console.log(result);  // Output: true
    }

    // Check if the item already exists in Orders
    const itemInOrders = Orders.find(order => order.sku === Item[0].sku);

    if (itemInOrders) {
        // If item exists, increment its quantity
        itemInOrders.product_stock_quantity = (parseInt(itemInOrders.product_stock_quantity) + 1).toString();
        setOrders([...Orders]); // Update Orders state with modified object
    } else {
        // If item doesn't exist, add new item
        setOrders([...Orders, ...Item]);
    }

    if (Item[0].product_stock_quantity == 0) {
        setShowQuanMsg(true);
    }

    setBrands("");
    setPenName("");
    setType("");
    SetSelectPenBySKU("");
    setOpen(false);
};


  // const addItem = () => {
//     console.log(Orders, Item);
//     if(Object.keys(Orders).length>0){
//       function areObjectsEqual(obj1, obj2) {
//         // Check if both objects have the same keys and values
//         return Object.keys(obj1).length === Object.keys(obj2).length &&
//                Object.entries(obj1).every(([key, value]) => obj2[key] === value);
//     }
    
//     function isArray1InArray2(Item, Orders) {
//         return Item.every(obj1 => 
//           Orders.some(obj2 => areObjectsEqual(obj1, obj2))
//         );
//     }
    
//     let result = isArray1InArray2(Item, Orders);
    
//     console.log(result);  // Output: true
  
// }
//     setOrders([...Orders, ...Item]);
//     if (Item[0].product_stock_quantity == 0) {
//       setShowQuanMsg(true);
//     }
//     setBrands("");
//     setPenName("");
//     setType("");
//     SetSelectPenBySKU("");
//     setOpen(false);
//   };

  const handleClose = () => {
    setBrands("");
    setPenName("");
    setType("");
    SetSelectPenBySKU("");
    if (ModalValue === "ApplyDiscount") {
      setDiscount(preDiscount);
      if (selected_coupon_code) {
        let code = coupon_list.filter(
          (item) => item.discount_percentage === selected_coupon_code
        )[0];
        SetCouponCode(code.coupon_code);
      }
    }
    setOpen(false);
  };

  const reset = () => {
    setBrands("");
    setPenName("");
    setType("");
    SetSelectPenBySKU("");
  };

  console.log(indexcheck);

  const handleQuotationModalClose = () => {
    setQuotationModal(false);
  };

  const SelectPenBySKU = (value) => {
    console.log(value)
    console.log(product_list)
    SetSelectPenBySKU(value);
    Item = product_list.filter((x) => x.sku === value);
    console.log(Item.length);
    if (Item.length > 0) {
      setBrands(Item[0].brand_name);
      setType(Item[0].pen_type);
      setPenName(Item[0].pen_name);
    }
  };

  const CouponCode = (value) => {
    if (value === couponCode) {
      SetCouponCode("");
    } else {
      SetCouponCode(value);
      let selectedCoupon = coupon_list.filter((x) => x.coupon_code === value);
      if (selectedCoupon.length > 0) {
        setSelectedCouponCode(selectedCoupon[0].discount_percentage);
        setCouponAlert("");
      } else {
        setCouponAlert("Please enter a valid coupon code.");
        setSelectedCouponCode("");
      }
    }
  };

  const getCustomerList = async (value) => {
    await fetch(CUSTOMER_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          if (defaultpersonId === "" && !location.state?.cl?.person_id) {
            setDefaultPersonId(data.data.Customer_list[0].person_id);
          } else {
            setDefaultPersonId(
              location.state?.cl?.person_id || defaultpersonId
            );
          }
          setCustomerList(data.data.Customer_list);
          if (!value) {
            localStorage.setItem(
              "selectedPerson",
              data.data.Customer_list[0].person_id
            );
            getCustomerDetails(data.data.Customer_list[0].person_id);
          }
        }
      });
  };

  const getDiscountList = async () => {
    await fetch(GET_DISCOUNT_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setDiscountPercentages(data.data.discount_list);
        }
      });
  };

  const getCouponList = async () => {
    await fetch(GET_COUPON_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setCouponList(data.data.coupon_list);
        }
      });
  };

  const applyDiscount = async (total, discount, selected_coupon_code) => {
    let coupon_result = total * (selected_coupon_code / 100);
    let discount_result = total * (discount / 100);
    let total_discount = discount_result + coupon_result;
    let grand_total_result = total - total_discount;
    setSelectedDiscount(Number(+discount + +selected_coupon_code).toFixed(0));
    setDiscountAmt(Number(total_discount).toFixed(2));
    setGrandTotal(Number(grand_total_result).toFixed(2));
    setOpen(false);
  };

  console.log(total, discount, grand_total);

  const getCustomerDetails = async (defaultpersonId) => {
    localStorage.setItem("selectedPerson", defaultpersonId);
    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: location.state?.cl?.person_id || defaultpersonId,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setQuotationList(data.data.Customer_quotations);
          setOriginalQuotationList(data.data.Customer_quotations);
          console.log();
          const contact = [];

          contact.push(data.data.Customer_contact_details);

          const mappedContact = contact[0].filter(
            (item) => item.contact_type === "phone"
          );
          const mappedEmail = contact[0].filter(
            (item) => item.contact_type === "email"
          );
          console.log(data.data)
          console.log(mappedEmail);
          console.log(data.data.Customer_address[0]);
          setcustomerPersonId(data.data.Customer_address[0].person_id);
          setPhoneNumber(mappedContact);
          setEmail(mappedEmail);
          setCustomerDetails(data.data);
          setCustomerQuotationsCount(data.data.Customer_quotations.length);
          setcustomerAddresses(data.data.Customer_address);
          setFirstName(data.data.Customer_details[0].first_name);
          setPersonId(data.data.Customer_details[0].person_id);
          setAddressId(data.data.Customer_details[0].address_id);
          setLastName(data.data.Customer_details[0].last_name);
          setPhone(data.data.Customer_contact_details[0]?.contact_details);
          setPlusCode(data.data.Customer_address[0].plus_code);
          setCompleteAddress(
            data.data.Customer_address[0].street_address +
            " " +
            data.data.Customer_address[0].city +
            " " +
            data.data.Customer_address[0].state +
            " " +
            data.data.Customer_address[0].country +
            " " +
            data.data.Customer_address[0].zip_code
          );
          setFirstAddress(data.data.Customer_address[0]);
          setDefaultPersonId(defaultpersonId);

          if (open === true) {
            setOpen(false);
          }
        }
      });
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const getQuotationDetails = async (id, list) => {
    await fetch(QUOTATION_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        quotation_id: id || location.state.cl.quotation_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setQuotationDetails(data.data.quotation_details[0]);
          var discountcalc =
            (+data.data.quotation_details[0].discount_amount /
              (+data.data.quotation_details[0].discount_amount +
                +data.data.quotation_details[0].final_amount)) *
            100;
          setDiscount(discountcalc.toFixed(2));
          setDiscountAmt(
            Number(data.data.quotation_details[0].discount_amount).toFixed(2)
          );
          if (list) {
            setOrders([...list, ...data.data.quotation_product_list]);
          } else {
            setOrders(data.data.quotation_product_list);
          }
          setIsAccordionOpen(true);
          console.log(Orders);
        }
      });
  };

  // console.log(default_discount_percentage.toFixed(2));

  const generateQuote = async (
    customerDetails,
    total,
    discount,
    final_amount
  ) => {
    const filterquantityuser = quantityuser.filter(Boolean);
    const mergedArray = Orders.map((obj1) => {
      const matchingObj = filterquantityuser.find(
        (obj2) => obj2.product_id === obj1.product_id
      );

      if (!obj1.product_pricing_usd) {
        obj1["unit_price"] = obj1.product_pricing_usd;
      } else {
        obj1["unit_price"] = obj1.unit_price;
      }

      if (!obj1.quantity) {
        obj1["quantity"] = "1";
      } else {
        obj1["quantity"] = obj1.quantity;
      }

      return matchingObj ? { ...obj1, ...matchingObj } : obj1;
    });
    await fetch(ADD_QUOTATION, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: location.state?.cl?.person_id || defaultpersonId,
        shipping_address_id: customerDetails.Customer_address[0].address_id,
        billing_address_id: customerDetails.Customer_address[0].address_id,
        total_amount: total,
        discount_amount: discount,
        tax_amount: "00",
        final_amount: final_amount,
        quotation_created_by: JSON.parse(user).person_id,
        quotation_status: "DRAFT",
        products: mergedArray,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSales(true);
          QuoCreatedhandleOpen(
            data.data.quotation_display_id[0]._quotation_display_id
          );
          setOrders(mergedArray);
          dispatch(hitNotificationFun(true));
        }
      });
  };

  const updateQuote = async (
    customerDetails,
    total,
    discount,
    final_amount
  ) => {
    const filterquantityuser = quantityuser.filter(Boolean);
    const mergedArray = Orders.map((obj1) => {
      const matchingObj = filterquantityuser.find(
        (obj2) => obj2.product_id === obj1.product_id
      );

      if (!obj1.product_pricing_usd) {
        obj1["unit_price"] = obj1.product_pricing_usd;
      } else {
        obj1["unit_price"] = obj1.unit_price;
      }

      if (!obj1.quantity) {
        obj1["quantity"] = "1";
      } else {
        obj1["quantity"] = obj1.quantity;
      }

      return matchingObj ? { ...obj1, ...matchingObj } : obj1;
    });
    await fetch(UPDATE_QUOTATION, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        quotation_id: quotationDetails.quotation_id,
        quotation_display_id: quotationDetails.quotation_display_id,
        person_id: quotationDetails.person_id,
        shipping_address_id: quotationDetails.shipping_address_id,
        billing_address_id: quotationDetails.billing_address_id,
        quotation_date: quotationDetails.quotation_date,
        total_amount: total,
        discount_amount: discount,
        tax_amount: "00",
        final_amount: final_amount,
        quotation_created_by: quotationDetails.quotation_created_by,
        quotation_status: quotationDetails.quotation_status,
        quotation_id_for_display_id:
          quotationDetails.quotation_id_for_display_id,
        quotation_approval_status: quotationDetails.quotation_approval_status,
        quotation_sent: quotationDetails.quotation_sent,
        quotation_emails: quotationDetails.quotation_emails,
        adobe_agreement_id: quotationDetails.adobe_agreement_id,
        quotation_sent_time: quotationDetails.quotation_sent_time,
        quotation_accept_time: quotationDetails.quotation_accept_time,
        products: mergedArray,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSales(true);
          QuoCreatedhandleOpen(data.data.quotation_display_id);
          setOrders(mergedArray);
          dispatch(hitNotificationFun(true));
        }
      });
  };

  const salesDetails = () => {
    navigate("/en/quotes", { state: { cl: customerDetails } });
  };

  const getProducts = async () => {
    await fetch(PRODUCT_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data.Product_list)
          setProductList(data.data.Product_list);
        }
      });
  };
  const updateUserName = async (e) => {
    e.preventDefault();
    console.log("updateUserName");
    try {
      const response = await fetch(UPDATE_CUSTOMER_NAME, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          person_id: location.state?.cl?.person_id || defaultpersonId,
          first_name: firstName,
          last_name: lastName,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        setSnackSeverity("success");
        setUpdateAlertMsg(data.message);
        setUpdateAlert(true);
        getCustomerDetails(location.state?.cl?.person_id || defaultpersonId);
        setOpen(false);
      } else {
        setUpdateAlertMsg(data.message);
        setUpdateAlert(true);
        getCustomerDetails(location.state?.cl?.person_id || defaultpersonId);
        setOpen(false);
        setSnackSeverity("error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    console.log("handlePhoneSubmit");
    const options = {
      person_id: personId,
      contact_details: phone,
      person_contact_detail_id: personContactId,
      contact_type: "phone",
      contact_priority: contactPriroty,
    };

    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        } else {
          setUpdateAlertMsg(data.message);
          setSnackSeverity("error");
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        }
      });
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    console.log("handlePhoneSubmit");
    const options = {
      person_id: personId,
      contact_details: email,
      person_contact_detail_id: personContactId,
      contact_type: "email",
      contact_priority: contactPriroty,
    };

    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        } else {
          setUpdateAlertMsg(data.message);
          setSnackSeverity("error");
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        }
      });
  };

  const handleEditAddressSubmit = async (e) => {
    e.preventDefault();
    console.log("handleEditAddressSubmit");
    const options = {
      address_id: addressId,
      person_id: personId,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zip,
      country: country,
      address_type: "",
      plus_code: "",
    };
    console.log(options);
    await fetch(EDIT_CUSTOMER_ADDRESS, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        } else {
          setSnackSeverity("error");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        }
      });
  };

  //Add New Address
  const handleAddNewAddressSubmit = async (e) => {
    e.preventDefault();
    console.log("handleEditAddressSubmit");
    const options = {
      person_id: personId,
      address_id: addressId,
      street_address: newstreetAdd,
      city: newcity,
      state: newstate,
      zip_code: newzip,
      country: newcountry,
      address_type: "",
      plus_code: "",
    };
    console.log(options);
    await fetch(ADD_CUSTOMER_ADDRESS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(personId);
          setOpen(false);
        } else {
          setSnackSeverity("error");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          setOpen(false);
          getCustomerDetails(personId);
        }
      });
  };

  //Add new contact
  const handleNewContactSubmit = async (e) => {
    e.preventDefault();
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: addNewPhone,
        contact_type: "phone",
        contact_priority: "primary"
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          console.log(data);
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(customerPersonId);
          setOpen(false);
        } else {
          setSnackSeverity("error");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(customerPersonId);
          setOpen(false);
        }
      });
  };

  //Add New Email
  const handleNewEmailSubmit = async (e) => {
    e.preventDefault();
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: addNewEmail,
        contact_type: "email",
        contact_priority: 1,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          console.log(data);
          setSnackSeverity("success");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(customerPersonId);
          setOpen(false);
        } else {
          setSnackSeverity("error");
          setUpdateAlertMsg(data.message);
          setUpdateAlert(true);
          getCustomerDetails(customerPersonId);
          setOpen(false);
        }
      });
  };

  useEffect(() => {
    // setOrders([]);
    setShowStockMsg("");
    getCouponList();
    getDiscountList();
    if (!location?.state?.cl?.person_id) {
      getCustomerList();
    }
    if (location?.state?.cl?.person_id) {
      localStorage.setItem("selectedPerson", location?.state?.cl?.person_id);
      getCustomerDetails();
    }
    // getQuotationDetails();
    if (location?.state?.cl?.quotation_id) {
      getQuotationDetails();
      setIsAccordionOpen(true);
    }
  }, []);

  const handleDeleteItem = (index) => {
    console.log(Orders, itemquantity);
    const newOrders = [...Orders];
    newOrders.splice(index, 1);
    setOrders(newOrders);

    const newQuan = [];
    Orders.forEach((item, index) => {
      if (itemquantity[index] == undefined) {
        if (item.quantity) {
          newQuan.push(item.quantity);
        } else {
          newQuan.push(1);
        }
      } else {
        newQuan.push(itemquantity[index]);
      }
    });

    newQuan.splice(index, 1);
    console.log(newQuan);
    setQuantity(newQuan);

    if (newQuan.length === 0) {
      setDiscount("");
      setPreDiscount("");
      SetCouponCode("");
      setSelectedCoupon("");
      setSelectedCouponCode("");
      setDiscountAmt("");
      setSelectedDiscount("");
    }
  };

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters
    const numericPhone = phoneNumber.replace(/\D/g, "");

    // Apply the phone number format (XXX) XXX-XXXX
    const formattedPhone = numericPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedPhone;
  };

  const validatePhone = (phoneNumber) => {
    // Use a simple validation for illustration purposes
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);
    setIsValidPhone(validatePhone(newPhone));
  };

  function formatUsPhone(phone) {
    var phoneTest = new RegExp(
      /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/
    );
    phone = phone?.trim();
    var results = phoneTest.exec(phone);
    if (results !== null && results.length > 8) {
      return (
        "(" +
        results[3] +
        ") " +
        results[4] +
        "-" +
        results[5] +
        (typeof results[8] !== "undefined" ? " x" + results[8] : "")
      );
    } else {
      return phone;
    }
  }

  const [QuoCreatedModalOpen, setQuoCreatedModalOpen] = React.useState(false);
  const QuoCreatedhandleOpen = (qt_Id) => {
    console.log(qt_Id);
    setQuoCreatedModalOpen(true);
    setTimeout(() => {
      setQuoCreatedModalOpen(false);
      navigate(`/en/quotes/${qt_Id}`);
    }, 3001);
  };
  const QuoCreatedhandleClose = () => setQuoCreatedModalOpen(false);

  const handleAddEmail = (e) => {
    if (e.target.value === "") {
      setIsValidEmail(false);
    }
    const newEmail = e.target.value;
    setNewEmail(e.target.value);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleCouponDiscountChange = (e, item) => {
    console.log(item);
    console.log(e.target.checked);
    if (e.target.checked === true) {
      setDiscount(item.discount_percentage);
      setSelectedCoupon(item);
    } else {
      setDiscount("");
    }
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <Snackbar
        open={updateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity={snackseverity}
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">{updateAlertMsg}</Typography>
        </Alert>
      </Snackbar>
      <Modal
        open={QuoCreatedModalOpen}
        onClose={QuoCreatedhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            className="payment_selector_modal common-display-style common_card_bs"
            sx={{ width: "350px" }}
          >
            <center>
              <Typography
                variant="p"
                style={{
                  color: "#24a4eb",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="common_font"
              >
                Quotation Created Successfully!
              </Typography>

              <img
                src={QuoCreted}
                alt="payment sucessfull"
                style={{ width: "150px", padding: "30px", marginLeft: "20px" }}
              />
              <br />
              <Typography
                variant="p"
                style={{
                  color: "#fec237",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
                className="common_font"
              >
                Redirecting to quotation details...
              </Typography>
            </center>
          </Box>
        </>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            {/* <CancelIcon sx={{ fontSize: "35px", color: "#56565b" }} className="all_box_hover customer-details-quotation-selector-modal-div-icon" onClick={() => { setOpen(false) }} /> */}

            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={handleClose}
            />

            <div className="modal-sub-div">
              {ModalValue === "AddItems" ? (
                <Box className="">
                  <center>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="common_font"
                    >
                      {/* Select Item */}
                      {selectItm.heading}
                    </Typography>
                  </center>
                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <TextField
                        id="select-pen-by-sku"
                        label="Enter SKU"
                        placeholder="Enter SKU"
                        value={Item[0]?.sku ? Item[0].sku : selectPenBySKU}
                        onChange={(e) => SelectPenBySKU(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                          shrink: true,
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </div>

                  <hr style={{ marginTop: "20px" }} />

                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <InputLabel className="common_font" id="brands-label">
                        Brand
                      </InputLabel>
                      <Select
                        labelId="brands-label"
                        id="brands-select"
                        value={brands}
                        label="Brands"
                        onChange={(e) => setBrands(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {uniqueBrandNames.map((pl, index) => (
                          <MenuItem
                            className="common_font"
                            id={`brands-menu-item-${index}`}
                            key={index}
                            value={pl}
                          >
                            {pl}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <InputLabel className="common_font" id="type-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="type-label"
                        id="type-select"
                        value={type}
                        label="Type"
                        onChange={(e) => setType(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {uniqueType.map((pt, index) => (
                          <MenuItem
                            className="common_font"
                            id={`type-menu-item-${index}`}
                            key={index}
                            value={pt}
                          >
                            {pt}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <InputLabel className="common_font" id="name-label">
                        Name
                      </InputLabel>
                      <Select
                        labelId="name-label"
                        id="name-select"
                        value={penName}
                        label="Name"
                        onChange={(e) => setPenName(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {uniquePenName.map((pn, index) => (
                          <MenuItem
                            className="common_font"
                            id={`name-menu-item-${index}`}
                            key={index}
                            value={pn}
                          >
                            {pn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="customer_details_add_items_div">
                    <Button
                      variant="contained"
                      onClick={reset}
                      id="reset"
                      sx={{
                        background: "#f1f1f2 !important",
                        color: "#000000 !important",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "8px 20px",
                      }}
                      className="common_font common-button"
                    >
                      {selectItm.clearFilter}
                    </Button>
                    {brands != "" && type != "" && penName != "" ? (
                      <Button
                        variant="contained"
                        onClick={addItem}
                        id="add"
                        style={{
                          // backgroundColor: "#4caf50",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          padding: "8px 20px",
                        }}
                        className="common_font common-button"
                      >
                        {/* Continue */}
                        {selectItm.button}
                        {">"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled
                        id="add"
                        style={{
                          // backgroundColor: "#4caf50",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          padding: "8px 20px",
                        }}
                        className="common_font common-button"
                      >
                        {/* Continue */}
                        {selectItm.button}
                        {">"}
                      </Button>
                    )}
                  </div>
                </Box>
              ) : ModalValue === "EditCustomerName" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      {/* Edit Customer Name */}
                      {editCustomerName.heading}
                    </h3>

                    <form onSubmit={updateUserName}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="first-name"
                            >
                              First Name
                            </InputLabel>
                            <OutlinedInput
                              id="First Name"
                              label="First Name"
                              name="First Name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="last-name"
                            >
                              Last Name
                            </InputLabel>
                            <OutlinedInput
                              id="Last Name"
                              label="Last Name"
                              name="Last Name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="common_font common-button"
                            type="submit"
                          >
                            {/* SUBMIT */}
                            {editCustomerName.button}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              ) : ModalValue === "EditCustomerAddress" ? (
                <Box className=" ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      {/* Edit Customer Address */}
                      {editCustomerAddress.heading}
                    </h3>

                    <form onSubmit={handleEditAddressSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="street address"
                            >
                              Street Address
                            </InputLabel>
                            <OutlinedInput
                              id="street address"
                              label="street address"
                              name="street address"
                              value={streetAddress}
                              onChange={(e) => setstreetAddress(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="city">
                              City
                            </InputLabel>
                            <OutlinedInput
                              id="city"
                              label="city"
                              name="city"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="state">
                              State
                            </InputLabel>
                            <OutlinedInput
                              id="state"
                              label="state"
                              name="state"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="country"
                            >
                              Country
                            </InputLabel>
                            <OutlinedInput
                              id="country"
                              label="country"
                              name="country"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="zip">
                              Zip Code
                            </InputLabel>
                            <OutlinedInput
                              id="zip"
                              label="Zip Code"
                              name="zip"
                              value={zip}
                              onChange={(e) => setZip(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <span className="common_font">
                              <Checkbox />
                              {card1.billAddress}
                              {/* Billing Address */}
                            </span>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item spacing={2} className="address_submit_button">
                        <Button
                          className="common_font common-button"
                          variant="contained"
                          type="submit"
                        >
                          {/* SUBMIT */}
                          {editCustomerAddress.button}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleOpen("Add_Address")}
                          type="button"
                          className="add_Addess common_font common-button"
                        >
                          {/* ADD ADDRESS */}
                          {editCustomerAddress.button2}
                        </Button>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              ) : ModalValue === "EditCustomerPhone" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PhoneIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    {editCustomerPhone.heading}
                  </h3>
                  <br />
                  <form onSubmit={handlePhoneSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="phone">
                            Phone Number
                          </InputLabel>
                          <OutlinedInput
                            id="phone"
                            label="Phone Number"
                            name="phone"
                            value={formatPhoneNumber(phone)}
                            onChange={handlePhoneChange}
                            error={!isValidPhone}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                          {!isValidPhone && (
                            <FormHelperText error>
                              Please enter a valid 10-digit phone number.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          className="common_font common-button"
                          variant="contained"
                          type="submit"
                        >
                          {/* SUBMIT */}
                          {editCustomerPhone.button}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              ) : ModalValue === "Add_Address" ? (
                <Box className=" ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      className="common_font"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Customer Address
                    </h3>
                    <form onSubmit={handleAddNewAddressSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="street address"
                            >
                              Street Address
                            </InputLabel>
                            <OutlinedInput
                              id="street address"
                              label="street address"
                              name="street address"
                              value={newstreetAdd}
                              onChange={(e) => setnewstreetAdd(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="city">
                              City
                            </InputLabel>
                            <OutlinedInput
                              id="city"
                              label="city"
                              name="city"
                              value={newcity}
                              onChange={(e) => setnewCity(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="country"
                            >
                              Country
                            </InputLabel>
                            <OutlinedInput
                              id="country"
                              label="country"
                              name="country"
                              value={newcountry}
                              onChange={(e) => setnewCountry(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="state">
                              State
                            </InputLabel>
                            <OutlinedInput
                              id="state"
                              label="state"
                              name="state"
                              value={newstate}
                              onChange={(e) => setnewState(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="zip">
                              Zip Code
                            </InputLabel>
                            <OutlinedInput
                              id="zip"
                              label="Zip Code"
                              name="zip"
                              value={newzip}
                              onChange={(e) => setnewZip(e.target.value)}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <span className="common_font">
                              <Checkbox />
                              Billing Address
                            </span>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="address_submit_button">
                        {newzip &&
                          newcity &&
                          newcountry &&
                          newstate &&
                          newstreetAdd ? (
                          <Button
                            variant="contained"
                            className="common-button common_font"
                            type="submit"
                          >
                            SUBMIT
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="common-button common_font"
                            sx={{ opacity: "40% !important" }}
                            type="submit"
                            disabled
                          >
                            SUBMIT
                          </Button>
                        )}
                      </Grid>
                    </form>
                  </Box>
                </Box>
              ) : ModalValue === "Change_Address" ? (
                <Box className=" ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                      className="common_font"
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      {/* Manage Address */}
                      {manageCustomerAddress.heading}
                    </h3>
                    <Grid container spacing={2}>
                      {customerAddresses.map((item, index) => {
                        console.log(item);
                        return (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            display={"flex"}
                            justifyContent={"space-between"}
                            width={500}
                          >
                            <Box className="manage_address">
                              {item ? item.country : ""},{" "}
                              {item ? item.city : ""}, {item ? item.state : ""},{" "}
                              {item ? item.street_address : ""},{" "}
                              {item ? item.zip_code : ""}{" "}
                            </Box>
                            <Box>
                              <CreateIcon
                                className="edit_icon"
                                onClick={() =>
                                  handleOpen("EditCustomerAddress", item)
                                }
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              ) : ModalValue === "Add-contact" ? (
                <Box className=" ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <AddIcCallIcon fontSize="small" />
                      <Typography
                        className="common_font"
                        sx={{ marginLeft: "10px" }}
                      >
                        Add New Phone Number
                      </Typography>
                    </h3>
                    <form onSubmit={handleNewContactSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              className="common_font"
                              htmlFor="Phone Number"
                            >
                              Phone Number
                            </InputLabel>
                            <OutlinedInput
                              id="Phone Number"
                              label="Phone Number"
                              name="Phone Number"
                              value={addNewPhone}
                              onChange={(e) =>
                                setaddNewPhonenumber(e.target.value)
                              }
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            className="common-button common_font"
                            variant="contained"
                            type="submit"
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              ) : ModalValue === "Add-email" ? (
                <Box className=" ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <AddIcCallIcon fontSize="small" />
                      <Typography
                        className="common_font"
                        sx={{ marginLeft: "10px" }}
                      >
                        {addCustomerEmail.heading}
                      </Typography>
                    </h3>
                    <form onSubmit={handleNewEmailSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel className="common_font" htmlFor="Email">
                              Email
                            </InputLabel>
                            <OutlinedInput
                              id="Email"
                              label="Email"
                              name="Email"
                              value={addNewEmail}
                              onChange={handleAddEmail}
                              error={!validateEmail}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                            {!isValidEmail && (
                              <FormHelperText error>
                                Please enter a valid email address.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            className="common-button common_font"
                            variant="contained"
                            type="submit"
                          >
                            {addCustomerEmail.button}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              ) : ModalValue === "EditCustomer" ? (
                <Box className="">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="common_font"
                  >
                    {searchCustomers}
                  </Typography>

                  <div style={{ marginTop: "20px" }}>
                    <TextField
                      label="Search"
                      id="customer-select"
                      fullWidth
                      value={searchQuery}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearchChange}
                      InputLabelProps={{
                        className: "common_font",
                      }}
                      inputProps={{
                        className: "common_font",
                      }}
                    />
                  </div>
                  <div
                    className="scrollable-container"
                    style={{
                      maxHeight: "530px",
                      marginBottom: "20px",
                    }}
                  >
                    {filteredCustomer.length > 0 ? (
                      filteredCustomer.map((cus, index) => {
                        const selectedperson =
                          localStorage.getItem("selectedPerson");
                        return (
                          <Card
                            className="customer-select-card"
                            id={`customer-select-card-${index}`}
                            onClick={() => getCustomerDetails(cus.person_id)}
                            sx={
                              selectedperson === cus.person_id
                                ? { backgroundColor: "#e5eaf2" }
                                : ""
                            }
                          >
                            <img
                              src={`https://ui-avatars.com/api/?color=ffff&name=${cus.first_name[0]}&&background=random`}
                              style={{
                                borderRadius: "50%",
                                width: "45px",
                                height: "45px",
                              }}
                              alt="avatar"
                            />
                            <div>
                              <Typography
                                sx={{
                                  fontSize: 20,
                                  marginLeft: "10px",
                                }}
                              />
                              <div>
                                <Typography
                                  sx={{
                                    fontSize: 20,
                                    marginLeft: "10px",
                                  }}
                                  color="text.primary"
                                  className="common_font"
                                >
                                  {cus.first_name} {cus.last_name}{" "}
                                  {cus.person_display_id ===
                                    customerList[0].person_display_id ? (
                                    <span className="default-badge common_font">
                                      Default
                                    </span>
                                  ) : null}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="common_font"
                                >
                                  &nbsp; &nbsp;{cus.occupation}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ fontWeight: "bold" }}
                                  className="common_font"
                                >
                                  &nbsp;({cus.person_display_id})
                                </Typography>
                              </div>
                            </div>
                          </Card>
                        );
                      })
                    ) : (
                      <Typography
                        variant="p"
                        color="text.secondary"
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                        className="common_font"
                      >
                        No Customer Found
                      </Typography>
                    )}
                  </div>
                </Box>
              ) : ModalValue === "EditEmail" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PhoneIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    {editCustomerEmail.heading}
                  </h3>
                  <br />
                  <form onSubmit={handleEmailSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="email">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            error={!isValidEmail}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                          {!isValidEmail && (
                            <FormHelperText error>
                              Please enter a valid email address.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          className="common_font common-button"
                          variant="contained"
                          type="submit"
                        >
                          {/* SUBMIT */}
                          {editCustomerPhone.button}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              ) : ModalValue === "ApplyDiscount" ? (
                <Box className="">
                  <center>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      className="common_font"
                    >
                      Discount
                    </Typography>
                  </center>

                  <div className="customer_details_add_items_div">
                    <FormControl
                      id="add_pen_brand_formControl"
                      className="customer_details_add_items_form_control"
                    >
                      <InputLabel className="common_font" id="brands-label">
                        Discount Percentage
                      </InputLabel>
                      <Select
                        labelId="discount-label"
                        id="discount-select"
                        value={discount}
                        label="Discount Percentage"
                        onChange={(e) => setDiscount(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      >
                        {discount_percentages.map((item, index) => (
                          <MenuItem
                            className="common_font"
                            id={`discount-menu-item-${index}`}
                            key={index}
                            value={item.discount_percentage}
                          >
                            {item.discount_percentage === "5.00"
                              ? "Five"
                              : item.discount_percentage === "10.00"
                                ? "Ten"
                                : item.discount_percentage === "15.00"
                                  ? "Fifteen"
                                  : item.discount_percentage === "20.00"
                                    ? "Twenty"
                                    : item.discount_percentage}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <Typography
                    id="modal-modal-title"
                    variant="p"
                    className="common_font"
                    sx={{ color: "blue" }}
                  >
                    Do you have any Coupon Code?
                  </Typography>
                  <div className="customer_details_add_items_div">
                    {coupon_list.map((item, index) => {
                      const currentDate = new Date();
                      const startDate = new Date(item.start_date);
                      const endDate = new Date(item.end_date);

                      const isWithinDateRange =
                        startDate <= currentDate && currentDate <= endDate;

                      return (
                        <>
                          <Box
                            key={item.id}
                            disabled={!isWithinDateRange}
                            className={`coupon-box ${!isWithinDateRange
                              ? "coupon-background"
                              : item.coupon_code === couponCode
                                ? "coupon-checkbox"
                                : ""
                              }`}
                          >
                            {!isWithinDateRange ? (
                              <Typography
                                align="center"
                                className="common_font"
                              >
                                {item.coupon_code}
                              </Typography>
                            ) : (
                              <Typography
                                align="center"
                                className="common_font"
                                onClick={() => CouponCode(item.coupon_code)}
                              >
                                {item.coupon_code}
                              </Typography>
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "700", color: "#eb0014" }}
                      className="common_font"
                    >
                      {couponAlert ? couponAlert : null}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      disabled={!couponCode && !discount}
                      onClick={() =>
                        applyDiscount(total, discount, selected_coupon_code)
                      }
                      id="apply_discount"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "8px 20px",
                      }}
                      className="common_font common-button"
                    >
                      {/* Continue */}
                      {selectItm.button}
                      {">"}
                    </Button>
                  </div>
                </Box>
              ) : null}
            </div>
          </Box>
        </>
      </Modal>
      <Modal
        open={quotationModal}
        onClose={handleQuotationModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // hideBackdrop={true}
      >
        <>
          <Box className="customer_details_quotation_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setQuotationModal(false);
              }}
            />
            <div className="modal-sub-div">
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
                className="common_font"
              >
                {quotations}
              </Typography>

              <Box sx={{ marginBottom: "30px" }}>
                <Box className="customer-details-quotation-form-control-box">
                  <FormControl
                    id="created-by-name-form-control"
                    className="customer-details-quotations-form-control"
                  >
                    <InputLabel className="common_font" id="brands-label">
                      Created By
                    </InputLabel>
                    <Select
                      labelId="brands-label"
                      id="brands-select"
                      label="Created By"
                      value={quotationValue}
                      onChange={(e) => {
                        filterByCreatedNameFun(e);
                      }}
                      InputLabelProps={{
                        className: "common_font",
                      }}
                      inputProps={{
                        className: "common_font",
                      }}
                    >
                      {createdByNameList.map((cbnl, index) => (
                        <MenuItem
                          sx={{ marginTop: "10px" }}
                          id={`brands-menu-item-${index}`}
                          key={index}
                          value={cbnl}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <Typography className="common_font">
                            {cbnl}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ marginLeft: "50px" }}
                    id="created-status-form-control"
                    className="customer-details-quotations-form-control"
                  >
                    <InputLabel className="common_font" id="brands-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="brands-label"
                      id="brands-select"
                      label="Status"
                      value={quotationStatusValue}
                      onChange={(e) => filterByStatusFun(e)}
                      InputLabelProps={{
                        className: "common_font",
                      }}
                      inputProps={{
                        className: "common_font",
                      }}
                    >
                      {statusList.map((sl, index) => (
                        <MenuItem
                          sx={{ marginTop: "10px" }}
                          id={`brands-menu-item-${index}`}
                          key={index}
                          value={sl}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          <Typography className="common_font">{sl}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <TableContainer className="table-container">
                <Table size="small" aria-label="a dense table">
                  <TableHead className="table-head">
                    <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Quotation ID
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Created By
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Create
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Status
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Total Item
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        Amount
                      </TableCell>
                      <TableCell className="common_font log_table_table_head head-table-cell">
                        View
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="">
                    {currentItems &&
                      currentItems.map((gpl, index) => {
                        console.log(gpl);
                        return (
                          <TableRow key={index} className="table-row-even">
                            <TableCell className="body-table-cell common_font">
                              <Chip
                                label={gpl.quotation_display_id}
                                style={{ fontWeight: "700" }}
                                className="common_font"
                              />
                            </TableCell>
                            <TableCell className="body-table-cell common_font">
                              {gpl.created_by_name}
                            </TableCell>
                            <TableCell className="body-table-cell common_font">
                              {formatclient(gpl.quotation_date)}
                            </TableCell>
                            <TableCell className="body-table-cell common_font">
                              <Chip
                                label={gpl.quotation_status}
                                style={{
                                  background: "#fff1d6",
                                  color: "#c88c32",
                                  fontWeight: "700",
                                }}
                                className="common_font"
                              />
                            </TableCell>
                            <TableCell className="body-table-cell common_font">
                              {gpl.total_item}
                            </TableCell>
                            <TableCell className="body-table-cell common_font">
                              ${gpl.total_amount}
                            </TableCell>
                            <TableCell className="common_font">
                              <VisibilityIcon
                                onClick={() => {
                                  navigate(
                                    `/en/quotes/${gpl.quotation_display_id}`,
                                    { state: gpl.quotation_id }
                                  );
                                }}
                                className="all_box_hover"
                                id="quotation-list-eye-icon"
                              // sx={{ color: "#38a5ff" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box className="pagination-box">
                <Pagination
                  className="pagination"
                  count={Math.ceil(quotationList.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  siblingCount={1}
                  boundaryCount={1}
                />
              </Box>
            </div>
          </Box>
        </>
      </Modal>

      {/* <NotificationComponent data={{Message : updateAlertMsg, event : updateAlert, severity : snackseverity }} /> */}

      <Card id="landing_card" className="landing_card common_card_bs">
        <CardContent className="customer_details_card">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 className="cus_detail_header common_font">
                    {/* Customer Details */}
                    {heading}
                  </h2>
                  {location.state?.status === "editQuotation" ? null : (
                    <Box>
                      <PersonSearchIcon
                        className="edit_icon"
                        style={{ fontSize: "30px" }}
                        id="SearchCustomer"
                        onClick={() => handleOpen("EditCustomer")}
                      />
                    </Box>
                  )}
                </div>
                <Box className="cus_name common_font">
                  <Box sx={{ marginBottom: "10px", display: "flex" }}>
                    {/* Customer ID */}
                    {subHeading}:{" "}
                    <b style={{ marginLeft: "10px" }}>
                      {customerDetails ? (
                        customerDetails.Customer_details[0].person_display_id
                      ) : (
                        <Skeleton width={100} height={30} />
                      )}
                    </b>
                  </Box>
                </Box>
                <Box className="cus_name common_font">
                  <Box display="flex" alignItems="center">
                    <span>
                      <AccountCircleIcon fontSize="large" />
                    </span>
                    <span>
                      {customerDetails ? (
                        <b style={{ marginLeft: "10px" }}>
                          {customerDetails
                            ? customerDetails.Customer_details[0].first_name
                            : "NA"}{" "}
                          {customerDetails
                            ? customerDetails.Customer_details[0].last_name
                            : "NA"}
                        </b>
                      ) : (
                        <Skeleton width={100} height={30} />
                      )}
                    </span>
                  </Box>
                  <Box>
                    <CreateIcon
                      className="edit_icon"
                      onClick={() => handleOpen("EditCustomerName")}
                    />
                  </Box>
                </Box>
                <span className="cus_email common_font">
                  {customerDetails
                    ? customerDetails.Customer_details[0].occupation
                    : ""}
                </span>
                <Box className="cus_address common_font">
                  <Box className="cus_address_sub">
                    <Box display="flex" alignItems="center">
                      <span className="customer_details_business_icon">
                        <BusinessIcon fontSize="large" />
                      </span>
                      <Box
                        className="customer_details_address_box"
                        sx={{ marginLeft: "10px" }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {/* Address */}
                          {card1.address}
                        </span>{" "}
                        :{" "}
                        {customerDetails ? (
                          <span className="customer_details_full_address">
                            {customerDetails
                              ? customerDetails.Customer_address[0]
                                .street_address
                              : ""}
                            ,{" "}
                            {customerDetails
                              ? customerDetails.Customer_address[0].city
                              : ""}
                            ,{" "}
                            {customerDetails
                              ? customerDetails.Customer_address[0].state
                              : ""}
                            ,{" "}
                            {customerDetails
                              ? customerDetails.Customer_address[0].country
                              : ""}
                            ,{" "}
                            {customerDetails
                              ? customerDetails.Customer_address[0].zip_code
                              : ""}
                          </span>
                        ) : (
                          <Skeleton width={100} height={30} />
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ marginLeft: "45px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        {/* Billing Address */}
                        {card1.billAddress}
                      </span>{" "}
                      :{" "}
                      {customerDetails ? (
                        <span className="customer_details_full_address">
                          {customerDetails
                            ? customerDetails.Customer_address[0].street_address
                            : ""}
                          ,{" "}
                          {customerDetails
                            ? customerDetails.Customer_address[0].city
                            : ""}
                          ,{" "}
                          {customerDetails
                            ? customerDetails.Customer_address[0].state
                            : ""}
                          ,{" "}
                          {customerDetails
                            ? customerDetails.Customer_address[0].country
                            : ""}
                          ,{" "}
                          {customerDetails
                            ? customerDetails.Customer_address[0].zip_code
                            : ""}
                        </span>
                      ) : (
                        <Skeleton width={100} height={30} />
                      )}
                    </Box>
                    <Box sx={{ marginLeft: "38px", marginTop: "10px" }}>
                      <Button
                        className="common_font"
                        onClick={() => handleOpen("Change_Address")}
                      >
                        {/* Manage Address */}
                        {card1.mngAddress}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <CreateIcon
                      className="edit_icon"
                      onClick={() =>
                        handleOpen("EditCustomerAddress", firstAddress)
                      }
                    />
                  </Box>
                </Box>{" "}
                <Box>
                  <Box
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ marginTop: "10px" }}
                    >
                      <span style={{ marginLeft: "10px" }}>
                        <EmailIcon fontSize="large" />
                      </span>
                      <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                        {card1.email} :{" "}
                      </span>
                      <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                        {Email.length > 0 ? (
                          <>
                            {Email.slice(0, 1).map((filteredItem) => {
                              console.log(filteredItem);
                              return (
                                <Typography
                                  className="common_font"
                                  key={filteredItem.id}
                                >
                                  {filteredItem.contact_details}
                                </Typography>
                              );
                            })}
                          </>
                        ) : (
                          <Typography>NA</Typography>
                        )}
                      </span>
                    </Box>
                    <Box>
                      {Email.length > 0 ? (
                        <>
                          {Email.slice(0, 1).map((item) => {
                            console.log(item);

                            return (
                              <>
                                <Box>
                                  <Box>
                                    <CreateIcon
                                      className="edit_icon"
                                      onClick={() =>
                                        handleOpen("EditEmail", item)
                                      }
                                    />
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <Box>
                          <Box>
                            <ContactMailOutlinedIcon
                              className="edit_icon"
                              onClick={() => handleOpen("Add-email")}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    style={{
                      marginLeft: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ marginTop: "10px" }}
                    >
                      <span style={{ marginLeft: "10px" }}>
                        <PhoneIcon fontSize="large" />
                      </span>
                      <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                        {card1.phnAddress} :{" "}
                      </span>
                      <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                        {phoneNumber.length > 0 ? (
                          <>
                            {phoneNumber.slice(0, 1).map((item) => {
                              const usPhoneNumberFormate = formatUsPhone(
                                item.contact_details
                              );
                              return (
                                <Typography className="common_font">
                                  {usPhoneNumberFormate}
                                </Typography>
                              );
                            })}
                          </>
                        ) : (
                          <Typography>NA</Typography>
                        )}
                      </span>
                    </Box>
                    <Box>
                      {phoneNumber.length > 0 ? (
                        <>
                          {phoneNumber.slice(0, 1).map((item) => {
                            console.log(item);

                            return (
                              <>
                                <Box>
                                  <Box>
                                    <CreateIcon
                                      className="edit_icon"
                                      onClick={() =>
                                        handleOpen("EditCustomerPhone", item)
                                      }
                                    />
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <Box>
                          <Box>
                            <AddIcCallIcon
                              className="edit_icon"
                              onClick={() => handleOpen("Add-contact")}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <div
                  style={{
                    marginLeft: "25px",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    class="fa-solid fa-file-invoice-dollar"
                    style={{
                      fontSize: "35px",
                    }}
                  ></i>
                  <Chip
                    onClick={() => {
                      setQuotationModal(true);
                    }}
                    label={`Quotations : ${quotationCount}`}
                    sx={{
                      fontWeight: "bold",
                      color: "rgb(17, 141, 87)",
                      background: "rgba(34, 197, 94, 0.16)",
                      marginLeft: "12px",
                    }}
                  />
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <CardContent>
                  <PlusCodeMap
                    AddressDetails={{
                      PlusCode: plusCode,
                      CompleteAddress: completeAddress,
                    }}
                    style={{ fontFamily: "Nunito !important" }}
                  />
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card
        id="landing_card"
        className="landing_card common_card_bs customer_details_add_item_card"
      >
        <CardContent className="">
          <h3 className="Orders common_font customer_details_card_item_header">
            {/* Create Quotation */}
            {card1.createQuotation}
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "15px",
            }}
          >
            <p className="Orders common_font customer_details_card_item_header">
              {card2.heading}
            </p>
            {isAccordionOpen ? (
              <span style={{ marginTop: "3px" }}>
                <ArrowDropUpIcon
                  id="arrow-drop-up-icon"
                  onClick={handleAccordionToggle}
                />
              </span>
            ) : (
              <span style={{ marginTop: "3px" }}>
                <ArrowDropDownIcon
                  id="arrow-drop-down-icon"
                  onClick={handleAccordionToggle}
                />
              </span>
            )}
          </div>
          {isAccordionOpen && (
            <>
              <TableContainer
                sx={{
                  marginLeft: "20px",
                  marginTop: "10px",
                  width: "98% !important",
                }}
              >
                <Table
                  className="table-dimension-cd-windowsets"
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <TableHead>
                    <Card
                      tabIndex={-1}
                      className="pen_details_document_card_head common_header_bg"
                    >
                      <CardContent className="pen_details_document_card_content">
                        <TableRow
                          className="pen_details_rowhover"
                          tabIndex={-1}
                        >
                          <TableCell
                            className="item_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="left"
                          >
                            SKU
                          </TableCell>
                          <TableCell
                            className="desc-item_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="left"
                          >
                            Item (Item Description)
                          </TableCell>
                          <TableCell
                            className="item_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="right"
                          >
                            Price Per Item (USD)
                          </TableCell>
                          <TableCell
                            className="item_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="left"
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            className="item_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="right"
                          >
                            Total (USD)
                          </TableCell>
                          <TableCell
                            className="action_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                            align="left"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </CardContent>
                    </Card>
                  </TableHead>

                  <TableBody>
                    {Orders.map((item, index) => {
                      console.log(item.quantity, itemquantity[index]);
                      var colors = item.colours.split(",");
                      const product_pricing_usd = Number(
                        item.product_pricing_usd
                      );
                      var unit_price;
                      if (item.product_stock_quantity == "0") {
                        unit_price = 0;
                      } else {
                        var quantity = itemquantity[index]
                          ? itemquantity[index]
                          : item.quantity || 1;
                        unit_price = +item.product_pricing_usd * +quantity || 0;
                      }
                      total = Number(+total + unit_price).toFixed(2);

                      return (
                        <Card
                          tabIndex={-1}
                          className="pen_details_document_card"
                        >
                          <CardContent className="pen_details_document_card_content">
                            <TableRow
                              className="rowhover"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell
                                className="item-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="left"
                              >
                                {/* {location.state?.status === "editQuotation" &&
                                index < location.state?.productList?.length ? (
                                  <Badge badgeContent="new" color="primary">
                                    <Chip
                                      className="common_font"
                                      label={item.sku}
                                    />
                                  </Badge>
                                ) : ( */}
                                <Chip
                                  className="common_font"
                                  label={item.sku}
                                />
                                {/* )} */}
                              </TableCell>
                              <TableCell
                                className="desc-item-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="left"
                              >
                                <b style={{ color: "green" }}>
                                  <Tooltip
                                    className="common_font"
                                    title="Item Name"
                                  >
                                    {item.pen_name}
                                  </Tooltip>
                                </b>
                                <br />
                                <Tooltip title="Brand">
                                  <Chip
                                    className="common_font"
                                    label={item.brand_name}
                                    style={{
                                      background: "#e8def8",
                                      fontWeight: "bold",
                                    }}
                                  />
                                </Tooltip>
                                <br />
                                <Tooltip title="Type">
                                  <span
                                    className="common_font"
                                    style={{ color: "#5C9472 " }}
                                  >
                                    {" "}
                                    {item.pen_type}{" "}
                                  </span>
                                </Tooltip>
                                <br />
                                <b
                                  className="common_font"
                                  style={{ color: "#7EB3E2" }}
                                >
                                  Available Colors:
                                </b>{" "}
                                <span>
                                  {colors.map((color, index) => (
                                    <Tooltip title={color}>
                                      <Chip
                                        size="small"
                                        key={index}
                                        className="colors common_font"
                                        style={{
                                          background: `${color}`,
                                        }}
                                      />
                                    </Tooltip>
                                  ))}
                                </span>
                              </TableCell>
                              <TableCell
                                className="item-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="right"
                              >
                                $ {product_pricing_usd.toFixed(2)}
                              </TableCell>
                              <TableCell
                                className="item-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="left"
                              >
                                <Typography
                                  className="common_font"
                                  variant="caption"
                                  sx={{ fontWeight: "700", color: "#4caf50" }}
                                >
                                  Available Stock :{" "}
                                  <span className="common_font">
                                    {item.product_stock_quantity}
                                  </span>
                                </Typography>
                                <TextField
                                  className="common_font"
                                  id={`itemquantity-${index}`}
                                  size="small"
                                  sx={{ width: "60%" }}
                                  defaultValue={
                                    item.quantity ? item.quantity : 1
                                  }
                                  value={itemquantity[index]}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      index,
                                      e.target.value,
                                      item.product_id,
                                      item.product_pricing_usd,
                                      item.product_stock_quantity
                                    )
                                  }
                                  InputLabelProps={{
                                    className: "common_font",
                                  }}
                                  inputProps={{
                                    className: "common_font",
                                  }}
                                  disabled={item.product_stock_quantity == 0}
                                />
                                <br />
                                <Typography
                                  variant="caption"
                                  sx={{ fontWeight: "700", color: "#eb0014" }}
                                  className="common_font"
                                >
                                  {item.product_stock_quantity == 0
                                    ? "This item is out of stock!!"
                                    : indexcheck === index
                                      ? stockmsg
                                      : ""}
                                </Typography>
                              </TableCell>

                              <TableCell
                                className="item-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="right"
                              >
                                $ {unit_price.toFixed(2)}
                              </TableCell>
                              <TableCell
                                className="action-table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                align="left"
                              >
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => handleDeleteItem(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </CardContent>
                        </Card>
                      );
                    })}
                    {Orders.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          padding: "5px",
                        }}
                      ></div>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              {sales === false ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="common_font common-button"
                    id={`add-items-${addItemId}`}
                    variant="contained"
                    onClick={() => {
                      handleOpen("AddItems");
                      addItemIdInc();
                    }}
                    style={{
                      marginLeft: "20px",
                      marginTop: "20px",
                      backgroundColor: "#4caf50",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    + {card1.additm}
                  </Button>
                  <div>
      </div>
                   <Button
                    className="common_font common-button"
                    id={`add-items-${addItemId}`}
                    variant="contained"
                    onClick={() => {
                      handleScanClick()
                      addItemIdInc();
                      
                    }}
                    style={{
                      marginLeft: "20px",
                      marginTop: "20px",
                      backgroundColor: "#4caf50",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    + Scan QR to Add Item
                  </Button> 
                  {/* <p>Scanned Result : {scannerKey} {data}</p> */}
                  <>
                  {/* {showScanner?'true':'false'} */}
                     {/* {showScanner===true? */}
                      
                        <QrReader
                         constraints={{
                          facingMode: 'user' // Specify to use the back camera
                        }}
                          key={scannerKey}
                          delay={300} // Optional delay
                          onResult={handleResult}
                          style={{ width: '100%' }}
                        />
                     
                     {/* } */}
                  </>
                  {Orders.length > 0 ? (
                    discount_percentages.length > 0 ? (
                      <Button
                        className="common_font apply-dis-button common-button"
                        id={`add-items-${addItemId}`}
                        variant="contained"
                        onClick={() => {
                          handleOpen("ApplyDiscount");
                          addItemIdInc();
                        }}
                        style={{
                          marginRight: "25px",
                          marginTop: "20px",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                      >
                        <img
                          src={offer2}
                          alt="apply discount"
                          width={25}
                          height={25}
                          style={{ marginRight: "5px" }}
                        />
                        Search Discount
                      </Button>
                    ) : (
                      <Button
                        className="common_font disabled-apply-dis-button common-button"
                        id={`add-items-${addItemId}`}
                        variant="contained"
                        disabled
                        style={{
                          marginRight: "25px",
                          marginTop: "20px",
                          fontWeight: "bold",
                          borderRadius: "8px",
                        }}
                      >
                        <img
                          src={disoffer}
                          alt="apply discount"
                          width={25}
                          height={25}
                          style={{ marginRight: "5px" }}
                        />
                        Search Discount
                      </Button>
                    )
                  ) : null}
                </div>
              ) : null}
            </>
          )}
        </CardContent>
      </Card>

      {isAccordionOpen && Orders.length > 0 ? (
        <Card
          id="landing_card"
          className="landing_card common_card_bs customer_details_add_item_card"
        >
          <CardContent sx={{ marginLeft: "20px", marginRight: "60px" }}>
            <h3
              style={{ paddingLeft: "10px !important" }}
              className="Orders common_font customer_details_card_item_header"
            >
              {card1.totals}
            </h3>
            <Box sx={{ marginLeft: "20px", marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardContent className="pen_details_document_card_content">
                  Sub Total:
                </CardContent>
                <CardContent
                  className="pen_details_document_card_content"
                  sx={{ marginRight: "20px" }}
                >
                  ${" "}
                  <span style={{ width: "50px", marginLeft: "10px" }}>
                    {total ? total : "00.00"}
                  </span>
                </CardContent>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardContent className="pen_details_document_card_content">
                  Taxes:
                </CardContent>
                <CardContent
                  className="pen_details_document_card_content"
                  sx={{ marginRight: "20px" }}
                >
                  ${" "}
                  <span style={{ width: "50px", marginLeft: "10px" }}>
                    {" "}
                    {"00.00"}
                  </span>
                </CardContent>
              </div>
              {selectedDiscount || location.state?.status == "editQuotation" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent className="pen_details_document_card_content">
                    Discount (
                    {ModalValue === "ApplyDiscount" && open === true
                      ? selectedDiscount
                      : discount || selected_coupon_code
                        ? +discount.slice(0, -3) +
                        +selected_coupon_code.slice(0, -3)
                        : discount}
                    %):
                  </CardContent>
                  <CardContent
                    className="pen_details_document_card_content"
                    sx={{ marginRight: "20px" }}
                  >
                    - ${" "}
                    <span style={{ width: "50px", marginLeft: "10px" }}>
                      {" "}
                      {ModalValue === "ApplyDiscount" && open === true
                        ? discountAmt
                        : Number(
                          total * (+selected_coupon_code / 100) +
                          total * (+discount / 100)
                        ).toFixed(2)}
                    </span>
                  </CardContent>
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardContent className="pen_details_document_card_content">
                  <b
                    style={{
                      fontSize: "18px",
                      textDecorationLine: "underline",
                      textDecorationStyle: "double",
                    }}
                  >
                    Grand Total:{" "}
                  </b>
                </CardContent>
                <CardContent
                  className="pen_details_document_card_content"
                  sx={{ marginRight: "20px" }}
                >
                  <b
                    style={{
                      fontSize: "18px",
                      textDecorationLine: "underline",
                      textDecorationStyle: "double",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    $
                    <Typography
                      className="common_font"
                      sx={{ width: "50px !important", marginLeft: "9px" }}
                    >
                      {" "}
                      {ModalValue === "ApplyDiscount" &&
                        open === true &&
                        grand_total === "00.00"
                        ? total
                        : open === false
                          ? Number(
                            total -
                            (total * (+selected_coupon_code / 100) +
                              total * (+discount / 100))
                          ).toFixed(2)
                          : grand_total}
                    </Typography>
                  </b>
                </CardContent>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginRight: "-40px",
                }}
              >
                {/* {sales === true ? (
                  <Button
                    className="common-button common_font"
                    id="sales"
                    variant="contained"
                    onClick={salesDetails}
                    style={{
                      marginTop: "20px",
                      // backgroundColor: "#4caf50",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    Sales
                  </Button>
                ) : showquanmsg === true ? (
                  <Button
                    id="generate_quote"
                    variant="contained"
                    disabled
                    className="common-button common_font"
                    style={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    {card1.generateQuote}
                  </Button>
                ) : ( */}
                {location.state?.status === "editQuotation" ? (
                  <Button
                    id="generate_quote"
                    variant="contained"
                    className="common_font common-button"
                    onClick={() =>
                      updateQuote(
                        customerDetails,
                        total,
                        Number(
                          total * (+selected_coupon_code / 100) +
                          total * (+discount / 100)
                        ).toFixed(2),
                        Number(
                          total -
                          (total * (+selected_coupon_code / 100) +
                            total * (+discount / 100))
                        ).toFixed(2)
                      )
                    }
                    sx={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      borderRadius: "8px",
                      marginRight: "20px",
                    }}
                  >
                    Update Quote
                  </Button>
                ) : (
                  <Button
                    id="generate_quote"
                    variant="contained"
                    className="common_font common-button"
                    onClick={() =>
                      generateQuote(
                        customerDetails,
                        total,
                        Number(
                          total * (+selected_coupon_code / 100) +
                          total * (+discount / 100)
                        ).toFixed(2),
                        Number(
                          total -
                          (total * (+selected_coupon_code / 100) +
                            total * (+discount / 100))
                        ).toFixed(2)
                      )
                    }
                    style={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      borderRadius: "8px",
                    }}
                  >
                    {card1.generateQuote}
                  </Button>
                )}
                {/* )} */}
              </div>
            </Box>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export default CustomerDetails;

// Author: sonal


import { combineReducers } from "redux";
import { cardReducerFun, notificationReducerFun, getUserProfileImageRedux } from "./CartReducers";

export default combineReducers({
    cardReducer: cardReducerFun,
    notificationReducer: notificationReducerFun,
    getUserProfileRedux: getUserProfileImageRedux

})
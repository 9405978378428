/** @format */

import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import TuneIcon from '@mui/icons-material/Tune';
import List from "@mui/material/List";
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  Pagination,
  Highlight,
} from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export const FacetDrawer = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleDrawerOpen}>
        <TuneIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        PaperProps={{ style: { width: "350px" } }} // Set the width of the drawer
      >
        {/* Drawer content here */}
        <h3>Brand</h3>
        <RefinementList attribute="brand_name" showMore={true} />
        <h3>Pen Type</h3>
        <RefinementList attribute="pen_type" showMore={true} />
        <h3>Material Type</h3>
        <RefinementList attribute="material_type" showMore={true} />
      </Drawer>
    </div>
  );
};

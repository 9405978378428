// Author: sonal

import i18n, { init } from 'i18next'
import languagedetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import global_en from '../src/translations/en/translation.json'
import global_hi from '../src/translations/hi/translation.json'


i18n.use(languagedetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
        debug: true,
        lng: "en",
        fallbackLng: "en",
        returnObjects: true,
        resources: {
            en: {
                translation: global_en
            },
            hi: {
                translation: global_hi
            }
        }
    })
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination, Typography, PaginationItem } from "@mui/material";
import Box from "@mui/material/Box";
import { Button, Modal, Backdrop, Fade } from "@mui/material";
import "../../Styles/customers.css";
import { useNavigate } from "react-router-dom";
import uploadProfileImage from "../../assets/img/upload_profile.png";
import Axios from "axios";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  CUSTOMER_LIST,
  CUSTOMER_DETAILS,
  ADD_NEW_CUSTOMER,
  UPLOAD_CUSTOMER_PROFILE,
} from "../../config/config.js";

import TextField from "@mui/material/TextField";
import male_icon from "../../assets/img/male_icon.png";
import femalee_icon from "../../assets/img/female_icon.png";
import PlusCodeMap from "../dashboard/PlusCodeMap.jsx";
import PersonIcon from "@mui/icons-material/Person";
import dobCal from "../../assets/img/dobCal.png";
import updateCal from "../../assets/img/updateCal.png";
import createCal from "../../assets/img/createCal.png";
import status from "../../assets/img/status.png";
import usercus from "../../assets/img/usercus.png";
import userLCus from "../../assets/img/userLCus.png";
import maleCus from "../../assets/img/maleCus.png";
import femaleCus from "../../assets/img/femaleCus.png";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SelectLabel from "../Layout/selectlabel";
import {
  Grid,
  InputLabel,
  OutlinedInput,
  FormControl,
  MenuItem,
  Select,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../../Styles/error.css";
import { useTranslation } from "react-i18next";

const moment = require("moment");

const formatclient = (serverTimestamp) => {
  return moment(serverTimestamp).format("DD MMM YYYY");
};

const formatDate = (dateString) => {
  const clientDate = new Date(dateString);

  const usDateformat = formatclient(clientDate);

  // console.log(usDateformat);

  const age = calculateAge(clientDate);
  const ageString = ` (${age} Y)`;

  return usDateformat + ageString;
};

const calculateAge = (birthDate) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

function convertFirstLetterCaps(str) {
  if (!str) {
    return str; // Handle empty string or null input
  }

  const firstLetter = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1).toLowerCase();

  return firstLetter + restOfString;
}

export default function UserList() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const { i18n, t } = useTranslation();
  const { heading, button } = t("manage-customer");
  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);
  const [textContent, settextContent] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [duplicateCustomerList, setDuplicateCustomerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [plusCode, setPlusCode] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerDOB, setCustomerDOB] = useState("");
  const [customerCreatedDate, setCustomerCreatedDate] = useState("");
  const [customerUpdatedDate, setCustomerUpdatedDate] = useState("");
  const [modalStatus, setModalStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfBirthValue, setDateOfBirthValue] = useState(null);
  console.log(dateOfBirth);
  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [maritalStatus, setmaritalStatus] = useState("");
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [userImage, setuserImage] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [handleUploadAlert, sethandleUploadAlert] = useState(false);
  const [uploadImageMsg, setuploadImageMsg] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const handleChildData = (data) => {
    setItemsPerPage(data);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredItems = customerList.filter(
    (customer) =>
      customer.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.gender.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.marital_status
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      customer.occupation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  console.log(filteredItems)

  const handleCustomerSearch = (e) => {
    settextContent(e.target.value);
    console.log(e);
    if (e.target.value === "") {
      // getCustomerListFun();
      setCustomerList(duplicateCustomerList)
    } else {

      const filteredProducts = customerList.filter((user) =>
        user.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        user.last_name.toLowerCase().includes(e.target.value.toLowerCase())
      )

      console.log(filteredProducts)
      setCustomerList(filteredProducts)
    }
  }

  const handleSpace = (e) => {
    console.log(e.keyCode)
    if (e.keyCode === 32) {
      settextContent("");
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset page to 1 when the search query changes
  };

  const handleDateOfBirthChange = (newvalue) => {
    console.log(newvalue.$d);
    const selectedDate = new Date(newvalue.$d);
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    const dateString = newvalue.$d.toISOString();
    setDateOfBirth(formattedDate);
  };

  const getCustomerListFun = async () => {
    try {
      const response = await fetch(CUSTOMER_LIST, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: token,
        },
      });

      const data = await response.json();
      console.log(data);

      if (data.success === true) {
        console.log(data);
        const getNewArray = data.data.Customer_list;
        let newArrayWithIndex = getNewArray.map((obj, index) => ({
          ...obj,
          index: index + 1,
        }));
        console.log(newArrayWithIndex);
        setDuplicateCustomerList(newArrayWithIndex)
        setCustomerList(newArrayWithIndex);
        // setCustomerList(data.data.Customer_list);
      }
    } catch (error) {
      console.error("Error fetching customer list:", error);
    }
  };
  const getCustomerDetails = async (person_id) => {
    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: person_id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("*********************",data.data);
        if (data.success === true) {
          setPlusCode(data.data.Customer_address[0].plus_code);
          setCompleteAddress(
            data.data.Customer_address[0].street_address +
            " " +
            data.data.Customer_address[0].city +
            " " +
            data.data.Customer_address[0].state +
            " " +
            data.data.Customer_address[0].country +
            " " +
            data.data.Customer_address[0].zip_code
          );
        }
        setOpenModal(true);
      });
  };
  const handleAddressView = (rowData) => {
    console.log("Address View:", rowData.person_id);
    getCustomerDetails(rowData.person_id);
  };
  useEffect(() => {
    getCustomerListFun();
  }, []);

  const adduser = () => {
    setModalStatus("Add-New-Customer");
    setModalOpen(true);
  };

  const handleAddProfile = () => {
    setModalStatus("AddOrUpdateProfile");
  };
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleCreateCustomer = async (e) => {
    const Token = localStorage.getItem("token");
    e.preventDefault();
    const bodyOptions = {
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dateOfBirth,
      gender: convertFirstLetterCaps(gender),
      occupation: occupation,
      marital_status: maritalStatus,
      street_address: streetAddress,
      city: city,
      state: state,
      zip_code: zipCode,
      country: country,
    };
    console.log(bodyOptions);
    try {
      const response = await fetch(ADD_NEW_CUSTOMER, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: Token,
        },
        body: JSON.stringify(bodyOptions),
      });

      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        setUpdateAlert(true);
        setUpdateAlertMsg(data.message);
        setSnackSeverity("success");
        setModalOpen(false);
        setTimeout(() => {
          navigate(`/${lng}/manage-customer/customer-profile`, {
            state: {
              details: { person_id: data.data.created_person_id },
            },
          });
        }, [2000]);
      } else {
        setUpdateAlert(true);
        setUpdateAlertMsg(data.message);
        setSnackSeverity("error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  //Create New Customer Profile
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const files = e.dataTransfer.files;
    handleFile(files[0]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      setuserImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //Profile Image
  const uploadCustomerProfileImage = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("user_id", "");
    formData.append("file", userImage);

    Axios.post(UPLOAD_CUSTOMER_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        sethandleUploadAlert(true);
        setuploadImageMsg(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleuploadPhotoAlertClose = () => {
    sethandleUploadAlert(false);
  };

  return (
    <div>
      <Snackbar
        open={handleUploadAlert}
        onClose={handleuploadPhotoAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleuploadPhotoAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">{uploadImageMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity={snackseverity}
          sx={{ width: "100%" }}
        >
          {updateAlertMsg}
        </Alert>
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalStatus === "Edit" ? (
          <Box className="customer_card_modal">
            <h3 style={{ marginTop: "0px", marginBottom: "0px" }}>
              Update Customer Details
            </h3>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                className="customer_card_modal_textfield"
                id="outlined-basic"
                label="First Name"
                variant="outlined"
              />

              <TextField
                className="customer_card_modal_textfield"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
              />

              <TextField
                className="customer_card_modal_textfield"
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />

              <TextField
                className="customer_card_modal_textfield"
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
              />

              <input
                onChange={(e) => {
                  setCustomerDOB(e.target.value);
                }}
                value={customerDOB}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  border: "1px solid #C4C4C4",
                  marginTop: "20px",
                  paddingLeft: "10px",
                  color: "gray",
                  textTransform: "uppercase",
                }}
                type="date"
                label="DOB"
              />

              <TextField
                className="customer_card_modal_textfield"
                id="outlined-basic"
                label="Occupation"
                variant="outlined"
              />

              <Button
                sx={{
                  width: "200px",
                  margin: "20px auto",
                  backgroundColor: "#0098DA",
                }}
                variant="contained"
              >
                Update
              </Button>
            </div>
          </Box>
        ) : modalStatus === "Add-New-Customer" ? (
          <>
            <Box className="customer_card_modal scrollable-container">
              <h3
                style={{
                  marginTop: "0px",
                  marginBottom: "20px",
                }}
                id="add-new-customer-modal"
              >
                <i className="fa-solid fa-user-plus"></i>
                <span className="common_font" style={{ marginLeft: "10px" }}>
                  Add New Customer
                </span>
              </h3>

              <form onSubmit={handleCreateCustomer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="first-name">
                        First name
                      </InputLabel>
                      <OutlinedInput
                        id="First Name"
                        label="First Name"
                        name="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="last-name">
                        Last name
                      </InputLabel>
                      <OutlinedInput
                        id="Last Name"
                        label="Last Name"
                        name="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel
                        className="common_font"
                        htmlFor="streetAddress"
                      >
                        Street Address
                      </InputLabel>
                      <OutlinedInput
                        id="streetAddress"
                        label="streetAddress"
                        name="streetAddress"
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="City">
                        City
                      </InputLabel>
                      <OutlinedInput
                        id="City"
                        label="City"
                        name="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="state">
                        State
                      </InputLabel>
                      <OutlinedInput
                        id="state"
                        label="state"
                        name="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="Zip-code">
                        Zip Code
                      </InputLabel>
                      <OutlinedInput
                        id="Zip Code"
                        label="Zip code"
                        name="Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="Country">
                        Country
                      </InputLabel>
                      <OutlinedInput
                        id="Country"
                        label="Country"
                        name="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel className="common_font" htmlFor="occupation">
                        Occupation
                      </InputLabel>
                      <OutlinedInput
                        id="occupation"
                        label="occupation"
                        name="occupation"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <LocalizationProvider
                        id="date-picker-add-customer"
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer
                          id="date-picker-add-customer"
                          components={["DatePicker"]}
                          sx={{ padding: "0px !important" }}
                        >
                          <DatePicker
                            disableFuture
                            className="common_font"
                            id="date-picker-add-customer"
                            label="D.O.B"
                            value={dayjs(dateOfBirth)}
                            onChange={handleDateOfBirthChange}
                            slotProps={{
                              textField: {
                                required: true,
                                id: "ReportDate",
                                className: "common_font",
                                inputProps: {
                                  className: "common_font",
                                },
                                InputLabelProps: {
                                  className: "common_font",
                                },
                                InputLabel: {
                                  className: "common_font",
                                },
                              },
                            }}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel
                        className="common_font"
                        htmlFor="maritalstatus"
                      >
                        Marital status
                      </InputLabel>
                      <Select
                        value={maritalStatus}
                        label="Marital status"
                        inputProps={{
                          name: "maritalstatus",
                          id: "maritalstatus",
                          className: "common_font",
                        }}
                        onChange={(e) => setmaritalStatus(e.target.value)}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                      >
                        <MenuItem className="common_font" value="married">
                          Married
                        </MenuItem>
                        <MenuItem className="common_font" value="single">
                          Single
                        </MenuItem>
                        <MenuItem className="common_font" value="divorced">
                          Divorced
                        </MenuItem>
                        <MenuItem className="common_font" value="unspecified">
                          Unspecified
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6} mt={1}>
                  <FormControl variant="outlined" fullWidth required>
                    <FormLabel
                      className="common_font"
                      id="demo-row-radio-buttons-group-label"
                    >
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <FormControlLabel
                        id="male-add-customer"
                        className="common_font"
                        value="male"
                        control={<Radio />}
                        label={<span className="common_font">Male</span>}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                      <FormControlLabel
                        id="female-add-customer"
                        className="common_font"
                        value="female"
                        control={<Radio />}
                        label={<span className="common_font">Female</span>}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />

                      <FormControlLabel
                        id="other-add-customer"
                        className="common_font"
                        value="other"
                        control={<Radio />}
                        label={<span className="common_font">Other</span>}
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid mt={2} item xs={12} className="customer-details">
                  <Button
                    id="create-add-customer"
                    variant="contained"
                    color="success"
                    type="submit"
                    className="common-button common_font"
                  >
                    Create
                  </Button>
                </Grid>
              </form>
            </Box>
          </>
        ) : modalStatus === "AddOrUpdateProfile" ? (
          <>
            <Box className="customer_card_modal">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3 className="common_font">Change Profile Photo</h3>
                <Box
                  className={`drag-drop-image ${dragOver ? "drag-over" : ""}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                    <input
                      type="file"
                      id="upload-photo"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Typography
                      className="common_font"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      {userImage ? (
                        <img src={previewUrl} alt="Preview" width={90} />
                      ) : (
                        <img
                          src={uploadProfileImage}
                          alt="uploadProfileImage"
                          width={90}
                        />
                      )}
                      {userImage ? null : "Drag & Drop"}
                    </Typography>
                  </label>
                </Box>
                {userImage ? (
                  <Typography className="common_font">
                    {userImage.name}{" "}
                  </Typography>
                ) : null}
                <Box className="upload-document">
                  {userImage ? (
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Button
                        className="common_font"
                        variant="contained"
                        color="success"
                        onClick={uploadCustomerProfileImage}
                      >
                        Upload Photo
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => setuserImage(null)}
                      >
                        Clear
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      className="common_font"
                      variant="contained"
                      disabled
                    >
                      Upload Photo
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="customer_card_modal">
            <h3>Customer Details</h3>
            <div sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={usercus}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_fn"
                    alt="usercus"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  First Name :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.first_name}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={userLCus}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_ln"
                    alt="userLCus"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Last Name :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.last_name}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={dobCal}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_fn"
                    alt="dobCal"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  DOB :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDOB}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={occupation}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_occupation"
                    alt="occupation"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Occupation :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.occupation}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  {customerDetails.gender === "Male" ? (
                    <img
                      src={maleCus}
                      className="customer_card_modal_details_icon customer_card_modal_img_user_male"
                      alt="maleCus"
                    />
                  ) : (
                    <img
                      src={femaleCus}
                      className="customer_card_modal_details_icon customer_card_modal_img_user_fn"
                      alt="femaleCus"
                    />
                  )}
                </Box>
                <Typography className="customer_card_modal_typography">
                  Gender :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.gender}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={status}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_ln"
                    alt="dobCal"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Marital Status :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.marital_status}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <PersonIcon className="customer_card_modal_details_icon" />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Person Type :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerDetails.person_type}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={createCal}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_occupation"
                    alt="dobCal"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Created Date :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerCreatedDate}
                </Typography>
              </Box>

              <Box className="customer_card_modal_box">
                <Box className="customer_card_modal_img_box">
                  <img
                    src={updateCal}
                    className="customer_card_modal_details_icon customer_card_modal_img_user_fn"
                    alt="dobCal"
                  />
                </Box>
                <Typography className="customer_card_modal_typography">
                  Updated Date :
                </Typography>
                <Typography className="customer_card_modal_txt">
                  {customerUpdatedDate}
                </Typography>
              </Box>
            </div>
          </Box>
        )}
      </Modal>

      <div
        className="product-list-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "10px",
        }}
      >

        {/* <h1 className="common_font">{heading}</h1> */}

        <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
          onSubmit={(e) => e.preventDefault()}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Customers"
            className="common_font"
            inputProps={{ "aria-label": "Search Customers" }}
            onChange={handleCustomerSearch}
            value={textContent}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault(); // Prevent form submission on Enter key
              }
              handleSpace(event); // Continue handling other keys
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <Button
          variant="contained"
          onClick={adduser}
          className="addNewUser-btn common-button"
          style={{ height: "40px", marginTop: "10px" }}
          id="add-new-customer"
        >
          <i className="fa-solid fa-user-plus"></i>
          <span className="common_font " style={{ marginLeft: "10px" }}>
            {" "}
            {button}
          </span>
        </Button>
      </div>
      {/* <Box
        sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "space-between" }}
      >
        <TextField
          className="common_font"
          label="Search Customer"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          inputProps={{
            className: "common_font",
            id: "customer-search-in-customer",
          }}
          InputLabelProps={{
            className: "common_font",
          }}
          sx={{ mr: 2 }}
        />
        <SelectLabel
          length={customerList.length}
          onSendData={handleChildData}
        />
      </Box> */}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {currentItems.map((customer, index) => (
          <Card
            className="common_card_bs"
            key={customer.person_id}
            sx={{ width: 300, margin: 2 }}
          >
            <CardHeader
              className="common_font"
              avatar={
                <Avatar>
                  {customer.gender === "Male" ? (
                    <img
                      src={male_icon} // Replace with actual URL for male avatar
                      alt="Male Avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={femalee_icon} // Replace with actual URL for female avatar
                      alt="Female Avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Avatar>
              }
              title={`${customer.first_name} ${customer.last_name}`}
              subheader={`Gender: ${customer.gender}`}
              titleTypographyProps={{
                className: "common_font",
              }}
              subheaderTypographyProps={{
                className: "common_font",
              }}
            />
            <CardContent>
              <div className="common_font">
                <strong>Marital Status:</strong> {customer.marital_status}
              </div>
              <div className="common_font">
                <strong>DOB(Age):</strong> {formatDate(customer.date_of_birth)}
              </div>
              <div className="common_font">
                <strong>Occupation:</strong> {customer.occupation}
              </div>
              <div>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => handleAddressView(customer)}
                  title="View Address"
                >
                  <LocationOnIcon />
                </IconButton>

                <IconButton
                  sx={{ color: "black" }}
                  title="Edit Customer Details"
                >
                  <EditIcon />
                </IconButton>

                <IconButton sx={{ padding: "0px !important", color: "black" }}>
                  <VisibilityIcon
                    id={`visibility-icon-${index}`}
                    onClick={() => {
                      navigate(`/${lng}/manage-customer/customer-profile`, {
                        state: {
                          details: customer,
                        },
                      });
                    }}
                  />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        ))}
        <Box sx={{ mt: 3 }}>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={openModal}>
              <div
                style={{
                  width: "60%",
                  height: "41.5%",
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                }}
              >
                <PlusCodeMap
                  AddressDetails={{
                    PlusCode: plusCode,
                    CompleteAddress: completeAddress,
                  }}
                />
              </div>
            </Fade>
          </Modal>
        </Box>
      </div>
      {filteredItems.length > 0 ? (
        <Box sx={{ mt: 3, marginBottom: "60px" }} className="pagination-box">
          <Pagination
            className="pagination"
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            siblingCount={1}
            boundaryCount={1}
            renderItem={(item) => {
              if (item.type === "previous") {
                return (
                  <PaginationItem
                    component={IconButton}
                    {...item}
                    disabled={item.disabled}
                    id="customer-card-pagination-left-arrow"
                    icon="left"
                  />
                );
              } else if (item.type === "next") {
                return (
                  <PaginationItem
                    component={IconButton}
                    {...item}
                    disabled={item.disabled}
                    id="customer-card-pagination-right-arrow"
                    icon="right"
                  />
                );
              } else {
                return <PaginationItem {...item} disabled={item.disabled} />;
              }
            }}
          />
        </Box>
      ) : null}
    </div>
  );
}

/** @format */

// api.js
const BASE_URL = "/"; //base

const AUTH_URL = `${BASE_URL}bsa/ms/am`; //auth
const CUSTOMER_URL = `${BASE_URL}bsa/ms/cm`; //customer
const PRODUCT_URL = `${BASE_URL}bsa/ms/pm`; //product
const SALES_URL = `${BASE_URL}bsa/ms/sm`; //sales
const QUOTATION_URL = `${BASE_URL}bsa/ms/qo`; //sales
const REPORTS_URL = `${BASE_URL}bsa/ms/rm`; //reports
// 143.244.187.70
//google-client-id
export const GOOGLE_CLIENT_ID =
  "589740642086-k877is3plbnisfs6kmc8to5ek73findf.apps.googleusercontent.com";
//end
// build info 
export const BUILDINFODATETIME = "Wednesday, 04 Sep 2024 [IST]"
export const BUILDINFOSTATUS = "SUCCESS"
export const BUILDINFOAPPVERSION = "#090424.00"

//auth
export const JENKINS_URL = `${BASE_URL}`;
export const BUILD_INFO_URL = `${BASE_URL}`;
export const LOGIN_URL = `${AUTH_URL}/signin`;
export const SIGNUP_URL = `${AUTH_URL}/signup`;
export const SIGNOUT = `${AUTH_URL}/signout`;
export const SIGNUP_WQR = `${AUTH_URL}/getqrcode`;
export const REGISTER_DEVICE = `${AUTH_URL}/email-to-register-device`;
export const TWO_FA_URL = `${AUTH_URL}/two-factor-authentication`;
export const TWO_FA_STATUS = `${AUTH_URL}/change-two-fa-status`;
export const CHECK_TWO_FA_STATUS = `${AUTH_URL}/check-two-fa-status`;
export const GOOGLE_AUTH = `${AUTH_URL}/auth/google`;
export const VERIFY_TOKEN = `${AUTH_URL}/check-token`;
//end

//customer
export const CUSTOMER_CONTACT_DETAILS = `${CUSTOMER_URL}/get-customer-contact-details`;
export const UPDATE_CUSTOMER_NAME = `${CUSTOMER_URL}/edit-customer-full_name`;
export const CUSTOMER_LIST = `${CUSTOMER_URL}/get-customer-list`;
export const CUSTOMER_DETAILS = `${CUSTOMER_URL}/get-customer-all-details`;
export const EDIT_CUSTOMER_PHONENUMBER = `${CUSTOMER_URL}/edit-customer-contact`;
export const EDIT_CUSTOMER_ADDRESS = `${CUSTOMER_URL}/edit-customer-address`;
export const ADD_CUSTOMER_ADDRESS = `${CUSTOMER_URL}/add-customer-address`;
export const ADD_CUSTOMER_PHONENUMBER = `${CUSTOMER_URL}/add-customer-contact`;
export const EDIT_CUSTOMER_PROFILE = `${CUSTOMER_URL}/update-customer-profile`;
export const ADD_NEW_CUSTOMER = `${CUSTOMER_URL}/add-customer`;
//end

// user
export const USER_LOGIN_DETAILS = `${AUTH_URL}/user-login-details`;
export const USER_CHANGE_PASSWORD = `${AUTH_URL}/update-user-password`;
export const USER_DETAILS = `${AUTH_URL}/user-list`;
export const GET_USER_NOTIFICATION_LIST = `${AUTH_URL}/user-notification`;
export const UPDATE_USER_NOTIFICATION = `${AUTH_URL}/update-notification`;
export const DELETE_USER_NOTIFICATION = `${AUTH_URL}/delete-notification`;
export const GET_USER_ORG = `${AUTH_URL}/get-user-org`;
export const UPLOAD_CUSTOMER_PROFILE = `${AUTH_URL}/upload-image`;
export const GET_CUSTOMER_PROFILE_IMAGE = `${AUTH_URL}/get-image`;
// end

//product
export const PRODUCT_LIST = `${PRODUCT_URL}/get-product-list`;
export const PRODUCT_DETAILS = `${PRODUCT_URL}/get-product-details`;
export const UPDATE_PRODUCT_DETAILS = `${PRODUCT_URL}/update-product-details`;
export const UPDATE_PRODUCT_SPECIFICATION = `${PRODUCT_URL}/update-product-specification`;
export const UPDATE_PRODUCT_STOCK = `${PRODUCT_URL}/update-product-stock`;
export const ADD_PRODUCT_STOCK = `${PRODUCT_URL}/add-product-stock`;
export const PRODUCT_STOCK_DETAILS = `${PRODUCT_URL}/get-product-restocking`;
export const ADD_PRODUCTS_IMAGE = `${PRODUCT_URL}/upload-product-image`;
export const GET_PRODUCTS_IMAGE = `${PRODUCT_URL}/get-product-image`;
export const GET_ALL_PRODUCTS_IMAGE = `${PRODUCT_URL}/get-product-image-all`;
export const GET_ALL_PRODUCT_LIST = `${PRODUCT_URL}/get-all-product-list`;
export const CREATE_PRODUCT = `${PRODUCT_URL}/create-product`;
export const DELETE_PRODUCT = `${PRODUCT_URL}/delete-product`;
export const TOP3_PRODUCT = `${PRODUCT_URL}/get_recommended_product_list`;
//end

//sales
export const ADD_QUOTATION = `${SALES_URL}/add-quotation`;
export const UPDATE_QUOTATION = `${SALES_URL}/update-quotation`;
export const QUOTATION_LIST = `${SALES_URL}/quatation-list`;
export const QUOTATION_DETAILS = `${SALES_URL}/quatation-details`;
export const QUOTATION_DETAILS_BY_DISPLAY_ID = `${SALES_URL}/quotation-details-display-id`;
export const SEND_QUOTATION = `${QUOTATION_URL}/quote-to-order`;
export const SALES_ORDER_LIST = `${SALES_URL}/sales-order-list`;
export const SALES_ORDER_DETAILS = `${SALES_URL}/sales-order-details-display-id`;
export const MAKE_PAYMENT = `${SALES_URL}/add-sales-order-payment`;
export const SHIPMENT_LIST = `${SALES_URL}/get-shipment-list`;
export const UPDATE_SHIPMENT_STATUS = `${SALES_URL}/update-shipment-status`;
export const GET_SHIPMENT_TRACKING = `${SALES_URL}/get-shipment-tracking`;
export const DELETE_DRAFT_QUO = `${SALES_URL}/delete-draft-quotation`;
export const GET_DISCOUNT_LIST = `${SALES_URL}/get-discount-list`;
export const GET_COUPON_LIST = `${SALES_URL}/get-coupon-list`;
export const GET_RETURN_LIST = `${SALES_URL}/get-return-list`;
export const ADD_RETURN_REQUEST = `${SALES_URL}/add-return`;
export const ADD_RETURN_DETAILS = `${SALES_URL}/add-return-details`;
export const GET_PRODUCT_LIST = `${SALES_URL}/sales-order-details-display-id`;
export const GET_RETURN_DETAILS = `${SALES_URL}/get-return-details`;
export const UPDATE_RETURN_STATUS = `${SALES_URL}/update-return-status`;
export const GET_RETURN_TRACKING = `${SALES_URL}/get-return-tracking`;

//end

// reports
export const SALES_REP_DASHBOARD_DATA = `${REPORTS_URL}/get-sales-rep-dashboard-data`;
export const POSTGRESQL_2_ALGOLIA_LOGS = `${REPORTS_URL}/get-postgresql-to-algolia-logs`;
// end

// logs
export const GET_LOGS = `${AUTH_URL}/get-logs`;

//Idle session timeout
export const IDLE_SESSION_TIMEOUT = 1000 * 60 * 30

import React, { useState } from "react";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Orders from "../customers/orders";
import "../../Styles/products.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const For = [
  {
    name: "Grand Parents",
  },
  {
    name: "Parents",
  },
  {
    name: "Siblings",
  },
  {
    name: "Self",
  },
  {
    name: "Other",
  },
];
const Purpose = [
  {
    name: "Gift",
  },
  {
    name: "For Work",
  },
  {
    name: "Other",
  },
];
const Occupation = [
  {
    name: "Working",
  },
  {
    name: "Non-Working",
  },
  {
    name: "Other",
  },
];
const Range = [
  {
    name: "100 - 500",
  },
  {
    name: "500 - 1000",
  },
  {
    name: "1000 - 10000",
  },
  {
    name: "10000 - other",
  },
];

export default function CreateCustomerOrder() {
  const navigate = useNavigate();

  const [forvalue, setFor] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupation, setOccupation] = useState("");
  const [color, setColor] = useState("");
  const [range, setRange] = useState("");
  const [quantity, setQuantity] = useState("");

  const dispatch = useDispatch();

  const customerDetails = {
    forvalue: forvalue,
    name: name,
    age: age,
    purpose: purpose,
    occupation: occupation,
    color: color,
    range: range,
    quantity: quantity,
  };

  const getCustomers = () => {
    // dispatch(addToCart(pensDetails));
    console.log(customerDetails);
    Orders.push(customerDetails);
  };

  return (
    <div id="add_pen_container">
      <Card id="add_pen_card">
        <CardContent>
          <Box id="add_pen_box">
            <Box
              id="add_pen_formcontrol_box"
              className="add_pen_formcontrol_box"
            >
              <FormControl
                id="add_pen_body_colors_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-colors-label" required>
                  For
                </InputLabel>
                <Select
                  labelId="body-colors-label"
                  id="body-colors-select"
                  value={forvalue}
                  label="For"
                  required
                  onChange={(e) => setFor(e.target.value)}
                >
                  {For.map((bcl, index) => (
                    <MenuItem
                      id={`body-colors-menu-item-${index}`}
                      key={index}
                      value={bcl.name}
                    >
                      {bcl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="text"
                id="add_pen_quantity_textfield"
                className="add_pen_textfield"
                label="Name"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />

              <TextField
                type="number"
                id="add_pen_quantity_textfield"
                className="add_pen_textfield"
                label="Age"
                required
                onChange={(e) => setAge(e.target.value)}
                value={age}
              />

              <FormControl
                id="add_pen_body_colors_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-colors-label" required>
                  Event/Purpose
                </InputLabel>
                <Select
                  labelId="body-colors-label"
                  id="body-colors-select"
                  value={purpose}
                  label="Event/Purpose"
                  required
                  onChange={(e) => setPurpose(e.target.value)}
                >
                  {Purpose.map((bcl, index) => (
                    <MenuItem
                      id={`body-colors-menu-item-${index}`}
                      key={index}
                      value={bcl.name}
                    >
                      {bcl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                id="add_pen_body_colors_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-colors-label" required>
                  Occupation
                </InputLabel>
                <Select
                  labelId="body-colors-label"
                  id="body-colors-select"
                  value={occupation}
                  label="Occupation"
                  required
                  onChange={(e) => setOccupation(e.target.value)}
                >
                  {Occupation.map((bcl, index) => (
                    <MenuItem
                      id={`body-colors-menu-item-${index}`}
                      key={index}
                      value={bcl.name}
                    >
                      {bcl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="text"
                id="add_pen_quantity_textfield"
                className="add_pen_textfield"
                label="Favourite Color"
                onChange={(e) => setColor(e.target.value)}
                value={color}
                required
              />

              <FormControl
                id="add_pen_body_shape_formControl"
                className="add_pen_formControl"
              >
                <InputLabel id="body-shape-label" required>
                  Range
                </InputLabel>
                <Select
                  labelId="body-shape-label"
                  id="body-shape-select"
                  value={range}
                  label="Range"
                  required
                  onChange={(e) => setRange(e.target.value)}
                >
                  {Range.map((bsl, index) => (
                    <MenuItem
                      id={`body-shape-menu-item-${index}`}
                      key={index}
                      value={bsl.name}
                    >
                      {bsl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="number"
                id="add_pen_quantity_textfield"
                className="add_pen_textfield"
                label="Quantity"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
                required
              />

              {forvalue &&
              name &&
              age &&
              purpose &&
              occupation &&
              color &&
              range &&
              quantity ? (
                <Button
                  id="add_customer-order"
                  onClick={() => {
                    getCustomers();
                    navigate("/customerdetails");
                  }}
                  className="add_customer-order"
                  variant="contained"
                >
                  + Create
                </Button>
              ) : (
                <Button
                  id="add_customer-order"
                  className="add_customer-order"
                  variant="contained"
                  disabled
                >
                  + Create
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from '@mui/material';
import penLogo from '../../assets/img/pensbrands/Hayman.png'
import penLogo2 from '../../assets/img/pensbrands/Copic.png'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BodyShapeList = [
    {
        name: "HAYMAN",
        point_type: "Broad",
        body_material: "Metal",
        pen_type: "Ink",
        body_color: "Black",
        ink_color: "Silver",
        body_shape: "Hexagonal",
        quantity: 5,
        image: penLogo
    },
    {
        name: "HAYMAN",
        point_type: "Broad",
        body_material: "Metal",
        pen_type: "Ink",
        body_color: "Black",
        ink_color: "Silver",
        body_shape: "Hexagonal",
        quantity: 5,
        image: penLogo2
    },
    {
        name: "HAYMAN",
        point_type: "Broad",
        body_material: "Metal",
        pen_type: "Ink",
        body_color: "Black",
        ink_color: "Silver",
        body_shape: "Hexagonal",
        quantity: 5,
        image: penLogo
    },
    {
        name: "HAYMAN",
        point_type: "Broad",
        body_material: "Metal",
        pen_type: "Ink",
        body_color: "Black",
        ink_color: "Silver",
        body_shape: "Hexagonal",
        quantity: 5,
        image: penLogo2
    },
    {
        name: "HAYMAN",
        point_type: "Broad",
        body_material: "Metal",
        pen_type: "Ink",
        body_color: "Black",
        ink_color: "Silver",
        body_shape: "Hexagonal",
        quantity: 5,
        image: penLogo
    },

]

export default function PensDetails() {
    const navigate = useNavigate()
    const [storePenDetails, setStorePenDetails] = useState([])

    const getPenDetails = useSelector(state => state.cardReducer)


    useEffect(() => {
        console.log(getPenDetails)
        setStorePenDetails(getPenDetails)
    }, [getPenDetails])

    return (
        <div>
            <Box>
                <TableContainer className="">

                    <Table
                        className="table-dimension-cd-windowsets"
                        aria-labelledby="tableTitle"
                        size="small"
                    >
                        <TableHead>
                            <Card tabIndex={-1} className="pen_details_document_card_head">
                                <CardContent className="pen_details_document_card_content">
                                    <TableRow
                                        className="pen_details_rowhover"
                                        tabIndex={-1}
                                    >
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Brands
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Ink Color
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Body Type
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Body Color
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Quantity

                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Body Shape
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Point Type
                                        </TableCell>
                                        <TableCell
                                            className="pen_details_table_content_name_cell_heade table_cell_f common_font tablecell_name_pdf_box"
                                            align="left"
                                        >
                                            Edit
                                        </TableCell>
                                    </TableRow>
                                </CardContent>
                            </Card>
                        </TableHead>

                        <TableBody>
                            {
                                storePenDetails.map((bsl, index) => {
                                    console.log(bsl)
                                    return (
                                        <Card tabIndex={-1} className="pen_details_document_card">
                                            <CardContent className="pen_details_document_card_content">
                                                <TableRow
                                                    className="rowhover"
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {/* <img style={{ width: "60px", height: "25px" }} src={bsl.image} alt="" /> */}
                                                        {bsl.brands.name}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.inkColors}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.bodyMaterials}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.bodyColors}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.quantity}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.bodyShape}
                                                    </TableCell>
                                                    <TableCell
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        {bsl.PointType}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                            navigate('/update-pen-details', {
                                                                state: {
                                                                    PenData: bsl
                                                                }
                                                            })}
                                                        className="table-content-name-cell table_cell_f common_font sticky_column_body tablecell_name_pdf_box"
                                                        align="left"
                                                    >
                                                        Edit
                                                    </TableCell>
                                                </TableRow>
                                            </CardContent>
                                        </Card>
                                    )
                                })
                            }

                        </TableBody>
                    </Table>

                </TableContainer>

            </Box>
        </div>
    )
}

// Author: sonal

import React, { createContext, useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginForm from "./components/auth/login/login";
import { BrowserRouter } from "react-router-dom/dist";
import { AlertProvider } from "./components/alert/alert-context";
import SignUpForm from "./components/auth/login/signup";
import QRCodeforExistingUser from "./components/auth/login/qrcodeforexistinguser";
import PrivateRoutes from "./utils/PrivateRoutes";
import PERMISSIONS from "./permissions/permissions";
import { AuthProvider } from "./provider/AuthProvider";
import Extra from "./components/Extra";
import Profile from "../src/components/Profile/Profile";
import Dashboard from "./components/dashboard/Dashboard";
import SelectedTypeProductList from "./components/Products/SelectedTypeProductList";
import AddedItem from "./components/Carts/AddedItem";
import PageNotFound from "../src/components/ErrorPage/PageNotFound";
import Payment from "../src/components/Payment/Payment";
import Layout from "./components/Layout/Layout";
import AdminDashboard from "./components/admin/dashboard";
import AddToCart from "../src/components/Carts/AddToCart";
import UserList from "./components/admin/UserList";
import Landing from "./components/dashboard/Landing";
import ListOfCustomers from "./components/customers/ListOfCustomers";
import AddPen from "./components/Products/AddPen";
import PensDetails from "./components/Products/PenDetails";
import UpdatePenDetails from "./components/Products/UpdatePenDetails";
import CustomerDetails from "./components/customers/CustomerDetails";
import CreateCustomerOrder from "./components/customers/CreateCustomerOrder";
import ProductList from "./components/Products/ProductList";
import ProductDetails from "./components/Products/ProductDetails";
import CustomerInfo from "./components/admin/adminInfo";
import CustomerCard from "./components/customers/customercard";
import CustomerProfile from "./components/customers/CustomerProfile";
import SalesList from "./components/customers/saleslist";
import SalesDetails from "./components/customers/salesdetails";
import PdfGenerator from "./components/PDFLayout/QuotationPdf";
import UserProfile from "./components/admin/UserProfile";
import ViewAllUsers from "./components/admin/ViewAllUsers";
import { SearchProducts } from "./components/Products/SearchProducts";
import { useTranslation } from "react-i18next";
import Reports from "./Reports/Reports";
import EditUserDetails from "./components/admin/EditUserDetails";
import CompanyDetails from "./components/customers/CompanyDetails";
import SalesOrderList from "./components/Orders/SalesOrderList";
import SalesOrderDetails from "./components/Orders/SalesOrderDetails";
import ShipmentList from "./components/Shipment/ShipmentList";
import UserLogs from "./components/admin/UserLogs";
import ManageProducts from "./components/admin/ManageProducts";
import { CreateProducts } from "./components/Products/CreateProducts";
import ProductsLogs from "./components/admin/ProductsLogs";
import ReturnItems from "./components/Shipment/ReturnItems";
import ReturnProductDetails from "./components/Shipment/Returnproductdetails";
import BuildInfo from "./components/BuildInfo";
import ListOfModules from "./components/admin/ListOfModules";
// import AskBetsy from "./components/AskBetsy/AskBetsy";

export const AuthContext = createContext(null);
export const SnackbarContext = React.createContext();

const App = () => {
  const [opensnackbar, setOpenSnackbar] = React.useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackseverity, setSnackSeverity] = useState("");

  const { i18n } = useTranslation();
  console.log(i18n.language);
  // Function to check if token is expired
  const isTokenExpired = (token) => {
    const decodedToken = decodeToken(token);
    return decodedToken.exp < Date.now() / 1000;
  };
  //end

  // Function to decode token
  const decodeToken = (token) => {
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } catch (error) {
      return {};
    }
  };
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token && !isTokenExpired(token);
  };

  console.log(isAuthenticated());

  useEffect(() => {
    const handleLogout = () => {
      window.location.href = "/login";
    };

    const logoutChannel = new BroadcastChannel("logout_channel");
    logoutChannel.addEventListener("message", (event) => {
      if (event.data === "logout") {
        handleLogout();
      }
    });

    return () => {
      logoutChannel.close();
    };
  }, []);

  const newPath = window.location.pathname;
  console.log(newPath);
  const lastLocation = localStorage.getItem("lastLocation");
  console.log(lastLocation);
  const siteName = localStorage.getItem("site_name");
  console.log(siteName);

  const redirectCompanyDashboard = () => {
    return (
      <>
        {siteName === "Goldspot" ? (
          <Navigate to={`/gold-spot/en/dashboard`} replace />
        ) : siteName === "Pen House" ? (
          <Navigate to={`/pen-house/en/dashboard`} replace />
        ) : (
          <Navigate to="/en/dashboard" replace />
        )}
      </>
    );
  };

  return (
    <SnackbarContext.Provider
      value={{
        opensnackbar,
        setOpenSnackbar,
        snackmsg,
        setSnackMsg,
        snackseverity,
        setSnackSeverity,
      }}
    >
      <BrowserRouter>
        <AuthProvider>
          <AlertProvider>
            <div className="App">
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    isAuthenticated() ? (
                      redirectCompanyDashboard()
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  }
                />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/signup" element={<SignUpForm />} />
                <Route
                  path="/qrcode-for-existing-user/:id"
                  element={<QRCodeforExistingUser />}
                />

                <Route path="/" element={<Layout />}>
                  <Route path="*" element={<Landing />} />
                  <Route path="/page-not-found" element={<PageNotFound />} />
                  <Route path="/admindashboard" element={<AdminDashboard />} />

                  <Route path="/users" element={<UserList />} />
                  <Route path="/build-info" element={<BuildInfo />} />
                  <Route path="/list-of-modules" element={<ListOfModules />} />
                  {/* <Route path="/build-info-1" element={<BuildInfo />} /> */}

                  <Route path=":company/:lng/dashboard" element={<Landing />} />

                  <Route path=":lng/dashboard" element={<Landing />} />

                  <Route path="/add-pen" element={<AddPen />} />
                  <Route
                    path="/update-pen-details"
                    element={<UpdatePenDetails />}
                  />
                  <Route path="/pens-details" element={<PensDetails />} />
                  <Route
                    path=":lng/dashboard/user-profile"
                    element={<UserProfile />}
                  />
                  {/* customers  */}
                  <Route
                    path="/:lng/dashboard/customer-list"
                    element={<ListOfCustomers />}
                  />
                  <Route
                    path="/:lng/manage-customer"
                    element={<CustomerCard />}
                  />
                  <Route path="/customers-card" element={<CustomerCard />} />
                  <Route path="/dashboard-1" element={<Dashboard />} />
                  <Route
                    path="/:lng/manage-customer/customer-profile"
                    element={<CustomerProfile />}
                  />
                  <Route
                    path="/:lng/company-details"
                    element={<CompanyDetails />}
                  />

                  <Route
                    path="/:lng/dashboard/customer-list/customer-details"
                    element={<CustomerDetails />}
                  />
                  <Route
                    path="/:lng/create-quotes"
                    element={<CustomerDetails />}
                  />
                  <Route
                    path="/create-customer-order"
                    element={<CreateCustomerOrder />}
                  />

                  <Route path="/:lng/quotes" element={<SalesList />} />
                  <Route path="/:lng/quotes/:id" element={<SalesDetails />} />
                  <Route
                    path="/en/quotes/view-quote/:id"
                    element={<PdfGenerator />}
                  />
                  <Route path="/profile" element={<Profile />} />

                  {/* reports  */}
                  <Route path="/:lng/reports" element={<Reports />} />

                  <Route path="/addtocart" element={<AddToCart />} />

                  {/* orders  */}
                  <Route
                    path="/:lng/sales-order-list"
                    element={<SalesOrderList />}
                  />
                  <Route
                    path="/:lng/sales-order-list/sales-order-details/:id"
                    element={<SalesOrderDetails />}
                  />

                  {/* logs  */}

                  {/* shipment  */}
                  <Route path="/:lng/shipment" element={<ShipmentList />} />
                  <Route path="/:lng/return-products" element={<ReturnItems />} />
                  <Route
                    path="/return-products-details"
                    element={<ReturnProductDetails />}
                  />

                  {/* products  */}
                  <Route path="/:lng/products-list" element={<ProductList />} />
                  <Route path="/search-products" element={<SearchProducts />} />
                  <Route
                    path="/:lng/products-list/product-description-page"
                    element={<ProductDetails />}
                  />
                  <Route path="/create-product" element={<CreateProducts />} />

                  <Route
                    path="/selectedtypeproductlist"
                    element={<SelectedTypeProductList />}
                  />
                  <Route path="/:lng/manage-users" element={<CustomerInfo />} />
                  <Route path="/:lng/user-logs" element={<UserLogs />} />
                  <Route path="/:lng/product-logs" element={<ProductsLogs />} />
                  {/* <Route path="/ask-betsy" element={<AskBetsy />} /> */}
                  <Route
                    path="/:lng/manage-products"
                    element={<ManageProducts />}
                  />

                  <Route
                    path="/:lng/manage-users/:id"
                    element={<EditUserDetails />}
                  />
                  <Route
                    path="/:lng/manage-users/all-users"
                    element={<ViewAllUsers />}
                  />

                  <Route path="/checkout" element={<Payment />} />
                  <Route path="/addcart" element={<AddedItem />} />
                </Route>

                <Route
                  element={
                    <PrivateRoutes permissions={[PERMISSIONS.CAN_VIEW_EXTRA]} />
                  }
                >
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="/page-not-found" element={<PageNotFound />} />

                  <Route path="extra" element={<Extra />} />
                </Route>
              </Routes>
            </div>
          </AlertProvider>
        </AuthProvider>
      </BrowserRouter>
    </SnackbarContext.Provider>
  );
};

export default App;

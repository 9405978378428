// Author: sonal

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/carts.css";
import { Box, Button, Typography } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../Redux/actions/actions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ShareIcon from "@mui/icons-material/Share";

export default function AddedItem() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const dispatch = useDispatch();

  const addedItemList = useSelector((state) => state.cardReducer);
  console.log(addedItemList);

  const getImage = location.state.products.image;
  const getPenName = location.state.products.name;
  const getPenPrice = location.state.products.price;

  const addProduct = (item) => {
    dispatch(addToCart(item));
  };

  const getupdatedStateData = useSelector(state => state.cardReducer);
  console.log(getupdatedStateData)

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingTop: "40px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "40px",
      }}
    >
      <div className="added_item_main_div">
        <Box className="">
          <img
            src={getImage}
            className="selected_type_product_list_img"
            alt="getimage"
          />

          <div className="selected_type_product_list_button_div">
            <Button
              onClick={() => {
                addProduct(location.state.products);
                
                navigate("/addtocart");
              }}
              startIcon={<AddShoppingCartIcon className="primary_icon" />}
              className="primary_button"
              variant="contained"
            >
              Add to cart
            </Button>

           
            <Button
              onClick={() => {
                navigate("/checkout");
              }}
              startIcon={<AttachMoneyIcon className="primary_icon" />}
              className="primary_button"
              variant="contained"
            >
              Buy Now
            </Button>
          </div>
        </Box>

        <Box className="added_item_main_div_box_1">
          <Typography className="primary_text">
            {getPenName} 0.55mm | Waterproof & Smooth Ink Flow Gel Pen
          </Typography>
          <Typography className="primary_text">${getPenPrice}</Typography>
          <Typography className="secondary_text">
            {" "}
            <span style={{ fontWeight: "bold" }}>Description :</span> Our pens
            are engineered with precision, ensuring smooth and consistent ink
            flow...
          </Typography>

          <Box className="added_item_rating_review_box">
            <Box className="added_item_rating_review_box_1">
              <Typography className="added_item_rating_review_txt ">
                Ratings & Reviews
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className=""
              >
                {" "}
                <ShareIcon sx={{ marginRight: "10px" }} /> Share
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "",
                alignItems: "center",
                marginTop: "10px",
              }}
              className="added_item_rating_review_sub_box"
            >
              <Button
                sx={{ marginRight: "10px !important" }}
                className="added_item_rating_review_button"
                variant="contained"
              >
                4.1
              </Button>
              <Typography className="added_item_rating_review_txt">
                1,149 ratings and 793 reviews
              </Typography>
            </Box>
          </Box>
          <Button
            sx={{ marginTop: "15px" }}
            className="secondary_button added_item_rate_product_button"
            variant="contained"
          >
            Rate product
          </Button>

          <Box className="added_item_highlights_box">
            <Typography className="added_item_highlights_txt">
              Highlights
            </Typography>
            <Box className="added_item_highlights_sub_box">
              <FiberManualRecordIcon sx={{ fontSize: "15px" }} />{" "}
              <Typography sx={{ marginLeft: "8px" }}>
                Body Color : Blue
              </Typography>
            </Box>
            <Box className="added_item_highlights_sub_box">
              <FiberManualRecordIcon sx={{ fontSize: "15px" }} />{" "}
              <Typography sx={{ marginLeft: "8px" }}>Made of metal</Typography>
            </Box>
            <Box className="added_item_highlights_sub_box">
              <FiberManualRecordIcon sx={{ fontSize: "15px" }} />{" "}
              <Typography sx={{ marginLeft: "8px" }}>
                Ink Color: Black
              </Typography>
            </Box>
            <Box className="added_item_highlights_sub_box">
              <FiberManualRecordIcon sx={{ fontSize: "15px" }} />{" "}
              <Typography sx={{ marginLeft: "8px" }}>Pack of 5</Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

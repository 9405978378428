import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import ProductsTypeList from "../Products/ProductsTypeList";
import { Grid, Paper } from "@mui/material";
import "../../Styles/admindashboard.css";
import user from "../../assets/img/avatar.jpg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import AdminStatus from "./adminstatus";
import { SnackbarContext } from "../../app";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
function createData(name, user, email, status, role) {
  return { name, user, email, status, role };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const rows = [
  createData("Test User", "Test User", "test@gmail.com", "Inactive", "Admin"),
  createData("Test One", "Test One", "testone@gmail.com", "Active", "User"),
];

function AdminDashboard() {

  const contextvalue = React.useContext(SnackbarContext);
  const [opensnackbar, setOpenSnackbar] = React.useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const [snackseverity, setSnackSeverity] = useState("");

  const navigate = useNavigate();
  
  const adduser = () => {
    navigate("/adduser");
  };


  const viewall = () => {
    navigate("/allproducts");
  };


  useEffect(() => {
    console.log(contextvalue);

    if (contextvalue.opensnackbar === true) {
      setSnackMsg(contextvalue.snackmsg);
      setSnackSeverity(contextvalue.snackseverity);
      setOpenSnackbar(contextvalue.opensnackbar);
      contextvalue.opensnackbar = false;
    }

  }, []);


  const handleAlertClose = () => {
    setOpenSnackbar(false)
  }

  return (
    <div>
      <Snackbar
        open={opensnackbar}
        className="snackBar-alert"
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          {snackmsg}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Paper className="admin-cards-top common_font">
            <h2>Welcome, Admin!</h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar />
            </LocalizationProvider>
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className="admin-cards-top common_font">
            <ProductsTypeList />
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                paddingTop: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={viewall}
                className="addNewUser-btn"
              >
                View All
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className="admin-cards-bottom common_font">
            <AdminStatus />
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className="admin-cards-bottom common_font">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#5e6278" }}
              >
                Users (2)
              </Typography>
              <Button
                variant="contained"
                onClick={adduser}
                className="addNewUser-btn"
              >
                <i class="fa-solid fa-user-plus"></i>
                <span>&nbsp; Add New User</span>
              </Button>
            </div>
            <TableContainer sx={{ mt: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="userlist">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <b>
                        <i
                          class="fa-solid fa-user"
                          style={{
                            "font-size": "20px",
                            "margin-left": "10px",
                          }}
                        ></i>
                      </b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>User</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Email</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Status</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Role</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left">
                        {" "}
                        <Avatar src={user} />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.status === "Active" ? (
                          <div
                            style={{
                              width: "max-content",
                              minWidth: "70px",
                              height: "max-content",
                              padding: "8px",
                              background: "#e9fbf0",
                              color: "#5c9472",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            {row.status}
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "max-content",
                              minWidth: "70px",
                              height: "max-content",
                              padding: "8px",
                              background: "#fce4e4",
                              color: "#9a4242",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            {row.status}
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminDashboard;

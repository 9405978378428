import React, { useEffect, useState } from "react";
import { Card, CardContent, Button } from "@mui/material";

import { PDFViewer } from "@react-pdf/renderer";
import { useLocation, useNavigate } from "react-router";
import PdfLayout from "./PDFLayout";

const PdfGenerator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const [showPdf, setShowPdf] = useState(false);
  const [quotation_details, setQuotationDetails] = useState([]);
  const [quotation_id, setQuotationID] = useState([]);
  const [contact_details, setContactDetails] = useState([]);
  const [quotation_product_list, setQuotationProductList] = useState([]);
  const [qrData, setQrData] = useState("");

  const handleClosePdf = (id) => {
    setShowPdf(false);
    navigate(`/en/quotes/${quotation_details[0].quotation_display_id}`, {
      state: id,
    });
  };

  useEffect(() => {
    setQuotationID(location.state.quotation_details[0].quotation_id);
    setQuotationDetails(location.state.quotation_details);
    setContactDetails(location.state.contact_details);
    setQuotationProductList(location.state.quotation_product_list);
    setQrData(location.state.qrDatajsonString);
    setShowPdf(true);
  }, [location]);

  let commonData = {};
  commonData["quotation_details"] = quotation_details;
  commonData["quotation_product_list"] = quotation_product_list;
  commonData["contact_details"] = contact_details;
  commonData["qrData"] = qrData;

  return (
    <div>
      {showPdf === true ? (
        <Card
          id="landing_card"
          className="landing_card common_card_bs"
          style={{ marginBottom: "20px", height: "100% !important" }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              size="small"
              className="common_font"
              onClick={() => handleClosePdf(quotation_id)}
              style={{
                marginTop: "20px",
                marginRight: "18px",
                background: "#000000",
                fontWeight: "bold",
              }}
            >
              CLOSE
            </Button>
          </div>

          <CardContent className="customer_details_card">
            <div>
              <PDFViewer width="100%" height="1000">
                <PdfLayout data={commonData} />
              </PDFViewer>
            </div>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PdfGenerator;

export const displaytimestampasperclient = (dateString) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
  };

  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clientDate = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(clientDate.toLocaleString("en-US", { timeZone: clientTimeZone }))
  );

  return formattedDate;
};

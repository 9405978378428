// Author: sonal

/** @format */

import React, { useEffect, useState } from "react";
import "../../Styles/customers.css";
import { Box, Button, Typography } from "@mui/material";
import userProfile from "../../assets/img/user.jpg";
import Axios from "axios";
import uploadProfileImage from "../../assets/img/upload_profile.png";
import { useLocation } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from 'dayjs';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_PHONENUMBER,
  CUSTOMER_CONTACT_DETAILS,
  CUSTOMER_DETAILS,
  EDIT_CUSTOMER_ADDRESS,
  EDIT_CUSTOMER_PHONENUMBER,
  EDIT_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_NAME,
  UPLOAD_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE_IMAGE,
} from "../../config/config";
import { format, parseISO } from "date-fns";
import CreateIcon from "@mui/icons-material/Create";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput, FormHelperText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BusinessIcon from "@mui/icons-material/Business";
import removeIcon from "../../assets/img/cancel.png";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const moment = require("moment");


export default function CustomerProfile() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  console.log(location.state.details.person_id);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerContactDetailId, setCustomerContactDetailId] = useState("");
  const [customerEmailContactDetailId, setCustomerEmailContactDetailId] =
    useState("");
  const [customerContactDetails, setCustomerContactDetails] = useState([]);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerConfirmPhoneNumber, setCustomerConfirmPhoneNumber] =
    useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  console.log(isValidPhone);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [customerOccupation, setCustomerOccupation] = useState("");
  const [customerGender, setCustomerGender] = useState("");
  const [customerMaritalStatus, setCustomerMaritalStatus] = useState("");
  const [customerDOB, setCustomerDOB] = useState("");

  const [customerCreatedDate, setCustomerCreatedDate] = useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [customerPersonId, setcustomerPersonId] = useState("");
  const [profileModal, setprofileModal] = useState(false);
  const [addCustomerPhoneNumber, setAddCustomerPhoneNumber] = useState("");
  console.log(addCustomerPhoneNumber);
  const [addCustomerEmail, setAddCustomerEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [contactPriority, setContactPriority] = useState("0");
  const [updateFirstName, setUpdateFirstName] = useState("");
  const [updateLastName, setupdateLastName] = useState("");
  const [updateCustomerGender, setUpdateCustomerGender] = useState("");
  const [updateCustomerDOB, setUpdateCustomerDOB] = useState();
  const [updateCustomerOccupation, setUpdateCustomerOccupation] = useState("");
  const [updateCustomerMaritalStatus, setUpdateCustomerMaritalStatus] =
    useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setstreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [addressId, setAddressId] = useState("");
  const [msgType, setmsgType] = useState("");
  const [addZip, setAddZip] = useState("");
  const [addCity, setAddCity] = useState("");
  const [addState, setAddState] = useState("");
  const [addCountry, setAddCountry] = useState("");
  const [addStreetAdd, setAddStreetAdd] = useState("");

  const [customerNameUpdateAlert, setCustomerNameUpdateAlert] = useState(false);
  const [customerNameUpdatetxt, setCustomerNameUpdatetxt] = useState("");
  const [customerPhoneUpdatetxt, setCustomerPhoneUpdatetxt] = useState("");
  const [customerEmailUpdatetxt, setCustomerEmailUpdatetxt] = useState("");
  const [customerAddressUpdatetxt, setCustomerAddressUpdatetxt] = useState("");
  const [customerProfileUpdatetxt, setCustomerProfileUpdatetxt] = useState("");

  const [modalStatus, setModalStatus] = useState("");
  const [alertStatus, setalertStatus] = useState("");

  const [addModalStatus, setAddModalStatus] = useState("");
  const [addAlertStatus, setAddAlertStatus] = useState("");

  const [customercontactAddAlert, setCustomercontactAddAlert] = useState(false);
  const [customerEmailAddtxt, setCustomerEmailAddtxt] = useState("");
  const [customerPhoneNumberAddtxt, setCustomerPhoneNumberAddtxt] =
    useState("");
  const [customerAddressAddtxt, setCustomerAddressAddtxt] = useState("");
  const [peoplePercentage, setPeoplePercentage] = useState("");
  const [peopleaddPercentage, setPeopleAddPercentage] = useState("");
  const [peopleConPercentage, setPeopleContactPercentage] = useState("");

  useEffect(() => {
    setCustomerDetails(location.state.details);
    getCustomerDetails();
    getCustomerContactDetails();
  }, []);

  const [getFilterPhoneData, setGetFilterPhoneData] = useState([]);
  console.log(getFilterPhoneData);
  const [getFilterEmailData, setGetFilterEmailData] = useState([]);
  const [userImage, setuserImage] = useState(null);
  const [handleUploadAlert, sethandleUploadAlert] = useState(false);
  const [uploadImageMsg, setuploadImageMsg] = useState("");
  const [imageUrl, setProfileImageUrl] = useState(userProfile);
  console.log(imageUrl);
  const [dragOver, setDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [checkAlertStatus, setCheckAlertStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const today = new Date();
  const tomorrow = new Date(today);

  tomorrow.setDate(tomorrow.getDate() + 1);
  const [dateOfBirth, setDateOfBirth] = useState(null)


  const getDateOfBirthFun = (newvalue) => {
    console.log(newvalue)
    const selectedDate = new Date(newvalue.$d);
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
    console.log(selectedDate)
    console.log(formattedDate)
    setDateOfBirth(formattedDate)
  }

  useEffect(() => {
    console.log(customerContactDetails);
    let filterPhoneData = [];
    let filterEmailData = [];
    customerContactDetails.map((fpd) => {
      console.log(fpd);
      if (fpd.contact_type === "phone") {
        filterPhoneData.push(fpd);
      } else {
        filterEmailData.push(fpd);
      }
    });
    console.log(filterPhoneData);
    setGetFilterPhoneData(filterPhoneData);
    setGetFilterEmailData(filterEmailData);
  }, [customerContactDetails]);

  const getCustomerDetails = async () => {
    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: location.state.details.person_id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        let profileData = [];
        console.log(data.data);

        setPeoplePercentage(data.data.Customer_KYC_Status[0].people_percentage);
        setPeopleAddPercentage(
          data.data.Customer_KYC_Status[0].person_address_percentage
        );
        setPeopleContactPercentage(
          data.data.Customer_KYC_Status[0].person_contact_details_percentage
        );

        setcustomerPersonId(data.data.Customer_details[0].person_id);
        setFirstName(data.data.Customer_details[0].first_name);
        setLastName(data.data.Customer_details[0].last_name);
        setupdateLastName(data.data.Customer_details[0].last_name);
        setUpdateFirstName(data.data.Customer_details[0].first_name);
        console.log(data.data.Customer_contact_details);
        setCustomerContactDetails(data.data.Customer_contact_details);
        setCustomerAddress(data.data.Customer_address);
        setCustomerOccupation(data.data.Customer_details[0].occupation);
        setCustomerGender(data.data.Customer_details[0].gender);
        setCustomerMaritalStatus(data.data.Customer_details[0].marital_status);

        const date = data.data.Customer_details[0].date_of_birth;
        const parsedDate = parseISO(date);
        const formattedDate = format(parsedDate, "dd MMM yyyy");
        console.log(formattedDate)
        setCustomerDOB(formattedDate);
        // setDateOfBirth
        const createdDate = data.data.Customer_details[0].person_created_at;
        const createdParsedDate = parseISO(createdDate);
        const createdFormattedDate = format(createdParsedDate, "dd MMM yyyy");
        setCustomerCreatedDate(createdFormattedDate);
      });
  };

  const getCustomerContactDetails = async () => {
    await fetch(CUSTOMER_CONTACT_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: location.state.details.person_id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        const contact = [];
        contact.push(data.data);
        console.log(contact[0].Customer_contact_details);
        const mappedEmail = contact[0].Customer_contact_details.filter(
          (item) => item.contact_type === "email"
        );
        console.log(mappedEmail);
      });
  };

  const updateUserName = async (e) => {
    console.log("updateUserName");
    try {
      const response = await fetch(UPDATE_CUSTOMER_NAME, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          person_id: customerPersonId,
          first_name: updateFirstName,
          last_name: updateLastName,
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        getCustomerDetails();
        setModalOpen(false);
        setalertStatus("updateCustomerName");
        setCustomerNameUpdatetxt(data.message);
        setCustomerNameUpdateAlert(true);
        setmsgType("success");
      } else {
        getCustomerDetails();
        setModalOpen(false);
        setalertStatus("updateCustomerName");
        setCustomerNameUpdatetxt(data.message);
        setCustomerNameUpdateAlert(true);
        setmsgType("error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updatePhoneNumber = async (e) => {
    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: customerPhoneNumber,
        confirm_contact_details: customerConfirmPhoneNumber,
        person_contact_detail_id: customerContactDetailId,
        contact_type: "phone",
        contact_priority: contactPriority,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedPhoneNumber");
          setCustomerPhoneUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setCustomerPhoneNumber("");
          setCustomerConfirmPhoneNumber("");
          setmsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedPhoneNumber");
          setCustomerPhoneUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setCustomerPhoneNumber("");
          setCustomerConfirmPhoneNumber("");
          setmsgType("error");
        }
      });
  };

  const updateEmail = async (e) => {
    await fetch(EDIT_CUSTOMER_PHONENUMBER, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: customerEmail,
        person_contact_detail_id: customerEmailContactDetailId,
        contact_type: "email",
        contact_priority: contactPriority,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerEmail");
          setCustomerEmailUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setCustomerEmail("");
          setmsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerEmail");
          setCustomerEmailUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setCustomerEmail("");
          setmsgType("error");
        }
      });
  };

  const updateCustomerAddress = async (e) => {
    await fetch(EDIT_CUSTOMER_ADDRESS, {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        address_id: addressId,
        person_id: customerPersonId,
        street_address: streetAddress,
        city: city,
        state: state,
        zip_code: zip,
        country: country,
        address_type: "",
        plus_code: "ABC12312",
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerAddress");
          setCustomerAddressUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setmsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerAddress");
          setCustomerAddressUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setmsgType("error");
        }
      });
  };

  const updateCustomerProfile = async (e) => {
    await fetch(EDIT_CUSTOMER_PROFILE, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        date_of_birth: dateOfBirth,
        gender: updateCustomerGender,
        occupation: updateCustomerOccupation,
        marital_status: updateCustomerMaritalStatus,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setModalOpen(false);
          setalertStatus("updatedCustomerProfile");
          setCustomerProfileUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setmsgType("success");
        } else {
          setModalOpen(false);
          setalertStatus("updatedCustomerProfile");
          setCustomerProfileUpdatetxt(data.message);
          setCustomerNameUpdateAlert(true);
          getCustomerDetails();
          setmsgType("error");
        }
      });
  };

  const getAddresDetails = (item) => {
    console.log(item);
    setCountry(item.country);
    setState(item.state);
    setZip(item.zip_code);
    setCity(item.city);
    setstreetAddress(item.street_address);
    setAddressId(item.address_id);
  };

  const handleClose = () => {
    setModalOpen(false);
    setUpdateFirstName("");
    setupdateLastName("");
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setAddCustomerEmail("");
    setAddCustomerPhoneNumber("");
  };

  const handleAlertClose = () => {
    setCustomerNameUpdateAlert(false);
    setShowErrorAlert(false);
  };

  const handleAddAlertClose = () => {
    setCustomercontactAddAlert(false);
  };

  const totalPercentage =
    parseInt(peoplePercentage) +
    parseInt(peopleaddPercentage) +
    parseInt(peopleConPercentage);
  console.log(totalPercentage);

  const isProfileComplete = totalPercentage === 100;
  console.log(isProfileComplete);

  const AddPhoneNumber = async (e) => {
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: formatUsPhone(addCustomerPhoneNumber),
        contact_priority: getFilterPhoneData.length + 1,
        contact_type: "phone",
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerPhoneNumberAddtxt(data.message);
          setAddAlertStatus("addPhoneNumer");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerPhoneNumber("");
          setmsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerPhoneNumberAddtxt(data.message);
          setAddAlertStatus("addPhoneNumer");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerPhoneNumber("");
          setmsgType("error");
        }
      });
  };

  const AddEmail = async (e) => {
    await fetch(ADD_CUSTOMER_PHONENUMBER, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: customerPersonId,
        contact_details: addCustomerEmail,
        contact_type: "email",
        contact_priority: getFilterEmailData.length + 1,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerEmailAddtxt(data.message);
          setAddAlertStatus("addEmail");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setmsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerEmailAddtxt(data.message);
          setAddAlertStatus("addEmail");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setAddCustomerEmail("");
          setmsgType("error");
        }
      });
  };

  const AddAddress = async (e) => {
    const options = {
      person_id: customerPersonId,
      street_address: addStreetAdd,
      city: addCity,
      state: addState,
      zip_code: addZip,
      country: addCountry,
      address_type: "",
      plus_code: "ABC12312",
    };
    console.log(options);
    await fetch(ADD_CUSTOMER_ADDRESS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(options),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAddModalOpen(false);
          setCustomerAddressAddtxt(data.message);
          setAddAlertStatus("addAddress");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setmsgType("success");
        } else {
          setAddModalOpen(false);
          setCustomerAddressAddtxt(data.message);
          setAddAlertStatus("addAddress");
          setCustomercontactAddAlert(true);
          getCustomerDetails();
          setmsgType("error");
        }
      });
  };

  const clickEditNames = () => {
    setModalStatus("updateCustomerName");
    setModalOpen(true);
    setUpdateFirstName(firstName);
    setupdateLastName(lastName);
  };

  const clickEditEmail = (ccd) => {
    setModalStatus("updatedEmail");
    setModalOpen(true);
    setCustomerEmailContactDetailId(ccd.person_contact_detail_id);
    setCustomerEmail(ccd.contact_details);
    setContactPriority(ccd.contact_priority);
    setIsValidEmail(validateEmail(ccd.contact_details));
  };

  const clickAddEmail = () => {
    setAddModalStatus("addEmail");
    setAddModalOpen(true);
  };

  const validatePhone = (phoneNumber) => {
    console.log(phoneNumber)
    const phonePattern = /^\d{10}$/;
    console.log(phonePattern.test(phoneNumber))
    return phonePattern.test(phoneNumber);
  };


  const clickEditPhoneNumber = (ccd) => {
    console.log(ccd);
    setContactPriority(ccd.contact_priority);
    setModalStatus("updatedPhoneNumber");
    setModalOpen(true);
    setCustomerContactDetailId(ccd.person_contact_detail_id);
    console.log(ccd.contact_details);
    const usPhoneNumberFormate = formatUsPhone(ccd.contact_details);

    setIsValidPhone(formatUsPhone(ccd.contact_details));
    setCustomerPhoneNumber(usPhoneNumberFormate);
  };

  const clickAddPhoneNumber = () => {
    setAddModalStatus("addPhoneNumber");
    setAddModalOpen(true);
  };

  const clickAddressEdit = (item) => {
    getAddresDetails(item);
    setModalStatus("updateCustomerAddress");
    setModalOpen(true);
  };

  const clickAddAddress = () => {
    setAddModalStatus("addAddress");
    setAddModalOpen(true);
  };

  const clickPersonalinfoEdit = () => {
    setModalStatus("updateCustomerProfile");
    setModalOpen(true);
    setUpdateCustomerOccupation(customerOccupation);
    setUpdateCustomerGender(customerGender);
    console.log(customerDOB)
    setUpdateCustomerDOB(customerDOB);
    setDateOfBirth(customerDOB);
    setUpdateCustomerMaritalStatus(customerMaritalStatus);
  };

  function formatUsPhone(phone) {
    // Remove any non-numeric characters
    const numericPhone = phone.replace(/\D/g, "");

    // Apply the phone number format (XXX) XXX-XXXX
    const formattedPhone = numericPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedPhone;
  }

  const getProgressBarColor = (percent) => {
    console.log(percent);
    if (percent <= 25) {
      return "red";
    } else if (percent <= 50) {
      return "orange";
    } else if (percent <= 75) {
      return "yellow";
    } else {
      return "green";
    }
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const files = e.dataTransfer.files;
    handleFile(files[0]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      // Check if the selected file type is allowed
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        setuserImage(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Display an error message for unsupported file types
        setShowErrorAlert(true);
        setCheckAlertStatus(false);
        setAlertMessage(
          "Unsupported file type. Please upload a PNG or JPG file."
        );
      }
    }
  };

  const handleProfileModalClose = () => {
    setprofileModal(false);
  };
  console.log(location.state.details.person_id);
  const uploadCustomerProfileImage = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("person_id", location.state.details.person_id);
    formData.append("images", userImage);

    Axios.post(UPLOAD_CUSTOMER_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setprofileModal(false);
        sethandleUploadAlert(true);
        setuploadImageMsg(response.data.message);

        getUserProfile();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    fetch(GET_CUSTOMER_PROFILE_IMAGE, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
        "Content-Type": "application/json", // Set the content type to JSON
        token: token,
      },
      body: JSON.stringify({ person_id: location.state.details.person_id }), // Stringify the JSON data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          console.log(data.url);

          setProfileImageUrl(`${data.url}?timestamp=${Date.now()}`);
        } else {
          console.log(data);
          setProfileImageUrl("");
        }

        // Call your setimage function here if needed
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  const handleuploadPhotoAlertClose = () => {
    sethandleUploadAlert(false);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setCustomerEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleAddEmail = (e) => {
    if (e.target.value === "") {
      setIsValidEmail(false);
    }
    const newEmail = e.target.value;

    setAddCustomerEmail(e.target.value);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleEditPhoneNumber = (e) => {
    const newPhone = e.target.value;
    setCustomerPhoneNumber(newPhone);
    setIsValidPhone(validatePhone(newPhone));

  };

  const handleAddPhone = (e) => {
    console.log(e.target.value);
    const newPhone = e.target.value;
    setAddCustomerPhoneNumber(newPhone);
    setIsValidPhone(validatePhone(newPhone));
  };

  const handleDateOfBirthChange = (newvalue) => {
    console.log(newvalue.$d);
    const dateString = newvalue.$d.toISOString();
    setUpdateCustomerDOB(dateString)
  };

  return (
    <div
      className="customer_profile_start_div"
      style={{ backgroundColor: "white", width: "100%" }}
    >
      <Snackbar
        open={customerNameUpdateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {alertStatus === "updateCustomerName" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-name-update-alert"
          >
            <Typography className="common_font">
              {customerNameUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedPhoneNumber" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-phone-update-alert"
          >
            <Typography className="common_font">
              {customerPhoneUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerAddress" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-address-update-alert"
          >
            <Typography className="common_font">
              {customerAddressUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerEmail" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-email-update-alert"
          >
            <Typography className="common_font">
              {customerEmailUpdatetxt}
            </Typography>
          </Alert>
        ) : alertStatus === "updatedCustomerProfile" ? (
          <Alert
            onClose={handleAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-profile-update-alert"
          >
            <Typography className="common_font">
              {customerProfileUpdatetxt}
            </Typography>
          </Alert>
        ) : null}
      </Snackbar>

      <Snackbar
        open={customercontactAddAlert}
        onClose={handleAddAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {addAlertStatus === "addEmail" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-email-add-alert"
          >
            <Typography className="common_font">
              {customerEmailAddtxt}
            </Typography>
          </Alert>
        ) : addAlertStatus === "addPhoneNumer" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-phone-add-alert"
          >
            <Typography className="common_font">
              {customerPhoneNumberAddtxt}
            </Typography>
          </Alert>
        ) : addAlertStatus === "addAddress" ? (
          <Alert
            onClose={handleAddAlertClose}
            severity={msgType}
            sx={{ width: "100%" }}
            id="customer-address-add-alert"
          >
            <Typography className="common_font">
              {customerAddressAddtxt}
            </Typography>
          </Alert>
        ) : null}
      </Snackbar>
      <Snackbar
        open={handleUploadAlert}
        onClose={handleuploadPhotoAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleuploadPhotoAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">{uploadImageMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {checkAlertStatus ? (
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">{alertMessage}</Typography>
          </Alert>
        ) : (
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            <Typography className="common_font">{alertMessage}</Typography>
          </Alert>
        )}
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // hideBackdrop={true}
      >
        <>
          <Box className="customer_details_selector_modal common_card_bs common-display-style">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setModalOpen(false);
              }}
            />
            <div className="modal-sub-div">
              {modalStatus === "updateCustomerName" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Name
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          id="first-name-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="first-name"
                          >
                            First Name
                          </InputLabel>
                          <OutlinedInput
                            id="First Name"
                            label="First Name"
                            name="First Name"
                            value={updateFirstName}
                            onChange={(e) => setUpdateFirstName(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          id="last-name-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="last-name"
                          >
                            Last Name
                          </InputLabel>
                          <OutlinedInput
                            id="Last Name"
                            label="Last Name"
                            name="Last Name"
                            value={updateLastName}
                            onChange={(e) => setupdateLastName(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          id="first-last-name-button-id"
                          className="common-button common_font"
                          onClick={updateUserName}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : modalStatus === "updatedPhoneNumber" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PersonIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    Edit Customer Phone
                  </h3>
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        id="phone-Number"
                        className="common_font"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel
                          className="common_font"
                          htmlFor="Phone Number"
                        >
                          Phone Number
                        </InputLabel>
                        <OutlinedInput
                          id="Phone Number"
                          label="Phone Number"
                          name="Phone Number"
                          error={!isValidPhone}
                          value={formatUsPhone(customerPhoneNumber)}
                          onChange={handleEditPhoneNumber}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                            maxLength: 10,
                          }}
                        />
                        {!isValidPhone && (
                          <FormHelperText error>
                            Please enter a valid 10-digit phone number.
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      {isValidPhone ? (
                        <Button
                          id="phone-button-id"
                          className="common-button common_font"
                          onClick={() => {
                            updatePhoneNumber();
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          id="add-email-modal-id"
                          sx={{ opacity: "40%", cursor: "not-allowed" }}
                          className="common-button common_font"
                          onClick={AddEmail}
                          disabled
                          variant="contained"
                          type="button"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : modalStatus === "updatedEmail" ? (
                <Box className="">
                  <h3
                    style={{
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <PersonIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                    Edit Customer Email
                  </h3>
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        id="edit-customer-email"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel className="common_font" htmlFor="Email">
                          Email
                        </InputLabel>
                        <OutlinedInput
                          id="Email"
                          label="Email"
                          name="Email"
                          value={customerEmail}
                          onChange={handleEmailChange}
                          error={!isValidEmail}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        />
                        {!isValidEmail && (
                          <FormHelperText error>
                            Please enter a valid email address.
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      {isValidEmail ? (
                        <Button
                          id="Edit-customer-email-update-button"
                          className="common-button common_font"
                          onClick={() => {
                            updateEmail();
                          }}
                          variant="contained"
                          type="submit"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          id="Edit-customer-email-update-button"
                          className="common-button common_font"
                          sx={{ opacity: "40%", cursor: "not-allowed" }}
                          variant="contained"
                          type="button"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ) : modalStatus === "updateCustomerAddress" ? (
                <Box className="edit_address ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Address
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          id="street-address-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="street address"
                          >
                            Street Address
                          </InputLabel>
                          <OutlinedInput
                            id="street address"
                            label="street address"
                            name="street address"
                            value={streetAddress}
                            onChange={(e) => setstreetAddress(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl id="city-id" fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="city">
                            City
                          </InputLabel>
                          <OutlinedInput
                            id="city"
                            label="city"
                            name="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl id="state-id" fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="state">
                            State
                          </InputLabel>
                          <OutlinedInput
                            id="state"
                            label="state"
                            name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          id="country-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="country">
                            Country
                          </InputLabel>
                          <OutlinedInput
                            id="country"
                            label="country"
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl id="zip-id" fullWidth variant="outlined">
                          <InputLabel className="common_font" htmlFor="zip">
                            Zip Code
                          </InputLabel>
                          <OutlinedInput
                            id="zip"
                            label="Zip Code"
                            name="zip"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid item xs={12} className="address_submit_button">
                      <Button
                        id="address-button-id"
                        onClick={() => {
                          updateCustomerAddress();
                        }}
                        className="common-button common_font"
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              ) : modalStatus === "updateCustomerProfile" ? (
                <Box className="edit_address ">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <BusinessIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Edit Customer Profile
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          id="occupation-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="Occupation"
                          >
                            Occupation
                          </InputLabel>
                          <OutlinedInput
                            id="Occupation"
                            label="Occupation"
                            name="Occupation"
                            value={updateCustomerOccupation}
                            onChange={(e) =>
                              setUpdateCustomerOccupation(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          id="gender-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="Gender">
                            Gender
                          </InputLabel>
                          <OutlinedInput
                            id="Gender"
                            label="Gender"
                            name="Gender"
                            value={updateCustomerGender}
                            onChange={(e) =>
                              setUpdateCustomerGender(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid xs={12} md={12} lg={6} xl={6}>

                      </Grid>

                      <Grid item xs={12}>
                        <FormControl id="dob-id" fullWidth variant="outlined">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disableFuture
                              sx={{ margin: "0px !important" }}
                              className="common_font"
                              id="date-picker-add-customer"
                              label="D.O.B"
                              value={dayjs(dateOfBirth)}
                              onChange={getDateOfBirthFun}
                              inputFormat="MM-DD-YYYY"
                              toolbarFormat="MM-DD-YYYY"
                              slotProps={{
                                textField: {
                                  required: true,
                                  id: "ReportDate",
                                  className: "common_font",
                                  inputProps: {
                                    className: "common_font",
                                  },
                                  InputLabelProps: {
                                    className: "common_font",
                                  },
                                  InputLabel: {
                                    className: "common_font",
                                  },
                                },
                              }}
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          id="marital-status-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="MaritalStatus"
                          >
                            Marital Status
                          </InputLabel>
                          <OutlinedInput
                            id="Marital-Status"
                            label="Marital Status"
                            name="Marital Status"
                            value={updateCustomerMaritalStatus}
                            onChange={(e) =>
                              setUpdateCustomerMaritalStatus(e.target.value)
                            }
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid item xs={12} className="address_submit_button">
                      <Button
                        id="personal-information-button-id"
                        onClick={() => {
                          updateCustomerProfile();
                        }}
                        className="common-button common_font"
                        variant="contained"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              ) : null}
            </div>
          </Box>
        </>
      </Modal>
      {/* add modal  */}
      <Modal
        open={addModalOpen}
        onClose={() => {
          handleAddClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // hideBackdrop={true}
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <div className="customer-details-quotation-selector-modal-div"></div>
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setAddModalOpen(false);
                setAddCustomerEmail("");
                setAddCustomerPhoneNumber("");
              }}
            />
            <div className="modal-sub-div">
              {addModalStatus === "addEmail" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Email
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          id="add-email-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="email">
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="email"
                            label="Email"
                            name="Email"
                            value={addCustomerEmail}
                            onChange={handleAddEmail}
                            error={!validateEmail}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                          {!isValidEmail && (
                            <FormHelperText error>
                              Please enter a valid email address.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        {isValidEmail ? (
                          <Button
                            id="add-email-modal-id"
                            className="common-button common_font"
                            onClick={AddEmail}
                            variant="contained"
                            type="submit"
                          >
                            Add Email
                          </Button>
                        ) : (
                          <Button
                            id="add-email-modal-id"
                            sx={{ opacity: "40%", cursor: "not-allowed" }}
                            className="common-button common_font"
                            onClick={AddEmail}
                            disabled
                            variant="contained"
                            type="button"
                          >
                            Add Email
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : addModalStatus === "addPhoneNumber" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Phone Number
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel
                            className="common_font"
                            htmlFor="Phone Number"
                          >
                            Phone Number
                          </InputLabel>
                          <OutlinedInput
                            id="Phone Number"
                            label="Phone Number"
                            name="Phone Number"
                            value={formatUsPhone(addCustomerPhoneNumber)}
                            error={!isValidPhone}
                            onChange={handleAddPhone}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                              maxLength: 10,
                            }}
                          />
                          {!isValidPhone && (
                            <FormHelperText error>
                              Please enter a valid Phone Number.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        {isValidPhone ? (
                          <Button
                            id="add-phone-id"
                            className="common-button common_font"
                            onClick={AddPhoneNumber}
                            variant="contained"
                            type="submit"
                          >
                            Add Phone Number
                          </Button>
                        ) : (
                          <Button
                            id="add-email-modal-id"
                            sx={{ opacity: "40%", cursor: "not-allowed" }}
                            className="common-button common_font"
                            onClick={AddEmail}
                            disabled
                            variant="contained"
                            type="button"
                          >
                            Add Phone Number
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : addModalStatus === "addAddress" ? (
                <Box className="">
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                    className="update_username_main"
                  >
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      <PersonIcon
                        fontSize="medium"
                        sx={{ marginRight: "5px" }}
                      />
                      Add Address
                    </h3>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          id="add-street-address"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="Street Address"
                          >
                            Street Address
                          </InputLabel>
                          <OutlinedInput
                            id="Street Address"
                            label="Street Address"
                            name="Street Address"
                            value={addStreetAdd}
                            onChange={(e) => setAddStreetAdd(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          id="add-city-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="City">
                            {" "}
                            City
                          </InputLabel>
                          <OutlinedInput
                            id="City"
                            label="City"
                            name="City"
                            value={addCity}
                            onChange={(e) => setAddCity(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          id="add-state-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="State">
                            State{" "}
                          </InputLabel>
                          <OutlinedInput
                            id="State"
                            label="State"
                            name="State"
                            value={addState}
                            onChange={(e) => setAddState(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          id="add-country-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel className="common_font" htmlFor="Country">
                            Country{" "}
                          </InputLabel>
                          <OutlinedInput
                            id="Country"
                            label="Country"
                            name="Country"
                            value={addCountry}
                            onChange={(e) => setAddCountry(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          id="add-zip-code-id"
                          fullWidth
                          variant="outlined"
                        >
                          <InputLabel
                            className="common_font"
                            htmlFor="Zip Code"
                          >
                            Zip Code
                          </InputLabel>
                          <OutlinedInput
                            id="Zip Code"
                            label="Zip Code"
                            name="Zip Code"
                            value={addZip}
                            onChange={(e) => setAddZip(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          id="add-address-id"
                          className="common-button common_font"
                          onClick={AddAddress}
                          variant="contained"
                          type="submit"
                        >
                          Add Address
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null}
            </div>
          </Box>
        </>
      </Modal>
      {/* customer profile update  */}
      <Modal
        open={profileModal}
        onClose={handleProfileModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={uploadCustomerProfileImage}>
          <Box className="user-profile-change">
            <Box
              onClick={handleProfileModalClose}
              className="close-profile-modal"
            >
              <CloseIcon />
            </Box>
            <h3 className="common_font">Change Profile Photo</h3>
            <Box
              className={`drag-drop-image ${dragOver ? "drag-over" : ""}`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                <input
                  type="file"
                  id="upload-photo"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept={"image/png" || "image/jpeg" || "image/jpg"}
                />
                <Typography
                  className="common_font"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {userImage ? (
                    <img src={previewUrl} alt="Preview" width={90} />
                  ) : (
                    <img
                      src={uploadProfileImage}
                      alt="uploadProfileImage"
                      width={90}
                    />
                  )}
                  {userImage ? null : "Drag & Drop"}
                </Typography>
              </label>
            </Box>
            {userImage ? (
              <Typography className="common_font">{userImage.name} </Typography>
            ) : null}
            <Box className="upload-document">
              {userImage ? (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    className="common_font"
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Upload Photo
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="warning"
                    // onClick={() => setuserImage(null)}
                    onClick={() => setuserImage(null)}
                  >
                    Clear
                  </Button> */}
                </Box>
              ) : (
                <Button className="common_font" variant="contained" disabled>
                  Upload Photo
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Modal>
      <div className="customer_profile_main_div common_card_bs">
        <div className="customer_profile_div">
          {/* <Grid container spacing={2}> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={4}> */}
          <Box className="customer_profile_box">
            <div className="customer_profile_box_div_head customer_profile_box_div_header_email">
              <Typography className="common_font text-color">
                Your Profile
              </Typography>
              <Box className="customer_profile_box_div_head_box">
                <Typography className="customer_profile_box_div_head_box_typography common_font">
                  Created
                </Typography>
                <Typography className="common_font text-color">
                  {customerCreatedDate}
                </Typography>
              </Box>
            </div>

            <div
              className="customer_profile_box_div_middle"
              onClick={() => setprofileModal(true)}
            >
              {imageUrl ? (
                <img
                  src={`${imageUrl}?timestamp=${Date.now()}`}
                  className="customer_profile_box_div_head_img"
                  alt="userProfile"
                />
              ) : (
                <img
                  src={userProfile}
                  className="customer_profile_box_div_head_img"
                  alt="userProfile"
                />
              )}
            </div>
            <div className="customer_profile_box_div_body">
              <Typography className="customer_profile_box_div_body_typography common_font">
                {firstName} {lastName}
              </Typography>

              <CreateIcon
                id="update-name-icon"
                onClick={() => {
                  clickEditNames();
                }}
                sx={{ cursor: "pointer" }}
              />
            </div>
          </Box>
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={4}> */}
          <Box className="customer_profile_box_email">
            <div className="customer_profile_box_div_head_email customer_profile_box_div_header_email">
              <Typography className="common_font text-color">Emails</Typography>
            </div>
            <div className="customer_profile_box_div_body_email">
              <div className="email-div-height ">
                {getFilterEmailData.map((ccd, index) => {
                  console.log(ccd);
                  const isPrimary = index === 0;
                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="customer_profile_box_div_body_email_txt common_font"
                        >
                          {isPrimary ? (
                            <Typography className="customer_profile_box_div_body_email_typography   common_font">
                              Primary
                            </Typography>
                          ) : (
                            ""
                          )}
                          <Typography className="common_font">
                            {" "}
                            {ccd.contact_details}
                          </Typography>
                        </Box>
                        <CreateIcon
                          id={`edit-icon-${index}`}
                          onClick={() => {
                            clickEditEmail(ccd);
                          }}
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </Box>
                    </>
                  );
                })}
              </div>

              <Button
                id="add-email-id"
                onClick={() => {
                  clickAddEmail();
                }}
                className="customer_profile_box_div_body_email_button common_font"
              >
                Add Email
              </Button>
            </div>
          </Box>
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={4}> */}
          <Box className="customer_profile_box_email-2">
            <div className="customer_profile_box_div_head_email customer_profile_box_div_header_email">
              <Typography className="common_font text-color">
                Phone Numbers
              </Typography>
            </div>
            <div className="customer_profile_box_div_body_email">
              <div className="email-div-height ">
                {getFilterPhoneData.map((ccd, index) => {
                  console.log(ccd, index);
                  const isContactPrimary =
                    ccd.contact_priority === "1" || index === 0;

                  const PhoneNumberUpdatedDate = ccd.updated_at;
                  const PhoneNumberParsedDate = parseISO(
                    PhoneNumberUpdatedDate
                  );
                  let PhoneNumberFormattedDate = format(
                    PhoneNumberParsedDate,
                    "dd MMM yyyy"
                  );
                  console.log(ccd);

                  const usPhoneFormate = formatUsPhone(ccd.contact_details);
                  console.log(usPhoneFormate);

                  return (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                          className="customer_profile_box_div_body_email_txt common_font"
                        >
                          {isContactPrimary ? (
                            <Typography className="customer_profile_box_div_body_email_typography common_font">
                              Primary
                            </Typography>
                          ) : (
                            " "
                          )}
                          <span>{formatUsPhone(ccd.contact_details)}</span>
                        </Typography>
                        <CreateIcon
                          id={`update-phone-icon-${index}`}
                          onClick={() => {
                            clickEditPhoneNumber(ccd);
                          }}
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </Box>
                    </>
                  );
                })}
              </div>
              <Button
                id="add-email-id"
                onClick={() => {
                  clickAddPhoneNumber();
                }}
                className="customer_profile_box_div_body_email_button common_font"
              >
                Add Phone Number
              </Button>
            </div>
          </Box>

          <div className="customer_profile_div">
            <Box className="customer_profile_box_address">
              <div className="customer_profile_box_div_head_email customer_profile_box_div_header_email">
                <Typography className="common_font text-color">
                  Address
                </Typography>
              </div>

              <div className="customer_profile_box_div_body_email">
                <div className="address-div-height">
                  {customerAddress.map((item, index) => {
                    const isAddress = index === 0;
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                        className="customer_profile_box_div_body_email_txt common_font"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {isAddress ? (
                            <Typography className="customer_profile_box_div_body_email_typography common_font">
                              Primary
                            </Typography>
                          ) : (
                            " "
                          )}
                          <Typography
                            key={index}
                            sx={{
                              marginLeft: "10px",
                              marginTop: "10px !important",
                            }}
                            className="common_font"
                          >
                            {item.street_address}, {item.city}, {item.state},{" "}
                            {item.country}
                          </Typography>
                        </Box>
                        <CreateIcon
                          id={`create-icon-${index}`}
                          onClick={() => {
                            clickAddressEdit(item);
                          }}
                          sx={{ cursor: "pointer", marginRight: "10px" }}
                        />
                      </Box>
                    );
                  })}
                </div>

                <Button
                  id="add-address-button"
                  onClick={() => {
                    clickAddAddress();
                  }}
                  className="customer_profile_box_div_body_email_button common_font"
                >
                  Add Address
                </Button>
              </div>
            </Box>
          </div>

          <Box className="customer_profile_box_personal_information">
            <Box
              sx={{}}
              className="customer_profile_box_div_head_email customer_profile_box_div_head_personal_profile">
              <Typography className="common_font text-color">
                Personal Information
              </Typography>
              <CreateIcon
                id="edit-occupation-icon"
                onClick={() => {
                  clickPersonalinfoEdit();
                }}
                sx={{ cursor: "pointer" }}
              />
            </Box>

            <div style={{ textAlign: "start" }} className="customer_profile_box_div_body_email">
              <Typography
                sx={{ marginLeft: "10px", marginTop: "6px !important" }}
                className="customer_profile_box_div_body_address_typography common_font"
              >
                Occupation
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                className="customer_profile_box_div_body_address_txt common_font"
              >
                {customerOccupation}
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "6px !important" }}
                className="customer_profile_box_div_body_address_typography common_font"
              >
                Gender
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                className="customer_profile_box_div_body_address_txt common_font"
              >
                {customerGender}
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "6px !important" }}
                className="customer_profile_box_div_body_address_typography common_font"
              >
                Date of Birth
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                className="customer_profile_box_div_body_address_txt common_font"
              >
                {customerDOB}
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "6px !important" }}
                className="customer_profile_box_div_body_address_typography common_font"
              >
                Marital Status
              </Typography>

              <Typography
                sx={{ marginLeft: "10px", marginTop: "0px !important" }}
                className="customer_profile_box_div_body_address_txt common_font"
              >
                {customerMaritalStatus}
              </Typography>
            </div>
          </Box>

          <Box className="customer_profile_box_address">
            <div className="customer_profile_box_div_head_email customer_profile_box_div_header_email">
              <div className="customer_profile_box_div_head_email">
                <Typography className="common_font text-color">
                  Profile Info
                </Typography>
              </div>
            </div>
            <div className="customer_profile_box_div_body_email">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Box className="profile-progress-box1">
                  <Box className="profile-percent">
                    <Typography className="profile-percent common_font">
                      {peoplePercentage &&
                        peopleaddPercentage &&
                        peopleConPercentage
                        ? parseInt(peopleConPercentage) +
                        parseInt(peopleaddPercentage) +
                        parseInt(peoplePercentage)
                        : "00"}
                      %
                    </Typography>
                  </Box>
                </Box>

                <Box className="profile-progress-box2">
                  <Box className="progress-bar-container">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${parseInt(peoplePercentage) +
                          parseInt(peopleaddPercentage) +
                          parseInt(peopleConPercentage)
                          }%`,
                        background: getProgressBarColor(
                          parseInt(peoplePercentage) +
                          parseInt(peopleaddPercentage) +
                          parseInt(peopleConPercentage)
                        ),
                      }}
                    ></div>
                  </Box>
                  <Box mt={2}>
                    {isProfileComplete === true ? (
                      <Typography className="common_font">
                        Customer Profile is Up-to-Date.
                      </Typography>
                    ) : (
                      <Typography className="common_font">
                        Complete Customer Profile to Add Quotation.
                      </Typography>
                    )}

                    <Box mt={2} className="progress-info">
                      <Typography className="common_font" align="left">
                        Why is this Important?{" "}
                        <Tooltip
                          title="Is it mandatory to include email and contact details when generating a quotation."
                          placement="bottom"
                        >
                          <InfoOutlinedIcon fontSize="" />
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}

// Author: sonal


import { ADD_TO_CART, HIT_GET_USER_PROFILE, HIT_NOTIFICATION_FUN, REMOVE_FROM_CART } from "../actions/cartConstants";

export const cardReducerFun = (state = [], action) => {
    console.log(action)
    console.log(state)
    switch (action.type) {
        case ADD_TO_CART:
            return [...state, action.data]
        case REMOVE_FROM_CART:
            const foundIndex = state.findIndex((item) => item.name === action.data);

            if (foundIndex !== -1) {
                const updatedState = [...state.slice(0, foundIndex), ...state.slice(foundIndex + 1)];
                return updatedState;
            } else {
                return state;
            }
        default:
            return state
    }
}

export const notificationReducerFun = (state = false, action) => {
    console.log(action)
    console.log(state)
    switch (action.type) {
        case HIT_NOTIFICATION_FUN:
            return state = action.data
        default:
            return state
    }
}
export const getUserProfileImageRedux = (state=false , action) => {
    console.log(action)
    console.log(state)
    switch (action.type) {
        case HIT_GET_USER_PROFILE:
            return state = action.data
        default:
            return state
    }
}


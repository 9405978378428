import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Typography } from "@mui/material";
import "../../Styles/admindashboard.css";

export default function AdminStatus() {
  return (
    <div>
      <h1 className="common_font">Status</h1>
      <Carousel>
        <div >
          <Typography variant="h5" style={{ color: "#19b674" }}>
            Users
          </Typography>
          <center>
            {" "}
            <p
              style={{
                background: "#19b674",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                paddingTop: "15px",
                fontWeight: "700",
                color: "#ffffff",
              }}
            >
              50%
            </p>
          </center>

          <p style={{ color: "#19b674", height: "100px" }}>
            Joined <b>50,000</b> Users <br />
            To start a blog, think of a topic about and first brainstorm ways to
            write details
          </p>
        </div>
        <div>
          <Typography variant="h5" style={{ color: "#f1bc00" }}>
            Products
          </Typography>
          <center>
            {" "}
            <p
              style={{
                background: "#f1bc00",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                paddingTop: "15px",
                fontWeight: "700",
                color: "#ffffff",
              }}
            >
              40%
            </p>
          </center>
          <p style={{ color: "#f1bc00" }}>
            Added <b>40,000</b> Products <br />
            To start a blog, think of a topic about and first brainstorm ways to
            write details
          </p>
        </div>
        <div>
          <Typography variant="h5" style={{ color: "#0098da" }}>
            Sales
          </Typography>
          <center>
            {" "}
            <p
              style={{
                background: "#0098da",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                paddingTop: "15px",
                fontWeight: "700",
                color: "#ffffff",
              }}
            >
              80%
            </p>
          </center>
          <p style={{ color: "#0098da" }}>
            Reached <b>80,000</b> Sales <br />
            To start a blog, think of a topic about and first brainstorm ways to
            write details
          </p>
        </div>
      </Carousel>
    </div>
  );
}

// Author: sonal

import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Layout = ({ customFooter = false }) => {
  const location = useLocation();
  const usertype = localStorage.getItem("usertype");
  const [open, setOpen] = useState(false);
  const [initialPath, setInitialPath] = useState(null);
  const [updateBodyClass, setUpdateBodyClass] = useState("");
  const [updateFooterClass, setUpdateFooterClass] = useState("");
  const userId = localStorage.getItem("user_id")
  const siteName = localStorage.getItem("site_name")
  const [opensnackbar, setOpenSnackbar] = React.useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  let sessionExpireStatus = false;
  const handleAlertClose = () => {
    setOpenSnackbar(false);
  };

  const [imageVal, setImageVal] = useState("")
  const valuFun = (getImage) => {
    console.log(getImage)
    setImageVal(getImage)
  }


  useEffect(() => {
    // Store the initial path when the component mounts
    setInitialPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (siteName === "Goldspot") {
      setUpdateBodyClass("company-bdx-body")
      setUpdateFooterClass("company-bdx-footer footer_position")
    }
    else if (siteName === "Pen House") {
      setUpdateBodyClass("company-bdx-body")
      setUpdateFooterClass("company-bdx-footer footer_position")
    }
    else {
      setUpdateBodyClass("company-bsa-body")
      setUpdateFooterClass("company-bsa-footer footer_position")
    }

  }, [siteName])
  // useEffect(()=>{
  //   console.log(sessionExpireStatus)
  //   if(sessionExpireStatus){
  //     setOpenSnackbar(true)
  //     setSnackMsg("Session Timeout... Please Relogin")
  //   }
  //  },[sessionExpireStatus])
  // Function to check token validity
  //   useEffect(()=>{
  //    console.log("calling")
  //     const token = localStorage.getItem('token');


  // })
  // Function to check if token is expired
  const isTokenExpired = (token) => {
    const decodedToken = decodeToken(token);
    return decodedToken.exp < Date.now() / 1000;
  };

  // Function to decode token
  const decodeToken = (token) => {
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } catch (error) {
      return {};
    }
  };
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    console.log(token)
    console.log(isTokenExpired(token))
    if (isTokenExpired(token)) {
      sessionExpireStatus = true
    }
    return token && !isTokenExpired(token);
  };

  // Check authentication before rendering Outlet
  if (!isAuthenticated()) {
    // Redirect to login if not authenticated
    localStorage.setItem("lastLocation", location.pathname);
    return <Navigate to="/login" replace />;
  }



  // // Function to redirect to login
  // function redirectToLogin() {
  //   window.location.href = '/login'; // Change '/login' to your application's login route
  // }
  //   window.addEventListener('focus', async () => {
  //     // Check token validity and refresh if necessary
  //     const validToken = await checkTokenValidity();
  //     if (!validToken) {
  //       redirectToLogin();
  //     }
  //   });



  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      className={updateBodyClass}

    >
      <Header getImage={imageVal} onOpenNav={() => setOpen(true)} />
      <StyledRoot>
        <Sidebar propsFun={valuFun} openNav={open} onCloseNav={() => setOpen(false)} />
        <Main
          sx={{
            paddingLeft: "20px !important",
            paddingRight: "20px !important",
            paddingBottom: "50px !important",
            paddingTop: "0px !important",
          }}

        >
          <Outlet />
          <Snackbar
            open={opensnackbar}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            className="snackBar-alert"
          >
            <Alert
              onClose={handleAlertClose}
              severity="success"
              sx={{ width: "100%" }}
              className="common-success-alert"
              id="welcome-msg"
            >
              <Typography id="welcome-msg" className="common_font">
                {snackmsg}
              </Typography>
            </Alert>
          </Snackbar>
          <div className={updateFooterClass}>
            <Footer />
          </div>
        </Main>
      </StyledRoot>
    </div>
  );
};

export default Layout;

import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Divider from "@mui/material/Divider";
import MessageIcon from "@mui/icons-material/Message";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import SearchIcon from "@mui/icons-material/Search";
import CompanyImage from "../../assets/img/Company1.png";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import userImage from "../../assets/img/user.jpg";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import LockResetIcon from "@mui/icons-material/LockReset";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  TWO_FA_STATUS,
  REGISTER_DEVICE,
  CHECK_TWO_FA_STATUS,
  GET_USER_NOTIFICATION_LIST,
  DELETE_USER_NOTIFICATION,
  UPDATE_USER_NOTIFICATION,
  SIGNOUT,
  GET_CUSTOMER_PROFILE_IMAGE,
  VERIFY_TOKEN,
  IDLE_SESSION_TIMEOUT,
} from "../../config/config";
import Modal from "@mui/material/Modal";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LanguageIcon from "@mui/icons-material/Language";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Tooltip from "@mui/material/Tooltip";
import "../../Styles/header.css";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import useTokenValidation from "../auth/login/useTokenValidation";
import { useIdleTimer } from "react-idle-timer";
const ITEM_HEIGHT = 40;

const moment = require("moment-timezone");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = ({ onOpenNav }) => {
  useTokenValidation();
  const location = useLocation();

  const navigate = useNavigate();
  const user_org = localStorage.getItem("user_org");
  const orgnizationUser = JSON.parse(user_org);

  const { i18n, t } = useTranslation();
  const { myProfile, logout } = t("header");
  const selector = useSelector((state) => state.notificationReducer);
  const reloadUserImage = useSelector((state) => state.getUserProfileRedux);

  const [myProfileAnchorEl, setMyProfileAnchorEl] = useState(null);
  const menuId = "primary-search-account-menu";
  const [twoFASecretStatus, setTwoFASecretStatus] = useState(false);
  const [userdata, setUserData] = useState({});
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [checkAlertStatus, setCheckAlertStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [value, setValue] = React.useState("unread");
  const [showDisableAlert, setShowDisableAlert] = useState(false);
  const [checkDisableAlertStatus, setCheckDisableAlertStatus] = useState(false);
  const [disableAlertMsg, setDisableAlertMsg] = useState("");
  const [formateddate, setFormatData] = useState("");
  const [userNotificationlist, setUserNotificationlist] = useState([]);
  const [userUnreadNotificationlist, setUserUnreadNotificationlist] = useState(
    []
  );
  const [userReadNotificationlist, setUserReadNotificationlist] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState("all");
  const [disableBackButton, setDisableBackButton] = useState(false);

  const [status, setStatus] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const onIdle = () => {
    setStatus("Idle");
  };

  const onActive = () => {
    setStatus("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: IDLE_SESSION_TIMEOUT,
    throttle: 500,
  });

  useEffect(() => {
    if (status === "Idle") {
      const token = localStorage.getItem("token");
      try {
        const response = fetch(VERIFY_TOKEN, {
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.error === "jwt expired") {
              navigate("/login");
            }
          });

        // return response.ok; // returns true if the status code is 200-299
      } catch (error) {
        console.error("Error checking token validity:", error);
        return false;
      }
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });
  const myProfileAHandleClick = (event) => {
    setMyProfileAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const myProfileAHandleClose = () => {
    setMyProfileAnchorEl(null);
  };

  const myProfileOpen = Boolean(myProfileAnchorEl);
  const id = myProfileOpen ? "simple-popover" : undefined;

  const [modalOpen, setModalOpen] = React.useState(false);

  const [imageUrl, setProfileImageUrl] = useState();
  // console.log(imageUrl)

  const iconButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        iconButtonRef.current &&
        !iconButtonRef.current.contains(event.target)
      ) {
        setOpenProfile(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleAlertClose = () => {
    setShowErrorAlert(false);
  };

  const handleDisableTWOFAAlertClose = () => {
    setShowDisableAlert(false);
  };

  const loggedInUser = localStorage.getItem("user");

  let foundUser = JSON.parse(loggedInUser);
  const token = localStorage.getItem("token");

  const handleMenuClose = () => {
    setMyProfileAnchorEl(null);
    navigate(`${lng}/dashboard/user-profile`, {
      state: {
        person_id: foundUser.person_id,
      },
    });
  };

  useEffect(() => {
    if (loggedInUser) {
      setUserData(foundUser);
      fetch(CHECK_TWO_FA_STATUS, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          userId: foundUser?.person_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            setTwoFASecretStatus(data.data[0]?.two_factor);
          }
        });
    }
  }, []);

  const handleDisableTwoFA = () => {
    setOpenProfile(false);
    const loggedInUser = localStorage.getItem("user");

    let foundUser;
    if (loggedInUser) {
      foundUser = JSON.parse(loggedInUser);
      fetch(TWO_FA_STATUS, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          email: foundUser.user_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            setTwoFASecretStatus(false);
            setShowDisableAlert(true);
            setDisableAlertMsg(data.message);
            setCheckDisableAlertStatus(true);
          } else {
            setShowDisableAlert(true);
            setDisableAlertMsg("Something went wrong! Please retry");
            setCheckDisableAlertStatus(false);
          }
        });
    }
  };

  const handleEnableTwoFA = async () => {
    setOpenProfile(false);
    await fetch(REGISTER_DEVICE, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        userdata: userdata,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setShowErrorAlert(true);
          setCheckAlertStatus(true);
          setAlertMessage("Email sent successfully!");
        } else {
          setShowErrorAlert(true);
          setCheckAlertStatus(false);
          setAlertMessage("Something went wrong! Please retry");
        }
      });
  };

  const getUserNotificationList = async () => {
    await fetch(GET_USER_NOTIFICATION_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setUserNotificationlist(data.data.user_notification);
          let readMsg = [];
          let unReadMsg = [];
          let getNtfctList = data.data.user_notification;
          getNtfctList.map((ele) => {
            if (ele.is_read === false) {
              unReadMsg.push(ele);
            } else {
              readMsg.push(ele);
            }
          });
          setUserUnreadNotificationlist(unReadMsg);
          setUserReadNotificationlist(readMsg);
        }
      });
  };

  const updateUserNotification = async (notification_id) => {
    await fetch(UPDATE_USER_NOTIFICATION, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        notification_id: notification_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setTimeout(() => {
            getUserNotificationList();
          }, [1200]);
        }
      });
  };

  const deleteUserNotification = async (notification_id) => {
    await fetch(DELETE_USER_NOTIFICATION, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        notification_id: notification_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          getUserNotificationList();
        }
      });
  };

  const [lng, setLng] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLng(lng);
    languageChangeFun(lng);
  };

  useEffect(() => {
    console.log(location.pathname);
    if (
      location.pathname.includes("/hi") ||
      location.pathname.includes("/en")
    ) {
      if (location.pathname.includes("/en")) {
        i18n.changeLanguage("en");
        setLng("en");
      } else {
        i18n.changeLanguage("hi");
        setLng("hi");
      }
    }
  }, []);

  //Side Drawer
  const [state, setState] = React.useState(false);

  const toggleDrawerClose = () => {
    setState(false);
  };

  const languageChangeFun = (lng) => {
    navigate(`/${lng}/dashboard`);
  };

  useEffect(() => {
    if (
      location.pathname === "/en/dashboard" ||
      location.pathname === "/hi/dashboard"
    ) {
      setDisableBackButton(true);
    } else {
      setDisableBackButton(false);
    }
  }, [location.pathname]);

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    if (
      location.pathname === "/en/dashboard" ||
      location.pathname === "/hi/dashboard"
    ) {
      setDisableBackButton(true);
    } else {
      navigate(-1);
      setDisableBackButton(false);
    }
  };

  useEffect(() => {
    if (selector === true) {
      getUserNotificationList();
    }
  }, [selector]);

  // useEffect(() => {
  //   console.log(reloadUserImage);
  //   if (reloadUserImage === true) {
  //     getUserProfile();
  //   }
  // }, []);

  useEffect(() => {
    getUserNotificationList();
    getUserProfile();

  }, []);

  const handleLogout = () => {
    fetch(SIGNOUT, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data?.responseData?.error === false) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          const logoutChannel = new BroadcastChannel("logout_channel");
          logoutChannel.postMessage("logout");

          navigate("/login");
        }
      });
  };

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".open_profile_popup_box")) {
      setOpenProfile(false);
    }
  };

  function formatLocalDate() {
    const userTimeZone = moment.tz.guess();
    const todayDate = moment.tz(userTimeZone);
    const formattedDate = todayDate.format("dddd,  DD MMM YYYY, HH:mm ");
    setFormatData(formattedDate);
  }

  useEffect(() => {
    formatLocalDate();
    const interval = setInterval(formatLocalDate, 60000);
    return () => clearInterval(interval);
  }, []);

  //get profile image
  const getUserProfile = () => {
    const token = localStorage.getItem("token");
    fetch(GET_CUSTOMER_PROFILE_IMAGE, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({ person_id: foundUser?.person_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setProfileImageUrl(`${data.url}?timestamp=${Date.now()}`);
        } else {
          setProfileImageUrl("");
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box onClick={handleOutsideClick}>
        <Drawer
          open={state}
          onClose={toggleDrawerClose}
          role="presentation"
          anchor="right"
        >
          <Box className="notification-drawer-bg">
            <Box className="notification-header-main">
              <h2 className="Notification-heading common_font">
                Notifications{" "}
              </h2>
              <Typography
                sx={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => setState(false)}
              >
                <CloseIcon />
              </Typography>
            </Box>
            <Divider />
            <Box className="common_font notification-drawer-navigation-box">
              <BottomNavigation
                className="common_font notification-drawer-navigation-bg"
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                sx={{ justifyContent: "space-around !important" }}
              >
                <BottomNavigationAction
                  onClick={() => setNotificationStatus("unread")}
                  label="Unread"
                  // value="unread"
                  className="common_font"
                  icon={<MarkUnreadChatAltIcon />}
                  id="unread-notifications"
                  InputLabelProps={{
                    className: "common_font",
                  }}
                  inputProps={{
                    className: "common_font",
                  }}
                />
                <BottomNavigationAction
                  onClick={() => setNotificationStatus("all")}
                  label="All"
                  icon={<MessageIcon />}
                  className="common_font"
                  // value="all"
                  id="all-notifications"
                  InputLabelProps={{
                    className: "common_font",
                  }}
                  inputProps={{
                    className: "common_font",
                  }}
                />
              </BottomNavigation>
            </Box>
            <Divider />
            <Box className="notification-main-box">
              {notificationStatus === "unread" ? (
                <>
                  {userUnreadNotificationlist.map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        className="slider"
                        sx={{ paddingLeft: "0px !important" }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Box
                              className="notification-card"
                              id="notification-card"
                            >
                              {item.name === "Password Changed" ? (
                                <LockResetIcon className="common-notification-icon" />
                              ) : item.name === "Quotation Created" ? (
                                <RequestPageIcon className="common-notification-icon" />
                              ) : (
                                <NotificationsActiveIcon className="common-notification-icon" />
                              )}

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "340px",
                                  marginLeft: "10px",
                                }}
                              >
                                <Typography
                                  onMouseOver={() =>
                                    updateUserNotification(item.notification_id)
                                  }
                                  sx={{ color: "black", marginBottom: "10px" }}
                                  className="common_font"
                                >
                                  {item.message}
                                </Typography>
                              </Box>

                              <Divider />
                            </Box>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </>
              ) : notificationStatus === "all" ? (
                <>
                  {userNotificationlist.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        paddingLeft: "0px !important",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            display: "flex !important",
                            alignItems: "center !important",
                          }}
                        >
                          <Box
                            className="notification-card common_card_bs"
                            id="notification-card"
                          >
                            {item.name === "Password Changed" ? (
                              <LockResetIcon className="common-notification-icon" />
                            ) : item.name === "Quotation Created" ? (
                              <RequestPageIcon className="common-notification-icon" />
                            ) : (
                              <NotificationsActiveIcon className="common-notification-icon" />
                            )}

                            <Box className="notification-unread-box">
                              <Typography sx={{ marginRight: "10px" }}>
                                {formattimeStampMoment(item.created_at)}
                              </Typography>

                              {item.is_read === false ? (
                                <Typography className="notification-unread-txt common_font">
                                  New
                                </Typography>
                              ) : null}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                width: "340px",
                                marginLeft: "0px",
                                marginTop: "20px",
                                height: "50px",
                              }}
                            >
                              <Typography
                                sx={{ color: "black", marginBottom: "10px" }}
                                className="common_font"
                              >
                                {item.message}
                              </Typography>
                            </Box>
                            <Typography>
                              <DeleteIcon
                                onClick={() => {
                                  deleteUserNotification(item.notification_id);
                                }}
                              />
                            </Typography>
                            <Divider />
                          </Box>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>

      <Snackbar
        open={showErrorAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {checkAlertStatus ? (
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            {alertMessage}
          </Alert>
        ) : (
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%" }}
            className="common-error-alert"
          >
            {alertMessage}
          </Alert>
        )}
      </Snackbar>

      <Snackbar
        open={showDisableAlert}
        onClose={handleDisableTWOFAAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {checkDisableAlertStatus ? (
          <Alert
            onClose={handleDisableTWOFAAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            {disableAlertMsg}
          </Alert>
        ) : (
          <Alert
            onClose={handleDisableTWOFAAlertClose}
            severity="error"
            sx={{ width: "100%" }}
            className="common-error-alert"
          >
            {disableAlertMsg}
          </Alert>
        )}
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={handleLanguageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="header_selector_modal">
          <Box>
            <Typography
              onClick={() => {
                changeLanguage("en");
                handleLanguageClose();
              }}
              className="open_profile_popup_typography common_font"
            >
              English
            </Typography>
            <hr />
            <Typography
              onClick={() => {
                changeLanguage("hi");
                handleLanguageClose();
              }}
              className="open_profile_popup_typography common_font"
            >
              Hindi
            </Typography>
          </Box>
        </Box>
      </Modal>

      <div className="header_main_div">
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box className="header-sub-div">
          <Box className="date-and-time">
            {!disableBackButton ? (
              <ArrowCircleLeftOutlinedIcon
                onClick={handleBackButtonClick}
                fontSize="large"
                className="back-button"
              />
            ) : (
              <ArrowCircleLeftOutlinedIcon
                fontSize="large"
                className="back-button"
                sx={{ opacity: "40%", cursor: "not-allowed" }}
              />
            )}
            <Typography className="common_font header-date">
              {formateddate}
            </Typography>
          </Box>
          <Box
            className="pen-company"
            onClick={() => navigate(`/${lng}/company-details`)}
          >
            <Typography className="common_font">
              <img src={CompanyImage} width={50} alt="company-name" />
            </Typography>
            <Typography className="company-name common_font">
              {orgnizationUser?.site_name}
            </Typography>
          </Box>

          <Box className="icons-list ">
            <Tooltip title="Search Products">
              <IconButton
                tton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => {
                  navigate("/search-products");
                }}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip className="common_font" title="Create Product">
              <IconButton
                tton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => {
                  navigate("/create-product");
                }}
              >
                <AddBoxIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip className="common_font" title="Create Quote">
              {" "}
              <IconButton
                id="add-quotations"
                tton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => {
                  navigate(`/${lng}/dashboard/customer-list/customer-details`);
                }}
              >
                <i
                  id="add-quotations"
                  class="fa-solid fa-file-invoice-dollar"
                  style={{
                    fontSize: "20px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <IconButton
              tton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <LanguageIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={() => setState(true)}
            >
              <Badge
                badgeContent={userUnreadNotificationlist.length}
                color="error"
              >
                <NotificationsIcon id="notificatin-icon" on />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={myProfileAHandleClick}
            >
              <img
                src={
                  imageUrl ? `${imageUrl}}` : userImage
                }
                style={{ width: "30px", width: "30px", borderRadius: "50%" }}
                alt=""
              />
            </IconButton>

            <Popover
              id={id}
              open={myProfileOpen}
              anchorEl={myProfileAnchorEl}
              onClose={myProfileAHandleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box className="open_profile_popup_box">
                <Box className="userIamge-box">
                  <img
                    src={
                      imageUrl
                        ? `${imageUrl}}`
                        : userImage
                    }
                    style={{
                      width: "60px",
                      width: "60px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />

                  <Typography className="common_font" sx={{ marginTop: "5px" }}>
                    {orgnizationUser?.first_name +
                      " " +
                      orgnizationUser?.last_name}{" "}
                    -{" "}
                    {orgnizationUser?.user_type === "User"
                      ? "Sales Rep"
                      : "Admin"}
                  </Typography>
                  <Typography className="common_font">
                    <b>{orgnizationUser?.site_name}</b>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    // className="my-profile-button common_font"
                    className="common-button common_font"
                    onClick={handleMenuClose}
                  >
                    {myProfile}
                  </Button>

                  <Button
                    // className="my-profile-button common_font"
                    className="common-button common_font"
                    onClick={handleLogout}
                  >
                    {logout}
                  </Button>
                </Box>
              </Box>
            </Popover>

            <Menu
              sx={{ marginTop: "40px !important" }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={menuId}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
              {twoFASecretStatus ? (
                <MenuItem onClick={handleDisableTwoFA}>Disable 2FA</MenuItem>
              ) : (
                <MenuItem onClick={handleEnableTwoFA}>Enable 2FA</MenuItem>
              )}
            </Menu>
          </Box>
          <div className="menu-icons">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "31ch",
                },
              }}
            >
              <MenuItem>
                <Tooltip className="common_font" title="Search Products">
                  <IconButton
                    id="search-products"
                    tton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => {
                      navigate("/search-products");
                      setAnchorEl(null);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip className="common_font" title="Create Product">
                  <IconButton
                    tton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => {
                      navigate("/create-product");
                      setAnchorEl(null);
                    }}
                  >
                    <AddBoxIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip className="common_font" title="Create Quote">
                  {" "}
                  <IconButton
                    tton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={() => {
                      navigate(
                        `/${lng}/dashboard/customer-list/customer-details`
                      );
                      setAnchorEl(null);
                    }}
                  >
                    <i
                      class="fa-solid fa-file-invoice-dollar"
                      style={{
                        fontSize: "20px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <IconButton
                  tton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => {
                    setModalOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  <LanguageIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={() => {
                    setState(true);
                    setAnchorEl(null);
                  }}
                >
                  <Badge
                    badgeContent={userUnreadNotificationlist.length}
                    color="error"
                  >
                    <NotificationsIcon id="notificatin-icon" on />
                  </Badge>
                </IconButton>

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                  // onClick={profileFun}
                  onClick={myProfileAHandleClick}
                >
                  <AccountCircle />
                </IconButton>

                <Popover
                  id={id}
                  open={myProfileOpen}
                  anchorEl={myProfileAnchorEl}
                  onClose={myProfileAHandleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box className="open_profile_popup_box">
                    <Box className="userIamge-box">
                      <img
                        id="user-image"
                        src={userImage}
                        width={70}
                        className="user-image"
                        alt="userimage"
                      />
                      <Typography
                        className="common_font"
                        sx={{ marginTop: "5px" }}
                      >
                        {orgnizationUser?.first_name +
                          " " +
                          orgnizationUser?.last_name}{" "}
                        -{" "}
                        {orgnizationUser?.user_type === "User"
                          ? "Sales Rep"
                          : "Admin"}
                      </Typography>
                      <Typography className="common_font">
                        <b>{orgnizationUser?.site_name}</b>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="my-profile-button common_font"
                        onClick={handleMenuClose}
                      >
                        My Profile
                      </button>

                      <button
                        className="my-profile-button common_font"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </Box>
                  </Box>
                </Popover>
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </div>
    </>
  );
};

export default Header;

import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "../../Styles/payment.css";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import PaymentOptions from "./PaymentOptions";
export default function Payment() {
  const getProductDetails = useSelector((state) => state.cardReducer);
  console.log(getProductDetails);

  const [paymentLogin, setPaymentLogin] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [orderSummary, setOrderSummary] = useState(false);
  const [paymentOption, setPaymentOption] = useState(false);

  return (
    <div>
      {paymentLogin ? (
        <Card>
          <CardContent
            className=""
            sx={{ padding: "0px !important", marginBottom: "20px" }}
          >
            <Box
              className="payment_card_content_cndt"
              sx={{ backgroundColor: "#7eb3e2", height: "50px" }}
            >
              <Typography className="payment_card_1_box_primary_txt_cndt">
                {" "}
                <span className="payment_card_1_span_cndt">1</span> LOGIN OR
                SIGNUP <CheckIcon className="payment_card_1_icon" />{" "}
              </Typography>
            </Box>

            <Box className="payment_card_box_2_cndt">
              <TextField
                className="payment_card_box_2_textfield_cndt"
                id="standard-basic"
                label="Enter Email/Mobile number"
                variant="standard"
              />
              <Button
                className="payment_card_box_2_button_cndt"
                variant="contained"
              >
                continue
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ padding: "0px !important" }}>
          <CardContent className="payment_card_content" sx={{}}>
            <Box className="payment_card_1_box">
              <Typography className="payment_card_1_box_primary_txt">
                {" "}
                <span className="payment_card_1_span">1</span> LOGIN{" "}
                <CheckIcon className="payment_card_1_icon" />{" "}
              </Typography>
              <Typography className="payment_card_1_box_secondary_txt">
                Username
                {"  "}example@bodex.io
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setPaymentLogin(true);
              }}
              className="payment_card_1_button"
            >
              CHANGE
            </Box>
          </CardContent>
        </Card>
      )}

      {deliveryAddress ? (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent
            className=""
            sx={{ padding: "0px !important", marginBottom: "20px" }}
          >
            <Box
              className="payment_card_content_cndt"
              sx={{ backgroundColor: "#7eb3e2", height: "50px" }}
            >
              <Typography className="payment_card_1_box_primary_txt_cndt">
                {" "}
                <span className="payment_card_1_span_cndt">2</span> DELIVERY
                ADDRESS
                <CheckIcon className="payment_card_1_icon" />{" "}
              </Typography>
            </Box>

            <Box className="payment_card_box_2_cndt">
              <Box sx={{}}>
                <TextField
                  sx={{ marginRight: "50px" }}
                  className="payment_card_box_2_textfield_cndt"
                  id="standard-basic"
                  label="Enter Area/Street"
                  variant="standard"
                />
                <TextField
                  className="payment_card_box_2_textfield_cndt"
                  id="standard-basic"
                  label="Enter City"
                  variant="standard"
                />
              </Box>
              <Box>
                <TextField
                  sx={{ marginRight: "50px" }}
                  className="payment_card_box_2_textfield_cndt"
                  id="standard-basic"
                  label="Enter State"
                  variant="standard"
                />
                <TextField
                  className="payment_card_box_2_textfield_cndt"
                  id="standard-basic"
                  label="Enter Pincode"
                  variant="standard"
                />
              </Box>
              <Button
                className="payment_card_box_2_button_cndt"
                variant="contained"
              >
                add address
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent className="payment_card_content" sx={{}}>
            <Box className="payment_card_1_box">
              <Typography className="payment_card_1_box_primary_txt">
                {" "}
                <span className="payment_card_1_span">2</span>DELIVERY ADDRESS{" "}
                <CheckIcon className="payment_card_1_icon" />
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setDeliveryAddress(true);
              }}
              className="payment_card_1_button"
            >
              CHANGE
            </Box>
          </CardContent>
        </Card>
      )}

      {orderSummary ? (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent
            className=""
            sx={{ padding: "0px !important", marginBottom: "20px" }}
          >
            <Box
              className="payment_card_content_cndt"
              sx={{ backgroundColor: "#7eb3e2", height: "50px" }}
            >
              <Typography className="payment_card_1_box_primary_txt_cndt">
                {" "}
                <span className="payment_card_1_span_cndt">3</span> ORDER
                SUMMARY
                <CheckIcon className="payment_card_1_icon" />{" "}
              </Typography>
            </Box>

            <Box className="payment_card_box_2_cndt">
              <Box>
                {getProductDetails.map((product) => {
                  return (
                    <div className="order_summary_img_desc_div">
                      <img
                        className="order_summary_img"
                        src={product.image}
                        alt=""
                      />
                      <Box className="order_summary_img_desc_box">
                        <Typography>{product.name} || 0.55mm</Typography>
                        <Typography>${product.price}</Typography>
                        <Typography>Blue Color || Pack of 5</Typography>
                      </Box>
                    </div>
                  );
                })}
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent className="payment_card_content" sx={{}}>
            <Box className="payment_card_1_box">
              <Typography className="payment_card_1_box_primary_txt">
                {" "}
                <span className="payment_card_1_span">3</span>ORDER SUMMARY{" "}
                <CheckIcon className="payment_card_1_icon" />
              </Typography>
            </Box>
            <Box
              onClick={() => setOrderSummary(true)}
              className="payment_card_1_button"
            >
              CHANGE
            </Box>
          </CardContent>
        </Card>
      )}

      <PaymentOptions />
    </div>
  );
}

import React, { useEffect,useState } from 'react';
import { useNavigate,Redirect  } from 'react-router-dom';
import { VERIFY_TOKEN } from "../../../config/config";
const useTokenValidation = () => {
  const navigate = useNavigate();


  const isTokenExpired = (token) => {
    const decodedToken = decodeToken(token);
    return decodedToken.exp < Date.now() / 1000;
  };

  const decodeToken = (token) => {
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded;
    } catch (error) {
      return {};
    }
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    console.log(token);
    console.log(isTokenExpired(token));
    return token && !isTokenExpired(token);
  };

  const checkAuthentication = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("lastLocation", window.location.pathname);
      console.log("logout");
    //   window.location.href = '/login';
    }
  };

  useEffect(() => {
    checkAuthentication();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log("handleVisibilityChange");
        checkAuthentication();
        console.log("calling")
        const token = localStorage.getItem('token');
        try {
            const response =  fetch(VERIFY_TOKEN, {
                method: "GET",
                mode: "cors",
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((data) => data.json())
                .then((data) => {
                  console.log(data)
                  if(data.error==='jwt expired'){
                    navigate('/login')
                  }
                }
                )
            console.log(response)
            
            // return response.ok; // returns true if the status code is 200-299
          } catch (error) {
            console.error('Error checking token validity:', error);
            return false;
          }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useTokenValidation;
// Author: sonal

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import returnProductPng from "../../assets/img/Return.png";

import {
  GET_SHIPMENT_TRACKING,
  SHIPMENT_LIST,
  UPDATE_SHIPMENT_STATUS,
  ADD_RETURN_REQUEST,
  GET_PRODUCT_LIST,
  ADD_RETURN_DETAILS,
  GET_RETURN_DETAILS,
} from "../../config/config";
import { Pagination } from "@mui/material";
import { Modal, PaginationItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import { displaytimestampasperclient } from "../../utils/dateutils.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import removeIcon from "../../assets/img/cancel.png";
import processIcon from "../../assets/img/stepper/process-1.png";
import outDeliveryIcon from "../../assets/img/stepper/out-delivery.png";
import returnIcon from "../../assets/img/stepper/return.png";
import { useNavigate } from "react-router-dom";
import transitIcon from "../../assets/img/stepper/delivery.png";
import outDeliveryIcon1 from "../../assets/img/stepper/out-delivery-1.png";
import deliveredIcon1 from "../../assets/img/stepper/delivered-1.png";
import pendingIcon1 from "../../assets/img/stepper/pending-1.png";
import onHoldIcon1 from "../../assets/img/stepper/onhold-1.png";
import completedIcon1 from "../../assets/img/stepper/completed-1.png";
import canceledIcon1 from "../../assets/img/stepper/cancelled-1.png";
import transitIcon1 from "../../assets/img/stepper/transit-1.png";
import delayIcon1 from "../../assets/img/stepper/delayed-1.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import viewStatus from "../../assets/img/stepper/status.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import "../../Styles/customerdetails.css";
import "../../Styles/products.css";
import TextField from "@mui/material/TextField";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import Textarea from "@mui/joy/Textarea";
import "../../Styles/shipment.css";
import { useTranslation } from "react-i18next";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
//dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SelectLabel from "../Layout/selectlabel";

const steps = ["Return request", "Update details"];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "15px",
}));

let shipmentStatusArray = [
  {
    status: "DRAFT",
    description:
      "The shipment request has been received but not yet processed or scheduled for shipment.",
  },
  {
    status: "Processing",
    description:
      "The shipment is being prepared, items are being picked, packed, and labeled for shipping.",
  },
  {
    status: "In Transit",
    description: "The shipment is on its way to the customer's destination.",
  },
  {
    status: "Out for Delivery",
    description:
      "The shipment has reached the local delivery center and is on its way to the customer's address.",
  },
  {
    status: "Delivered",
    description:
      "The shipment has been successfully delivered to the customer.",
  },
  {
    status: "Delayed",
    description:
      "There is a delay in the shipment due to unforeseen circumstances such as weather conditions, logistical issues, etc.",
  },
  {
    status: "On Hold",
    description:
      "The shipment is temporarily on hold due to customer request, payment issues, or other reasons.",
  },
  {
    status: "Cancelled",
    description:
      "The shipment has been cancelled either by the customer or the seller.",
  },
  {
    status: "Returned",
    description:
      "The shipment has been returned by the customer for any reason.",
  },
  {
    status: "Completed",
    description:
      "The entire shipment process, including delivery and any necessary follow-up actions, has been completed successfully.",
  },
];

let returnReasonArray = [
  { description: "Defective Product" },
  { description: "Incorrect Product" },
  { description: "Damaged During Shipping" },
  { description: "Dissatisfaction with Quality" },
  { description: "Not as Expected " },
  { description: "Dissatisfaction with Quality" },
  { description: "Change of Mind" },
  { description: "Customer Service Issues" },
];

export default function ShipmentList() {
  const navigate = useNavigate();
  const shipmentListRef = React.useRef();
  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "shipment_table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5;
    const dpi = 300;

    html2canvas(shipmentListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("shipment-list.pdf");
      }
    );
  };

  const { i18n } = useTranslation();

  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;

  const token = localStorage.getItem("token");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [shipmentList, setShipmentList] = useState([]);
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [shipmentStatusDescription, setShipmentStatusDescription] =
    useState("");
  const [shipmentModalStatus, setShipmentModalStatus] = useState(false);
  const [shipmentTrackingStatus, setShipmentTrackingStatus] = useState([]);
  const [shipmentTrackingModalStatus, setShipmentTrackingModalStatus] =
    useState(false);
  const [returnStatusTrackModal, setReturnStatusTrackModal] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = shipmentList.slice(indexOfFirstItem, indexOfLastItem);
  const [returnProductStatus, setreturnProductStatus] = useState("");
  const [customerId, setCustomerid] = useState("");
  const [salesId, setSalesId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const loggedInUser = localStorage.getItem("user");
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");
  const [salesDisplayId, setSalesDisplayId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const [personId, setpersonId] = useState("");

  console.log(activeStep);
  console.log(steps.length);

  const [returnMessage, setReturnMessage] = useState("");
  const [productList, setProductlist] = useState([]);
  console.log(productList)
  const [description, setDescription] = useState("");
  const [updateQuantity, setupdateQuantity] = useState([]);
  const [returnId, setreturnId] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [returnItems, setReturnItems] = useState([]);
  console.log(returnItems);
  console.log(quantity);

  useEffect(() => {
    const newReturnItems = productList.map((item) => {
      console.log(item);
      setpersonId(item.person_id);
      return {
        product_id: item.product_id,
        quantity: Number(item.quantity),
        unit_price: item.unit_price,
      };
    });
    setReturnItems(newReturnItems);
  }, [productList]);

  const addReturnItem = (productId, quantity, unitPrice) => {
    console.log(productId, quantity);
    const existingItemIndex = returnItems.findIndex(
      (item) => item.product_id === productId
    );
    console.log(existingItemIndex);

    if (existingItemIndex !== -1) {
      console.log("if");
      const updatedReturnItems = [...returnItems];
      console.log(updatedReturnItems);
      updatedReturnItems[existingItemIndex].quantity += quantity;
      updatedReturnItems[existingItemIndex].unit_price = unitPrice;
      console.log(updatedReturnItems);
      setReturnItems(updatedReturnItems);
    } else {
      console.log("else");
      const newReturnItem = {
        product_id: productId,
        quantity: quantity,
        unit_price: "342",
      };
      setReturnItems([...returnItems, newReturnItem]);
    }
  };

  const quantityIncrease = (index, person_id, unitPrice) => {
    setpersonId(person_id);
    console.log(productList);
    const updatedProductList = [...productList];
    updatedProductList[index].quantity =
      parseInt(updatedProductList[index].quantity, 10) + 1;
    setProductlist(updatedProductList);

    const { product_id } = updatedProductList[index];
    addReturnItem(product_id, 1, unitPrice);
  };

  const quantityDecrease = (index, person_id, unitPrice) => {
    setpersonId(person_id);
    const updatedProductList = [...productList];

    if (updatedProductList[index].quantity > 0) {
      updatedProductList[index].quantity =
        parseInt(updatedProductList[index].quantity, 10) - 1;
      setProductlist(updatedProductList);

      const { product_id } = updatedProductList[index];
      addReturnItem(product_id, -1, unitPrice);
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (buttonposition) => {
    if (buttonposition === 0) {
      handleSubmitReturnRequest();
    } else if (buttonposition === 1) {
      handleAddReturnDetails();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //handle dialog
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  let foundUser = JSON.parse(loggedInUser);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  console.log(shipmentTrackingStatus);

  const getShipmentListFun = async () => {
    await fetch(SHIPMENT_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setShipmentList(data.data.shipment_list);
        }
      });
  };

  console.log(foundUser.person_id);

  const UpdateShipmentStatusFun = async () => {
    await fetch(UPDATE_SHIPMENT_STATUS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        sales_id: shipmentDetails.sales_id,
        status: shipmentStatus,
        details: shipmentStatusDescription,
        person_id: foundUser.person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          console.log(data);
          getShipmentListFun();
          setShipmentModalStatus(false);
        }
      });
  };

  const getTrackStatusFun = async (val) => {
    await fetch(GET_SHIPMENT_TRACKING, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        sales_id: val,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          setShipmentTrackingStatus(data.data.shipment_tracking_details);
        }
      });
  };

  useEffect(() => {
    getShipmentListFun();
  }, []);

  const handleClose = () => {
    setShipmentModalStatus(false);
  };

  const handleModalOpen = () => {
    setShipmentModalStatus(true);
  };

  const handleShipmentTrackClose = () => {
    setShipmentTrackingModalStatus(false);
  };

  const handleShipmentTrackModalOpen = () => {
    setShipmentTrackingModalStatus(true);
  };

  const handleReturnStatusModalClose = () => {
    setReturnStatusTrackModal(false);
  };

  const openModalToUpdateStatusFun = (value) => {
    console.log(value);
    handleModalOpen();
    setShipmentDetails(value);
    setShipmentStatus(value.delivery_status);
  };

  const openModalToTrackStatusFun = (value) => {
    console.log(value);
    handleShipmentTrackModalOpen();
    setShipmentDetails(value);
    setShipmentStatus(value.delivery_status);
  };

  useEffect(() => {
    const selectedStatus = shipmentStatusArray.find(
      (item) => item.status === shipmentStatus
    );
    if (selectedStatus) {
      setShipmentStatusDescription(selectedStatus.description);
    }
  }, [shipmentStatus]);

  const handleClickOpen = () => {
    setOpen(true);
    setOpenDialog(false);
  };

  const handledialogClose = () => {
    setOpen(false);
  };

  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  const displayTooltipFun = (sts) => {
    console.log(sts);
    return (
      <>
        <Box className="shipment-track-status-tool-tip-box">
          <Typography className="shipment-track-status-tool-tip-typo common_font">
            Status
          </Typography>
          <Typography className="common_font">{sts.status}</Typography>
        </Box>

        <Box className="shipment-track-status-tool-tip-box">
          <Typography className="shipment-track-status-tool-tip-typo common_font">
            Changes By
          </Typography>
          <Typography className="common_font">{sts.sales_rep_name}</Typography>
        </Box>

        <Box className="shipment-track-status-tool-tip-box">
          <Typography className="shipment-track-status-tool-tip-typo common_font">
            Time
          </Typography>
          <Typography className="common_font">
            {displaytimestampasperclient(sts.log_time)}
          </Typography>
        </Box>

        <Box className="shipment-track-status-tool-tip-box">
          <Typography
            sx={{ marginRight: "66px" }}
            className="shipment-track-status-tool-tip-typo common_font"
          >
            {" "}
            Details
          </Typography>
          <Typography className="common_font">{sts.details}</Typography>
        </Box>
      </>
    );
  };

  const returnRequest = (sl) => {
    console.log(sl);
    setreturnProductStatus(sl.delivery_status);
    setCustomerid(sl.person_id);
    setSalesId(sl.sales_id);
    setCustomerName(sl.customer_name);
    setSalesDisplayId(sl.sales_display_id);
  };

  const handleSubmitReturnRequest = () => {
    fetch(ADD_RETURN_REQUEST, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        sales_id: salesId,
        customer_id: customerId,
        customer_name: customerName,
        status: returnProductStatus,
        return_reason: returnMessage,
        comments: description,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setreturnId(data.data.add_return[0]._return_id);
        }
      });
  };

  const companyaddress = JSON.parse(localStorage.getItem("user_org"));
  console.log(companyaddress);

  const handleAddReturnDetails = () => {
    fetch(ADD_RETURN_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        return_id: returnId,
        return_data: returnItems,
        action_type: "return",
        action_taken_by: personId,
        comments: shipmentStatusDescription,
        return_address: companyaddress.location_address,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          navigate("/return-products-details", {
            state: {
              return_id: data.data.return_id,
              person_id: personId,
            },
          });
        }
      });
  };

  console.log(productList);

  const ProductList = (sales_display_id) => {
    fetch(GET_PRODUCT_LIST, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        sales_display_id: sales_display_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data.sales_order_product_list);

        setProductlist(data.data.sales_order_product_list);
        const quantity = [];
        quantity.push(data.data.sales_order_product_list);
        quantity.map((item) => {
          console.log(item);
        });
      });
  };
  const [returnDetails, setReturnDetails] = useState([]);
  const getReturnDetails = () => {
    fetch(GET_RETURN_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        return_id: returnId,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        setReturnDetails(data.data.return_details);
      });
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ padding: "20px", border: "1px solid black" }}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography className="common_font">
                Are you sure want to return the item ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="common-button" onClick={handleDialogClose}>
              No
            </Button>
            <Button className="common-button" onClick={handleClickOpen}>
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Modal
        open={shipmentModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setShipmentModalStatus(false);
              }}
            />
            <Box sx={{ padding: "32px !important" }} className="">
              <h2 className="common_font product-list-update-add-stock-header">
                Update Status
              </h2>
              <Box className="product_details_specification_modal_box">
                <FormControl
                  sx={{ width: "350px !important", marginBottom: "30px" }}
                  id="add_pen_brand_formControl"
                  className="customer_details_add_items_form_control"
                >
                  <InputLabel className="common_font" id="brands-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="brands-label"
                    id="shipped-status"
                    label="Status"
                    value={shipmentStatus}
                    onChange={(e) => setShipmentStatus(e.target.value)}
                    InputLabelProps={{
                      className: "common_font",
                    }}
                    inputProps={{
                      className: "common_font",
                    }}
                  >
                    {shipmentStatusArray.map((sta, index) => {
                      return (
                        <MenuItem
                          className="common_font"
                          value={sta.status}
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        >
                          {sta.status}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Textarea
                className="common_font"
                sx={{ height: "100px" }}
                placeholder="Description"
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
                id="status-description"
                value={shipmentStatusDescription}
                onChange={(e) => {
                  setShipmentStatusDescription(e.target.value);
                }}
              />
              <Button
                id="shipped-status-update-button"
                onClick={UpdateShipmentStatusFun}
                sx={{ width: "100px", marginBottom: "10px", marginTop: "25px" }}
                className="common-button common_font"
              >
                Update
              </Button>
            </Box>
          </Box>
        </>
      </Modal>

      <Modal
        open={shipmentTrackingModalStatus}
        onClose={handleShipmentTrackClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                setShipmentTrackingModalStatus(false);
              }}
            />

            <Box sx={{ padding: "32px !important" }}>
              <h2 className="common_font product-list-view-stock-details-modal-header">
                Track Shipment
              </h2>

              <Box className="product-list-modal-sku-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo">
                  SALES ID
                </Typography>
                <Typography
                  sx={{ width: "120px !important" }}
                  className="common_font product-list-modal-sku-box-txt"
                >
                  {shipmentDetails.sales_display_id}
                </Typography>
              </Box>

              <Box
                sx={{ marginTop: "20px" }}
                className="product-list-view-stock-details-modal-icon-box"
              >
                <Box
                  // sx={{ overflowX: "auto!important" }}
                  className="shipment-tracking-status-box shipment-tracking-status-box-modal-content"
                >
                  {shipmentTrackingStatus.map((sts, index) => {
                    return (
                      <Box key={index}>
                        <Box>
                          <Tooltip title={displayTooltipFun(sts)}>
                            <div
                              style={{ height: "20px" }}
                              className="all_box_hover common-stepper-icon-container  "
                            >
                              {sts.status === "Processing" ? (
                                <img
                                  src={processIcon}
                                  className="common-stepper-img "
                                  alt="process-icon"
                                />
                              ) : sts.status === "In Transit" ? (
                                <img
                                  src={transitIcon}
                                  className="common-stepper-transit-img"
                                  alt="transit-icon"
                                />
                              ) : sts.status === "Out for Delivery" ? (
                                <img
                                  src={outDeliveryIcon1}
                                  className="common-stepper-out-delivery-img"
                                  alt="delivery-icon"
                                />
                              ) : sts.status === "Delivered" ? (
                                <img
                                  src={deliveredIcon1}
                                  className="common-stepper-out-delivery-img"
                                  alt="delivered-icon"
                                />
                              ) : sts.status === "DRAFT" ? (
                                <img
                                  src={pendingIcon1}
                                  className="common-stepper-img"
                                  alt="pending-icon"
                                />
                              ) : sts.status === "Completed" ? (
                                <img
                                  src={completedIcon1}
                                  className="common-stepper-img"
                                  alt="complete-icon"
                                />
                              ) : sts.status === "On Hold" ? (
                                <img
                                  src={onHoldIcon1}
                                  className="common-stepper-img"
                                  alt="on-hold-icon"
                                />
                              ) : sts.status === "Returned" ? (
                                <img
                                  src={returnIcon}
                                  className="common-stepper-return-img"
                                  alt="return-icon"
                                />
                              ) : sts.status === "Delayed" ? (
                                <img
                                  src={delayIcon1}
                                  className="common-stepper-delay-img"
                                  alt="delay-icon"
                                />
                              ) : sts.status === "Cancelled" ? (
                                <img
                                  src={canceledIcon1}
                                  className="common-stepper-img"
                                  alt="delay-icon"
                                />
                              ) : null}
                              {index !== shipmentTrackingStatus.length - 1 && (
                                <div className="common-stepper-line"></div>
                              )}
                            </div>
                            {index !== shipmentTrackingStatus.length - 1 && (
                              <div className="common-stepper-space"></div>
                            )}
                          </Tooltip>
                        </Box>

                        <Typography
                          className="small-txt"
                          sx={{ marginTop: "20px", width: "100px" }}
                        >
                          <span className="common_font">{sts.status}</span>
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>

      <Modal
        open={returnStatusTrackModal}
        onClose={handleReturnStatusModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal common-display-style common_card_bs">
            <img
              className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
              src={removeIcon}
              alt="removeIcon"
              onClick={() => {
                handleReturnStatusModalClose();
              }}
            />
            <Box sx={{ padding: "32px !important" }} className="">
              <h2 className="common_font product-list-update-add-stock-header">
                Return Request Details
              </h2>
              <Box className="product-list-modal-sku-box return-sub-header-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo return-sub-header-txt">
                  RETURN ID
                </Typography>
                <Typography
                  sx={{ width: "120px !important" }}
                  className="common_font product-list-modal-sku-box-txt"
                >
                  #949922
                </Typography>
              </Box>
              <Box className="product-list-modal-sku-box return-sub-header-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo return-sub-header-txt">
                  RETURN REASON
                </Typography>
                <Typography className="common_font return-sub-header-sub-txt">
                  Product Damage
                </Typography>
              </Box>
              <Box className="product-list-modal-sku-box return-sub-header-box">
                <Typography className="common_font product-list-view-stock-details-modal-head-typo return-sub-header-txt">
                  RETURN PRODUCT QUANTITY
                </Typography>
                <Typography className="common_font return-sub-header-sub-txt">
                  2
                </Typography>
              </Box>

              <h3
                style={{ marginTop: "20px" }}
                className="common_font product-list-update-add-stock-header"
              >
                Track Your Refund Order
              </h3>

              <Box>
                <div className="return-track-order-div">
                  <Box className="return-track-order-div-box">
                    <CheckCircleIcon sx={{ fontSize: "30px" }} />
                    <div>
                      <Typography
                        className="
                      common_font medium-txt"
                      >
                        Refund Request
                      </Typography>
                      <Typography className="common_font very-small-txt">
                        13 Mar 2024
                      </Typography>
                    </div>
                  </Box>
                  <Box>
                    <RequestPageIcon sx={{ fontSize: "40px" }} />
                  </Box>
                </div>

                <div className="return-track-order-vertical-line"></div>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>

      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(currentItems);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>

      <Modal
        open={open}
        onClose={handledialogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="customer_details_selector_modal common-display-style common_card_bs">
          <img
            className="all_box_hover customer-details-quotation-selector-modal-div-icon modal-cancel-img"
            src={removeIcon}
            alt="removeIcon"
            onClick={() => {
              setOpen(false);
            }}
          />

          <Box sx={{ width: "90%", padding: "32px" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>
                      <span className="common_font">{label}</span>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box
                  sx={{ mt: 2, mb: 1, maxHeight: "600px", overflowY: "auto" }}
                >
                  {activeStep === 0 ? (
                    <Box sx={{ padding: "6px !important" }}>
                      <h3 className="common_font">
                        Why are you returning this item?
                      </h3>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <FormControl
                          fullWidth
                          sx={{
                            width: "350px !important",
                            marginBottom: "30px",
                          }}
                          id="add_pen_brand_formControl"
                          className="customer_details_add_items_form_control"
                        >
                          <InputLabel className="common_font" id="brands-label">
                            Select a reason
                          </InputLabel>
                          <Select
                            labelId="brands-label"
                            id="shipped-status"
                            label="Select a reason"
                            value={returnMessage}
                            onChange={(e) => setReturnMessage(e.target.value)}
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                          >
                            {returnReasonArray.map((sta, index) => {
                              return (
                                <MenuItem
                                  className="common_font"
                                  value={sta.description}
                                  InputLabelProps={{
                                    className: "common_font",
                                  }}
                                  inputProps={{
                                    className: "common_font",
                                  }}
                                >
                                  {sta.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          fullWidth
                          sx={{
                            width: "350px !important",
                            marginBottom: "30px",
                          }}
                          id="add_pen_brand_formControl"
                          className="customer_details_add_items_form_control"
                        >
                          <Textarea
                            className="common_font"
                            sx={{ height: "100px" }}
                            placeholder="Description"
                            InputLabelProps={{
                              className: "common_font",
                            }}
                            inputProps={{
                              className: "common_font",
                            }}
                            id="status-description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  ) : activeStep === 1 ? (
                    <Box sx={{ padding: "32px !important" }} className="">
                      <h2
                        className="common_font product-list-update-add-stock-header "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="common_font">Return</Typography>
                        <Typography className="common_font">
                          Sales ID :{"  "}
                          <Chip
                            className="common_font"
                            label={salesDisplayId}
                            size="medum"
                          />
                        </Typography>
                      </h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          {productList.map((item, index) => {
                            console.log(item);
                            {/* const images = item.images.metadata
                              .slice(0, 1)
                              .map((item) => {
                                return item.url;
                              }); */}
                            const product_Id = item.product_id;
                            {/* console.log(images); */ }
                            console.log(product_Id);
                            return (
                              <Item>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Box>
                                    <img
                                      src={images[0]}
                                      width={50}
                                      alt="returnProductPng"
                                    />
                                  </Box> */}
                                  <Box>
                                    <Typography>{item.pen_name}</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        quantityDecrease(
                                          index,
                                          item.person_id,
                                          item.unit_price
                                        )
                                      }
                                    >
                                      <RemoveIcon fontSize="small" />
                                    </Box>

                                    <Box className="quantity-box">
                                      {item.quantity}
                                    </Box>

                                    <Box
                                      sx={{
                                        padding: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        quantityIncrease(
                                          index,
                                          item.person_id,
                                          item.unit_price
                                        )
                                      }
                                    >
                                      <AddIcon fontSize="small" />
                                    </Box>
                                  </Box>
                                </Box>
                              </Item>
                            );
                          })}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Item>
                            <Textarea
                              className="common_font"
                              sx={{ height: "100px" }}
                              placeholder="Description"
                              InputLabelProps={{
                                className: "common_font",
                              }}
                              inputProps={{
                                className: "common_font",
                              }}
                              id="status-description"
                              value={shipmentStatusDescription}
                              onChange={(e) => {
                                setShipmentStatusDescription(e.target.value);
                              }}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box sx={{ padding: "32px !important" }} className="">
                      {returnDetails.map((item) => {
                        console.log(item);
                        return (
                          <Box>
                            <div className="return-stepper-final-step-div">
                              <Typography className="common_font">
                                Return ID
                              </Typography>
                              <Typography>{item.return_id}</Typography>
                            </div>
                            <div className="return-stepper-final-step-div">
                              <Typography>Status</Typography>
                              <Chip
                                className="common_font"
                                label={item.action_type}
                                color="success"
                                size="medum"
                              />
                            </div>
                            <div className="return-stepper-final-step-div">
                              <Typography>Item Name</Typography>
                              <Typography>{item.pen_name}</Typography>
                            </div>
                            <div className="return-stepper-final-step-div">
                              <Typography>Item Quantity</Typography>
                              <Typography>{item.quantity}</Typography>
                            </div>
                            <div className="return-stepper-final-step-div">
                              <Typography>Return Reason</Typography>
                              <Typography>{item.comments}</Typography>
                            </div>
                            <Divider />
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {returnMessage && description && activeStep === 0 ? (
                      <Button
                        className="common-button"
                        onClick={() => handleNext(activeStep)}
                      >
                        Next
                      </Button>
                    ) : shipmentStatusDescription && activeStep === 1 ? (
                      <Button
                        className="common-button"
                        onClick={() => handleNext(activeStep)}
                      >
                        Update
                      </Button>
                    ) : activeStep === 2 ? (
                      <Button
                        className="common-button"
                        onClick={() => handleNext(activeStep)}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        className="common-button"
                        disabled
                        sx={{ opacity: "40%", cursor: "not-allowed" }}
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        {/* <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Logs Info"
            className="common_font"
            inputProps={{ "aria-label": "Search Logs Info" }}
            onChange={handleSearchChange}
            value={textContent}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper> */}
        <SelectLabel
          length={shipmentList.length}
          onSendData={handleChildData}
        />
      </div>

      <Paper className="paper common_card_bs">
        <Box className="sales-order-list-export-box">
          <Button
            sx={{ padding: "0px !important" }}
            className="common_font"
            onClick={handleExportClick}
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        </Box>

        <TableContainer ref={shipmentListRef} className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead className="table-head">
              <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Sales ID
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Customer
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Created By
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Created
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  No. of Items
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Status
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Amount
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Track Status
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  Return Item
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="">
              {currentItems &&
                currentItems.map((sl, index) => {
                  console.log(sl);
                  return (
                    <TableRow key={index}
                      className={index % 2 === 0 ? 'table-row-even product-list-alternate-table' : 'table-row-even'}
                    // className="table-row-even"
                    >
                      <TableCell className="body-table-cell common_font ">
                        <Chip
                          label={sl.sales_display_id}
                          style={{ fontWeight: "700" }}
                          className="common_font"
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {sl.customer_name}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {sl.created_by_name}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {displaytimestampasperclient(sl.sale_date)}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {sl.total_item}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {/* {sl.delivery_status} */}
                        <Tooltip title="Change Status">
                          {sl.delivery_status === "Out for Delivery" ? (
                            <Typography
                              id="status-change-typo"
                              sx={{
                                display: "flex",
                                width: "150px",
                                justifyContent: "space-around",
                                backgroundColor: "#EBEBEB !important",
                              }}
                              onClick={() => {
                                openModalToUpdateStatusFun(sl);
                              }}
                              className="sales-order-list-status-txt common_font small-txt all_box_hover"
                            >
                              <CreateIcon sx={{ fontSize: "18px" }} />

                              {sl.delivery_status}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                display: "flex",
                                width: "100px",
                                justifyContent: "space-around",
                                backgroundColor: "#EBEBEB !important",
                              }}
                              className="sales-order-list-status-txt common_font small-txt all_box_hover"
                            >
                              <CreateIcon
                                onClick={() => {
                                  openModalToUpdateStatusFun(sl);
                                }}
                                sx={{ fontSize: "18px" }}
                              />

                              {sl.delivery_status === "Returned" ? (
                                <Typography
                                  className="common_font"
                                  onClick={() => {
                                    setReturnStatusTrackModal(true);
                                  }}
                                >
                                  {sl.delivery_status}
                                </Typography>
                              ) : (
                                <Typography className="common_font">
                                  {" "}
                                  {sl.delivery_status}
                                </Typography>
                              )}
                            </Typography>
                          )}
                        </Tooltip>
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        ${sl.total_amount}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <img
                          id="track-status-img"
                          onClick={() => {
                            getTrackStatusFun(sl.sales_id);
                            openModalToTrackStatusFun(sl);
                          }}
                          src={viewStatus}
                          className="common-stepper-img all_box_hover"
                          alt="viewStatus"
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <img
                          id="return-item-img"
                          onClick={() => {
                            handleDialogOpen(
                              returnRequest(sl),
                              ProductList(sl.sales_display_id)
                            );
                          }}
                          src={returnProductPng}
                          className="common-stepper-img all_box_hover"
                          alt="viewStatus"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box className="pagination-box">
        <Pagination
          id="shipment-list-pagination"
          className="pagination common_font"
          count={Math.ceil(shipmentList.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          siblingCount={1}
          boundaryCount={1}
        // renderItem={(item) => {
        //   const isArrowButton =
        //     item.type === "previous" || item.type === "next";
        //   return (
        //     <PaginationItem
        //       component={IconButton}
        //       {...item}
        //       disabled={
        //         isArrowButton && item.type === "previous" && currentPage === 1
        //       }
        //       id={isArrowButton ? "shipment-list-pagination" : undefined}
        //       icon={
        //         isArrowButton
        //           ? item.type === "previous"
        //             ? "left"
        //             : "right"
        //           : null
        //       }
        //     />
        //   );
        // }}
        />
      </Box>
    </div>
  );
}

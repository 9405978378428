import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path;
  // ||"/dashboard"
  const [userD, setUserD] = useState({
    usertype: "",
    permissions: []
  });
  const login = (userD) => {
    console.log(userD)
    if (userD === "Admin") {
      setUserD({ usertype: userD, permissions: ["view_dashboard"] });
    } else {
      setUserD({ usertype: userD, permissions: ["view_extra"] });
    }
    navigate(redirectPath, { replace: true });
  };
  const logout = () => {
    setUserD({ usertype: "", permissions: [] });
  };
  return (
    <AuthContext.Provider value={{ userD, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};

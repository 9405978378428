// Author: sonal

/** @format */

//import from packages
import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, TextField, Button, Stack } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useAlert } from "../../alert/alert-context";
import { useAuth } from "../../../provider/AuthProvider";
import Snackbar from "@mui/material/Snackbar";
import { useHistory } from "react-router-dom";
import bsas from "../../../assets/img/BSA-22.png";

//microservices
import {
  GOOGLE_AUTH,
  LOGIN_URL,
  TWO_FA_URL,
  REGISTER_DEVICE,
  VERIFY_TOKEN,
} from "../../../config/config";

//images
import loginimage from "../../../assets/img/png/loginbg.jpg";
import logo from "../../../assets/img/bodex_logo.png";
import googleimg from "../../../assets/img/google.png";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
//css
import "../../../Styles/login.css";
import "../../../index.css";
import { SnackbarContext } from "../../../app";
import Footer from "../../Footer/Footer";
import { useTranslation } from "react-i18next";
import BODEX_LOGO from "../../../assets/img/Bodex.png";

export default function LoginForm() {
  const lastLocation = localStorage.getItem("lastLocation");
  console.log(lastLocation);
  const contextvalue = React.useContext(SnackbarContext);

  const { i18n } = useTranslation();
  console.log(i18n.language);
  const [lng, setLng] = useState(i18n.language);
  console.log(lng);

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);
  const { showAlert } = useAlert();
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSignupAlert, setShowSignupAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [twoFASecret, setTwoFASecret] = useState("");
  const [twoFAtoken, setTwoFAToken] = useState("");
  const [user, setUser] = useState([]);
  const [email, setEmail] = useState("");
  const [registerDevice, setRegisterDevice] = useState(false);
  const [userdata, setUserData] = useState({});
  const [opensnackbar, setOpenSnackbar] = React.useState(false);
  const [snackmsg, setSnackMsg] = useState("");
  const effectRan = useRef(false);
  const handleAlertClose = () => {
    setShowLoginAlert(false);
    setShowErrorAlert(false);
    setOpenSnackbar(false);
  };

  const dateTime = new Date().getFullYear();

  const handleSignupClose = () => {
    setShowSignupAlert(false);
  };

  const { login } = useAuth();
  const Googlelogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
    },
    flow: "auth-code",
    onError: (error) => console.log("Login Failed:", error),
  });
  const loggedInUser = localStorage.getItem("user");
  let foundUser;
  if (loggedInUser) {
    foundUser = JSON.parse(loggedInUser);
    console.log(foundUser);
    console.log(foundUser?.token);
  }
  useEffect(() => {
    const token = localStorage?.getItem("token");
    if (token) {
      try {
        const response = fetch(VERIFY_TOKEN, {
          method: "GET",
          mode: "cors",
          headers: { 'Authorization': `Bearer ${token}` },
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data)
            if (data.error === 'jwt expired') {
              setOpenSnackbar(true)
              setSnackMsg("Session Timeout. Please relogin to continue")
              window.localStorage.clear();
              window.sessionStorage.clear();
            }
          }
          )
        console.log(response)

        // return response.ok; // returns true if the status code is 200-299
      } catch (error) {
        console.error('Error checking token validity:', error);
        return false;
      }
    }
  }, [])
  useEffect(() => {
    const userGoogleAuthVerification = async () => {
      await fetch(GOOGLE_AUTH, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "*/*",
        },
        body: JSON.stringify({ ...user }),
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            showAlert("Login successful!");
            setShowLoginAlert(true);
            let user = JSON.stringify(data.data.setuserdata);
            localStorage.setItem("user", user);
            navigate(`/${lng}/dashboard`);
          } else {
            setShowErrorAlert(true);
            setAlertMessage("UnAuthorized User");
          }
        });
    };
    if (user.code !== undefined) {
      userGoogleAuthVerification();
    }
  }, [user]);

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const Logo = () => <img src={logo} alt="logo" className="logo" />;

  const userLogin = async () => {
    await fetch(LOGIN_URL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        useremail: username,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          console.log(data);
          console.log(data.data.response?.twofa);
          let twoFAStatus = data.data?.response?.two_factor;
          let twoFASecret = data.data?.response?.two_factor_secret;
          setUserData(data.data?.sess?.setuserdata);
          let user = JSON.stringify(data.data?.sess?.setuserdata);
          let token = data.data?.sess?.setuserdata.token;
          let user_org = JSON.stringify(data.data.user_org[0]);
          let user_type = data.data.response?.user_type;
          let user_id = data.data.user_org[0].user_id;
          let site_name = data.data.user_org[0].site_name;
          console.log(user_org);

          console.log(token);
          console.log(user);
          localStorage.setItem("user", user);
          localStorage.setItem("token", token);
          localStorage.setItem("user_org", user_org);
          localStorage.setItem(
            "usertype",
            data.data?.sess?.setuserdata.usertype
          );
          localStorage.setItem("user_type", user_type);
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("site_name", site_name);
          login(data.data?.sess?.setuserdata?.usertype);
          if (twoFAStatus === true) {
            setTwoFASecret(twoFASecret);
            console.log("test");
          } else {
            console.log(data);
            showAlert(data.message);
            setShowLoginAlert(true);
            // data.data.user_org[0].first_name
            // data.data.user_org[0].last_name

            contextvalue.setSnackSeverity("success");
            contextvalue.setSnackMsg(
              `Welcome ${data.data.user_org[0].first_name} ${data.data.user_org[0].last_name}`
            );
            // contextvalue.setSnackMsg("Welcome to Base SaaS App!");
            contextvalue.setOpenSnackbar(true);

            if (data.data?.user_org[0]?.site_name === "Goldspot") {
              navigate(`/gold-spot/${lng}/dashboard`);
              localStorage.removeItem("lastLocation");
            } else if (data.data?.user_org[0]?.site_name === "Pen House") {
              navigate(`/pen-house/${lng}/dashboard`);
              localStorage.removeItem("lastLocation");
            } else {
              if (data.data?.response?.user_type === "Admin") {
                navigate(`/${lng}/dashboard`);
              } else {
                if (lastLocation) {
                  navigate(lastLocation);
                } else {
                  navigate(`/${lng}/dashboard`);
                  localStorage.removeItem("lastLocation");
                }
              }
            }
          }
        } else {
          console.log(data.error);
          setShowErrorAlert(true);
          setAlertMessage(data.error.message);
        }
      });
  };
  const authenticateUser = async () => {
    await fetch(TWO_FA_URL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        secret: twoFASecret,
        token: twoFAtoken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          console.log(data);
          if ((data.success === true) & (data.data.verified === true)) {
            // localStorage.setItem('user', userdata)
            showAlert("Login successful!");
            setShowLoginAlert(true);

            contextvalue.setSnackSeverity("success");
            contextvalue.setSnackMsg("Welcome to Base SaaS App!");
            contextvalue.setOpenSnackbar(true);

            navigate(`/${lng}/dashboard`);
          }
        } else {
          if ((data.success === false) & (data.data.verified === false)) {
            setShowErrorAlert(true);
            setAlertMessage(
              "OTP verification failed: Invalid verification code"
            );
          }
        }
      });
  };

  const handleSignupClick = () => {
    setShowSignupAlert(true);
  };

  const handleRegisteringDevice = async () => {
    console.log(userdata);
    console.log(email);

    if (email === userdata.email) {
      await fetch(REGISTER_DEVICE, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          userdata: userdata,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            setShowErrorAlert(false);
            setShowLoginAlert(true);
            setAlertMessage("Email sent successfully!");
          } else {
            setShowLoginAlert(false);
            setShowErrorAlert(true);
            setAlertMessage("Something went wrong! Please retry");
          }
        });
    } else {
      setShowLoginAlert(false);
      setShowErrorAlert(true);
      setAlertMessage("Please enter registered email address");
    }
  };

  const isFormValid = username.trim() !== "" && password.trim() !== "";

  return (
    <div>
      <Snackbar
        open={showErrorAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="login-snackBar-alert"
      >
        {showLoginAlert ? (
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            <Typography className="common_font"> {alertMessage}</Typography>
          </Alert>
        ) : (
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%" }}
            className="common-error-alert"
          >
            <Typography className="common_font"> {alertMessage}</Typography>
          </Alert>
        )}

      </Snackbar>
      <Snackbar
        open={opensnackbar}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="login-snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "100%" }}
          className="common-error-alert"

        >
          <Typography className="common_font">
            {snackmsg}
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSignupAlert}
        onClose={handleSignupClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="login-snackBar-alert"
      >
        <Alert
          onClose={handleSignupClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          <Typography className="common_font">
            This functionality is currently not operational.
          </Typography>
        </Alert>
      </Snackbar>

      <div className="login-container" id="login-container">
        <Card
          sx={{ padding: "20px !important" }}
          className="login-page css-selector"
          id="login-page"
        >
          <img
            id="bodex_logo"
            src={bsas}
            alt="BSa Logo"
            className="sidebar_logo_img-1"
          />

          {twoFASecret ? (
            <CardContent id="twoFA-card-content">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {registerDevice ? (
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={8}
                    lg={6}
                    id="register-device-grid"
                  >
                    <Stack spacing={3}>
                      <Typography
                        variant="h4"
                        className="heading common_font"
                        id="register-device-heading"
                      >
                        Registering device
                      </Typography>
                      <p
                        className="common_font"
                        id="register-device-description"
                      >
                        Please enter your registered email address to register
                        your device.
                      </p>

                      <TextField
                        label="Email"
                        variant="outlined"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        className="textfield-container common_font"
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                      <center>
                        <Button
                          className="css-selector button common_font"
                          variant="contained"
                          onClick={handleRegisteringDevice}
                          id="register-device-button"
                        >
                          Send
                        </Button>
                      </center>
                    </Stack>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={10} md={8} lg={6} id="twoFA-grid">
                      <Stack spacing={3}>
                        <Typography
                          variant="h4"
                          className="heading common_font"
                          id="twoFA-heading"
                        >
                          Two-Factor Authentication
                        </Typography>
                        <TextField
                          label="Verification Code"
                          variant="outlined"
                          id="otp"
                          type="number"
                          value={twoFAtoken}
                          onChange={(e) => setTwoFAToken(e.target.value)}
                          fullWidth
                          className="textfield-container common_font"
                          InputLabelProps={{
                            className: "common_font",
                          }}
                          inputProps={{
                            className: "common_font",
                          }}
                        />
                        <center>
                          <Button
                            className="css-selector button common_font"
                            variant="contained"
                            disabled={twoFAtoken.length !== 6}
                            onClick={authenticateUser}
                            id="authenticate-button"
                          >
                            Authenticate
                          </Button>
                        </center>
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          ) : (
            <CardContent id="login-card-content">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={10} md={8} lg={6} id="login-grid">
                  <Stack spacing={3}>
                    <Typography
                      variant="h4"
                      className="heading common_font"
                      id="login-heading"
                    >
                      LOGIN
                    </Typography>
                    <TextField
                      label="Username"
                      variant="outlined"
                      id="user"
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                      fullWidth
                      className="textfield-container common_font"
                      InputLabelProps={{
                        className: "common_font",
                      }}
                      inputProps={{
                        className: "common_font",
                      }}
                    />
                    <FormControl sx={{ m: 1 }} variant="outlined">
                      <InputLabel
                        className="common_font"
                        htmlFor="outlined-adornment-password"
                      >
                        Password *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handlePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password *"
                        className="outlined-input common_font"
                        InputLabelProps={{
                          className: "common_font",
                        }}
                        inputProps={{
                          className: "common_font",
                        }}
                      />
                    </FormControl>
                    <center>
                      {isFormValid ? (
                        <Button
                          sx={{ width: "100%" }}
                          // button
                          className="css-selector common-button common_font"
                          variant="contained"
                          onClick={userLogin}
                          id="login-button"
                        >
                          LOGIN
                        </Button>
                      ) : (
                        <Button
                          sx={{ width: "100%" }}
                          // disabled-button
                          className="css-selector common-button common_font"
                          variant="contained"
                          id="disabled-login-button"
                        >
                          LOGIN
                        </Button>
                      )}
                      <br />
                      <br />
                      <div className="container" id="login-container">
                        <div className="line"></div>
                        <div className="or common_font">OR</div>
                        <div className="line"></div>
                      </div>
                      <br />
                      <>
                        <img
                          className="pointer"
                          src={googleimg}
                          alt="Google"
                          onClick={() => Googlelogin()}
                          id="google-login-image"
                        />
                      </>
                      <p className="common_font">Don't have an account ? </p>
                      {/* <a
                          style={{ color: "blue" }}
                          className="pointer"
                          onClick={handleSignupClick}
                        >
                          SIGN UP
                        </a> */}
                      <Button
                        sx={{ width: "100%" }}
                        className="css-selector common-button-signup common_font"
                        variant="contained"
                        onClick={handleSignupClick}
                        id="signup-button"
                      >
                        SIGN UP
                      </Button>
                    </center>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>

        <div className="login-page-footer-main-div">
          {/* <Footer /> */}
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="footer_div_login"
          >
            <a href="https://bodex.io/" target="_blank" rel="noreferrer">
              <img src={BODEX_LOGO} alt="BODEX LOGO" width={100} />
            </a>

            <Typography className="common_font">
              <a
                className="common_font"
                href="https://bodex.io/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "black" }}
              >
                &copy; Copyright {dateTime}
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import user from "../../assets/img/avatar.jpg";
import { Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function createData(name, user, email, status, role) {
  return { name, user, email, status, role };
}

const rows = [
  createData("Test User", "Test User", "test@gmail.com", "Inactive", "Admin"),
  createData("Test One", "Test One", "testone@gmail.com", "Active", "User"),
  createData("Test Two", "Test Two", "testtwo@gmail.com", "Inactive", "Admin"),
  createData(
    "Test Three",
    "Test Three",
    "testthree@gmail.com",
    "Active",
    "User"
  ),
  createData("Test Four", "Test Four", "testfour@gmail.com", "Active", "User"),
];

export default function UserList() {
  const navigate = useNavigate();
  const adduser = () => {
    navigate("/adduser");
  };
  return (
    <div>
      <div
        className="product-list-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "10px",
        }}
      >
        <h1 className="common_font">Users (5)</h1>
        <Button
          variant="contained"
          onClick={adduser}
          className="addNewUser-btn"
          style={{ height: "40px", marginTop: "10px" }}
        >
          <i class="fa-solid fa-user-plus"></i>
          <span>&nbsp; Add New User</span>
        </Button>
      </div>
      <Paper>
        <TableContainer sx={{ mt: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label="userlist">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <b>
                    <i
                      class="fa-solid fa-user"
                      style={{
                        "font-size": "20px",
                        "margin-left": "10px",
                      }}
                    ></i>
                  </b>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <b>User</b>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <b>Email</b>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <b>Status</b>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <b>Role</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">
                    {" "}
                    <Avatar src={user} />
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    {row.status === "Active" ? (
                      <div
                        style={{
                          width: "max-content",
                          minWidth: "70px",
                          height: "max-content",
                          padding: "8px",
                          background: "#e9fbf0",
                          color: "#5c9472",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        {row.status}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "max-content",
                          minWidth: "70px",
                          height: "max-content",
                          padding: "8px",
                          background: "#fce4e4",
                          color: "#9a4242",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        {row.status}
                      </div>
                    )}
                  </TableCell>
                  <TableCell align="left">{row.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

// Author: sonal

import React, { useEffect, useRef, useState } from "react";
import "../Styles/reports.css";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
// import { BarChart } from '@mui/x-charts/BarChart';
// import { axisClasses } from '@mui/x-charts';
// import { LineChart } from '@mui/x-charts/LineChart';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  AreaChart,
  Area,
  PieChart,
  Pie,
} from "recharts";
import {
  CUSTOMER_LIST,
  PRODUCT_LIST,
  QUOTATION_LIST,
  SALES_ORDER_LIST,
  SALES_REP_DASHBOARD_DATA,
} from "../config/config";
import html2canvas from "html2canvas";
import DownloadIcon from "@mui/icons-material/Download";
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import jsPDF from "jspdf";

import borghini from "../assets/img/pensbrands/borghini.jpeg";
import Cerruti from "../assets/img/pensbrands/Cerruti.jpeg";
import Copic from "../assets/img/pensbrands/Copic.png";
import Hayman from "../assets/img/pensbrands/Hayman.png";
import Montblanc from "../assets/img/pensbrands/Montblanc.png";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupsIcon from "@mui/icons-material/Groups";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import productIcon from "../assets/img/product.png";
import salesIcon from "../assets/img/sales.png";
import { useTranslation } from "react-i18next";

const moment = require("moment");

export default function Reports() {
  const lineChartRef = React.useRef();
  const salesChartRef = useRef();
  const productChartRef = useRef();
  const salesAmountChartRef = useRef();
  const outreachCustomersChartRef = useRef();

  const handleDownloadLineChange = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;
    html2canvas(lineChartRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("line-chart.pdf");
      }
    );
  };

  const handleDownloadSalesChart = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;
    html2canvas(salesChartRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("sales-chart.pdf");
      }
    );
  };

  const handleDownloadProductChart = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;
    html2canvas(productChartRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("products-chart.pdf");
      }
    );
  };

  const handleDownloadSalesAmountChart = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;
    html2canvas(salesAmountChartRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("sales-amount-chart.pdf");
      }
    );
  };
  const handleDownloadOutreachCustomerChart = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;
    html2canvas(outreachCustomersChartRef.current, {
      dpi: dpi,
      scale: scale,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "pt", "letter");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("outreact-customer-chart.pdf");
    });
  };
  const { t } = useTranslation();
  const {
    totalOverview,
    quotationCreation,
    sales,
    products,
    topSellingProducts,
    salesAmounttxt,
    outreachCustomers,
  } = t("reports");

  const [totalQuotatinCreation, setTotalQuotatinCreation] = useState("");
  const [quotatinPending, setQuotatinPending] = useState("");
  const [totalProducts, setTotalProducts] = useState("");
  const [totalSales, setTotalSales] = useState("");
  const [totalSalesAmount, setTotalSalesAmount] = useState("");
  const [totalCustomer, setTotalCustomer] = useState("");
  const [allCustomerList, setAllCustomerList] = useState([]);
  const [femaleCustomers, setFemaleCustomers] = useState("");
  const [maleCustomers, setMaleCustomers] = useState("");
  const [underAgeCustomer, setUnderAgeCustomer] = useState("");
  const [marriedCustomer, setMarriedCustomer] = useState("");

  const [quotationList, setQuotationList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);

  console.log(salesAmount);

  console.log(femaleCustomers);

  const pieChartdata = [
    { name: "Female Customers", value: femaleCustomers },
    { name: "Male Customers", value: maleCustomers },
    { name: "Under Age Customers", value: underAgeCustomer },
    // { name: 'Group D', value: 200 },
  ];

  const data01 = [
    { name: "Male Customers", value: maleCustomers },
    { name: "Female Customers", value: femaleCustomers },
    { name: "Under Age Customers", value: underAgeCustomer },
    { name: "Married Customers", value: marriedCustomer },
  ];

  console.log(totalQuotatinCreation);

  const token = localStorage.getItem("token");
  const loggedInUser = localStorage.getItem("user");
  console.log(loggedInUser);

  let foundUser = JSON.parse(loggedInUser);

  console.log(foundUser);

  function calculateAge(dateOfBirth) {
    const today = new Date();

    const birthDate = new Date(dateOfBirth);

    const formattedDate = birthDate.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    console.log(formattedDate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  const getSalesRepDashboardData = async () => {
    await fetch(SALES_REP_DASHBOARD_DATA, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: foundUser.person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.error === false) {
          setTotalQuotatinCreation(
            data.data.Dashboard_data[0].total_quotation_creation
          );

          setQuotatinPending(
            data.data.Dashboard_data[0].total_pending_quotation
          );

          setTotalProducts(data.data.Dashboard_data[0].total_products);
          setTotalSales(data.data.Dashboard_data[0].total_sales);
          setTotalSalesAmount(data.data.Dashboard_data[0].total_sales_amount);
          setTotalCustomer(data.data.Dashboard_data[0].total_customers);
        }
      });
  };

  const getCustomerList = async () => {
    await fetch(CUSTOMER_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setAllCustomerList(data.data.Customer_list);
          const calculatedAge = calculateAge(
            data.data.Customer_list[0].date_of_birth
          );
        }
      });
  };

  const AllDocuments = [];

  function getMonthAbbreviation(dateString) {
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthIndex];
  }

  const monthsWithQuantity = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };
  const monthsWithQuantity1 = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  const getQuotationList = async () => {
    await fetch(QUOTATION_LIST, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        person_id: foundUser.person_id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          let getQuotationList = data.data.quatatation_list;
          getQuotationList.forEach((entry) => {
            const month = getMonthAbbreviation(entry.quotation_date);
            monthsWithQuantity[month] += parseInt(entry.total_item);
          });

          const result = Object.keys(monthsWithQuantity).map((month) => ({
            Month: month,
            Quantity: monthsWithQuantity[month],
          }));

          console.log(result);
          setQuotationList(result);
        }
      });
  };

  const salesOrderListFun = async () => {
    await fetch(SALES_ORDER_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "appplication/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data);
          let salesOrderList = data.data.sales_order_list;
          salesOrderList.forEach((entry) => {
            const month = getMonthAbbreviation(entry.sale_date);
            monthsWithQuantity[month] += parseInt(entry.total_item);
            monthsWithQuantity1[month] += Number(entry.total_amount);
          });
          console.log(monthsWithQuantity1);

          const result = Object.keys(monthsWithQuantity).map((month) => ({
            Month: month,
            Sales: monthsWithQuantity[month],
          }));
          const result1 = Object.keys(monthsWithQuantity1).map((month) => ({
            Month: month,
            SalesAmount: monthsWithQuantity1[month],
          }));
          console.log(result1);
          console.log(result);
          setSalesList(result);
          setSalesAmount(result1);
        }
      });
  };

  const getProducts = async () => {
    await fetch(PRODUCT_LIST, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          console.log(data);
          let productList = data.data.Product_list;
          productList.forEach((entry) => {
            const month = getMonthAbbreviation(
              entry.product_quantity_last_updated
            );
            monthsWithQuantity[month] += parseInt(entry.product_stock_quantity);
          });

          const result = Object.keys(monthsWithQuantity).map((month) => ({
            Month: month,
            Products: monthsWithQuantity[month],
          }));

          console.log(result);
          setProductList(result);
        }
      });
  };

  useEffect(() => {
    getSalesRepDashboardData();
    getCustomerList();
    getQuotationList();

    salesOrderListFun();
    getProducts();
  }, []);

  useEffect(() => {
    let storeAge = [];
    console.log(allCustomerList);
    const femaleFilter = allCustomerList.filter(
      (user) => user.gender === "Female"
    );

    const maleFilter = allCustomerList.filter((user) => user.gender === "Male");
    const marriedFilter = allCustomerList.filter(
      (user) => user.marital_status === "Married"
    );

    allCustomerList.map((ele) => {
      console.log(calculateAge(ele.date_of_birth));
      storeAge.push(calculateAge(ele.date_of_birth));
    });

    const ageFilter = storeAge.filter((age) => age > 18);

    setMarriedCustomer(marriedFilter.length);
    setUnderAgeCustomer(storeAge.length - ageFilter.length);
    setFemaleCustomers(femaleFilter.length);
    setMaleCustomers(maleFilter.length);

    console.log();
  }, [allCustomerList]);

  const exportChart = () => {
    const chartContainer = document.getElementById("chart-container");

    html2canvas(chartContainer).then((canvas) => {
      const chartImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const link = document.createElement("a");
      link.download = "chart.png";
      link.href = chartImage;
      link.click();
    });
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="common_card_bs reports-first-card">
        <h2 className="common_font">{totalOverview}</h2>

        <Box>
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-first-grid-div">
                <Typography className="common_font">
                  Quotation Created
                </Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  <FormatListBulletedIcon />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {totalQuotatinCreation}
                  </Typography>
                </Box>
              </div>
            </Grid>

            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-second-grid-div">
                <Typography className="common_font">
                  Pending Quotation{" "}
                </Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  <HourglassTopIcon />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {quotatinPending}
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-third-grid-div">
                <Typography className="common_font">Products</Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  {/* <ProductionQuantityLimitsIcon /> */}
                  <img
                    src={productIcon}
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {totalProducts}
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-forth-grid-div">
                <Typography className="common_font">Sales </Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  {/* <PersonIcon /> */}
                  <img
                    src={salesIcon}
                    alt=""
                    style={{ width: "25px", height: "25px" }}
                  />

                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {totalSales}
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-fifth-grid-div">
                <Typography className="common_font">Sales Amount</Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  <AttachMoneyIcon />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {totalSalesAmount}
                  </Typography>
                </Box>
              </div>
            </Grid>

            <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
              <div className="reports-first-card-sixth-grid-div">
                <Typography className="common_font">
                  Outreach Customers
                </Typography>
                <Box className="reports-first-card-first-grid-div-box">
                  <GroupsIcon sx={{ fontSize: "30px" }} />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="common_font secondary_text"
                  >
                    {totalCustomer}
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="reports-second-card">
        <Box className="common_card_bs reports-second-card-box-first">
          <div className="reports-second-card-box-first-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {quotationCreation}
            </Typography>
            <Typography
              className="common_font all_box_hover reports-export-div"
              sx={{ marginRight: "20px" }}
            >
              <DownloadIcon
                onClick={handleDownloadLineChange}
              // onClick={exportChart}
              />
              Export
            </Typography>
          </div>

          <ResponsiveContainer
            ref={lineChartRef}
            id="chart-container"
            width="100%"
            height="100%"
          >
            <LineChart
              width={500}
              height={300}
              // data={lineChartData}
              data={quotationList}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="uv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
              <Line type="monotone" dataKey="Quantity" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <Box className="common_card_bs reports-second-card-box-second">
          <div className="reports-second-card-box-second-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {sales}
            </Typography>
            <Typography
              className="common_font all_box_hover reports-export-div"
              sx={{ marginRight: "20px" }}
            >
              <DownloadIcon
                onClick={handleDownloadSalesChart}
              // onClick={exportChart}
              />
              Export
            </Typography>
          </div>

          <ResponsiveContainer ref={salesChartRef} width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              // data={barChartdata}
              data={salesList}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Sales"
                fill="#8884d8"
                shape={<TriangleBar />}
                label={{ position: "top" }}
              >
                {barChartdata.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </div>

      <div className=" reports-third-card">
        <Box className="common_card_bs reports-third-card-box-first">
          <div className="reports-third-card-box-first-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {products}
            </Typography>
            <Typography
              className="common_font all_box_hover reports-export-div"
              sx={{ marginRight: "20px" }}
            >
              <DownloadIcon
                onClick={handleDownloadProductChart}
              // onClick={exportChart}
              />
              Export
            </Typography>
          </div>
          <ResponsiveContainer ref={productChartRef} width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={productList}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="Products" fill="#8884d8" />
              {/* <Bar yAxisId="right" dataKey="Products" fill="#82ca9d" /> */}
            </BarChart>
          </ResponsiveContainer>
        </Box>

        <Box className="common_card_bs reports-third-card-box-second">
          <div className="reports-third-card-box-second-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {topSellingProducts}
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container spacing={1}>
              <Grid mt={5} item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img src={Copic} alt="" />
              </Grid>

              <Grid mt={5} item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img src={Montblanc} style={{ width: "200px" }} alt="" />
              </Grid>

              <Grid mt={3} item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img style={{ marginLeft: "10px" }} src={Cerruti} alt="" />
              </Grid>

              <Grid mt={3} item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img style={{ marginLeft: "-15px" }} src={borghini} alt="" />
              </Grid>
              <Grid mt={3} mb={3} item xl={6} lg={6} md={6} sm={6} xs={6}>
                <img
                  style={{ width: "150px", marginLeft: "20px" }}
                  src={Hayman}
                  alt=""
                />
              </Grid>
            </Grid>
          </div>
          {/* <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            width={500}
                            height={400}
                            data={areaChartdata}
                            margin={{
                                top: 50,
                                right: 30,
                                left: 0,
                                bottom: 50,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="Sales" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer> */}
        </Box>
      </div>

      <div className=" reports-third-card">
        <Box className="common_card_bs reports-third-card-box-first">
          <div className="reports-forth-card-box-first-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {salesAmounttxt}
            </Typography>
            <Typography
              className="common_font all_box_hover reports-export-div"
              sx={{ marginRight: "20px" }}
            >
              <DownloadIcon
                onClick={handleDownloadSalesAmountChart}
              // onClick={exportChart}
              />
              Export
            </Typography>
          </div>
          <ResponsiveContainer
            ref={salesAmountChartRef}
            width="100%"
            height="100%"
          >
            <LineChart
              width={500}
              height={300}
              // data={dashedChartdata}
              data={salesAmount}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 50,
              }}
              className="common_font"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="SalesAmount"
                stroke="#8884d8"
                strokeDasharray="5 5"
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#82ca9d"
                strokeDasharray="3 4 5 2"
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <Box className="common_card_bs reports-third-card-box-second">
          <div className="reports-forth-card-box-second-typo-div reports-export-div">
            <Typography className="common_font reports-charts-header-txt large-txt">
              {outreachCustomers}
            </Typography>
            <Typography
              className="common_font all_box_hover reports-export-div"
              sx={{ marginRight: "20px" }}
            >
              <DownloadIcon
                onClick={handleDownloadOutreachCustomerChart}
              // onClick={exportChart}
              />
              Export
            </Typography>
          </div>
          <ResponsiveContainer
            ref={outreachCustomersChartRef}
            width="100%"
            height="100%"
          >
            {/* <PieChart width={500} height={500}>
                            <Pie
                                data={pieChartdata} 
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                className='common_font'
                            >
                                {pieChartdata.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart> */}
            <PieChart width={400} height={400}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data01}
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
                label
              >
                {pieChartdata.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </div>
    </div>
  );
}

// line chart

const lineChartData = [
  {
    name: "Week-1",
    // uv: 4000,
    // pv: 2400,
    // amt: 2400,
    Quotation: 2400,
  },
  {
    name: "Week-2",
    uv: 3001,
    pv: 1398,
    amt: 2210,
    Quotation: 1398,
  },
  {
    name: "Week-3",
    uv: 2000,
    pv: 9800,
    amt: 2290,
    Quotation: 9800,
  },
  {
    name: "Week-5",
    uv: 2780,
    pv: 3908,
    amt: 2000,
    Quotation: 3908,
  },
  {
    name: "Week-6",
    uv: 1890,
    pv: 4800,
    amt: 2181,
    Quotation: 4800,
  },
  {
    name: "Week-7",
    uv: 2390,
    pv: 3800,
    amt: 2500,
    Quotation: 3800,
  },
  {
    name: "Week-8",
    uv: 3490,
    pv: 4300,
    amt: 2100,
    Quotation: 4300,
  },
];

// bar chart

const colors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "red",
  "pink",
  "00C49F",
];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3
    }
${x + width / 2}, ${y}
C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width
    }, ${y + height}
Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const barChartdata = [
  {
    name: "Week-1",
    uv: 4000,
    Quotation: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Week-2",
    uv: 3001,
    Quotation: 3001,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Week-3",
    uv: 2000,
    Quotation: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Week-4",
    uv: 2780,
    Quotation: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Week-5",
    uv: 1890,
    Quotation: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Week-6",
    uv: 2390,
    Quotation: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Week-7",
    uv: 3490,
    Quotation: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Week-8",
    uv: 1190,
    Quotation: 1190,
    pv: 4300,
    amt: 2100,
  },
];

// biaxis chart

const biAxisChartdata = [
  {
    name: "Week-1",
    uv: 4000,
    Products: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Week-2",
    uv: 3001,
    Products: 3001,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Week-3",
    uv: 2000,
    Products: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Week-4",
    uv: 2780,
    Products: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Week-5",
    uv: 1890,
    Products: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Week-6",
    uv: 2390,
    Products: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Week-7",
    uv: 3490,
    Products: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Week-8",
    uv: 2390,
    Products: 2390,
    pv: 4300,
    amt: 2100,
  },
];

// dashed line chart
const dashedChartdata = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    SalesAmount: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3001,
    pv: 1398,
    SalesAmount: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    SalesAmount: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    SalesAmount: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    SalesAmount: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    SalesAmount: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
  {
    name: "Sep",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
  {
    name: "Dec  ",
    uv: 3490,
    pv: 4300,
    SalesAmount: 4300,
    amt: 2100,
  },
];

// pie chart

const COLORS = ["#0088FE", "#F765AF", "#FFBB28", "#FF8042"];
// 0088FE

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

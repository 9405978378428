import React from 'react'
import moduleImg from '../../assets/img/modules.png'
import listOfModuleImg from '../../assets/img/ListofModules.png'
import '../../Styles/admindashboard.css'
import { Typography } from '@mui/material'
import { Box, Card, CardContent, } from "@mui/material";
export default function ListOfModules() {
    return (
        <div>

            <Card
                id="landing_card"
                className="landing_card landing_card_res common_card_bs list-of-modules-sub-card "
            >
                <CardContent style={{ width: "100% !important", height: "100% !important", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px !important" }} className="customer_details_card">
                    {/* <Typography className='primary_text list-of-modules-txt'>List of Modules</Typography> */}
                    {/* <div className='list-of-modules-sub-div'> */}
                    {/* <img src={listOfModuleImg} alt="moduleImg" className='list-of-modules-img' /> */}
                    <img src={listOfModuleImg} alt="moduleImg" className='list-of-modules-img2' />
                    {/* </div> */}
                </CardContent>
            </Card>
        </div>
    )
}

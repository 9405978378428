// Author: sonal

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "../../Styles/users.css";
import "../../Styles/customers.css";
import profilePhoto from "../../assets/img/user.jpg";
import profilebg from "../../assets/img/2.jpg";
import { Button, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { OutlinedInput } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import uploadProfileImage from "../../assets/img/upload_profile.png";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {
  CHECK_TWO_FA_STATUS,
  CUSTOMER_DETAILS,
  REGISTER_DEVICE,
  SIGNOUT,
  TWO_FA_STATUS,
  USER_CHANGE_PASSWORD,
  USER_LOGIN_DETAILS,
  UPLOAD_CUSTOMER_PROFILE,
  GET_CUSTOMER_PROFILE_IMAGE,
} from "../../config/config";

import { useLocation, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Pagination } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  hitNotificationFun,
  hitGetUserProfile,
} from "../Redux/actions/actions";

const moment = require("moment");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserProfile() {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(UPLOAD_CUSTOMER_PROFILE);

  const loggedInUser = localStorage.getItem("user");
  let foundUser = JSON.parse(loggedInUser);

  const reloadUserImage = useSelector((state) => state.getUserProfileRedux);
  console.log(reloadUserImage);

  const [passwordModalOpen, setPasswordModalOpen] = React.useState(false);
  const [password, setPassword] = useState("");
  const [changePasswordAlert, setChangePasswordAlert] = useState(false);
  const [changePasswordAlertMsg, setchangePasswordAlertMsg] = useState("");
  const [personId, setPersonId] = useState("");
  const [personDisplayId, setPersonDisplayId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [occupation, setOccupation] = useState("");
  const [age, setAge] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [customerContactDetails, setCustomerContactDetails] = useState([]);
  const [customerEmail, setCustomerEmail] = useState("");
  const [getFilterPhoneData, setGetFilterPhoneData] = useState([]);
  const [getFilterEmailData, setGetFilterEmailData] = useState([]);
  const [imageUrl, setProfileImageUrl] = useState("");
  console.log(imageUrl);
  const [loginDetails, setLoginDetails] = useState([]);
  const [lastLoginDetails, setLastLoginDetails] = useState([]);
  const [lastLoginTime, setLastLoginTime] = useState([]);
  const [lastLoginDate, setLastLoginDate] = useState([]);
  const [loginDetailsModal, setLoginDetailsModal] = useState(false);
  const [profileModal, setprofileModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userdata, setUserData] = useState({});
  const [twoFASecretStatus, setTwoFASecretStatus] = useState(false);
  const [showDisableAlert, setShowDisableAlert] = useState(false);
  const [disableAlertMsg, setDisableAlertMsg] = useState("");
  const [checkDisableAlertStatus, setCheckDisableAlertStatus] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [checkAlertStatus, setCheckAlertStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userImage, setuserImage] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [handleUploadAlert, sethandleUploadAlert] = useState(false);
  const [uploadImageMsg, setuploadImageMsg] = useState("");
  const [email, setEmail] = useState([]);
  const [contact, setContact] = useState([]);

  const [loader, setloader] = useState(false);
  console.log(userImage);

  let itemsPerPage;
  if (window.innerWidth > 600) {
    itemsPerPage = 10;
  } else {
    itemsPerPage = 3;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loginDetails.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClose = () => {
    setPasswordModalOpen(false);
  };

  const handleProfileModalClose = () => {
    setprofileModal(false);
    setuserImage(null);
  };

  const handleLoginClose = () => {
    setLoginDetailsModal(false);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const getCustomerDetails = async () => {
    await fetch(CUSTOMER_DETAILS, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ person_id: location.state.person_id }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.data);
        if (data.success === true) {
          const contact = [];
          contact.push(data.data.Customer_contact_details);
          const mappedContact = contact[0].filter(
            (item) => item.contact_type === "phone"
          );
          const mappedEmail = contact[0].filter(
            (item) => item.contact_type === "email"
          );
          setContact(mappedContact);
          setEmail(mappedEmail);
          setPersonId(data.data.Customer_details[0].person_id);
          setPersonDisplayId(data.data.Customer_details[0].person_display_id);
          setFirstName(data.data.Customer_details[0].first_name);
          setLastName(data.data.Customer_details[0].last_name);
          setGender(data.data.Customer_details[0].gender);
          setMaritalStatus(data.data.Customer_details[0].marital_status);
          setOccupation(data.data.Customer_details[0].occupation);
          const calculatedAge = calculateAge(
            data.data.Customer_details[0].date_of_birth
          );
          setAge(calculatedAge);
          setCustomerContactDetails(data.data.Customer_contact_details);
          console.log(data.data.Customer_contact_details[0].contact_details);
          setCustomerEmail(
            data.data.Customer_contact_details[0].contact_details
          );
        }
      });
  };

  const userLoginDetails = async () => {
    await fetch(USER_LOGIN_DETAILS, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        user_id: foundUser?.user_id,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setLoginDetails(data.data.user_login_details);
          setLastLoginDetails(data.data.user_login_details[0]);
          const timestamp =
            data.data.user_login_details[0].web_app_user_last_login;
          const formattedTimeAgo = timeAgo(timestamp);
          console.log(formattedTimeAgo);
          setLastLoginTime(formattedTimeAgo);
        }
      });
  };

  const changeUserPassword = async () => {
    await fetch(USER_CHANGE_PASSWORD, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
      body: JSON.stringify({
        user_id: foundUser?.user_id,
        password: password,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setPasswordModalOpen(false);
          setchangePasswordAlertMsg(data.message);
          setChangePasswordAlert(true);
          dispatch(hitNotificationFun(true));
        }
      });
  };

  const handleLogout = () => {
    fetch(SIGNOUT, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data?.responseData?.error === false) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    if (loggedInUser) {
      setUserData(foundUser);
      fetch(CHECK_TWO_FA_STATUS, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          userId: foundUser?.person_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            console.log(data.data[0]?.two_factor);
            setTwoFASecretStatus(data.data[0]?.two_factor);
          }
        });
    }
  }, []);

  const handleDisableTwoFA = () => {
    let foundUser;
    if (loggedInUser) {
      foundUser = JSON.parse(loggedInUser);
      fetch(TWO_FA_STATUS, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          email: foundUser.user_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            setTwoFASecretStatus(false);
            setShowDisableAlert(true);
            setDisableAlertMsg(data.message);
            setCheckDisableAlertStatus(true);
          } else {
            setShowDisableAlert(true);
            setDisableAlertMsg("Something went wrong! Please retry");
            setCheckDisableAlertStatus(false);
          }
        });
    }
  };

  const handleEnableTwoFA = async () => {
    await fetch(REGISTER_DEVICE, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        userdata: userdata,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setShowErrorAlert(true);
          setCheckAlertStatus(true);
          setAlertMessage("Email sent successfully!");
        } else {
          setShowErrorAlert(true);
          setCheckAlertStatus(false);
          setAlertMessage("Something went wrong! Please retry");
        }
      });
  };
  //customer profile image
  const person_id = location.state.person_id;
  const getUserProfile = () => {
    fetch(GET_CUSTOMER_PROFILE_IMAGE, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
        "Content-Type": "application/json", // Set the content type to JSON
        token: token,
      },
      body: JSON.stringify({ person_id: person_id }), // Stringify the JSON data
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setProfileImageUrl(`${data.url}?timestamp=${Date.now()}`);
        } else {
          setProfileImageUrl("");
        }

        // Call your setimage function here if needed
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  const uploadCustomerProfileImage = async (event) => {
    setloader(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("person_id", location.state.person_id);
    formData.append("images", userImage);

    Axios.post(UPLOAD_CUSTOMER_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        setprofileModal(false);
        sethandleUploadAlert(true);
        setuploadImageMsg(response.data.message);
        dispatch(hitGetUserProfile(true));
        setTimeout(() => {
          dispatch(hitGetUserProfile(false));
        }, 200);
        getUserProfile();
        setuserImage(null);
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    userLoginDetails();
  }, []);

  function calculateAge(dateOfBirth) {
    const today = new Date();

    const birthDate = new Date(dateOfBirth);

    const formattedDate = birthDate.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    console.log(formattedDate);
    setDateOfBirth(formattedDate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  function timeAgo(timestamp) {
    const currentTime = new Date();
    const providedTime = new Date(timestamp);
    const formattedDate = providedTime.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    console.log(formattedDate);
    setLastLoginDate(formattedDate);
    const timeDifference = currentTime - providedTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);

    if (minutes < 1) {
      return "Just now";
    } else if (minutes === 1) {
      return "1 minute ago";
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else {
      const hours = Math.floor(minutes / 60);
      if (hours === 1) {
        return "1 hour ago";
      } else if (hours < 24) {
        return `${hours} hours ago`;
      } else {
        const days = Math.floor(hours / 24);
        if (days === 1) {
          return "1 day ago";
        } else {
          return `${days} days ago`;
        }
      }
    }
  }

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (reloadUserImage === true) {
      getUserProfile();
    }
  }, [reloadUserImage]);

  useEffect(() => {
    console.log(customerContactDetails);
    let filterPhoneData = [];
    let filterEmailData = [];
    customerContactDetails.map((fpd) => {
      console.log(fpd);
      if (fpd.contact_type === "phone") {
        filterPhoneData.push(fpd);
      } else {
        filterEmailData.push(fpd);
      }
    });
    console.log(filterPhoneData);
    console.log(filterEmailData);
    setGetFilterPhoneData(filterPhoneData);
    setGetFilterEmailData(filterEmailData);
  }, [customerContactDetails]);

  const handleDisableTWOFAAlertClose = () => {
    setShowDisableAlert(false);
  };

  const handleAlertClose = () => {
    setShowErrorAlert(false);
  };
  const handleChangePasswordAlertClose = () => {
    setChangePasswordAlert(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    const files = e.dataTransfer.files;
    handleFile(files[0]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
    console.log(file);
  };

  const handleFile = (file) => {
    console.log(file);
    if (file) {
      // Check if the selected file type is allowed
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        setuserImage(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        // Display an error message for unsupported file types
        setShowErrorAlert(true);
        setCheckAlertStatus(false);
        setAlertMessage(
          "Unsupported file type. Please upload a PNG or JPG file."
        );
      }
    }
  };
  const handleuploadPhotoAlertClose = () => {
    sethandleUploadAlert(false);
  };

  const userType = localStorage.getItem("user_type");

  return (
    <div style={{ marginBottom: "80px" }}>
      <Snackbar
        open={changePasswordAlert}
        onClose={handleChangePasswordAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleChangePasswordAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">
            {changePasswordAlertMsg}
          </Typography>
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {checkAlertStatus ? (
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            <Typography className="common_font">{alertMessage}</Typography>
          </Alert>
        ) : (
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%" }}
            className="common-error-alert"
          >
            <Typography className="common_font">{alertMessage}</Typography>
          </Alert>
        )}
      </Snackbar>

      <Snackbar
        open={showDisableAlert}
        onClose={handleDisableTWOFAAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {checkDisableAlertStatus ? (
          <Alert
            onClose={handleDisableTWOFAAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            <Typography className="common_font">{disableAlertMsg}</Typography>
          </Alert>
        ) : (
          <Alert
            onClose={handleDisableTWOFAAlertClose}
            severity="error"
            sx={{ width: "100%" }}
            className="common-error-alert"
          >
            <Typography className="common_font">{disableAlertMsg}</Typography>
          </Alert>
        )}
      </Snackbar>

      <Snackbar
        open={handleUploadAlert}
        onClose={handleuploadPhotoAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleuploadPhotoAlertClose}
          severity="success"
          sx={{ width: "100%" }}
          className="common-success-alert"
        >
          <Typography className="common_font">{uploadImageMsg}</Typography>
        </Alert>
      </Snackbar>

      <Modal
        open={passwordModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="customer_details_selector_modal">
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              className="update_username_main"
            >
              <h3
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <KeyIcon fontSize="medium" sx={{ marginRight: "5px" }} />
                Reset Password
              </h3>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel className="common_font" htmlFor="first-name">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="First Name"
                      label="First Name"
                      name="First Name"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputLabelProps={{
                        className: "common_font",
                      }}
                      inputProps={{
                        className: "common_font",
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {password ? (
                    <Button
                      className="common-button common_font"
                      onClick={() => {
                        changeUserPassword();
                      }}
                      variant="contained"
                      type="submit"
                    >
                      continue
                    </Button>
                  ) : (
                    <Button
                      className="common-button common_font"
                      variant="contained"
                      type="submit"
                    >
                      continue
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      </Modal>

      {/* login details modal  */}

      <Modal
        open={loginDetailsModal}
        onClose={handleLoginClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box className="user-profile-login-details-modal">
            <Typography
              sx={{ fontSize: "18px", marginBottom: "20px" }}
              className="common_font"
            >
              Login Details
            </Typography>
            <TableContainer className="table-container">
              <Table size="small" aria-label="a dense table">
                <TableHead className="table-head">
                  <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      Status
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      Type
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      IP
                    </TableCell>
                    <TableCell className="common_font log_table_table_head head-table-cell">
                      Date/ Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="">
                  {currentItems &&
                    currentItems.map((gpl, index) => {
                      return (
                        <TableRow key={index} className="table-row-even">
                          <TableCell className="body-table-cell common_font">
                            {gpl.login_status === "success" ? (
                              <Typography className="user-profile-recent-login-status-txt common_font">
                                {gpl.login_status}
                              </Typography>
                            ) : (
                              <Typography className="user-profile-recent-login-status-f-txt common_font">
                                {gpl.login_status}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell className="body-table-cell common_font">
                            {gpl.login_type}
                          </TableCell>
                          <TableCell className="body-table-cell common_font">
                            {gpl.login_from_ip}
                          </TableCell>
                          <TableCell className="common_font">
                            {formattimeStampMoment(gpl.web_app_user_last_login)}
                            / {formatclient(gpl.web_app_user_last_login)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box className="pagination-box">
              <Pagination
                className="pagination"
                count={Math.ceil(loginDetails.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size="large"
                siblingCount={1}
                boundaryCount={1}
              />
            </Box>
          </Box>
        </>
      </Modal>

      <Modal
        open={profileModal}
        onClose={handleProfileModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={uploadCustomerProfileImage}>
          <Box className="user-profile-change">
            <Box
              onClick={handleProfileModalClose}
              className="close-profile-modal"
            >
              <CloseIcon />
            </Box>
            <h3 className="common_font">Change Profile Photo</h3>
            <Box
              className={`drag-drop-image ${dragOver ? "drag-over" : ""}`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
                <input
                  type="file"
                  id="upload-photo"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept={"image/png" || "image/jpeg" || "image/jpg"}
                />
                <Typography
                  className="common_font"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {userImage ? (
                    <img src={previewUrl} alt="Preview" width={90} />
                  ) : (
                    <img
                      src={uploadProfileImage}
                      alt="uploadProfileImage"
                      width={90}
                    />
                  )}
                  {userImage ? null : "Drag & Drop"}
                </Typography>
              </label>
            </Box>
            {userImage ? (
              <Typography className="common_font">{userImage.name}</Typography>
            ) : null}
            <Box className="upload-document">
              {userImage ? (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    className="common_font"
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    Upload Photo{" "}
                    {loader ? (
                      <CircularProgress
                        sx={{ marginLeft: "20px " }}
                        color="inherit"
                        size="20px"
                      />
                    ) : null}
                  </Button>
                  {/* <Button variant="contained" color="warning"
                  onClick={handleClearButtonClick}
                  >
                    Clear
                  </Button> */}
                </Box>
              ) : (
                <Button className="common_font" variant="contained" disabled>
                  Upload Photo
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Modal>

      <Box
        sx={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}
        className=" user-profile-first-card common_card_bs"
      >
        <div>
          <img
            src={profilebg}
            alt="profilebg"
            className="user-profile-bg-img"
          />
        </div>

        <Box className="user-profile-profile-main-box">
          <div className="user-profile-profile-div">
            <div
              className="user_profile_picture_main"
              onClick={() => setprofileModal(true)}
            >
              {imageUrl !== "" ? (
                <img
                  src={`${imageUrl}?timestamp=${Date.now()}`}
                  className="user-profile-first-card-profile-img"
                  alt="userProfile"
                />
              ) : (
                <img
                  src={profilePhoto}
                  className="user-profile-first-card-profile-img"
                  alt="userProfile"
                />
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 0,
                  cursor: "pointer",
                }}
              >
                <AddAPhotoIcon style={{ color: "#ffff", fontSize: "25px" }} />
              </div>
            </div>

            <Typography className="user-profile-first-card-profile-img-name"></Typography>
            <Typography className="common_font">
              {firstName} {lastName}
            </Typography>
          </div>

          <div className="user-profile-details-div">
            <Box className="user-profile-details-div-box">
              <div>
                <Typography className="common_font">
                  Person Display ID
                </Typography>
                <Typography className="common_font">
                  {personDisplayId}
                </Typography>
              </div>
              <div>
                <Typography className="common_font">Occupation</Typography>
                <Typography className="common_font">{occupation}</Typography>
              </div>
            </Box>

            <Box className="user-profile-details-div-box-2">
              <div>
                <Typography className="common_font">Age</Typography>
                <Typography className="common_font">{age}</Typography>
              </div>
            </Box>
          </div>
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Box className="user-profile-second-card-sub-box">
          <div className="user-profile-second-card-first-div common_card_bs">
            <Box className="user-profile-second-card-first-div-head-box">
              <Typography className="user-profile-second-card-first-div-head-box-typograpy common_font">
                Personal Information
              </Typography>
            </Box>

            <Box className="user-profile-second-card-first-div-sub-box ">
              <div className="user-profile-second-card-first-div-sub-box-div-1">
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  Age
                </Typography>
                <Typography className="common_font">{age}</Typography>
              </div>
              <div className="user-profile-second-card-first-div-sub-box-div">
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  Marital Status
                </Typography>
                <Typography className="common_font">{maritalStatus}</Typography>
              </div>
              <div
                className="user-profile-second-card-first-div-sub-box-div"
                style={{ marginRight: "20px" }}
              >
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  Country
                </Typography>
                <Typography className="common_font">US</Typography>
              </div>
            </Box>

            <Box className="user-profile-second-card-first-div-sub-box">
              <div className="user-profile-second-card-first-div-sub-box-div-1">
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  Role
                </Typography>
                <Typography className="common_font">{userType}</Typography>
              </div>
              <div className="user-profile-second-card-first-div-sub-box-div">
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  Gender
                </Typography>
                <Typography className="common_font">{gender}</Typography>
              </div>
              <div
                className="user-profile-second-card-first-div-sub-box-div"
                style={{ marginRight: "20px" }}
              >
                <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                  DOB
                </Typography>
                <Typography className="common_font">{dateOfBirth}</Typography>
              </div>
            </Box>
          </div>

          <div className="user-profile-second-card-first-div user-profile-second-card-contact common_card_bs">
            <Box className="user-profile-second-card-first-div-head-box">
              <Typography className="user-profile-second-card-first-div-head-box-typograpy common_font">
                Contacts
              </Typography>
            </Box>

            <Box className="user-profile-second-card-first-div-sub-box ">
              {contact.length > 0 ? (
                <>
                  {contact.slice(0, 1).map((contact, index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="user-profile-second-card-contact-div">
                          <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                            Phone Number
                          </Typography>
                          <Typography className="common_font">
                            {contact.contact_details}
                          </Typography>
                        </div>
                      </Box>
                    );
                  })}
                </>
              ) : null}
              {contact.length > 0 ? (
                <>
                  {contact.slice(1, 2).map((contact, index) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="user-profile-second-card-contact-div">
                          <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                            Alternate Number
                          </Typography>
                          <Typography className="common_font">
                            {contact.contact_details}
                          </Typography>
                        </div>
                      </Box>
                    );
                  })}
                </>
              ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {email.length > 0 ? (
                <>
                  {email.slice(0, 1).map((email, index) => {
                    console.log(email);
                    return (
                      <Box
                        className="user-profile-second-card-first-div-sub-box"
                        key={index}
                      >
                        <div className="user-profile-second-card-contact-div">
                          <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                            Email Address
                          </Typography>
                          <Typography className="common_font">
                            {email.contact_details}
                          </Typography>
                        </div>
                      </Box>
                    );
                  })}
                </>
              ) : null}
              {email.length > 0 ? (
                <>
                  {email.slice(1, 2).map((email, index) => {
                    console.log(email);
                    return (
                      <Box
                        className="user-profile-second-card-first-div-sub-box"
                        key={index}
                      >
                        <div className="user-profile-second-card-contact-div">
                          <Typography className="user-profile-second-card-first-div-sub-box-div-heading common_font">
                            Alternate Address
                          </Typography>
                          <Typography className="common_font">
                            {email.contact_details}
                          </Typography>
                        </div>
                      </Box>
                    );
                  })}
                </>
              ) : null}
            </Box>
          </div>
        </Box>

        <Box
          className="user-profile-second-card-sub-box"
          sx={{ marginTop: "50px" }}
        >
          <div className="user-profile-second-card-first-div common_card_bs">
            <Box className="user-profile-second-card-first-div-head-box">
              <Typography className="user-profile-second-card-first-div-head-box-typograpy common_font">
                Recent Login Status
              </Typography>
            </Box>

            <Box
              sx={{ flexDirection: "column" }}
              className="user-profile-second-card-first-div-sub-box "
            >
              <div className="user-profile-common-display">
                <Typography className="user-profile-recent-login-status-typography common_font">
                  Login Status{" "}
                </Typography>
                <Typography className="user-profile-recent-login-status-txt common_font">
                  {lastLoginDetails.login_status}
                </Typography>
              </div>

              <div
                style={{ marginTop: "10px" }}
                className="user-profile-common-display"
              >
                <Typography className="user-profile-recent-login-status-typography common_font">
                  Login Type{" "}
                </Typography>
                <Typography className="common_font">
                  {lastLoginDetails.login_type}
                </Typography>
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="user-profile-common-display"
              >
                <Typography className="user-profile-recent-login-status-typography common_font">
                  Login IP{" "}
                </Typography>
                <Typography className="common_font">
                  {" "}
                  {lastLoginDetails.login_from_ip}
                </Typography>
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="user-profile-common-display"
              >
                <Typography className="user-profile-recent-login-status-typography common_font">
                  Last Login Time{" "}
                </Typography>
                <Typography className="common_font">
                  {lastLoginTime}/ {lastLoginDate}
                </Typography>
              </div>

              <Typography
                onClick={() => {
                  setLoginDetailsModal(true);
                }}
                className="common_font small-txt user-profile-login-status-lrn-typo all_box_hover"
              >
                Learn More
              </Typography>
            </Box>
          </div>

          <div className="user-profile-second-card-first-div user-profile-second-card-contact common_card_bs">
            <Box className="user-profile-second-card-first-div-head-box">
              <Typography className="user-profile-second-card-first-div-head-box-typograpy common_font">
                Account Settings
              </Typography>
            </Box>

            <Box className="user-profile-second-card-first-div-sub-box">
              <div className="user-profile-second-card-account-div">
                <Button
                  sx={{ width: "190px" }}
                  onClick={() => {
                    setPasswordModalOpen(true);
                  }}
                  className="common-button common_font"
                >
                  Reset Password
                </Button>
              </div>
            </Box>

            <Box className="user-profile-second-card-account-box">
              <div className="user-profile-second-card-account-div">
                {twoFASecretStatus ? (
                  <Button
                    onClick={handleDisableTwoFA}
                    sx={{ width: "190px", marginTop: "10px" }}
                    className="common-button common_font"
                  >
                    Disable 2FA
                  </Button>
                ) : (
                  <Button
                    onClick={handleEnableTwoFA}
                    sx={{ width: "190px", marginTop: "10px" }}
                    className="common-button common_font"
                  >
                    Enable 2FA
                  </Button>
                )}
              </div>
            </Box>

            <Box className="user-profile-second-card-account-box">
              <div className="user-profile-second-card-account-div">
                <Button
                  onClick={handleLogout}
                  sx={{ width: "190px", marginTop: "10px" }}
                  className="common-button common_font"
                >
                  Logout
                </Button>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  );
}

// Author: sonal

/** @format */

//import from packages
import "../../../Styles/signup.css";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, TextField, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import { useAlert } from "../../alert/alert-context";
//config
import { SIGNUP_URL, SIGNUP_WQR } from "../../../config/config";
import Snackbar from '@mui/material/Snackbar';

//images
import signupImage from "../../../assets/img/png/signup.png";
import logo from "../../../assets/img/bodex_logo.png";

//css
import "../../../Styles/login.css";
import "../../../index.css";
import PERMISSIONS from "../../../permissions/permissions";

export default function SignUpForm() {
  const navigate = useNavigate();
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [base64String, setBase64String] = useState();
  const [activate2FA, setActivate2FA] = useState(false);
  const [secret, setSecret] = useState("NO-SECRET");
  const [twoFAConfig, setTwoFAConfig] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  let userPermission = JSON.stringify(PERMISSIONS)
  const handleAlertClose = () => {
    setShowLoginAlert(false);
    setShowErrorAlert(false);
  };



  // const handleChange = (event) => {
  //   setActivate2FA(event.target.checked);
  // };

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const Logo = () => <img src={logo} alt="logo" className="logo" />;
  const QRCode = () => <img src={imageUrl} alt="DecodedImage" />;

  const validatePassword = (input) => {
    // Password validation criteria: Minimum 8 characters
    return input.length >= 8;
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);

    // Check for password validation
    const isValid = validatePassword(inputValue);
    setPasswordError(!isValid);

    // Check if passwords match
    setPasswordMatchError(confirmPassword !== inputValue);
  };

  const handleConfirmPasswordChange = (e) => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);

    // Check if passwords match
    setPasswordMatchError(password !== inputValue);
  };

  // const handleGoToSignInClick = () => {
  //   navigate("/login");
  // };

  const validateEmail = (input) => {
    // Basic email validation, you can customize it based on your requirements
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Check for email validation
    setEmailError(!validateEmail(inputValue));
  };

  useEffect(() => {
    if (base64String) {
      console.log(base64String);
      // const [, encodedData] = base64String.split(',');
      const encodedData = base64String.split(",")[1]; // Remove the prefix
      if (encodedData) {
        console.log(encodedData);
        const binaryData = atob(encodedData);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: "image/png" });
        const url = URL.createObjectURL(blob);
        setImageUrl(url);

        // Cleanup function to revoke the object URL when the component unmounts
        return () => URL.revokeObjectURL(url);
      }
    }
  }, [base64String]);

  const callUserSignUp = async () => {
    if (activate2FA) {
      await fetch(SIGNUP_WQR, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          include2FA: activate2FA,
          useremail: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.success === true) {
            if (data.data?.qrCode) {
              setBase64String(data.data.qrCode);
              setSecret(data.data.secret);
              setTwoFAConfig({
                qrCode: data.data.qrCode,
                otpAuthUrl: data.data.otpAuthUrl,
              });
            } else {
              if (data.message === "User registered successfully!") {
                setShowErrorAlert(true);
                setShowLoginAlert(true)
                setAlertMessage("User registered successfully!");
                setTimeout(() => {
                  navigate("/login");
                }, 3001);
              } else if (data.message === "User already exists!") {
                setShowErrorAlert(true);
                setShowLoginAlert(false)
                setAlertMessage("User already exists!");
              }
            }
          }
        });
    } else {
      userSignUp();
    }
  };
  const userSignUp = async () => {
    await fetch(SIGNUP_URL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        useremail: email,
        username: email,
        password: password,
        usertype: "Admin",
        userpermission: [PERMISSIONS.CAN_VIEW_DASH],
        include2FA: activate2FA,
        secret: secret,
        twofaconfig: twoFAConfig,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          if (data.data?.qrCode) {
            setBase64String(data.data.qrCode);
          } else {
            if (data.message === "User registered successfully!") {
              setShowErrorAlert(true);
              setShowLoginAlert(true)

              setAlertMessage(
                "User registered successfully! Please login to get started."
              );
              setTimeout(() => {
                navigate("/login");
              }, 3001);
            } else if (data.message === "User already exists!") {
              setShowErrorAlert(true);
              setShowLoginAlert(false)
              setAlertMessage("User already exists!");
            }
          }
        }
      });
  };

  const handleGoToSignInClick = () => {
    console.log(firstName, lastName, email, password, "Admin", activate2FA);
    userSignUp();
    navigate("/login");
  };

  const isFormValid =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    email.trim() !== "" &&
    password.trim() !== "" &&
    confirmPassword.trim() !== "" &&
    password === confirmPassword &&
    !emailError &&
    !passwordError &&
    !passwordMatchError;


  return (
    <div className="login-container">


      <Snackbar
        open={showErrorAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        {
          showLoginAlert ? <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
            className="common-success-alert"
          >
            {alertMessage}
          </Alert> :
            <Alert
              onClose={handleAlertClose}
              severity="error"
              sx={{ width: "100%" }}
              className="common-error-alert"
            >
              {alertMessage}
            </Alert>
        }

      </Snackbar>
      <Card className="login-page css-selector" id="signup-login-card">
        <img src={logo} alt="LOGO" className="bodex_logo" id="logo" />

        {imageUrl ? (
          <CardContent id="qr-code-card">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={10} md={8} lg={6} id="qr-code-grid">
                <Stack spacing={3}>
                  <Typography variant="h4" className="heading" id="qr-code-heading">
                    Scan the below QRCode
                  </Typography>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    {imageUrl ? <QRCode id="qrcode-component" /> : <p>Loading image...</p>}
                  </Stack>
                  <center>
                    <Typography variant="h8" id="qr-code-instructions">
                      Use your device to scan the QR code for enabling the Two-factor Authentication.
                    </Typography>
                  </center>
                  <center>
                    <Button
                      className="css-selector button"
                      variant="contained"
                      onClick={handleGoToSignInClick}
                      id="go-to-signin-button"
                    >
                      Go to Signin
                    </Button>
                  </center>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <CardContent id="signup-card">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={10} md={8} lg={6} id="signup-grid">
                <Stack spacing={3}>
                  <Typography variant="h4" className="heading" id="signup-heading">
                    SIGN UP
                  </Typography>
                  <Stack direction="row" spacing={2} justifyContent="center" id="name-inputs">
                    <TextField
                      label="First Name"
                      variant="outlined"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      className="textfield-container"
                    />
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      className="textfield-container"
                    />
                  </Stack>
                  <TextField
                    label="Email"
                    variant="outlined"
                    id="user"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                    className="textfield-container"
                    error={emailError}
                    helperText={
                      emailError ? "Please enter a valid email address" : ""
                    }
                  />

                  <TextField
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    fullWidth
                    required
                    error={passwordError}
                    helperText={
                      passwordError
                        ? "Password must be at least 8 characters"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handlePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    className="outlined-input"
                    error={passwordMatchError}
                    helperText={
                      passwordMatchError ? "Password does not match" : ""
                    }
                  />

                  <p>
                    Already have an account?{" "}
                    <a
                      style={{ color: "blue" }}
                      className="pointer"
                      onClick={handleGoToSignInClick}
                      id="signin-link"
                    
                    >
                      SIGN-IN
                    </a>
                  </p>
                  <center>
                    {isFormValid ? (
                      <Button
                        className="css-selector sign_button"
                        variant="contained"
                        onClick={callUserSignUp}
                        id="signup-button"
                      >
                        SIGN UP
                      </Button>
                    ) : (
                      <Button
                        className="css-selector disabled-button"
                        variant="contained"
                        disabled
                        id="disabled-signup-button"
                      >
                        SIGN UP
                      </Button>
                    )}
                  </center>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>


    </div>
  );
}

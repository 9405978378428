import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import WarningIcon from "@mui/icons-material/Warning";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Chip,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GET_USER_ORG,
  QUOTATION_DETAILS,
  SEND_QUOTATION,
  QUOTATION_DETAILS_BY_DISPLAY_ID,
} from "../../config/config";
import { displaytimestampasperclient } from "../../utils/dateutils";
import PdfLayout from "../PDFLayout/PDFLayout";
import QRCode from "qrcode.react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SiteLogo from "../../assets/img/ThePenCompany.png";
import SiteLogo2 from "../../assets/img/thePenComapyNewLogo.png";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "../../Styles/salesdetails.css";
import SendQuotation from "../PDFLayout/SendQuotation";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f3f4",
  },
}));

const SalesDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const [quotation_details, setQuotationDetails] = useState([]);
  const [org_details, setOrgDetails] = useState([]);
  const [file, setFile] = useState("");
  const [contact_details, setContactDetails] = useState([]);
  const [quotation_product_list, setQuotationProductList] = useState([]);
  const [quotation_product_list_count, setQuotationProductListCount] =
    useState(0);
  const [contact_number, setContactNumber] = useState(null);
  const [contact_email, setContactEmail] = useState(null);
  const [all_email, setAllEmail] = useState([]);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [snackseverity, setSnackSeverity] = useState("");
  const [updateAlertMsg, setUpdateAlertMsg] = useState("");

  const [isEmailChecked, setIsEmailChecked] = useState([]);
  const [sendingQuotation, setSendingQuotation] = useState(false);

  const handleAlertClose = () => {
    setUpdateAlert(false);
  };

  const [lng, setLng] = useState(i18n.language);

  const qrData = "https://bsa.bodex.io";

  const qrDatajsonString = JSON.stringify(qrData);

  useEffect(() => {
    getQuotationDetails();
    getUserOrg();
  }, []);

  const { id } = useParams();

  const getQuotationDetails = async () => {
    await fetch(QUOTATION_DETAILS_BY_DISPLAY_ID, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        quotation_display_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          setQuotationDetails(data.data.quotation_details);
          setContactDetails(data.data.contact_details);
          if (data.data.contact_details.length > 0) {
            var phone = data.data.contact_details.filter(
              (item) => item.contact_type === "phone"
            );
            var email = data.data.contact_details.filter(
              (item) => item.contact_type === "email"
            );
            setContactNumber(phone[0]?.contact_details);
            setContactEmail(email[0]?.contact_details);
            if (email && email[0]) {
              setIsEmailChecked([...isEmailChecked, email[0].contact_details]);
            }
            setAllEmail(email);
          }
          setQuotationProductList(data.data.quotation_product_list);
          setQuotationProductListCount(data.data.quotation_product_list.length);
        }
      });
  };

  const getUserOrg = async () => {
    await fetch(GET_USER_ORG, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === true) {
          console.log(data.data);
          setOrgDetails(data.data.user_org_details);
        }
      });
  };

  let commonData = {};
  commonData["quotation_details"] = quotation_details;
  commonData["quotation_product_list"] = quotation_product_list;
  commonData["contact_details"] = contact_details;
  commonData["qrData"] = qrDatajsonString;

  const viewQuotationPdf = () => {
    navigate(
      `/en/quotes/view-quote/${quotation_details[0]?.quotation_display_id}`,
      {
        state: {
          quotation_details,
          contact_details,
          quotation_product_list,
          qrDatajsonString,
        },
      }
    );
  };

  const editQuotation = () => {
    navigate(`/${lng}/dashboard/customer-list/customer-details`, {
      state: { cl: quotation_details[0], status: "editQuotation" },
    });
  };

  const sendQuotationPdf = async (file, quotation_details) => {
    setSendingQuotation(true);
    await fetch(SEND_QUOTATION, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        uploadedfile: file,
        email_id: isEmailChecked,
        person_id: quotation_details[0].person_id,
        customer_name:
          quotation_details[0]?.customer_first_name +
          " " +
          quotation_details[0]?.customer_last_name,
        quotation_display_id: quotation_details[0]?.quotation_display_id,
        quotation_id: quotation_details[0]?.quotation_id,
        sales_rep: JSON.parse(user).userfullname,
      }),
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === false) {
          setSendingQuotation(false);
          getQuotationDetails();
          setState({ ...state, ["right"]: false });
          setUpdateAlertMsg(data.message);
          setSnackSeverity("success");
          setUpdateAlert(true);
        } else {
          setUpdateAlertMsg(data.message);
          setSnackSeverity("error");
          setUpdateAlert(true);
        }
      });
  };

  const uploadFile = (file, quotation_details) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    if (file !== null) {
      blobToBase64(file).then((res) => {
        setFile(res);
        sendQuotationPdf(res, quotation_details);
      });
    }
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleCheckboxChange = (event) => {
    var check = isEmailChecked.filter((item) => item === event.target.value);
    if (check.length > 0) {
      var removeUncheckEmail = isEmailChecked.filter(
        (item) => item !== event.target.value
      );
      setIsEmailChecked(removeUncheckEmail);
    } else {
      setIsEmailChecked([...isEmailChecked, event.target.value]);
    }
  };

  const list = (anchor) => (
    <Box sx={{ width: 700, padding: "20px" }} role="presentation">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography
            variant="h6"
            className="common_font"
            style={{ fontWeight: "bold" }}
          >
            Send Quotation
          </Typography>
          {quotation_details[0]?.quotation_approval_status ? (
            <Chip
              label={quotation_details[0]?.quotation_approval_status}
              className="common_font"
              sx={{
                background: "#fec237",
                height: "25px !important",
                cursor: "pointer",
              }}
              variant="small"
            ></Chip>
          ) : null}
        </div>

        <div>
          <Button
            className="common_font common-button"
            variant="contained"
            sx={{
              background: "#f1f1f2 !important",
              color: "#000000 !important",
            }}
            onClick={toggleDrawer(anchor, false)}
          >
            Cancel
          </Button>
          {all_email.length > 0 && isEmailChecked.length > 0 ? (
            <BlobProvider
              document={<PdfLayout data={commonData} />}
              fileName="Quotation.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Button
                    className="common_font common-button"
                    variant="contained"
                    sx={{ marginLeft: "20px" }}
                    disabled
                  >
                    Send
                  </Button>
                ) : (
                  <LoadingButton
                    loading={sendingQuotation}
                    loadingPosition="end"
                    endIcon={<SendIcon />}
                    className="common_font common-button"
                    variant="contained"
                    sx={{ marginLeft: "20px" }}
                    onClick={() => uploadFile(blob, quotation_details)}
                  >
                    Send
                  </LoadingButton>
                )
              }
            </BlobProvider>
          ) : null}
        </div>
      </div>
      <div
        style={{
          minHeight: "750px",
          background: "#f8f8f8",
          padding: "20px",
          marginTop: "20px",
          borderRadius: "12px",
        }}
      >
        <div
          style={
            all_email.length > 0
              ? {}
              : { display: "flex", justifyContent: "flex-start" }
          }
        >
          <Typography variant="p" className="common_font">
            <b className="font-color-sales-details">To : </b>
          </Typography>
          <div>
            {all_email.length > 0 ? (
              all_email.map((email, index) => {
                return index === 0 ? (
                  <FormControlLabel
                    className="common_font"
                    control={<Checkbox defaultChecked />}
                    label={email.contact_details}
                    value={email.contact_details}
                    onChange={handleCheckboxChange}
                  />
                ) : (
                  <FormControlLabel
                    className="common_font"
                    control={<Checkbox />}
                    label={email.contact_details}
                    value={email.contact_details}
                    onChange={handleCheckboxChange}
                  />
                );
              })
            ) : (
              <Typography
                className="common_font"
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  padding: "5px",
                  backgroundColor: "#fff2d3",
                  borderRadius: "8px",
                  border: "1px solid #e5a716",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                <WarningIcon color="warning" /> &nbsp;Please add an email to
                this Customer Profile.
              </Typography>
            )}
          </div>
        </div>
        <div>
          <SendQuotation data={quotation_details} />
        </div>
      </div>
    </Box>
  );

  return (
    <Card
      id="landing_card"
      className="landing_card common_card_bs"
      style={{ marginBottom: "20px" }}
    >
      {" "}
      <Snackbar
        open={updateAlert}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-alert"
      >
        <Alert
          onClose={handleAlertClose}
          severity={snackseverity}
          sx={{ width: "100%" }}
        >
          {updateAlertMsg}
        </Alert>
      </Snackbar>
      <CardContent className="customer_details_card">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "30px",
            paddingLeft: "20px",
            paddingTop: "10px",
          }}
        >
          <div>
            {/* <img src={SiteLogo} alt="site-logo" />
            <br />   */}
            <img src={SiteLogo2} alt="site-logo" className="quatation-details-pen-img" />
          </div>
          <div>
            {quotation_details[0]?.quotation_status === "DRAFT" ? (
              <IconButton
                aria-label="delete"
                size="large"
                onClick={editQuotation}
              >
                <EditIcon sx={{ color: "#38a5ff" }} />
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton
              aria-label="delete"
              size="large"
              onClick={viewQuotationPdf}
            >
              <VisibilityIcon
                id="sales-details-quotation-view-icon"
                sx={{ color: "#38a5ff" }}
              />
            </IconButton>
            <PDFDownloadLink
              document={<PdfLayout data={commonData} />}
              fileName="Quotation.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <IconButton aria-label="delete" size="large" disabled>
                    <CloudDownloadIcon sx={{ color: "#38a5ff" }} />
                  </IconButton>
                ) : (
                  <IconButton aria-label="delete" size="large">
                    <CloudDownloadIcon sx={{ color: "#38a5ff" }} />
                  </IconButton>
                )
              }
            </PDFDownloadLink>
            <IconButton aria-label="upload" size="large">
              <SendIcon
                sx={{ color: "#38a5ff" }}
                onClick={toggleDrawer("right", true)}
              />
            </IconButton>
            <SwipeableDrawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
            >
              {list("right")}
            </SwipeableDrawer>
            {/* <IconButton aria-label="delete" size="large">
            <PrintIcon />
          </IconButton> */}
            {/* <IconButton aria-label="delete" size="large">
            <ShareIcon />
          </IconButton> */}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              marginTop: "20px",
              paddingLeft: "20px",
            }}
          >
            <Typography
              variant="h2"
              className="common_font"
              sx={{ fontWeight: "bold" }}
            >
              {org_details[0]?.location_name}{" "}
              <Tooltip title={org_details[0]?.site_description}>
                <InfoIcon
                  fontSize="small"
                  style={{ cursor: "pointer", opacity: "0.3" }}
                />
              </Tooltip>
            </Typography>
            <Typography
              variant="p"
              className="common_font"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ color: "#38a5ff" }} />
              {org_details[0]?.location_address}
            </Typography>
          </div>

          <div
            style={{
              marginTop: "20px",
              marginRight: "30px",
              textAlign: "right",
            }}
          >
            <Chip
              label={quotation_details[0]?.quotation_status}
              sx={
                quotation_details[0]?.quotation_status == "DRAFT"
                  ? { fontWeight: "700" }
                  : quotation_details[0]?.quotation_status ==
                    "OUT_FOR_ACCEPTANCE"
                    ? {
                      backgroundColor: "#fff1d6",
                      color: "#c88c32",
                      fontWeight: "700",
                    }
                    : quotation_details[0]?.quotation_status == "COMPLETED"
                      ? {
                        backgroundColor: "#dbf6e5 !important",
                        color: "#118d57",
                        fontWeight: "700",
                      }
                      : {}
              }
              className="common_font"
            />
            <Typography
              variant="h7"
              component="p"
              className="common_font"
              style={{ marginTop: "10px" }}
            >
              <b>Quotation ID : </b>
              {quotation_details[0]?.quotation_display_id}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              paddingLeft: "20px",
            }}
          >
            <div>
              <Typography variant="h7" component="p" className="common_font">
                <b>Quotation From : </b>
              </Typography>

              <Typography
                variant="h7"
                style={{ marginTop: "10px" }}
                component="p"
                className="common_font"
              >
                {quotation_details[0]?.sales_rep_name}{" "}
                <span style={{ fontSize: "13px" }}>(Sales Rep)</span>
              </Typography>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h7" component="p" className="common_font">
                <b>Shipping Address :</b>{" "}
                <Tooltip
                  title={quotation_details[0]?.shipping_address_type || "Home"}
                >
                  <InfoIcon
                    fontSize="small"
                    style={{ cursor: "pointer", opacity: "0.3" }}
                  />
                </Tooltip>
                <br />
                <span>
                  Street :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.shipping_street_address}
                  </b>
                  <br />
                  City :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.shipping_city}
                  </b>
                  <br />
                  State / Zip :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.shipping_state}
                    {" / "}
                    {quotation_details[0]?.shipping_zip_code}
                  </b>
                  <br />
                  Country :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.shipping_country}
                  </b>
                </span>
              </Typography>
              {contact_number != null ? (
                <Typography variant="h7" component="p" className="common_font">
                  Phone :{" "}
                  <b className="font-color-sales-details">{contact_number}</b>
                </Typography>
              ) : null}
              {contact_email != null ? (
                <Typography variant="h7" component="p" className="common_font">
                  Email :{" "}
                  <b className="font-color-sales-details">{contact_email}</b>
                </Typography>
              ) : null}
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h7" component="p" className="common_font">
                <b>Quotation Created At : </b>
              </Typography>

              <Typography
                variant="h7"
                style={{ marginTop: "10px" }}
                component="p"
                className="common_font"
              >
                <span>
                  {quotation_details[0]
                    ? displaytimestampasperclient(
                      quotation_details[0].quotation_date
                    )
                    : ""}
                </span>
              </Typography>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              paddingLeft: "20px",
            }}
          >
            <div>
              <Typography variant="h7" component="p" className="common_font">
                <b>Quotation To : </b>
              </Typography>

              <Typography
                variant="h7"
                style={{ marginTop: "10px" }}
                component="p"
                className="common_font"
              >
                {quotation_details[0]?.customer_first_name}{" "}
                {quotation_details[0]?.customer_last_name}{" "}
                <span style={{ fontSize: "13px" }}>
                  ({quotation_details[0]?.person_type})
                </span>
              </Typography>
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <Typography variant="h7" component="p" className="common_font">
                <b>Billing Address :</b>{" "}
                <Tooltip
                  title={quotation_details[0]?.billing_address_type || "Home"}
                >
                  <InfoIcon
                    fontSize="small"
                    style={{ cursor: "pointer", opacity: "0.3" }}
                  />
                </Tooltip>{" "}
                <br />
                <span>
                  Street :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.billing_street_address}
                  </b>
                  <br />
                  City :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.billing_city}
                  </b>
                  <br />
                  State / Zip :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.billing_state}
                    {" / "}
                    {quotation_details[0]?.billing_zip_code}
                  </b>
                  <br />
                  Country :{" "}
                  <b className="font-color-sales-details">
                    {quotation_details[0]?.billing_country}
                  </b>
                </span>
              </Typography>
            </div>
          </div>
          <div></div>
          <div style={{ marginTop: "20px", paddingRight: "30px" }}>
            <QRCode value={qrData} size={100} level={"H"} />
          </div>
        </div>
      </CardContent>
      <Paper className="paper" style={{ padding: "40px" }}>
        <TableContainer className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead className="table-head">
              <TableRow className="log_table_table_row  head-table-row common_header_bg ">
                <TableCell className="common_font log_table_table_head head-table-cell">
                  <b>SKU</b>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  <b>Description</b>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell">
                  <b>Qty</b>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell"
                  style={{ textAlign: "right" }}
                >
                  <b>Unit Price</b>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell-message"
                  style={{ textAlign: "right" }}
                >
                  <b>Total</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotation_product_list &&
                quotation_product_list.map((item, index) => {
                  const unit_price = Number(item.unit_price).toFixed(2);
                  const item_total_price = Number(
                    item.item_total_price
                  ).toFixed(2);
                  return (
                    <StyledTableRow key={index} className="table-row-even">
                      <TableCell className="body-table-cell common_font">
                        <Chip
                          label={item.sku}
                          style={{ fontWeight: "700" }}
                          className="common_font"
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        <b className="font-color-sales-details">
                          {item.pen_name}
                        </b>
                        <br />
                        {item.special_features.length > 50
                          ? `${item.special_features.slice(0, 60)}...`
                          : item.special_features}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {item.quantity}
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        style={{ textAlign: "right" }}
                      >
                        $ {unit_price}
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        style={{ textAlign: "right" }}
                      >
                        $ {item_total_price}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableRow>
              <TableCell
                colSpan={3}
                className="body-table-cell common_font table-cell-without-border-bottom"
              />
              <TableCell
                colSpan={1}
                className="body-table-cell common_font table-cell-without-border-bottom"
                align="right"
              >
                <b className="font-color-sales-details">Sub Total</b>
              </TableCell>
              <TableCell
                align="right"
                className="body-table-cell common_font table-cell-without-border-bottom"
              >
                $ {Number(quotation_details[0]?.total_amount).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={3}
                className="body-table-cell common_font table-cell-without-border-bottom"
              />
              <TableCell
                colSpan={1}
                align="right"
                className="body-table-cell common_font table-cell-without-border-bottom"
              >
                <b className="font-color-sales-details">Taxes</b>
              </TableCell>
              <TableCell
                align="right"
                className="body-table-cell common_font table-cell-without-border-bottom"
              >
                $ {Number(quotation_details[0]?.tax_amount).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={3}
                className="body-table-cell common_font table-cell-without-border-bottom"
              />
              <TableCell
                colSpan={1}
                align="right"
                className="body-table-cell common_font"
              >
                <b className="font-color-sales-details">Discount</b>
              </TableCell>
              <TableCell align="right" className="body-table-cell common_font">
                - $ {Number(quotation_details[0]?.discount_amount).toFixed(2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={3}
                className="body-table-cell common_font table-cell-without-border-bottom"
              />
              <TableCell
                colSpan={1}
                align="right"
                className="body-table-cell common_font table-cell-without-border-bottom"
              >
                <b
                  style={{
                    fontSize: "18px",
                    textDecorationLine: "underline",
                    textDecorationStyle: "double",
                  }}
                >
                  Grand Total
                </b>
              </TableCell>
              <TableCell
                align="right"
                className="body-table-cell common_font table-cell-without-border-bottom"
              >
                <b
                  style={{
                    fontSize: "18px",
                    textDecorationLine: "underline",
                    textDecorationStyle: "double",
                  }}
                >
                  $ {Number(quotation_details[0]?.final_amount).toFixed(2)}
                </b>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </Card>
  );
};

export default SalesDetails;

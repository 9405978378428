// Author: sonal

import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Pagination, Typography } from "@mui/material";
import { SALES_ORDER_LIST } from "../../config/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { displaytimestampasperclient } from "../../utils/dateutils.js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import "../../Styles/orders.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";
import SelectLabel from "../Layout/selectlabel";

const moment = require("moment");

export default function SalesOrderList() {
  const salesOrderListRef = React.useRef();

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, "sales_order_table_data.xlsx");
  };

  const exportToPdf = () => {
    const scale = 5; // Increase scale for better resolution
    const dpi = 300;

    html2canvas(salesOrderListRef.current, { dpi: dpi, scale: scale }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "pt", "letter");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("sales-order-list.pdf");
      }
    );
  };

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const { i18n } = useTranslation();

  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const exportOpen = Boolean(exportAnchorEl);
  const exportId = exportOpen ? "simple-popover" : undefined;

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState(false);
  const [createdByNameList, setCreatedByNameList] = useState([]);
  const [customerByNameList, setCustomerByNameList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [originalQuotationList, setOriginalQuotationList] = useState([]);
  const [defaultCustomerBy, setDefaultCustomerBy] = useState("All");
  const [defaultCreatedBy, setDefaultCreatedBy] = useState("All");
  const [defaultStatusBy, setDefaultStatusBy] = useState("All");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salesOrderList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const formatclient = (serverTimestamp) => {
    return moment(serverTimestamp).format("DD MMM YYYY");
  };

  function formattimeStampMoment(time) {
    const providedDate = time;
    const timeAgo = moment(providedDate).fromNow();
    return timeAgo;
  }

  const salesOrderListFun = async () => {
    await fetch(SALES_ORDER_LIST, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "appplication/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setOriginalQuotationList(data.data.sales_order_list);
          setSalesOrderList(data.data.sales_order_list);
        }
      });
  };

  useEffect(() => {
    salesOrderListFun();
  }, []);

  useEffect(() => {
    if (salesOrderList || filterValue) {
      let getCustomerByName = [];
      let getCreatedByName = [];
      let getStatus = [];
      console.log(originalQuotationList);
      originalQuotationList.map((ele) => {
        getCustomerByName.push(ele.customer_name);
        getCreatedByName.push(ele.created_by_name);
        getStatus.push(ele.delivery_status);
      });

      const uniqueCustomerByNameArray = [...new Set(getCustomerByName)];
      uniqueCustomerByNameArray.unshift("All");

      const uniqueCreatedByNameArray = [...new Set(getCreatedByName)];
      uniqueCreatedByNameArray.unshift("All");

      const uniqueStatusArray = [...new Set(getStatus)];
      uniqueStatusArray.unshift("All");

      setCustomerByNameList(uniqueCustomerByNameArray);
      setCreatedByNameList(uniqueCreatedByNameArray);
      setStatusList(uniqueStatusArray);
      // setOriginalStatusList(uniqueStatusArray)
    }
  }, [salesOrderList, filterValue]);

  const filterByCustomerNameFun = (e) => {
    setDefaultCustomerBy(e.target.value);
    const searchTerm = e.target.value.trim().toLowerCase();

    if (!searchTerm) {
      setSalesOrderList([...originalQuotationList]);
      return;
    }

    if (e.target.value === "All") {
      setSalesOrderList(originalQuotationList);
    } else {
      const filteredQuotations = originalQuotationList.filter((val) =>
        val.customer_name.toLowerCase().includes(searchTerm)
      );
      setSalesOrderList(filteredQuotations);
    }
  };

  const filterByCreatedNameFun = (e) => {
    setDefaultCreatedBy(e.target.value);
    const searchTerm = e.target.value.trim().toLowerCase();

    if (!searchTerm) {
      setSalesOrderList([...originalQuotationList]);
      return;
    }
    console.log(e.target.value);

    if (e.target.value === "All") {
      setSalesOrderList(originalQuotationList);
    } else {
      const filteredQuotations = originalQuotationList.filter((val) =>
        val.created_by_name.toLowerCase().includes(searchTerm)
      );
      setSalesOrderList(filteredQuotations);
    }
  };

  const filterByStatusFun = (e) => {
    setDefaultStatusBy(e.target.value);
    const searchTerm = e.target.value.trim().toLowerCase();

    if (!searchTerm) {
      setSalesOrderList([...originalQuotationList]);
      return;
    }
    console.log(e.target.value);

    if (e.target.value === "All") {
      setSalesOrderList(originalQuotationList);
    } else {
      const filteredQuotations = originalQuotationList.filter((val) =>
        val.delivery_status.toLowerCase().includes(searchTerm)
      );
      setSalesOrderList(filteredQuotations);
    }
  };
  console.log(lng);
  const navigatSalesOrderDetailsFun = (item) => {
    navigate(
      `/${lng}/sales-order-list/sales-order-details/${item.sales_display_id}`,
      {
        state: {
          item: item,
        },
      }
    );
  };

  const [textContent, setTextContent] = useState("");

  const handleSearchChange = async (e) => {
    setTextContent(e.target.value);
    console.log(e.target.value);
    if (e.target.value === "") {
      salesOrderListFun();
    } else {
      const filterSalesOrder = salesOrderList.filter(
        (item) =>
          item.created_by_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.customer_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.delivery_status
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.sales_display_id
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      console.log(filterSalesOrder);
      setSalesOrderList(filterSalesOrder);
    }
  };

  const handleChildData = (data) => {
    setItemsPerPage(data);
  };

  console.log(salesOrderList);

  return (
    <div>
      <Popover
        id={exportId}
        open={exportOpen}
        anchorEl={exportAnchorEl}
        onClose={handleExportClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          onClick={() => {
            exportToExcel(currentItems);
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 0px 15px" }}
        >
          Download as xlsx
        </Typography>
        <Typography
          onClick={() => {
            exportToPdf();
            handleExportClose();
          }}
          className="all_box_hover"
          sx={{ margin: "10px 15px 10px 15px" }}
        >
          Download as Pdf
        </Typography>
      </Popover>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <Paper
          component="form"
          className="search_document_paper common_card_bs_sub"
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Order"
            className="common_font"
            inputProps={{ "aria-label": "Search Quotation" }}
            onChange={handleSearchChange}
            value={textContent}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <SelectLabel
          length={salesOrderList.length}
          onSendData={handleChildData}
        />
      </div>
      <Box
        sx={{ marginBottom: "30px" }}
        className="customer-details-quotation-form-control-box"
      >
        <FormControl
          // sx={{ backgroundColor: "white" }}
          id="sales-order-list-customer-form-control"
          className="customer-details-quotations-form-control"
        >
          <InputLabel className="common_font" id="brands-label">
            Customer{" "}
          </InputLabel>
          <Select
            labelId="brands-label"
            id="brands-select"
            label="Customer"
            value={defaultCustomerBy}
            onChange={(e) => {
              filterByCustomerNameFun(e);
              setFilterValue(true);
            }}
            InputLabelProps={{
              className: "common_font",
            }}
            inputProps={{
              className: "common_font",
            }}
          >
            {customerByNameList.map((cbnl, index) => (
              <MenuItem
                sx={{ marginTop: "10px" }}
                id={`brands-menu-item-${index}`}
                key={index}
                value={cbnl}
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
              >
                <Typography className="common_font"> {cbnl}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ marginLeft: "50px" }}
          id="sales-order-list-created-by-form-control"
          className="customer-details-quotations-form-control"
        >
          <InputLabel className="common_font" id="brands-label">
            Created By
          </InputLabel>
          <Select
            labelId="brands-label"
            id="brands-select"
            label="Created By"
            value={defaultCreatedBy}
            onChange={(e) => {
              filterByCreatedNameFun(e);
              setFilterValue(true);
            }}
            InputLabelProps={{
              className: "common_font",
            }}
            inputProps={{
              className: "common_font",
            }}
          >
            {createdByNameList.map((cbnl, index) => (
              <MenuItem
                sx={{ marginTop: "10px" }}
                id={`brands-menu-item-${index}`}
                key={index}
                value={cbnl}
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
              >
                <Typography className="common_font"> {cbnl}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ marginLeft: "50px" }}
          id="sales-order-list-status-form-control"
          className="customer-details-quotations-form-control"
        >
          <InputLabel className="common_font" id="brands-label">
            Status
          </InputLabel>
          <Select
            labelId="brands-label"
            id="brands-select"
            label="Status"
            value={defaultStatusBy}
            onChange={(e) => {
              filterByStatusFun(e);
              setFilterValue(true);
            }}
            InputLabelProps={{
              className: "common_font",
            }}
            inputProps={{
              className: "common_font",
            }}
          >
            {statusList.map((sl, index) => (
              <MenuItem
                sx={{ marginTop: "10px" }}
                id={`brands-menu-item-${index}`}
                key={index}
                value={sl}
                InputLabelProps={{
                  className: "common_font",
                }}
                inputProps={{
                  className: "common_font",
                }}
              >
                <Typography className="common_font"> {sl}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Paper
        sx={{ paddingBottom: "20px !imprtant" }}
        className="paper common_card_bs"
      >
        <Box className="sales-order-list-export-box">
          <Button
            className="common_font"
            onClick={handleExportClick}
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        </Box>

        <TableContainer ref={salesOrderListRef} className="table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead className="table-head">
              <TableRow
                className="log_table_table_row  head-table-row common_header_bg"
                style={{ cursor: "pointer" }}
              >
                <TableCell className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom table-cell-sort-order">
                  <Box className="table-cell-sort-order-box">Sales ID </Box>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom">
                  <Box className="table-cell-sort-order-box">Customer </Box>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell table-cell-sort-order table-cell-without-border-bottom">
                  <Box className="table-cell-sort-order-box">Created By </Box>
                </TableCell>

                <TableCell className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom">
                  <Box className="table-cell-sort-order-box">Created </Box>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom">
                  <Box className="table-cell-sort-order-box">No. of Items </Box>
                </TableCell>
                <TableCell className="common_font log_table_table_head head-table-cell-message table-cell-sort-order table-cell-without-border-bottom">
                  <Box className="table-cell-sort-order-box">Status </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  sx={{ textAlign: "right !important" }}
                  align="right"
                >
                  <Box
                    className="table-cell-sort-order-box"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    Amount{" "}
                  </Box>
                </TableCell>
                <TableCell
                  className="common_font log_table_table_head head-table-cell table-cell-without-border-bottom"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems &&
                currentItems.map((item, index) => {
                  console.log(item);
                  return (
                    <TableRow
                      onClick={() => {
                        navigatSalesOrderDetailsFun(item);
                      }}
                      key={index}
                      className={index % 2 === 0 ? 'table-row-even product-list-alternate-table all_box_hover' : 'table-row-even all_box_hover'}
                    >
                      <TableCell className="body-table-cell common_font">
                        <Chip
                          label={item.sales_display_id}
                          style={{ fontWeight: "700" }}
                          className="common_font"
                        />
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {item.customer_name}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {item.created_by_name}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {displaytimestampasperclient(item.sale_date)}
                      </TableCell>
                      <TableCell className="body-table-cell common_font">
                        {item.total_item}
                      </TableCell>

                      <TableCell className="body-table-cell common_font">
                        {item.delivery_status === "Confirmed" ? (
                          <Typography className="sales-order-list-status-txt common_font small-txt">
                            {item.delivery_status}
                          </Typography>
                        ) : (
                          <Typography className="sales-order-list-status-otr-txt common_font small-txt">
                            {item.delivery_status}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        sx={{ textAlign: "right" }}
                      >
                        <b>${item.total_amount}</b>
                      </TableCell>
                      <TableCell
                        className="body-table-cell common_font"
                        sx={{ textAlign: "center" }}
                      >
                        <VisibilityIcon
                          id={`order-list-eye-icon-${index}`}
                          // onClick={() => {
                          //   navigatSalesOrderDetailsFun(item);
                          // }}
                          className="all_box_hover"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{ marginBottom: "20px !important" }}
          className="pagination-box"
        >
          <Pagination
            id="product-list-pagination"
            className="pagination common_font"
            count={Math.ceil(salesOrderList.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            siblingCount={1}
            boundaryCount={1}
          />
        </Box>
      </Paper>
    </div>
  );
}

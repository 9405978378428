import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import Store from "./components/Redux/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIENT_ID } from "./config/config";
import './i18n'

import { LoadScript } from '@react-google-maps/api';

const googleMapsApiKey = 'AIzaSyBwNIm4NF2Dh-T24xsfT0xgiYtRA5OuAgI';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <React.StrictMode>
        <React.Suspense fallback="loading...">
          <Provider store={Store}>
            <App />
          </Provider>
        </React.Suspense>
      </React.StrictMode>
    </LoadScript>
  </GoogleOAuthProvider>
);

import React, { } from "react";
import "../../index.css";
import '../../Styles/dashboard.css'
// import Carousel from "./DashboardCarousel";
import ProductsTypeList from "../Products/ProductsTypeList";
import AllProductsList from "../Products/AllProductsList";



const Dashboard = () => {

    return (
        <div >
            {/* <Carousel /> */}

            <div className="product_list_main_div">
                <ProductsTypeList />
            </div>

            <div>
                <AllProductsList />
            </div>
        </div>
    );
};

export default Dashboard;

// Author: sonal

/** @format */

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Drawer } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
// components
import Scrollbar from "./scrollbar";
import NavSection from "./nav-section";
//
// import SidebarNavConfig from "./sidebar_config";
import SideBarImage from "../../assets/img/sidebar.jpg";
import { Typography } from "@mui/material";

import logo from "../../assets/img/bodex_logo.png";
import bsa from "../../assets/img/BSA-11.png";
import bsas from "../../assets/img/BSA-22.png";
import betsy from "../../assets/img/betsy.png";
import "../../index.css";
import { SIGNOUT, GET_CUSTOMER_PROFILE_IMAGE } from "../../config/config";
import UserImage from "../../assets/img/user.jpg";
import legoIcon from "../../assets/img/lego.jpg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GridViewIcon from "@mui/icons-material/GridView";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Sidebar({ openNav, onCloseNav, propsFun }) {
  const { t } = useTranslation();
  const {
    das,
    pro,
    cus,
    qao,
    use,
    rpts,
    srcpro,
    salesTitle,
    ordersTitle,
    shipmentTitle,
    adminTitle,
    userTitle,
    logoutTitle,
    rtn,
    bts,
    userLogsTitle,
    manageProductTitle,
    productLogsTitle,
    buildInfoTitle,
    listOfModulesTitle,
  } = t("sidebar");
  console.log(t.language);

  const reloadUserImage = useSelector((state) => state.getUserProfileRedux);

  const { i18n } = useTranslation();

  const [lng, setLng] = useState("");

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const siteName = localStorage.getItem("site_name");

  const SidebarNavConfig = [
    {
      title: das,
      path: `/${lng}/dashboard`,
      icon: "fa-solid fa-gauge",
    },
    {
      title: pro,
      path: `/${lng}/products-list`,
      icon: "fa-solid fa-store",
    },
    {
      title: cus,
      path: `/${lng}/manage-customer`,
      icon: "fa-solid fa-user",
    },

    {
      title: salesTitle,
      path: `${lng}/quotes`,
      icon: "fa-solid fa-file-invoice-dollar",
      info: [
        {
          title: qao,
          path: `${lng}/quotes`,
          icon: "fa-solid fa-list",
        },

        {
          title: ordersTitle,
          path: `${lng}/sales-order-list`,
          icon: "fa-solid fa-box",
        },
      ],
    },
    {
      title: adminTitle,
      path: "/manage-users",
      icon: "fa-solid fa-toolbox",
      info: [
        {
          title: rpts,
          path: `${lng}/reports`,
          icon: "fa-solid fa-chart-simple",
        },
               {
          title: manageProductTitle,
          path: `/${lng}/manage-products`,
          icon: "fa-brands fa-product-hunt",
        },
        {
          title: productLogsTitle,
          path: `/${lng}/product-logs`,
          icon: "fa-brands fa-product-hunt",
        },
        {
          title: userTitle,
          path: `${lng}/manage-users`,
          icon: "fa-solid fa-users",
        },

        {
          title: userLogsTitle,
          path: `/${lng}/user-logs`,
          icon: "fa-regular fa-user",
        },
       
        {
          title: buildInfoTitle,
          path: `/build-info`,
          icon: "fa-solid fa-info",
        },
        {
          title: listOfModulesTitle,
          path: `/list-of-modules`,
          img: GridViewIcon,
        },
        {
          title: "",
          path: "",
          img: "",
        },
      ],
    },
    {
      title: shipmentTitle,
      path: `${lng}/shipment`,
      icon: "fa-solid fa-truck-fast",
    },

    {
      title: rtn,
      path: `${lng}/return-products`,
      icon: "fa-solid fa-arrow-rotate-left",
    },
  ];

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const dashboard = () => {
    navigate(`/${lng}/dashboard`);
  };
  const userName = localStorage.getItem("first_name");

  const handleLogout = () => {
    window.localStorage.clear();
    fetch(SIGNOUT, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        if (data?.responseData?.error === false) {
          navigate("/login");
        }
      });
  };

  //customer profile image
  const [imageUrl, setProfileImageUrl] = useState("");
  const loggedInUser = localStorage.getItem("user");

  let foundUser = JSON.parse(loggedInUser);

  const person_id = foundUser?.person_id;
  const getUserProfile = () => {
    fetch(GET_CUSTOMER_PROFILE_IMAGE, {
      method: "POST",
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
        "Content-Type": "application/json", 
        token: "",
      },
      body: JSON.stringify({ person_id: person_id }), 
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success === true) {
          setProfileImageUrl(`${data.url}?timestamp=${Date.now()}`);
        } else {
          setProfileImageUrl("");
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  propsFun(imageUrl);

  useEffect(() => {
    getUserProfile();
  }, []);


  const userType = localStorage.getItem("usertype");

  const user_org = localStorage.getItem("user_org");
  const orgnizationUser = JSON.parse(user_org);

  console.log(orgnizationUser);

  const [updateCompanyDrawer, setUpdateCompanyDrawer] = useState("");

  useEffect(() => {
    if (siteName === "Goldspot") {
      setUpdateCompanyDrawer("company-bdx-drawer");
    } else if (siteName === "Pen House") {
      setUpdateCompanyDrawer("company-bdx-drawer");
    } else {
      setUpdateCompanyDrawer("drawer_main_style");
    }
  }, [siteName]);

  // cSpell:ignore simplebar,RecorDEX
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        "@media (max-width: 375px )": {
          height: 0.5, 
        },
      }}
    >
      <Box className="sidebar-nav-section-scroller">
        <NavSection data={SidebarNavConfig} />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="Sidebar"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              backgroundRepeat: "no-repeat",
              position: "right",
              borderRightStyle: "dashed",
            },
            className: `${updateCompanyDrawer}`,
          }}
        >
          <Box
            sx={{
              px: 2.5,
              py: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            unspecified
          >
            <img
              src={bsas}
              alt="BSa Logo"
              className="sidebar_logo_img-1"
              onClick={dashboard}
            />

            <Typography
              sx={{ color: "white" }}
              className="common_font large-txt"
            >
              Baseline SaaS App
            </Typography>

            <Box className="sidebar_profile_box">
              <img
                src={
                  imageUrl !== ""
                    ? `${imageUrl}?timestamp=${Date.now()}`
                    : UserImage
                }
                style={{ width: "60px", width: "60px", borderRadius: "50%" }}
                alt=""
              />
            </Box>
            <Typography className="common_font user-type">
              {userType === "User" ? "Sales-Rep" : userType}
            </Typography>
          </Box>
          {renderContent}
          <Box
            onClick={handleLogout}
            sx={{
              position: "fixed",
              bottom: 0,
              width: NAV_WIDTH,
              zIndex: 1300,
              backgroundColor: "#3A3B3D",
              color: "white",
              display: "flex",
              cursor: "pointer",
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginLeft: "35px",
                gap: "10px",
                paddingTop: "10px",
              }}
            >
              <i className="sidebar_logout_icon fa-solid fa-right-from-bracket"></i>
              <Typography className="sidebar_logout common_font">
                {logoutTitle}
              </Typography>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              backgroundRepeat: "no-repeat",
            },
            className: `${updateCompanyDrawer}`,
          }}
        >
          <Box
            sx={{
              px: 2.5,
              py: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            unspecified
          >
            <img
              src={bsas}
              alt="BSa Logo"
              className="sidebar_logo_img-1"
              onClick={dashboard}
            />

            <Typography
              sx={{ color: "white" }}
              className="common_font large-txt"
            >
              Baseline SaaS App
            </Typography>

            <Box className="sidebar_profile_box">
              <img
                src={
                  imageUrl !== ""
                    ? `${imageUrl}?timestamp=${Date.now()}`
                    : UserImage
                }
                style={{ width: "60px", width: "60px", borderRadius: "50%" }}
                alt=""
              />
            </Box>
            <Typography className="common_font user-type">
              {userType === "User" ? "Sales-Rep" : userType}
            </Typography>
          </Box>
          {renderContent}
          <Box
            onClick={handleLogout}
            sx={{
              position: "fixed",
              bottom: 0,
              width: NAV_WIDTH,
              zIndex: 1300,
              backgroundColor: "#3A3B3D",
              color: "white",
              display: "flex",
              cursor: "pointer",
              paddingBottom: "20px",
            }}
          >
            <Box sx={{ display: "flex", marginLeft: "35px", gap: "10px" }}>
              <i className="sidebar_logout_icon fa-solid fa-right-from-bracket"></i>
              <Typography className="sidebar_logout common_font">
                {logoutTitle}
              </Typography>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_PRODUCTS_IMAGE,
  GET_ALL_PRODUCTS_IMAGE,
  TOP3_PRODUCT,
} from "../../config/config";
import {
  InstantSearch,
  SearchBox,
  Hits,
  VoiceSearch,
  RefinementList,
  Pagination,
  Highlight,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Stack,
  colors,
  Tooltip,
  Chip,
  Box,
  Typography,
  useTheme,
  Button,
  Checkbox,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { FacetDrawer } from "./FacetDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import firstImg from "../../assets/img/carousel/1.jpg";
import { dark } from "@mui/material/styles/createPalette";
import { Padding } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { connectHighlight } from "react-instantsearch-dom";

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          <mark
            key={index}
            style={{ backgroundColor: "yellow", fontStyle: "normal" }}
          >
            {part.value}
          </mark>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </span>
  );
});

const searchClient = algoliasearch(
  "SPJGXRC95C",
  "dc4a97ddf3e23063ec164759613195ef"
);
console.log(searchClient);

const RemoveButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} size="small">
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export const SearchProducts = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const theme = useTheme();
  const [productImages, setProductImages] = useState([]);
  const [productList, setProductList] = useState([]);
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    getAllProductImage();
  }, []);

  const getAllProductImage = async () => {
    await fetch(GET_ALL_PRODUCTS_IMAGE, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success == true) {
          console.log(data.data);
          setProductImages(data.data);
        }
      });
  };
  const { i18n } = useTranslation();
  const [lng, setLng] = useState("");
  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  // console.log(productImages);

  function Hit({ hit }) {
    let prices = hit.product_pricing_usd.split(",").map((price) => {
      price = price.trim();
      let parsedPrice = parseFloat(price);
      if (!isNaN(parsedPrice)) {
        return parsedPrice.toFixed(2);
      } else {
        return "Invalid Price";
      }
    });

    const { i18n } = useTranslation();

    const [lng, setLng] = useState("");
    useEffect(() => {
      setLng(i18n.language);
    }, [i18n.language]);

    // Find the index of productImages where product_id matches hit.product_id
    const index = productImages.findIndex(
      (image) => image.product_id === hit.product_id
    );

    // Set imageUrl based on the dynamic index or fallback to firstImg
    const imageUrl =
      index !== -1 ? productImages[index].metadata[0].url : firstImg;

    const CheckboxContainer = styled("div")({
      position: "absolute",
      top: 0,
      right: 0,
      padding: "8px",
    });
    const StyledPaper = styled(Paper)(({ theme }) => ({
      position: "relative",
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    }));

    const selectProduct = async (hit) => {
      if (productList.length > 0) {
        var checkProductPresence = productList.filter(
          (x) => x.product_id === hit.product_id
        );
        if (checkProductPresence.length > 0) {
          var updatedProductList = productList.filter(
            (x) => x.product_id !== hit.product_id
          );
          setProductList([...updatedProductList]);
        } else {
          setProductList([...productList, hit]);
        }
      } else {
        setProductList([...productList, hit]);
      }
    };

    console.log(productList);

    return (
      <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
        <StyledPaper
          elevation={3}
          className="product-paper common_card_bs"
        // onClick={() =>
        //   navigate(`/${lng}/products-list/product-description-page`, {
        //     state: {
        //       productId: hit.product_id, // Pass hit.product_id as state
        //     },
        //   })
        // }
        >
          <CheckboxContainer>
            <Checkbox
              onClick={() => selectProduct(hit)}
              checked={productList.some((x) => x.product_id === hit.product_id)}
            />
          </CheckboxContainer>
          {index !== -1 ? (
            <img
              className="product_search_img show-below-600"
              src={imageUrl}
              alt=""
            />
          ) : (
            <img
              className="product_search_img show-below-600"
              src={firstImg}
              alt=""
            />
          )}

          <Stack direction="row" spacing={5} alignItems="flex-start">
            {index !== -1 ? (
              <img
                className="product_search_img hide-on-small"
                src={imageUrl}
                alt=""
              />
            ) : (
              <img
                className="product_search_img hide-on-small"
                src={firstImg}
                alt=""
              />
            )}

            <Stack direction="column" spacing={1} alignItems="flex-end">
              <h2>
                <span className="title-text pen-name">Type</span>
              </h2>
              <p>
                <span className="bold-text pen-name">Brand</span>
              </p>
              <p>
                <span className="bold-text pen-name">Name</span>
              </p>
              <p>
                <span className="bold-text pen-name">SKU</span>
              </p>
              <p>
                <span className="bold-text pen-name">Price</span>
              </p>
              <p>
                <span className="bold-text pen-name">Color</span>
              </p>
              <p>
                <span className="bold-text pen-name">Material Type</span>
              </p>
              <p>
                <span className="bold-text pen-name">Ink Type</span>
              </p>
              <br />
            </Stack>

            {/* Stack for variables aligned to the left */}
            <Stack direction="column" spacing={1} alignItems="flex-start">
              <h2 className="pen-name">{hit.pen_type}</h2>
              <p className="pen-name">
                {" "}
                <CustomHighlight attribute="brand_name" hit={hit} />
              </p>
              <p className="pen-name">
                <CustomHighlight attribute="pen_name" hit={hit} />
              </p>
              <p className="pen-name">
                <CustomHighlight attribute="sku" hit={hit} />
              </p>
              <p className="pen-name">${prices}</p>
              <p className="pen-name">
                {hit.colours
                  .split(",")
                  .slice(0, 10)
                  .map((name, index) => (
                    <Tooltip title={name} key={index}>
                      <Chip
                        size="small"
                        className="colors"
                        style={{
                          background: `${name}`,
                        }}
                      />
                    </Tooltip>
                  ))}
              </p>
              <p className="pen-name">{hit.material_type}</p>
              <p className="pen-name">{hit.ink_type}</p>
            </Stack>
          </Stack>
          {/* </div> */}
        </StyledPaper>
      </Grid>
    );
  }

  const parseBrandName = (brand) => {
    const [brandName, brandNumber] = brand.split(/(\d+)/);
    return (
      <span>
        {brandName} <span>{brandNumber}</span>
      </span>
    );
  };

  const matches = useMediaQuery("(min-width:600px)"); // Check if screen width is at least 600px

  // // Sort function to sort hits alphabetically based on brand name
  // const sortByBrand = (a, b) => {
  //   const brandA = a.brand_name.toUpperCase();
  //   const brandB = b.brand_name.toUpperCase();

  //   if (brandA < brandB) {
  //     return -1;
  //   }
  //   if (brandA > brandB) {
  //     return 1;
  //   }
  //   return 0;
  // };

  const [top3Products, setTop3Details] = useState([]);
  useEffect(() => {
    const fetchTop3Products = async () => {
      try {
        const response = await fetch(TOP3_PRODUCT, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            token: token, // Assuming token is defined somewhere
          },
        });
        const data = await response.json();
        if (data.success === true) {
          console.log("Response", data.data.RecommendedProductList);
          setTop3Details(data.data.RecommendedProductList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTop3Products();
  }, []);

  const handleClick = (productId) => {
    navigate(`/${lng}/products-list/product-description-page`, {
      state: {
        productId: productId,
        showDeleteIcon: false,
      },
    });
  };

  return (
    <div align="center">
      <div align="center">
        <Typography
          className="common_font"
          variant="h3"
          style={{ textAlign: "center" }}
        >
          Top 3 Selling Products
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          style={{ marginTop: "20px" }}
        >
          {top3Products.map((product, index) => (
            <Paper
              key={index}
              className="top_products_card"
              style={{
                padding: "10px",
                maxWidth: "180px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                cursor: "pointer", // Add cursor pointer to indicate it's clickable
              }}
              onClick={() => handleClick(product.product_id)}
            >
              <Stack direction="column" spacing={1} alignItems="flex-start">
                <Typography
                  className="common_font top3-product-name"
                  variant="body1"
                >
                  {product.pen_name}
                </Typography>
                <Typography className="common_font sku-border" variant="body1">
                  {product.sku}
                </Typography>
              </Stack>
            </Paper>
          ))}
        </Stack>
      </div>
      <InstantSearch searchClient={searchClient} indexName="bsa_PRODUCTDETAILS">
        {/* SearchBox */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "40%" }}>
            <SearchBox />
          </div>

          <div>
            <div style={{ marginTop: "18px" }}>
              <VoiceSearch />
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            {productList.length > 0 && (
              <Button
                className="common-button"
                sx={{ padding: "5px 15px !important" }}
                onClick={() =>
                  navigate(`/${lng}/quotes`, {
                    state: {
                      productList: productList,
                      addquote: true,
                    },
                  })
                }
              >
                Add To Existing Quote
              </Button>
            )}
          </div>
          <div style={{ marginLeft: "20px" }}>
            {productList.length > 0 && (
              <Button
                className="common-button"
                sx={{ padding: "5px 15px !important" }}
                onClick={() =>
                  navigate(`/${lng}/dashboard/customer-list/customer-details`, {
                    state: {
                      productList: productList,
                    },
                  })
                }
              >
                Create New Quote
              </Button>
            )}
          </div>
        </div>
        <br />

        {/* <Button onClick={getProductsImages}>Show</Button> */}

        {!matches && <FacetDrawer />}
        {/* Grids inside Box */}
        <Box display="flex" flexDirection="row">
          {/* RefinementList Grid */}
          <Box className="facet-container">
            <div align="left" className="">
              <Grid item container>
                {/* Facet Box (Hide on smaller screens) */}
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                >
                  <Box className="facet-box common_card_bs">
                    {/* Content for Facet Box */}
                    <p className="facet-heading brand_top">Brand</p>
                    <div className="facet-div">
                      <RefinementList
                        attribute="brand_name"
                        limit={10} // Set the initial limit
                        showMoreLimit={100} // Set the limit for "Show More" option
                        showMore={true}
                        sortBy={["name:asc"]}
                        transformItems={(items) =>
                          items.map((item) => ({
                            ...item,
                            label: parseBrandName(item.label),
                          }))
                        }
                      />
                    </div>
                    <hr className="facet-hr" />

                    <p className="facet-heading">Pen Type</p>
                    <div className="facet-div">
                      <RefinementList
                        attribute="pen_type"
                        limit={10} // Set the initial limit
                        showMoreLimit={100} // Set the limit for "Show More" option
                        showMore={true}
                        sortBy={["name:asc"]}
                        transformItems={(items) =>
                          items.map((item) => ({
                            ...item,
                            label: parseBrandName(item.label),
                          }))
                        }
                      />
                    </div>
                    <hr className="facet-hr" />

                    <p className="facet-heading">Material Type</p>
                    <div className="facet-div">
                      <RefinementList
                        attribute="material_type"
                        sortBy={["name:asc"]}
                        limit={10} // Set the initial limit
                        showMoreLimit={100} // Set the limit for "Show More" option
                        showMore={true}
                        transformItems={(items) =>
                          items.map((item) => ({
                            ...item,
                            label: parseBrandName(item.label),
                          }))
                        }
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>

          {/* Hits Grid */}
          <Box width="100%" mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={2} md={2} lg={9}>
                <Hits
                  hitComponent={Hit}
                // transformItems={(hits) => hits.sort(sortByBrand)}
                />
              </Grid>
            </Grid>
          </Box>

          {/*  Grid (Right Side) */}
          {productList.length > 0 && (
            <Box className="facet-container">
              <div align="left" className="">
                <Grid item container>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                  >
                    <Box className="select-product-box common_card_bs">
                      {/* Content for Right Facet Box */}
                      <p className="facet-heading brand_top">
                        Selected Product ({productList.length})
                      </p>
                      <hr className="facet-hr" />
                      <ul
                        style={{
                          listStyle: "disc",
                          paddingLeft: "10%",
                          textAlign: "left",
                        }}
                      >
                        {productList.map((product, index) => (
                          <li
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ marginRight: "8px" }}>
                              &#8226; {product.pen_name}
                            </span>
                            <RemoveButton
                              onClick={() => {
                                const updatedProductList = productList.filter(
                                  (p, i) => i !== index
                                );
                                setProductList(updatedProductList);
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          )}
          {/*  Grid (Right Side) Ends*/}
        </Box>

        {/* Pagination */}
        <Pagination className="pagination-list" />
      </InstantSearch>
    </div>
  );
};
